import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import theme from '@org/client-libs-themes';
// import { InLink } from '@org/client-components-core';
// import { styles } from '@org/client-libs-styles';

// https://codesandbox.io/s/elated-sara-27cf0

// let lastY = 0, thisY = 0, directionClass = "";
// let lastY = window.pageYOffset || document.documentElement.scrollTop;
// let top  = window.pageYOffset || document.documentElement.scrollTop;
// let left = window.pageXOffset || document.documentElement.scrollLeft;
// console.log('FadeInSection', 'lastY', lastY, 'top', top, window.scrollY, window);
// let direction = "";

let localStyles = {
  // ...styles,
  fadeInSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    opacity: '0',
    // transform: 'translateY(5vh)',
    visibility: 'hidden',
    transition: 'opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out',
    willChange: 'opacity, transform, visibility',
  },
  // fadeInSectionDn: {
  //   transform: 'translateY(5vh)',
  // },
  // fadeInSectionUp: {
  //   transform: 'translateY(-5vh)',
  // },
  visible: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    opacity: '1',
    transform: 'none',
    visibility: 'visible',
  }
};

const useStyles = makeStyles(localStyles);

export function FadeInSection(props) {
  let classes = useStyles();
  const [ isVisible, setVisible ] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    let isMounted = true;
    // directionClass = clsx(classes.fadeInSection, classes.fadeInSectionDn);

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (isMounted)
          setVisible(entry.isIntersecting);
        // console.log(entry.id, entry.isIntersecting, isVisible);
      });
    });
  
    observer.observe(domRef.current);

    return () => {
      isMounted = false;
      if (domRef.current)
        observer.unobserve(domRef.current);
    };
  }, []);

  // let lastY = window.pageYOffset;
  // let lastY  = window.pageYOffset || document.documentElement.scrollTop;
  // console.log('FadeInSection', 'lastY', lastY, window.scrollY, window);
  // thisY  = window.pageYOffset || document.documentElement.scrollTop;
  // if (thisY > lastY)
  //   directionClass = clsx(classes.fadeInSection, classes.fadeInSectionDn);
  // else if (thisY < lastY)
  //   directionClass = clsx(classes.fadeInSection, classes.fadeInSectionUp);
  // if (thisY !== lastY)
  //   console.log(lastY, thisY, thisY - lastY, directionClass);
  // lastY = thisY;

  return (
    <>
    <div
      // className={`${isVisible ? 'fadeOutSectionVisible' : 'fadeOutSectionHidden'}`}
      // className={isVisible ? clsx(classes.fadeInSection, classes.fadeInSectionDn, classes.visible) : clsx(classes.fadeInSection, classes.fadeInSectionDn)}
      className={isVisible ? clsx(classes.fadeInSection, classes.visible) : clsx(classes.fadeInSection)}
      // className={isVisible ? clsx(directionClass, classes.visible) : directionClass}
      ref={domRef}
      // {...props}
    >
      {props.children}
    </div>
    </>
  );
}

FadeInSection.propTypes = {
  children: PropTypes.any,
};
