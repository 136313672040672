import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import { default as MuiBreadcrumbs } from '@material-ui/core/Breadcrumbs';
// import { InLink } from '@org/client-components-core';
import { styles } from '@org/client-libs-styles';

const useStyles = makeStyles((theme) => ({
  ...styles,
  breadcrumbs: {
    marginBottom: theme.spacing(2),
  },
}));

export function Breadcrumbs(props) {
  let classes = useStyles();

  return(
    <MuiBreadcrumbs aria-label='breadcrumbs' className={classes.breadcrumbs} >
      {/* <InLink color='inherit' title='Home' to='/' />
      <InLink color='inherit' title='Dev' to='/dev' />
      <Typography color='textPrimary' >Components</Typography> */}
      {props.children}
    </MuiBreadcrumbs>
  );
}

Breadcrumbs.propTypes = {
  children: PropTypes.node.isRequired,
};

