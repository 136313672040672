// import React, { useEffect } from "react";
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
import { publicSchema } from '@org/client-graphql';

export function CustomRoute({ component: Component, test, to, ...rest }) {
  // console.log('CustomRoute', 'test:', test, 'to:', to, 'rest:', rest);
  return (
    <Route
      render={props => (
        test
        ? <Component {...props} {...rest} />
        : <Redirect to={to} />
      )}
    />
  );
}

CustomRoute.propTypes = {
  component: PropTypes.oneOfType([ PropTypes.func, PropTypes.shape() ]).isRequired,
  test: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
};

// let groups = [];
let isAuthenticated = false;
let isAuthorized = false;

export function AuthenticatedRoute(props) {
  // if (props.isAuthenticated == null)
  //   console.error('AuthenticatedRoute was not passed isAuthenticated', props);
  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  // console.warn('AuthenticatedRoute', data);
  // if (data) {
  //   isAuthenticated = data.isAuthenticated;
  //   console.log('NewAuthenticatedRoute', isAuthenticated, data);
  //   return (
  //     // <CustomRoute {...props} test={props.isAuthenticated} to='/sign-in' />
  //     <CustomRoute {...props} test={isAuthenticated} to='/sign-in' />
  //   );
  // }
  // return null;
  if (data)
    isAuthenticated = data.isAuthenticated;
  // console.log('NewAuthenticatedRoute', isAuthenticated, data);
  return (
    // <CustomRoute {...props} test={props.isAuthenticated} to='/sign-in' />
    <CustomRoute {...props} test={isAuthenticated} to='/sign-in' />
  );
}

AuthenticatedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export function NotAuthenticatedRoute(props) {
  // console.log('NotAuthenticatedRoute', props);
  // if (props.isAuthenticated == null)
  //   console.error('NotAuthenticatedRoute was passed isAuthenticated', props);
  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  if (data)
    isAuthenticated = data.isAuthenticated;
  // console.log('NotAuthenticatedRoute', isAuthenticated, data);

  return (
    <CustomRoute {...props} test={!isAuthenticated} to='/user-not-authorized' />
  );
}

NotAuthenticatedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
};

// useEffect is an attempt to get rid the following error when using useQuery:
// Warning: Can't perform a React state update on an unmounted component.
export function AuthorizedRoute(props) {
  // if (props.isAuthenticated == null)
  //   console.error('AuthorizedRoute was not passed isAuthenticated', props);
  // let isMounted;
  let { allowed, ...rest } = props;
  const { data } = useQuery(publicSchema.GET_GROUPS);
  // const { data } = isMounted ? useQuery(publicSchema.GET_GROUPS) : null;
  if (data?.groups)
    isAuthorized = data.groups.some(elem => allowed.includes(elem));
  // console.log('AuthorizedRoute', allowed, isAuthorized, data);
  // useEffect(() => {
  //   isMounted = true;
  //   return () => { // componentDidUnmount
  //     isMounted = false;
  //   };
  // }, []);

  return (
    <CustomRoute {...rest} test={isAuthorized} to='/' />
    /* <CustomRoute {...rest} test={isAuthorized} /> */
  );
}

AuthorizedRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  allowed: PropTypes.array.isRequired,
};
