import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

let localStyles = {
  ...styles,
  heading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    // flexDirection: 'row',
    // justifyContent: 'center',
    alignItems: 'center',
    // alignSelf: 'center',
    flexGrow: 0,
    height: 'auto',
    whiteSpace: 'pre-line',
  },
  headingSpacing: {
    margin: theme.spacing(0, 0, 3, 0),
  },
  subHeadingSpacing: {
    margin: theme.spacing(0, 0, 2.5, 0),
  },
  headingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 0,
    height: 'auto',
    maxHeight: 'fit-content',
    // width: '100%',
  },
  headingLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '20%',
  },
  headingCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    padding: theme.spacing(0, 1, 0, 1),
  },
  headingRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    width: '20%',
  },
  headingTitle: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    // whiteSpace: 'nowrap',
    width: 'auto',
    maxWidth: 'fit-content',
    textAlign: 'center',
  },
  headingTitleVariant: 'h3',
  subHeadingTitleVariant: 'h4',
  formTitleVariant: 'h4',
  pageHeadingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: theme.spacing(0, 0, 4, 0),
    width: '100%',
  },
  pageTitle: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: 'center',
  },
  pageTitleVariant: 'h3',
  pageHeadingSpan: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  pageHeadingSpanCenter: {
    alignItems: 'center',
    flexGrow: 0,
  },
  pageHeadingSpanLeft: {
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  pageHeadingSpanRight: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginBottom: theme.spacing(1),
  },
  sectionHeading: {
    // alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  sectionBody: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  docs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // alignItems: 'center',
    alignItems: 'baseline',
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.only('xs')]: { // sm, small: 600px
      flexDirection: 'column',
    },
  },
  rowLabel: {
    [theme.breakpoints.only('xs')]: { // sm, small: 600px
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('sm')]: { // sm, small: 600px
      minWidth: '20rem',
    },
  },
  fieldVariant: 'h6',
  // variants: {
  //   [theme.breakpoints.only('xs')]: { // sm, small: 600px
  //     title: 'h5',
  //     field: 'body1',
  //   },
  //   [theme.breakpoints.up('sm')]: { // sm, small: 600px
  //     title: 'h3',
  //     field: 'h6',
  //   },
  // },
// }));
};

const useStyles = makeStyles(localStyles);

export function Heading(props) {
  let classes = useStyles();
  return(
    // <div className={clsx(classes.fullWidth, classes.heading, classes.headingSpacing)} >
    <div className={clsx(classes.fullWidth, classes.heading, classes.headingSpacing)} >
      <Typography variant={localStyles.headingTitleVariant} component='h1' className={classes.headingTitle} >
        {props.children}
      </Typography>
    </div>
  );
}

Heading.propTypes = {
  children: PropTypes.node,
};

export function SubHeading(props) {
  let classes = useStyles();
  return(
    // <div className={clsx(classes.fullWidth, classes.heading, classes.headingSpacing)} >
    <div className={clsx(classes.fullWidth, classes.heading, classes.subHeadingSpacing)} >
      <Typography variant={localStyles.subHeadingTitleVariant} component='h1' className={classes.headingTitle} >
        {props.children}
      </Typography>
    </div>
  );
}

SubHeading.propTypes = {
  children: PropTypes.node,
};


export function HeadingTitle(props) {
  let variant = (props.variant) ? props.variant : localStyles.headingTitleVariant;
  let classes = useStyles();
  return(
    <Typography variant={variant} component='h1' className={classes.headingTitle} style={props.style} >
      {props.children}
    </Typography>
  );
}

HeadingTitle.propTypes = {
  style: PropTypes.shape(),
  variant: PropTypes.string,
  children: PropTypes.node,
};

export function HeadingWrapper(props) {
  let classes = useStyles();
  return(
    <div className={clsx(classes.fullWidth, classes.headingWrapper, classes.headingSpacing)} >
      {props.children}
    </div>
  );
}

HeadingWrapper.propTypes = {
  children: PropTypes.node,
};

export function HeadingRow(props) {
  let classes = useStyles();
  return(
    <div className={clsx(classes.fullWidth, classes.headingWrapper, classes.headingSpacing)} >
      {props.children}
    </div>
  );
}

HeadingRow.propTypes = {
  children: PropTypes.node,
};

export function HeadingLeft(props) {
  let classes = useStyles();
  return(
    <span className={classes.headingLeft} >
      {props.children}
    </span>
  );
}

HeadingLeft.propTypes = {
  children: PropTypes.node,
};

export function HeadingCenter(props) {
  let classes = useStyles();
  return(
    <span className={classes.headingCenter} >
      {props.children}
    </span>
  );
}

HeadingCenter.propTypes = {
  children: PropTypes.node,
};

export function HeadingRight(props) {
  let classes = useStyles();
  return(
    <span className={classes.headingRight} >
      {props.children}
    </span>
  );
}

HeadingRight.propTypes = {
  children: PropTypes.node,
};
