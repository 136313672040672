import React from 'react';
// import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { Page, Heading } from '@org/client-components-custom';
// import theme from '@org/client-libs-themes';
// import { styles } from '@org/client-libs-styles';

// const localStyles = {
//   ...styles,
// };

// const useStyles = makeStyles(localStyles);

export function Brochure() {
  // let classes = useStyles();
  return(
    <Page id='brochure' >
      <Heading>{'Form ADV Brochure'}</Heading>
      Coming soon...
    </Page>
  );
}
