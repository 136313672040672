import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Form, TextField, EmailField, PrimaryButton } from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { handleError } from '@org/common-errors';
import { styles } from '@org/client-pages-auth/authStyles';
import {
  // handleAuthentication,
  publicResolvers,
  PublicHandler,
} from '@org/client-graphql';

// let location = 'client.pages.auth.ConfirmEmail';

const useStyles = makeStyles(styles);


let defaultValues = {
  email: "",
  confirmCode: "",
};

let stateValues = {};

let isMounted = false;
let publicHandler;

export function ConfirmEmail(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formError: null });
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) {
        window.scrollTo(0, 0);

        if (isMounted) {
          if (props.location.state && props.location.state.data)
            stateValues = props.location.state.data;
          else
            stateValues = defaultValues;
          setState({ ...state, isLoading: false });

          // if (!props.location.state || !props.location.state.data)
          //   setState((prevState) => ({...prevState, isLoading: false, formError: 'Nothing passed to location.state'}));
        }
      }
    })();
    
    return () => {
      isMounted = false;
    };
  }, []);

  async function handleResendCode(event, form) {
    event.preventDefault();
    let { email } = form.values;
    let input = { email };
    try {
      if (!email.length)
        throw new Error('Please enter your email address');
      if (!form.validated['email'])
        throw new Error('Please enter a valid email address');

      let response = await publicResolvers.resendCode(input);
      if (response.success)
        setState({ ...state, formMesssage: 'New code sent', formError: null });
      if (response.error)
        throw response.error;
    } catch (error) {
      setState({ ...state, formError: handleError({ error }) });
    }
  }

  async function handleSubmit(event, form) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });
    try {
      let { email, confirmCode } = form.values;
      let input = { email, confirmCode };

      // let response = await publicResolvers.confirmSignUp(input);
      let response = await publicHandler.confirmSignUp(input);

      // if (response.success) {
      //   let success = {
      //     pathname: '/success',
      //     state: {
      //       data: { successMessage: 'Your email is confirmed' },
      //     },
      //   };
      //   props.history.push(success);
      // }
      if (response.success) {
        // await handleAuthentication(response);
        await publicHandler.handleAuthentication(response);
        props.history.push('/user');
      }

      if (response.error)
        throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: handleError({ error }) });
    }
  }

  return (
    <>
      <Form id='confirm-email' defaultValues={defaultValues} stateValues={stateValues} validationSchema={authSchema} onSubmit={handleSubmit} {...props} >
        {props => {
        {/* let { email } = props.values; */}
        let enabled = props.isValidated && !state.isSubmitting;
        return (
          <>
          <FormLayout id='confirm-email' >

            <Heading>{'Confirm Email'}</Heading>

            <Grid id='confirm-email' style={{width: '100%'}} >

              <Grid item >
                <FormMessage message={'Please check your email and enter the code here'} />
              </Grid>

              {state.formError &&
              <Grid item >
                <FormError message={state.formError.message} />
              </Grid>}

              {!stateValues.email && // show email it was not passed in from props.location.state.data
              <Grid item >
                <EmailField {...props.fieldProps} name='email' required={true} />
              </Grid>}

              <Grid item >
                <TextField {...props.fieldProps}
                  name='confirmCode'
                  label='Confirm Code'
                  required={true}
                />
              </Grid>

              <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
                <PrimaryButton
                  {...props.submitProps}
                  name='confirm-email'
                  title='Submit'
                  disabled={!enabled}
                  onClick={(event) => handleSubmit(event, props)}
                />
              </Grid>

              <Grid item className={classes.bottomLink} >
                <Link variant='body2' component="button" onClick={(event) => handleResendCode(event, props)} >
                  {'Resend confirmation code'}
                </Link>
              </Grid>

            </Grid>

          </FormLayout>

          <DisplayState title='state' state={{ local: state, form: props.stateProps }} open={true} />
          </>
        );
        }}
      </Form>
    </>
  );
}

ConfirmEmail.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  values: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
};
