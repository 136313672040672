// import { Handler } from '../../libs/classes/handler';
import { Handler } from '@org/client-libs-classes';
// import { authorizedClient, authorizedSchema, } from '../index';
import { authorizedClient, authorizedSchema, } from '@org/client-graphql';

export class PostgresHandler extends Handler {
  constructor(props) {
    super({ ...props, client: authorizedClient });
    this.schema = authorizedSchema;
  }

  // shouldn't this be a mutation?
  closeConnections() {
    let params = {
      query: authorizedSchema.CLOSE_CONNECTIONS,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }
}

export const postgresHandler = new PostgresHandler({ location: {}, history: {} });
