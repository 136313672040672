import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { shallowEqual, deepDifference } from '@org/common-tools';

// Cases
// - Basic empty form
// - Update form where data passed in before 1st render
// - Update form where data retrieved after 1st render

// change calculateValues to calculateOnChange
// add calculateOnBlur -- e.g. for showing form errors rather than field errors
// add formError, formMessage props available in formProps

// change required to another way to determine when to validate the whole form..?
// - is anything not required considered validated?
// - requiredValues: [ updateType, required: {} ]???
// the problem is how to validate a form where some values need to be touched and
// others don't. how can we determine which is which?
// - this can be the case in a create form where the user wishes to accept the default value
// - or in an update form where we need new information

// The real question on above is not when to validate the individual fields, but
// 1) when to validate the entire form and
// 2) whether that affects what is submitted (e.g. 1 field is updated on an update
//    form but the form fails validation???)
// Maybe this should be handled automatically anyway for migration???

let validationSchema;

let calculateValues;

export function FormUp2(props) {
  // console.log('');
  console.log('FormUp2', 'props', props);
  let [ state, setState ] = useState({
    isLoading: true,
    isValidated: false,
    initial: props.initialValues,
    values: props.initialValues,
    required: props.requiredValues ? props.requiredValues : {},
    touched: props.touchedValues ? props.touchedValues : {},
    validated: props.validatedValues ? props.validatedValues : {},
    errors: {},
  });

  // console.log('FormUp2', state.values);
  let { values, touched, validated, errors, required } = state;
  // console.log('FU2', 'values', values);

  if (state.isLoading) {
    if (!props.initialValues)
      throw new Error(`FormUp2: 'initialValues' is required in props`);

    if (!props.validationSchema)
      throw new Error(`FormUp2: 'validationSchema' is required in props`);

    validationSchema = props.validationSchema;

    if (props.calculateValues)
      calculateValues = props.calculateValues;
  }

  // // Use updatedValues for changes to form data above the form inputs
  // // e.g. an async data fetch in the page which returns after the page has rendered
  // // with initialValues.
  // // These values replace state.initial and state.values
  // if (props.updatedValues != null) {
  //   console.log('FU2', 'props.updatedValues', props.updatedValues);
  //   let { updatedValues } = props;
  //   let { initial, values, validated } = state;
  //   // let initial = state.initial;
  //   // let values = state.values;
  //   // let initialValues = {};
  //   // let values = {};

  //   // let validated = state.validated;
  //   for (let index in Object.keys(values)) {
  //     let name = Object.keys(values)[index];
  //     if (updatedValues[name] && values[name] !== updatedValues[name]) {
  //       console.log('FU2', index, name, values[name], '=>', props.updatedValues[name]);
  //       initial[name] = updatedValues[name];
  //       values[name] = updatedValues[name];
  //       validated[name] = true;
  //     }
  //   }
  //   // for (let index in Object.keys(updatedValues)) {
  //   //   let name = Object.keys(updatedValues)[index];
  //   //   if (values[name] != null && values[name] !== updatedValues[name]) {
  //   //     console.log('FU2', index, name, values[name], '=>', props.updatedValues[name]);
  //   //     initialValues[name] = updatedValues[name];
  //   //     values[name] = updatedValues[name];
  //   //     // validated[name] = true;
  //   //   }
  //   // }

  //   // props.updatedValues = null; // updatedValues must be cleared in the parent
  //   // if (!shallowEqual(state.values, values))
  //   //   setState({ ...state, values });
  //   // setState({
  //   //   ...state,
  //   //   // initial: { ...state.initial, ...initial },
  //   //   values: { ...state.values, ...values },
  //   //   // validated: { ...state.validated, ...validated },
  //   // });

  //   console.log('FU2', 'updatedValues', 'state', state);
  //   console.log('');
  // }

  useEffect(() => {
    console.log('FU2', 'componentDidMount');
    setState({ ...state, isLoading: false });
  }, []);

  useEffect(() => {
    console.log('FU2', 'useEffect', '[ values ]', state.values);
    // update calculated values after any value changes (if necessary)

    // console.log('');
    // console.log('FU2', 'useEffect', 'values', values);
    (async () => { // just in case
      if (calculateValues) {
        let calculatedValues = calculateValues(values);
        // console.log('FU2', 'useEffect', 'values', values);
        // console.log('FU2', 'useEffect', 'state.values', state.values);
        console.log('FU2', 'useEffect', 'calculatedValues', calculatedValues);
        if (!shallowEqual(values, calculatedValues)) {
          let diff = deepDifference(calculatedValues, values);
          console.log('diff', diff);
          setState({ ...state, values: { ...state.values, ...diff } });
        }
      }
    })();

  }, [ values ]);

  // useEffect(() => {
  //   console.log('FU2', 'useEffect', 'isValidated', state.isValidated, state.validated);
  // }, [ isValidated ]);

  // https://stackoverflow.com/questions/55344925/how-to-make-sure-a-react-state-using-usestate-hook-has-been-updated
  useEffect(() => {
    // validate the form whenver any input validation changes
    // console.log('');
    console.log('FU2', 'useEffect', '[ validated ]', state.isValidated, state.validated);
    (async () => {
      await validateForm();
    })();
    // console.log('FU2', 'useEffect', 'validated', state.isValidated, state.validated);
  }, [ validated ]);

  function resetForm(_event) {
    // console.log('resetForm', resetValues);
    setState({
      ...state,
      isValidated: false,
      values: state.initial,
      required: {},
      touched: {},
      validated: {},
      errors: {},
      formMessage: null,
      formError: null,
    });
  }

  function validateForm() {
    // console.log('');
    // console.log('FU2', 'validateForm', 'state', state);
    // console.log('validateForm', 'validated', validated);
    // console.log('validateForm', 'errors', errors);

    try {
      // all required inputs must have been validated

      Object.keys(required).forEach(name => {
        // console.log('FU2', 'validateForm', name, validated[name]);

        if (!validated[name]) // don't set an error until touched
          throw new Error(`Required value '${name}' not validated!`);
      });

      // no inputs (required or not) can have validation errors
      if (Object.keys(errors).length)
        throw new Error(`form errors found`);

      // console.info('validateForm', 'validated!');

      // if (!state.isValidated)
        setState({ ...state, isValidated: true });

      console.info('validateForm', 'validated!', state.isValidated);
    } catch (error) {
      // console.error('validateForm', error.message);
      // console.warn('validateForm', error.message);
      console.info('validateForm', 'Not validated!');
      // console.log(state.errors);
      if (state.isValidated)
        setState({ ...state, isValidated: false });
      // console.log(state.errors);
    }
  }

  // used in handleBlur, and handleChange when validating the input
  // e.g. Checkbox, SelectTextField, pasting into TextField
  async function setValidated(name, value) {
    console.log('FU2', 'setValidated', `'${name}'`, `'${value}'`);
    setState(prevState => ({
      ...prevState,
      values: { ...prevState.values, [name]: value},
      touched: { ...prevState.touched, [name]: true},
      validated: { ...prevState.validated, [name]: true},
    }));
  }

  async function validateChange(name, value) {
    console.log('FormUp2', 'validateChange', `'${name}'`, `'${value}'`);
    let response = await validationSchema.validateAt(name, { [name]: value }); // !!! for yup.trim() !!!
    value = (response !== value) ? response : value;

    if (errors[name])
      delete errors[name];

    await setValidated(name, value);
  }

  // used in handleChange when NOT validating the input
  // e.g. individual NumberField keystrokes
  async function setValue(name, value) {
    console.log('FU2', 'setValue', `'${name}'`, `'${value}'`);
    setState(prevState => ({ // prevState needed for setValues
      ...prevState,
      // isValidated: false,
      values: { ...prevState.values, [name]: value },
      touched: { ...prevState.touched, [name]: true },
      validated: { ...prevState.validated, [name]: false },
      // errors: we validate every keystroke on fields with an error
    }));
  }

  async function setValues(values) {
    console.log('FU2', 'setValues', values);
    for (let index in Object.keys(state.values)) {
      let name = Object.keys(state.values)[index];
      if (values[name] && values[name] !== state.values[name]) {
        await setValue(name, values[name]);
        await validateChange(name, values[name]);
      }
    }
  }

  async function setError(name, value, error) {
    // console.log('FU2', 'setValidated', `'${name}'`, `'${value}'`);
    setState({
      ...state,
      isValidated: false,
      values: { ...values, [name]: value}, // so we can fix bad input values!
      touched: { ...touched, [name]: true},
      validated: { ...validated, [name]: false},
      errors: { ...errors, [name]: error.message},
    });
  }

  // parseEvent is shared by handleChange and handleBlur
  // Different MUI inputs pass different event objects
  // Some of the objects do not pass the value event.target.name so I pass that as the second argument
  // TextField(event.target: { type: 'text', name, value })
  // NumberField(event.target: { floatValue, name }) // typeof value === 'string'
  // SelectTextField(event.target: { name, value })
  // DatePicker(Date object, name)
  // Checkbox(event.target: { type: 'checkbox', name, checked }) // value === ""
  function parseEvent(event, name) {
    // console.log('parseEvent', event, name);
    // console.log('parseEvent', event.target.name, event.target.type, event.target.value, event.target.checked, name);
    // console.log(typeof event?.target?.floatValue);

    let value;
    if (typeof event?.toDate === 'function' && event?.toDate() instanceof Date) {
      // DatePicker (dayjs formatted object)
      value = dayjs(event).format('YYYY-MM-DD');
      // console.info('parseEvent', 'DatePicker', name, value);
    } else if (event?.target?.type === 'checkbox') {
      name = event.target.name;
      value = event.target.checked;
      // console.info('parseEvent', 'Checkbox', name, value);
    } else if (typeof event?.target?.floatValue === 'number') {
      name = event.target.name;
      value = event.target.floatValue;
      // console.info('parseEvent', 'Number TextField', name, value);
    } else {
      name = event.target.name;
      value = event.target.value;
      // console.info('parseEvent', 'Select/TextField', `'${name}'`, `'${value}'`);
    }
    return { name, value };
  }

  async function handleBlur(event, arg) {
    // console.log('');
    // console.log('FormUp2', 'handleBlur', event, arg);
    // console.log('FormUp2', 'handleBlur', event.target.name, event.target.type, event.target.value, event.target.checked);

    let { name, value } = parseEvent(event, arg);
    // console.log('FormUp2', 'handleBlur', `'${name}'`, `'${value}'`);

    try {
      await validateChange(name, value); // throws an error if invalid
      // await setValidated(name, value); // moved into validateChange
    } catch(error) {
      console.error('FormUp2', 'handleBlur', name, error.message);
      await setError(name, value, error);
    }
  }

  async function handleChange(event, arg) {
    // console.log('');
    console.log('FU2', 'handleChange', event, arg);
    let { name, value } = parseEvent(event, arg);
    console.log('FU2', 'handleChange', `'${name}'`, `'${value}'`);

    if (value == null)
      console.info('FU2', 'handleChangeEvent', 'Unknown input type',  `'${name}'`, `'${value}'`, event);

    // Don't validate all inputs after every key stroke. The following are the
    // only cases where we want to validate on every change.
    let validate = false;
    if (!touched[name] && typeof value === 'string' && value.length > 1) // pasted values & password keepers
      validate = true;
    else if (typeof value === 'boolean') // always validate checkbox
      validate = true;
    else if (errors[name]) // try to fix existing errors on each keystroke
      validate = true;
    else if (touched[name] && validated[name]) // so the user can fix a typo, even if the input is valid
      validate = true;
    // else if (props?.calculatedValues?.includes(name))
    //   validate = true;

    // console.log('validate?', validate, name, value);

    if (validate) {
      try {
        await validateChange(name, value); // throws an error if invalid
        // await setValidated(name, value); // moved into validateChange
      } catch (error) { // error message logged in validate
        console.error('FU2', 'handleChange', error.message);
        await setError(name, value, error);
      }
    } else {
      await setValue(name, value);
    }
  }

  return (
    <>
    <form id={props.id} noValidate autoComplete='off' style={{ width: '100%', maxWidth: '100% !important' }} >
      {props.children({
        ...state,
        setValues,
        resetForm,
        handleChange,
        handleBlur,
        fieldProps: { values, errors, validated, handleChange, handleBlur },
        passwordProps: { values, errors, validated, handleChange, handleBlur },
        checkboxProps: { values, errors, validated, handleChange },
        selectProps: { values, errors, validated, handleChange },
      })}
    </form>
    </>
  );
}

FormUp2.propTypes = {
  id: PropTypes.string,
  initialValues: PropTypes.shape(),
  updatedValues: PropTypes.shape(),
  touchedValues: PropTypes.shape(),
  validatedValues: PropTypes.shape(),
  requiredValues: PropTypes.shape(),
  calculateValues: PropTypes.func,
  validationSchema: PropTypes.shape(),
  children: PropTypes.func,
};
