import React from 'react';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';

import { publicSchema } from '@org/client-graphql';

export function Authenticated() {
  const { loading, error, data } = useQuery(publicSchema.IS_AUTHENTICATED);
  // console.log('Authentcated', loading, error, data);

  let isAuthenticated = false;
  if (loading)
    return <div>{'Loading...'}</div>;
  if (error)
    return <div>Error! {error.message}</div>;
  if (data)
    isAuthenticated = data.isAuthenticated;

  return (
    <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }} /* onClick={toggleAuthentication} */ >
      User: {isAuthenticated ? 'Authenticated' : 'Not Authenticated'}
    </Typography>
  );
}

export default Authenticated;
