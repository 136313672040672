import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { PublicHandler, AuthenticatedHandler } from '@org/client-graphql';
import { BackdropLoader, FadeInSection } from '@org/client-components-core';
import { Page, Heading, NoteCard, NoteCard2 } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';

function PublicNoteCards() {
  return (
    <div id='public-note-cards' >
      <Typography variant='h4' component='h2' style={{ display: 'flex', flexDirection: 'column', justityContent: 'flex-start', alignItems: 'center', flexGrow: 1, width: '100%' }} >
        {/* {'Publicly available investment information is coming soon. Please Sign In to see detailed investment information.'} */}
        {'Publicly available investment information coming soon...'}
      </Typography>
    </div>
  );
}

function AuthenticatedNoteCards(props) {
  // console.log('AuthenticatedNoteCards', props);
  
  return (
    <div id='authenticatedHandler-note-cards' style={{display: 'flex', flexDirection: 'column', justityContent: 'flex-start', alignItems: 'center', flexGrow: 1, width: '100%'}} >
      {props?.items.map((note, index) => {
        return (
          <div key={index} >
            <FadeInSection key={index} id={index} style={{display: 'flex', flexDirection: 'column'}} >
              <NoteCard note={note} {...props} />
            </FadeInSection>
          </div>
        );
      })}
    </div>
    );
}

AuthenticatedNoteCards.propTypes = {
  items: PropTypes.array,
};

let isAuthenticated = false;
let isMounted = false;
let publicHandler;
let authenticatedHandler;

export function Invest(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formError: null });

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

    (async () => {
      try {

        isAuthenticated = await publicHandler.isAuthenticated();

        let values = {};
        if (isAuthenticated) {
          let [ notes, loans ] = await Promise.all([
            authenticatedHandler.listNotes({ sort: false }),
            authenticatedHandler.listLoans(),
          ]);
          console.warn('Invest', notes, loans);
  
          notes.forEach(note => {
            let index = loans.findIndex(loan => loan.id === note.referenceId);
            if (index === -1)
              console.warn(`note ${note.id} did not find loan referenceId ${note.referenceId}`);
            note.referenceItem = loans[index];
          });
          values = notes;
        } else {
          let response = await publicHandler.listNotes({ sort: false });
          values = response;
        }

        if (isMounted)
          setState({ ...state, isLoading: false, values });

      } catch (error) {
        if (isMounted)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();
    
    return () => { isMounted = false; };
  }, []);

  async function handleDetail(note) {
    try {
      if (isMounted)
        setState({ ...state, isSubmitting: true });
      props.history.push({
        pathname: '/invest/detail',
        state: {
          note,
          // noteCashflows,
          // loanCashflows,
        },
      });
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: true });
      console.error(error);
    }
  }

  let items = state.values ? state.values : [];

  let noteCardProps = { isAuthenticated, items };

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <Page id='invest-page' >

      <Heading>{'Invest'}</Heading>

      {(!state.isLoading && !isAuthenticated) &&
      <PublicNoteCards handleTransaction={handleDetail} isAuthenticated={state.isAuthenticated} />}

      {!state.isLoading && isAuthenticated && !items.length && 
      <Typography variant='h4' component='h2' >{'There are no investments available at this time'}</Typography>}

      {(!state.isLoading && isAuthenticated) &&
      <AuthenticatedNoteCards handleTransaction={handleDetail} { ...noteCardProps } />}

      <DisplayState title='state' state={{ isAuthenticated, state }} open={false} />
    </Page>
    </>
  );
}

Invest.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isAuthenticated: PropTypes.bool,
};
