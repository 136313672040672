import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { publicSchema } from '@org/client-graphql';
import { mainListItems as listItems } from './mainListItems';

const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/getting-started/installation/" {...props} style={{maxWidth: '100%', textDecoration: 'none'}} />
));

export function MainDrawerLinkList(props) {
  let { onClick } = props;
  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  let isAuthenticated = false;
  if (data) isAuthenticated = data.isAuthenticated;

  return(
    <div onClick={onClick} role="presentation" >
      <List component="nav" aria-label="main drawer links">
  
        {listItems.map((item, key) => (
          <Link key={key} component={LinkBehavior} to={item.to} >
            <ListItem button >
              <ListItemText primary={
                <Typography variant="h5" color="textPrimary" >
                  {item.text}
                </Typography>
              } />
            </ListItem>
          </Link>
        ))}

        {isAuthenticated &&
        <Link component={LinkBehavior} to={'/the-fund'} >
          <ListItem button >
            <ListItemText primary={
              <Typography variant="h5" color="textPrimary" >
                {'The Fund'}
              </Typography>
            } />
          </ListItem>
        </Link>}

      </List>
    </div>
  );
}

MainDrawerLinkList.propTypes = {
  onClick: PropTypes.func,
};
