export const mainListItems = [
  {
    to: '/learn',
    text: 'Learn'
  },{
    to: '/invest',
    text: 'Invest'
  },{
    to: '/partner',
    text: 'Partner',
  }
];
