import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import {
  Form, DatePicker, InLink,
  ButtonBar, PrimaryButton,
  TextField, AmountField, SelectTextField,
} from '@org/client-components-core';
import {
  Heading, FlexRow,
  FormLayout, FormMessage, FormError,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { paymentSchema } from '@org/common-yup';
import {
  // authorizedResolvers,
  AuthorizedHandler,
} from '@org/client-graphql';
// import { defaultLoanValues } from './inputs';
import { referenceItemTypes } from '@org/common-data-types';

let stateValues = {};
// let now = new Date();
// // let eventDateString = dayjs(now).format('YYYY-MM-DD');
// let eventDate = dayjs(now).format('YYYY-MM-DD');

let authorizedHandler;

let defaultValues = {
  referenceItemType: "NOTE",
  referenceItemId: "",
  eventDate: dayjs().format('YYYY-MM-DD'),
  amount: 0.00,
};

export function CreatePayment(props) {
  let isMounted = true;
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, values: {}, formMessage: null, formError: null,
  });

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  useEffect(()=>{
    window.scrollTo(0, 0);
    console.log('CreatePayment', props.location.state);

    (async () => {
      try {
        if (props.location.state) {
          let values = props.location.state;
          stateValues = {
            referenceItemType: values.itemType,
            referenceItemId: values.id,
            eventDate: dayjs().format('YYYY-MM-DD'),
          };
    
          // let trades = await authorizedResolvers.listTrades({ itemType: values.itemType, id: values.id });
          let trades = await authorizedHandler.listTrades({ itemType: values.itemType, id: values.id });
          console.log('trades', trades);
        }
        if (isMounted)
          setState({ ...state, isLoading: false});
      } catch (error) {
        if (isMounted)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();

    if (isMounted)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  },[]);

  async function handleSubmit(event, form) {
    event.preventDefault();
    let { values } = form;
    setState({ ...state, isSubmitting: true });

    let input = {
      referenceType: values.referenceItemType,
      referenceId: values.referenceItemId,
      eventDate: values.eventDate,
      amount: values.amount,
    };
    console.log('CreatePayment', input);
    try {
      // let response = await authorizedResolvers.createPayment(input);
      let response = await authorizedHandler.createPayment(input);

      if (response?.success)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: `Payment successfully created` });

      if (response?.error)
        throw response.error;
    } catch (error) {
      console.error('handleSubmit', error);
      setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  return (
    <>
    <Form id='create-payment' defaultValues={defaultValues} stateValues={stateValues} validationSchema={paymentSchema} {...props} >
      {props => {
      let enabled = (props.isValidated && !state.isSubmitting && !state.isCompleted);
      return (
        <>
        <FormLayout id='create-payment' >

          <FlexRow>
            <InLink to='/admin/note-detail' title='Back' />
          </FlexRow>

          <Heading>{'Create Payment'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <SelectTextField {...props.fieldProps} name={'referenceItemType'} label={'Item Type'} items={referenceItemTypes} required={true} />
            </Grid>

            <Grid item >
              <TextField {...props.fieldProps} name='referenceItemId' label='Item ID' required={true} />
            </Grid>

            <Grid item >
              <DatePicker {...props.fieldProps} name='eventDate' label='Payment Date' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...props.fieldProps} name='amount' label='Amount' required={true} />
            </Grid>

            {/* <Grid item >
              <AmountField {...props.fieldProps} name='principalAmount' label='Principal' required={true} />
            </Grid> */}

            {/* <Grid item >
              <AmountField {...props.fieldProps} name='paymentAmount' label='Payment' required={true} />
            </Grid> */}

            <Grid item >
              <ButtonBar>
                <PrimaryButton {...props.buttonProps} title='Submit' disabled={!enabled}
                onClick={(event) => handleSubmit(event, props)} />
              </ButtonBar>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, stateValues, form: props.stateProps }} open={true} />
        </>
      );}}

    </Form>
    </>
  );
}

CreatePayment.propTypes = {
  location: PropTypes.shape(),
  isValidated: PropTypes.bool,
  // values: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  // amount: PropTypes.number(),
};
