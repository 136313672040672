import dayjs from 'dayjs';
import { round } from '@org/common-formatters';
import { RecordClass } from './Record';
import { ValuesOfCorrectTypeRule } from 'graphql';

export class NoteClass extends RecordClass {
  constructor(input) {
    super(input);

    // Only create a Note from a specific reference item
    if (!input)
      throw new Error(`${this.constructor.name} constructor need input from the referenceItem! Received: '${JSON.stringify(input)}'`);

    // this.table = UserTable;

    // this.schema = loanRecordSchema;

    this.keys.itemType = 'NOTE';

    // let { referenceItem } = input;

    let launchDate = input?.launchDate ? dayjs(input.launchDate) : (dayjs(input.referenceOriginationDate).add(1, 'month')).date(1);
    // console.log('launchDate', launchDate.format('YYYY-MM-DD'));
    let firstPaymentDate = input?.firstPaymentDate ? dayjs(input.firstPaymentDate) : (launchDate.add(1, 'month')).date(1 + 19);
    // console.log('firstPaymentDate', firstPaymentDate.format('YYYY-MM-DD'));
    let nextPaymentDate = input?.nextPaymentDate ? dayjs(input.nextPaymentDate) : firstPaymentDate;
    // console.log('nextPaymentDate', nextPaymentDate.format('YYYY-MM-DD'));

    this.defaultValues = {
      itemName: input?.itemName ? input.itemName : "",
      description: input?.description ? input.description : "",
      originalBalance: 0.0,
      factor: 1.0,
      currentBalance: 0.0,
      managementRate: 1.0,
      coupon: 0.0,
      minimumAmount: 5000.0,
      price: 100.0,
      launchDate: launchDate.format('YYYY-MM-DD'),
      availableBalance: 0.0,
      paymentDelay: 19,
      firstPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
      nextPaymentDate: nextPaymentDate.format('YYYY-MM-DD'),
      referenceType: input?.referenceType ? input.referenceType : "",
      referenceId: input?.referenceId ? input.referenceId : "",
      referenceAssetType: input?.referenceAssetType ? input.referenceAssetType : "",
      referenceAssetSubType: input?.referenceAssetSubType ? input.referenceAssetSubType : "",
      referenceBalance: input?.referenceBalance ? input.referenceBalance : 0.0,
      referenceNetRate: input?.referenceNetRate ? input.referenceNetRate : 0.0,
      referenceOriginationDate: input.referenceOriginationDate,
    };

    this.requiredValues = {
      itemName: true,
      description: true,
    };

    this.attributes = {
      ...this.defaultValues,
    };

    this.calculateValues(input);

    // if (input)
    //   this.update(input);
    if (input)
      this.initialize(input);
  }

  // !!! must take input so new/updated values can be passed from FormUp2 !!!
  calculateValues(input) {
    // console.info(`${this.constructor.name} calculateValues input: ${JSON.stringify(input, null, ' ')}`);

    // We need the to make a copy so we can compare the results with input in FormUp2
    let values = {
      ...this.attributes, // allow not all values to be passed in during construction
      ...input
    };

    // we can't have zero original balance
    if (!values.originalBalance && !this.attributes.originalBalance) {
      values.originalBalance = this.attributes.referenceBalance;
      values.availableBalance = this.attributes.referenceBalance;
    }

    // we can't have zero coupon
    if (!values.coupon && !this.attributes.coupon)
      values.coupon = this.attributes.referenceNetRate - this.attributes.managementRate;

    let originalBalance = values.originalBalance ? values.originalBalance : this.attributes.originalBalance;
    let factor = values.factor ? values.factor : this.attributes.factor;
    // let ratio = values.originalBalance / values.referenceBalance;
    // ratio *= values.coupon / values.referenceNetRate;

    if (originalBalance !== this.attributes.originalBalance)
      values.currentBalance = originalBalance * factor;

    if (factor !== this.attributes.factor)
      values.currentBalance = originalBalance * factor;

    // if (ratio !== this.attributes.ratio)
    //   values.ratio = ratio;

    let referenceNetRate = values.referenceNetRate ? values.referenceNetRate : this.attributes.referenceNetRate;
    let managementRate = values.managementRate ? values.managementRate : this.attributes.managementRate;

    // console.log(referenceNetRate, this.attributes.referenceNetRate, managementRate);

    if (referenceNetRate !== this.attributes.referenceNetRate)
      values.coupon = referenceNetRate - managementRate;

    if (managementRate !== this.attributes.managementRate)
      values.coupon = referenceNetRate - managementRate;

    // if (this.referenceItem) {
    //   let nextPaymeDate = dayjs(this.referenceItem.nextPaymentDate);
    //   values.nextPaymentDate = nextPaymeDate.add(this.attributes.paymentDelay, 'day');
    // }

    // console.log(values);

    this.attributes = { ...this.attributes, ...values };

    // console.log(this.attributes);

    // form FormUp2
    return values;
  }

  // input = reference cashflow
  applyCashflow(input) {
    // console.log('Note', 'applyCashflow', cashflow);

    let ratio = this.attributes.originalBalance / this.attributes.referenceBalance;
    ratio = this.attributes.coupon / this.attributes.referenceNetRate;

    let paymentDate = dayjs(input.eventDate);
    paymentDate = paymentDate.add(this.attributes.paymentDelay, 'day');
    // console.log('getNextCashflow', paymentDate);

    let principalAmount = round(input.principalAmount * ratio, 2);
    let interestAmount = round(input.interestAmount * ratio, 2);
    let paymentAmount = round(interestAmount + principalAmount, 2);

    this.attributes.currentBalance -= principalAmount;
    this.attributes.factor = round(this.attributes.currentBalance / this.attributes.originalBalance, 12);

    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      eventDate: paymentDate.format('YYYY-MM-DD'),
      interestAmount,
      principalAmount,
      paymentAmount,
      balance: this.attributes.currentBalance,
    };
  }

  getCashflowUpdates() {
    return {
      id: this.keys.id,
      currentBalance: this.attributes.currentBalance,
      factor: this.attributes.factor,
      currentTerm: this.attributes.currentTerm,
      nextPaymentDate: this.attributes.nextPaymentDate,
    };
  }
}
