import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

let localStyles = {
  ...styles,
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexGrow: 0,
    height: 'auto',
    maxHeight: 'fit-content',
    width: '100%',
    // [theme.breakpoints.only('xs')]: { // sm, small: 600px
    //   flexDirection: 'column',
    // },
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // flexGrow: 1,
    width: '100%',
    // width: 'auto',
    minWidth: 'fitContent',
  },
  headingRoot: {
    display: 'flex',
    flexDirection: 'row',
  },
  fullWidth: {
    width: '100%',
    flexGrow: 1,
  },
  headingSpan: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  headingSpanLeft: {
    display: 'flex',
  },
  headingSpanRight: {
    display: 'flex',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginBottom: theme.spacing(1),
  },
  sectionHeading: {
    // alignItems: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
  sectionBody: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  docs: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    minWidth: 'fit-content',
    marginTop: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // alignItems: 'center',
    alignItems: 'baseline',
    width: '100%',
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.only('xs')]: { // sm, small: 600px
      flexDirection: 'column',
    },
  },
  rowLabel: {
    [theme.breakpoints.only('xs')]: { // sm, small: 600px
      textDecoration: 'underline'
    },
    [theme.breakpoints.up('sm')]: { // sm, small: 600px
      minWidth: '20rem',
    },
  },
  fieldVariant: 'body1',
  // variants: {
  //   [theme.breakpoints.only('xs')]: { // sm, small: 600px
  //     title: 'h5',
  //     field: 'body1',
  //   },
  //   [theme.breakpoints.up('sm')]: { // sm, small: 600px
  //     title: 'h3',
  //     field: 'h6',
  //   },
  // },
// }));
  bulletSpan: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    margin: theme.spacing(0, 0, 1, 0),
  },
};

const useStyles = makeStyles(localStyles);

export function FlexRow(props) {
  let classes = useStyles();
  return (
    <div className={classes.flexRow} {...props} >
      {props.children}
    </div>
  );
}

FlexRow.propTypes = {
  children: PropTypes.node,
};

export function FlexColumn(props) {
  // console.log('FlexColumn', props.className);
  let classes = useStyles();

  let className = props.className ? clsx(classes.flexColumn, props.className) : classes.flexColumn;

  return (
    // <div className={classes.flexColumn} {...props} >
    <div className={className} {...props} >
      {props.children}
    </div>
  );
}

FlexColumn.propTypes = {
  className: PropTypes.shape(),
  children: PropTypes.node,
};

export function LabelledFieldSpan(props) {
  const classes = useStyles();
  return(
    <span className={classes.row} >
      <Typography variant={localStyles.fieldVariant} className={classes.rowLabel} >
        {props.title}
      </Typography>
      <Typography variant={localStyles.fieldVariant} >
        {props.value}
      </Typography>
    </span>
  );
}

LabelledFieldSpan.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
};

// const Bullet = (props) => {
export function Bullet(props) {
  let classes = useStyles();
  return <><span className={classes.bulletSpan} >&nbsp;&#10033;&nbsp;&nbsp;<span>{props.children}</span></span></>;
}

Bullet.propTypes = {
  children: PropTypes.node,
};
