import { Handler } from '@org/client-libs-classes';
// import { publicClient } from './client';
import { publicSchema } from './schema';
import {
  publicClient, authenticatedClient, authorizedClient
} from '@org/client-graphql';

export class PublicHandler extends Handler {
  constructor(props) {
    super({ ...props, client: publicClient });
    this.schema = publicSchema;
  }

  async pingPublic() {
    let params = {
      query: publicSchema.PING_PUBLIC,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async isOnline() {
    let params = {
      query: publicSchema.IS_ONLINE,
    };
    return this.query(params);
  }

  async isAuthenticated() {
    let params = {
      query: publicSchema.IS_AUTHENTICATED,
    };
    return this.query(params);
  }
  
  async isAdministrator() {
    let params = {
      query: publicSchema.IS_ADMINISTRATOR,
    };
    return this.query(params);
  }
  
  async isDeveloper() {
    let params = {
      query: publicSchema.IS_DEVELOPER,
    };
    return this.query(params);
  }
  
  async isSuper() {
    let params = {
      query: publicSchema.IS_SUPER,
    };
    return this.query(params);
  }

  async setAuthenticated(input) {
    console.warn('setAuthenticated', input);
    return publicClient.writeQuery({
      query: publicSchema.SET_AUTHENTICATED,
      data: { isAuthenticated: input },
    });
  }

  async confirmInvitation(input) {
    let params = {
      mutation: this.schema.CONFIRM_INVITATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async confirmResetPassword(input) {
    let params = {
      mutation: this.schema.CONFIRM_RESET_PASSWORD,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async confirmSignUp(input) {
    let params = {
      mutation: this.schema.CONFIRM_SIGN_UP,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async getGroups() {
    let params = {
      query: publicSchema.GET_GROUPS,
    };
    return this.query(params);
  }

  async setGroups(input) {
    console.warn('publicHandler', 'setGroups', input, typeof input);
    publicClient.writeQuery({
      query: publicSchema.SET_GROUPS,
      data: { groups: input },
    });
    publicClient.writeQuery({
      query: publicSchema.IS_ADMINISTRATOR,
      data: { isAdministrator: input.includes('Admin') },
    });
    publicClient.writeQuery({
      query: publicSchema.IS_DEVELOPER,
      data: { isDeveloper: input.includes('Dev') },
    });
    publicClient.writeQuery({
      query: publicSchema.IS_SUPER,
      data: { isSuper: input.includes('Super') },
    });
    // return true;
  }

  async handleAuthentication(input) {
    console.warn('publicHandler', 'handleAuthentication', input);
    this.setAuthenticated(true);
    this.setGroups(input.groups);
  }

  async handleClearAuthentication() {
    console.warn('publicHandler', 'handleClearAuthentication');
    await publicHandler.setAuthenticated(false);
    await publicHandler.setGroups([]);
    await Promise.all([
      publicClient.resetStore(),
      authenticatedClient.resetStore(),
      authorizedClient.resetStore(),
    ]);
  }

  async getNote(input) {
    let params = {
      query: this.schema.GET_NOTE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getVersion() {
    let params = {
      query: publicSchema.GET_VERSION,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async initiateResetPassword(input) {
    let params = {
      mutation: this.schema.INITIATE_RESET_PASSWORD,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async initiateSignUp(input) {
    let params = {
      mutation: this.schema.INITIATE_SIGN_UP,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async listNotes(input) {
    let params = {
      query: this.schema.LIST_NOTES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async resendResetPassword(input) {
    let params = {
      mutation: this.schema.RESEND_RESET_PASSWORD,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async resendSignUp(input) {
    let params = {
      mutation: this.schema.RESEND_SIGN_UP,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async signIn(input) {
    let params = {
      mutation: this.schema.SIGN_IN,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    let response = await this.mutate(params);
    this.handleAuthentication(response);
    return response;
  }

  async verifyInvitation(input) {
    let params = {
      mutation: this.schema.VERIFY_INVITATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }
}

// Needed for offline tests
export const publicHandler = new PublicHandler({ location: {}, history: {} });
