import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiCircularProgress } from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { // Loader,
  FlexColumn,
} from '@org/client-components-custom';
// import theme from '@org/client-libs-themes';

export function CircularProgress(props) {
  return (
    <FlexColumn id='circular-progress' style={{ justifyContent: 'flex-start', flexGrow: 1 }} >
      <Box position="relative" display="inline-flex" >
        <MuiCircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
      <Typography variant="body2" color="textSecondary" >{'Available'}</Typography>
    </FlexColumn>
  );
}

CircularProgress.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 3,
    width: 100,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 3,
    // backgroundColor: '#1a90ff',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 3,
    width: 150,
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 3,
    // backgroundColor: '#FFC200', // '#1a90ff',
    backgroundColor: theme.palette.primary.main,
    // width: '100%',
  },
}));

export function LinearProgressWithLabel(props) {
  let classes = useStyles();
  let amountAvailable = `${Math.round(props.value)}% ${props.label}`;
  return (
    <>
      <FlexColumn style={{ alignItems: 'center' }} >
        <LinearProgress variant="determinate" classes={classes} value={props.value} />
        <Typography variant="body2" color="textSecondary" >{amountAvailable}</Typography>
      </FlexColumn>
    </>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
