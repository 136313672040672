import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AuthenticatedHandler } from '@org/client-graphql';
import {
  FormUp, InLink, SelectTextField, PrimaryButton, SecondaryButton, BackdropLoader
} from '@org/client-components-core';
import {
  Heading, // FlexRow,
  FormLayout, FormMessage, FormError,
  // CloseIconButton, EditIconButton,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';
import { securitySchema } from '@org/common-yup';
import { shallowEqual } from '@org/common-tools/compare';

let defaultValues = {
  emails: [""],
  email: "",
  backupEmail: "",
  createdAt: "", ipAddress: "", viewerType: "", viewerOs: "", viewerBrowser: ""
};

const useStyles = makeStyles(styles);

// let stateValues = {};
let authenticatedHandler;

export function Security(props) {
  let isMounted = true;
  const [ state, setState ] = useState({
    isLoading: true, isEditing: false, isSubmitting: false, formMessage: null, formError: null, changed: {},
  });
  const [ form, setForm ] = useState({
    // values: calculateValues({ ...defaultValues, ...props.location?.state }),
    origValues: defaultValues,
    values: { ...defaultValues },
    touched: {},
    validated: {},
    errors: {},
  });

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  let classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      try {
        // let [ me, auth, session, emails ] = await Promise.all([
        let response = await Promise.all([
          authenticatedHandler.getMe(),
          authenticatedHandler.getSession(),
          authenticatedHandler.listEmails(),
        ]);
        // console.log('Security', response);
        // let [ me, auth, session, emails ] = response;
        let [ me, session, emails ] = response;

        if (isMounted) {
          // if (auth.error) {
          let values = { email: me.email, emails, session };
          setForm({ ...form, values, origValues: values });
          setState({ ...state, isLoading: false });
        }

      } catch (error) {
        if (isMounted)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();

    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event) {
    event.preventDefault(); // do I need this?
    setState({ ...state, isSubmitting: true });

    if (isMounted)
        setState({ ...state, isSubmitting: true, formMessage: null, formError: null});

    // let input = {};

    try {
      let input = { email: form.values.email };
      // let response = await authenticatedHandler.updateMe(input);
      // await authenticatedHandler.updateMe(input);
      await authenticatedHandler.changeEmail(input);
      if (isMounted) {
        setForm({ ...form, origValues: form.values, touched: {}, validated: {}, errors: {} });
        setState({ ...state, isSubmitting: false, isEditing: false, formMessage: 'Update successful', formError: null });
      }
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }

  }

  function handleCancel() {
    setForm({ ...form, values: form.origValues, touched: {}, validated: {}, errors: {} });
  }

  let isUpdated = !shallowEqual(form.values, form.origValues);

  let disabled = form.values.emails.length > 1 ? false : true;

  let emailProps = { name: 'email', label: 'Primary Email', required: true, disabled };
  // let backupEmailProps = { name: 'backupEmail', label: 'Backup Email', required: true, disabled };

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <FormUp id='user-security' {...props} state={form} setState={setForm} validationType={'update'} validationSchema={securitySchema} >
    {/* <Form id='user-security' defaultValues={defaultValues} validationType='update' validationSchema={securitySchema} {...props} > */}
      {props => {
      {/* console.log('Security', 'render props', props); */}
      {/* let enabled = props.isValidated && !state.isSubmitting && isUpdated; */}
      let submitEnabled = props.isValidated && !state.isSubmitting && isUpdated;
      let cancelEnabled = !state.isSubmitting && isUpdated;
      {/* let emails = props.stateProps.values.emails.map(elem => { return { label: elem, value: elem }; }); */}
      let emails = form.values.emails.map(elem => { return { label: elem, value: elem }; });
      {/* console.log('emails', emails); */}
      {/* stateValues = { ...stateValues, ...props.values }; */}
      return (
        <>
        <FormLayout>

          {/* <Heading>{'Security'}</Heading> */}

          {/* <FlexRow style={{ justifyContent: 'space-between'}} >
            <InLink to='/admin/manage-loans' title='Back' />
            <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexGrow: 1 }} >
              {!state.isEditing &&
              <EditIconButton {...props} handleClick={() => handleEditClick()} />}
              {state.isEditing &&
              <CloseIconButton {...props} handleClick={() => handleEditClick()} />}
            </span>
          </FlexRow> */}

          <Heading>{'Security'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item style={{margin: theme.spacing(0, 0, 2, 0)}} >
              <InLink title='Change Password' to='/user/change-password' />
            </Grid>

            <Grid item style={{margin: theme.spacing(0, 0, 3, 0)}} >
              <InLink title='Add Email' to='/user/add-email' />
            </Grid>

            <Grid item >
              <SelectTextField disabled={false} {...emailProps} {...props.fieldProps} items={emails} />
            </Grid>

            {/* {emails.length > 1 &&
            <Grid item >
              <SelectTextField disabled={false} {...backupEmailProps} {...props.fieldProps} items={emails} />
            </Grid>} */}

            {/* {isUpdated && */}
            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                // {...props.submitProps}
                aria-label='submit button'
                title='Submit'
                disabled={!submitEnabled}
                onClick={handleSubmit}
              />
              <SecondaryButton
                aria-label='cancel button'
                title='Cancel'
                disabled={!cancelEnabled}
                onClick={handleCancel}
              />
            </Grid>

          </Grid>

        </FormLayout>

        {/* <FormLayout>

          <Grid style={{width: '100%'}} >

            <Grid item >
              <TextField {...sessionInitiatedProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...ipAddressProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...viewerTypeProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...viewerOsProps} {...props.fieldProps} />
            </Grid>

            <Grid item >
              <TextField {...viewerBrowserProps} {...props.fieldProps} />
            </Grid>

          </Grid>

        </FormLayout> */}

        <DisplayState title='state' state={{ state, form: { isValidated: props.isValidated, ...form } }} open={true} />
        </>
      );}}
    </FormUp>
    </>
  );
}

Security.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
};
