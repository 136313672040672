import dayjs from 'dayjs';
import { RecordClass } from '@org/common-classes/Record';
// import { CashflowClass } from '@org/common-classes/Cashflow';
// import { UserTable } from '@org/server-libs-data';
import { round } from '@org/common-formatters';
import { isDefined } from '@org/common-tools';

/**
 * LoanClass
 * 
 */

// const loanRecordShape = {
//   id: ulidShape
//     .required('A valid ULID is required for id'),
// };
// const userRecordSchema = yup.object().shape(userRecordShape);

function calcFirstPaymentDate(originationDate) {
  let dayOfMonth = originationDate.get('date');

  if (dayOfMonth === 1)
    return originationDate.add(1, 'month');
  else {
    let firstPaymentDate = originationDate.add(2, 'month');
    // console.log(firstPaymentDate.format('YYYY-MM-DD'));
    firstPaymentDate = firstPaymentDate.set('date', 1);
    // console.log(firstPaymentDate.format('YYYY-MM-DD'));

    return firstPaymentDate;
  }
}

export class LoanClass extends RecordClass {
  constructor(input) {
    super(input);

    // if (input.createdBy) {
    //   console.warn(`${this.constructor.name}: 'createdBy' found in input. Please remove!`);
    //   delete input.createdBy;
    // }

    // this.table = UserTable;

    // this.schema = loanRecordSchema;

    this.keys.itemType = 'LOAN';

    let originationDate = dayjs();
    let firstPaymentDate = calcFirstPaymentDate(originationDate);

    // used for create form
    this.defaultValues = {
      borrowerName: "",
      propertyAddress: "",
      assetType: "REAL_ESTATE",
      assetSubType: "RESIDENTIAL",
      originalBalance: 0.0,
      factor: 1.0,
      currentBalance: 0.0,
      originalTerm: 12,
      currentTerm: 12,
      amortizationTerm: 0,
      paymentFrequency: "MONTHLY",
      interestRate: 0.0,
      servicingRate: 0.5,
      interestType: '30_360',
      netRate: 0.0,
      paymentAmount: 0.0,
      originationDate: originationDate.format('YYYY-MM-DD'),
      firstPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
      nextPaymentDate: firstPaymentDate.format('YYYY-MM-DD'),
      appraisalValue: 0.0,
      perDiemAmount: 0.0,
      perDiemDays: 0,
      perDiemPayment: 0.0,
    };

    this.requiredValues = {};
    // this.requiredValues = {
    //   borrowerName: true,
    //   propertyAddress: true,
    //   // originationDate: true,
    //   // firstPaymentDate: true,
    //   originalBalance: true,
    //   // originalTerm: true,
    //   interestRate: true,
    // };

    this.attributes = {
      ...this.defaultValues,
    };

    this.calculateValues(input);

    // if (input)
    //   this.update(input);
    if (input)
      this.initialize(input);
  }

  // !!! must take input so new/updated values can be passed from FormUp2 !!!
  calculateValues(input) {
    // console.info(`${this.constructor.name} calculateValues input: ${JSON.stringify(input, null, ' ')}`);

    // We need the to make a copy so we can compare the results with input in FormUp2
    let values = {
      ...this.attributes, // allow not all values to be passed in during construction
      ...input
    };

    if (values.originationDate !== this.attributes.originationDate) {
      let originationDate = dayjs(values.originationDate);
      let firstPaymentDate = calcFirstPaymentDate(originationDate);
      values.firstPaymentDate = firstPaymentDate.format('YYYY-MM-DD');
      values.nextPaymentDate = values.firstPaymentDate;
    }

    if (values.firstPaymentDate !== this.attributes.firstPaymentDate) {
      let originationDate = dayjs(values.originationDate);
      let firstPaymentDate = calcFirstPaymentDate(originationDate);
      values.firstPaymentDate = firstPaymentDate.format('YYYY-MM-DD');
      values.nextPaymentDate = values.firstPaymentDate;
    }

    // we need to know if both values have changed to correctly calculate currentBalance
    let originalBalance = values.originalBalance ? values.originalBalance : this.attributes.originalBalance;
    let factor = values.factor ? values.factor : this.attributes.factor;

    if (values.originalBalance !== this.attributes.originalBalance)
      values.currentBalance = originalBalance * factor;

    if (factor !== this.attributes.factor)
      values.currentBalance = originalBalance * factor;

    // we need to know if both values have changed to correctly calculate netRate
    let interestRate = values.interestRate ? values.interestRate : this.attributes.interestRate;
    let servicingRate = values.servicingRate ? values.servicingRate : this.attributes.servicingRate;

    if (interestRate !== this.attributes.interestRate)
      values.netRate = interestRate - servicingRate;

    if (servicingRate !== this.attributes.servicingRate)
      values.netRate = interestRate - servicingRate;

    let paymentAmount = this.calcPayment(values);
    if (paymentAmount !== this.attributes.paymentAmount)
      values.paymentAmount = paymentAmount;

    // console.log('before calcPerDiemAmount', values);

    let perDiemAmount = this.calcPerDiemAmount(values);
    if (perDiemAmount !== this.attributes.perDiemAmount)
      values.perDiemAmount = perDiemAmount;

    let perDiemDays = this.calcPerDiemDays(values);
    if (perDiemDays !== this.attributes.perDiemDays)
      values.perDiemDays = perDiemDays;

    let perDiemPayment = round(perDiemDays * perDiemAmount, 2);
    if (perDiemPayment !== this.attributes.perDiemPayment)
      values.perDiemPayment = perDiemPayment;

    this.attributes = { ...this.attributes, ...values };

    // for FormUp2
    return values;
  }

  noteInput() {
    return ({
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      referenceAssetType: this.attributes.assetType,
      referenceAssetSubType: this.attributes.assetSubType,
      referenceBalance: this.attributes.originalBalance,
      referenceNetRate: this.attributes.netRate,
      referenceOriginationDate: this.attributes.originationDate,
    });
  }

  getNextPaymentDate() {
    if (this.attributes.paymentFrequency === "MONTHLY") {
      let nextPaymentDate = (dayjs(this.attributes.nextPaymentDate).add(1, 'month')).format('YYYY-MM-DD');
      return nextPaymentDate;
    } else
      throw new Error('Loan', 'getNextPaymentDate', `Unknown 'paymentFrequency'`);
  }

  calcInterest() {
    let interestAmount = this.attributes.interestRate / 1200 * this.attributes.currentBalance;
    return round(interestAmount, 2);
  }

  calcPayment() {
    let { originalBalance, interestRate, amortizationTerm } = this.attributes;
    let i = interestRate / 1200;
    let n = amortizationTerm;
    let paymentAmount = 0;

    if (amortizationTerm === 0)
      paymentAmount = originalBalance * interestRate / 1200;
    else if (interestRate && originalBalance)
      paymentAmount = originalBalance * i * (Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    else
      paymentAmount = 0;

    paymentAmount = round(paymentAmount, 2);

    return paymentAmount;
  }

  getPayment(remainingTerm) {
    // In the future changing payment amounts will be calculated here...
    let paymentAmount = this.calcPayment(remainingTerm);
    let interestAmount = this.calcInterest(remainingTerm);
    let principalAmount = paymentAmount - interestAmount;
    return { paymentAmount, interestAmount, principalAmount };
  }

  getNextPayment() {
    return this.getPayment(this.attributes.remainingTerm);
  }

  getNextCashflow() {
    let payment = this.getNextPayment();
    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      eventDate: this.attributes.nextPaymentDate,
      interestAmount: payment.interestAmount,
      principalAmount: payment.principalAmount,
      paymentAmount: payment.paymentAmount,
      balance: this.attributes.currentBalance - payment.principalAmount,
    };
  }

  // input = cashflow or payment?
  applyNextCashflow(input) {
    this.attributes.currentBalance -= input.principalAmount;
    this.attributes.nextPaymentDate = this.getNextPaymentDate();
    this.attributes.currentTerm -= 1;
  }

  getCashflowUpdates() {
    return {
      id: this.keys.id,
      currentBalance: this.attributes.currentBalance,
      factor: this.attributes.factor,
      currentTerm: this.attributes.currentTerm,
      nextPaymentDate: this.attributes.nextPaymentDate,
    };
  }

  calcPerDiemAmount(input) {
    // console.log('calcPerDiemAmount', 'input', input);
    let { originalBalance, interestRate } = input;

    // console.log(isDefined(originalBalance), isDefined(interestRate));

    [ 'originalBalance', 'interestRate' ].forEach(elem => {
      if (!isDefined(input[elem])) {
        console.error('calcPerDiemAmount', elem, 'missing in input');
        return 0;
      } else if (!input[elem])
        return 0;
    });

    // if (!isDefined(originalBalance) || !isDefined(interestRate)) {
    //   console.error(`Missing input element in 'calcPerDiemAmount:`, JSON.stringify(input));
    //   return 0;
    // }

    // if (!originalBalance || !interestRate)
    //   return 0;

    let payment = interestRate / 100 * originalBalance / 360;
    payment = round(payment, 2);
    return payment;
  }
  
  calcPerDiemDays(input) {
    // console.log('Loan Class', 'calcPerDiemDays', 'input', input);
    let { originationDate } = input;

    if (!isDefined(originationDate)) {
      // console.log(isDefined(originationDate), originationDate);
      console.error(`Missing input elements in 'calcPerDiemDays:`, JSON.stringify(input));
      return 0;
    }

    let date = dayjs(originationDate);
    let nextDate = date.add(1, 'month');
    nextDate = nextDate.set('date', 1);
    let days = nextDate.diff(date, 'days');
    return days;
  }

  getClosingCashflow() {
    // this.attributes.perDiemAmount = this.calcPerDiemAmount(this.attributes);
    // this.attributes.perDiemDays = this.calcPerDiemDays(this.attributes);
    // this.attributes.perDiemPayment = this.attributes.perDiemAmount * this.attributes.perDiemDays;
    // return this.attributes.perDiemPayment;

    // console.log('LoanClass', 'getClosingCashflow', this.values());

    // let input = {
    return {
      referenceType: this.keys.itemType,
      referenceId: this.keys.id,
      eventDate: this.attributes.originationDate,
      interestAmount: this.attributes.perDiemPayment,
      principalAmount: 0.0,
      paymentAmount: this.attributes.perDiemPayment,
      balance: this.attributes.originalBalance,
    };

    // let cashflowClass = new CashflowClass(input);

    // return cashflowClass.values();
  }
}
