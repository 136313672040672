import dayjs from 'dayjs';
import { AuthorizedHandler } from '@org/client-graphql';

export const columns = [
  { field: 'email', headerName: 'Email', width: 250 },
  { field: 'expirationDate', headerName: 'Expiration Date', width: 170 },
  { field: 'confirmationDate', headerName: 'Confirmation Date', width: 170 },
];

export async function getRows(props) {
  let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let invitations = await authorizedHandler.listInvitations();

  invitations.forEach(invitation => {
    invitation.expirationDate = dayjs(invitation.expirationDate).format('MM/DD/YY hh:mm a');
    invitation.confirmationDate = invitation.confirmationDate ? dayjs(invitation.confirmationDate).format('MM/DD/YY hh:mm a') : 'UNCONFIRMED';
  });

  return invitations;
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
