import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import { LoaderIcon } from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

const localStyles = {
  ...styles,
  centeredDivStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-(10/2)rem',
    marginTop: '-(10/2)rem',
    zIndex: '9999',
  },
  loaderStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '4rem',
    color: theme.palette.primary.main,
    animation: 'spin 2s infinite linear',
  },
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  linearProgress: {
    verticalAlign: 'top',
    height: '100%',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
};

const useStyles = makeStyles(localStyles);

export function Spinner(props) {
  let classes = useStyles();
  if (!props.isLoading)
    return null;
  return(
    <>
    <div style={classes.centeredDivStyle} >
      <CssBaseline />
      <CircularProgress disableShrink />
    </div>
    </>
  );
}

Spinner.propTypes = {
  isLoading: PropTypes.bool,
};

export function Loader() {
  let classes = useStyles();
  return(
    <div className={classes.centeredDivStyle} >
      <LoaderIcon style={classes.loaderStyle} />
    </div>
  );
}

Loader.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  pageLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
};

export function BackdropLoader(props) {
  // console.log('BackdropLoader', props);
  let classes = useStyles();
  return(
    <Backdrop id='backdrop-loader' className={classes.backdropStyle} open={props.open ? props.open : true} >
      <div className={classes.linearProgress}>
        <LinearProgress />
      </div>
    </Backdrop>
  );
}

BackdropLoader.propTypes = {
  open: PropTypes.bool,
};
