import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';
import { PrimaryButton, InLink } from '@org/client-components-core';
// import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloProvider } from '@apollo/client';
import {
  publicClient,
  publicResolvers,
  PublicHandler,
} from '@org/client-graphql';
import { Online, Authenticated } from '@org/client-components-custom';

const localStyles = {
  ...styles,
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'flex-start',
    alignItems: 'center',
  },
};

const useStyles = makeStyles(localStyles);

let publicHandler;

export function Experimental(props) {
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  async function toggleIsAuthenticated() {
    // let isAuthenticated = await publicResolvers.isAuthenticated();
    let isAuthenticated = await publicHandler.isAuthenticated();
    // await publicResolvers.setAuthenticated(!isAuthenticated);
    await publicHandler.setAuthenticated(!isAuthenticated);
  }

  return(
    <ApolloProvider client={publicClient} >
      <div className={classes.pageRoot} >
        <Online />

        <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
          {'Dev Home'}
        </Typography>

        <section className={classes.section} >
          <Typography variant={localStyles.sectionTitleVariant} component='h1' className={classes.sectionTitle} >
            {'Platform'}
          </Typography>
          <Authenticated />
          <PrimaryButton title='Toggle' onClick={() => toggleIsAuthenticated()} validated={true} loading={false} />
        </section>

        <Typography variant="h4" component="h2" >Public Pages</Typography>
        <InLink title='Sign In' to='/sign-in' />
        <InLink title='Sign Up' to='/sign-up' />
        <InLink title='Confirm Email' to='/confirm-email' />
        <InLink title='Reset Password' to='/reset-password' />
        <InLink title='Change Email' to='/change-email' />
        <InLink title='Change Password' to='/change-password' />
        <InLink title='Invitation' to='/invitation' />

        <Typography variant="h4" component="h2" >Authenticated Pages</Typography>
        <InLink title='User' to='/user' />

        <Typography variant="h4" component="h2" >Admin Pages</Typography>
        <InLink title='Admin' to='/admin' />
        <InLink title='Manage Users' to='/admin/manage-users' />

        <Typography variant="h4" component="h2" >Dev Pages</Typography>
        <InLink title='Dev' to='/dev' />
        <InLink title='Components' to='/dev/components' />
        <InLink title='Cache' to='/dev/cache' />
        <InLink title='Fonts' to='/dev/fonts' />
        <InLink title='SVG' to='/dev/svg' />

      </div>
    </ApolloProvider>
  );
}

// export default Experimental;

Experimental.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
