import React from 'react';
// import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import Fade from '@material-ui/core/Fade';
// import Grow from '@material-ui/core/Grow';
import CodeRoundedIcon from '@material-ui/icons/Code';
import { IconButton } from '@org/client-components-core';
import { publicSchema } from '@org/client-graphql';
// import { useStyles } from './appBarStyles';

// const styles = {
//   greyIconButton: {
//     margin: theme.spacing(0.5),
//     backgroundColor: theme.palette.grey.main,
//     color: theme.palette.primary.contrastText,
//   },
// };

export function DevIconButton(props) {
  // console.log('DevIconButton', props);
  const { loading, data, error } = useQuery(publicSchema.IS_DEVELOPER);
  const theme = useTheme();
  // const isSm = useMediaQuery(theme.breakpoints.down("sm")); // not only!!!
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  // const { data } = useQuery(publicSchema.IS_DEVELOPER);
  // console.log('DevIconButton', loading, data, error);
  let isDev = false;
  if (loading || error)
    isDev = false;
  if (data)
    isDev = data.isDeveloper;
  // const classes = useStyles();

  let size = 'medium';
  let fontSize = 'large';
  if (isXs)
    size = 'small';

  if (isDev)
    // return <IconButton onClick={() => alert('click')} icon={<CodeRoundedIcon fontSize='large' />} />;
    // <IconButton onClick={() => alert('click')} className={ classes.greyIconButton } icon={<CodeRoundedIcon fontSize='large' />} />
    // return <IconButton onClick={() => props.history.push('/dev')} icon={<CodeRoundedIcon fontSize='large' />} />;
    return (
      // <Grow in={isDev} style={{ transitionDelay: isDev ? '3000ms' : '0ms' }} >
      <IconButton
        name='dev-icon-button'
        aria-label="developer button"
        size={size}
        onClick={() => props.history.push('/dev')}
        icon={<CodeRoundedIcon fontSize={fontSize} />}
      />
      // </Grow>
    );
  else
    return null;
}

export default DevIconButton;

// DevIconButton.propTypes = {
//   open: PropTypes.bool,
//   handleOpenDrawer: PropTypes.func,
// };
