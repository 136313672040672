import { onError } from "@apollo/client/link/error";
// import { onError } from 'apollo-link-error';
// import { valueFromASTUntyped } from 'graphql';

// [Apollo Client: Error handling](https://www.apollographql.com/docs/react/v2/data/error-handling/)
// [Apollo Server: Error handling](https://www.apollographql.com/docs/apollo-server/data/errors/)
// [`apollo-link-error`](https://www.apollographql.com/docs/link/links/error/)

export class AuthError extends Error {
  constructor(message, code, ...params) {
    super(message, code, ...params);

    this.name = 'AuthError';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }

    this.code = code;
  }
}

// function errorResponse({ path, error }) {
//   // console.log('errorResponse', path, error);
//   return {
//     data: {
//       [path]: {
//         success: false,
//         data: null,
//         error: error,
//       }
//     },
//     // error: undefined,
//   };
// }

export const errorLink = onError(({ operation, response, graphQLErrors, networkError, forward }) => { // eslint-disable-line
  // console.error('----- errorLink -----', Object.getOwnPropertyNames(networkError));
//  console.warn('----- errorLink -----');
  // console.error('errorLink', 'operation', operation);
  // console.error('errorLink', operation.operationName, operation.variables);
  // console.error('errorLink', operation.operationName);
//  console.warn('operation:', operation);
  // console.warn('operationName:', operation.operationName);
  // console.warn('extensions:', operation.extensions);

  if (response?.errors) {
    // console.warn('response:', response);
    // console.error(`${response.errors.length} error(s) found.`);
    response.errors.forEach(error => {
      // console.error(error?.extensions?.name, error?.extensions?.code, `'${error.message}'`);
      console.error('errorLink:', operation.operationName, error?.extensions?.name, error?.extensions?.code, `'${error.message}'`);

      if (error.message === 'TypeError: String cannot represent value')
        console.error('Check your GraphQL schema. It looks like you are not returning what your schema expected.');
    });
    // console.info('');
    // throw response.errors[0]; // this doesn't work. apollo-client doesn't seem to catch errors thrown here.
  }

//  console.warn('networkError:', networkError);
//  console.warn('graphQLErrors:', graphQLErrors);
//  console.warn('forward:', forward);
  // graphQLErrors?.forEach(error => {
  //   console.warn('graphQLErrors:', error.extensions?.code, error.message);
  //   // return error;
  //   // throw error;
  //   // if (error.extensions?.code === 'SESSION_NOT_FOUND')
  //   //   // throw new AuthError('Session not found', 'SESSION_NOT_FOUND');
  //   //   throw new Error('Session not found');
  // });

  if (networkError) {
    console.error('errorLink:', 'networkError', networkError.statusCode, networkError.message);

    if (networkError?.result?.errors) {
      let { errors } = networkError.result;
      // console.info(`${errors.length} networkError(s) found`);
      for (let index in errors) {
        let error = errors[index];
        // console.error('errorLink', 'networkError', errors[index]?.extensions?.name, errors[index]?.extensions?.code, `'${errors[index].message}'`);
        console.error('errorLink:', 'networkError', operation.operationName, error?.extensions?.name, error?.extensions?.code, `'${error.message}'`);
        // if (errors[index]?.extensions?.code && forward.includes(errors[index].extensions.code))
        //   throw new AuthError(errors[index].message, errors[index].extensions.code);
        // response = errors[index];
        // break;
        // throw errors[index]; // this doesn't work. apollo-client doesn't seem to catch errors thrown here.
      }
      // response = errors[index];
    }
  }

  if (graphQLErrors) {
    // console.info(`${graphQLErrors.length} graphQLErrors(s) found`);
    for (let index in graphQLErrors) {
      let error = graphQLErrors[index];
      // console.error('errorLink', 'graphQLErrors', graphQLErrors[index]?.extensions?.name, graphQLErrors[index]?.extensions?.code, `'${graphQLErrors[index].message}'`);
      // throw graphQLErrors[index]; // this doesn't work. apollo-client doesn't seem to catch errors thrown here.
      console.error('errorLink:', 'graphQLErrors', operation.operationName, error?.extensions?.name, error?.extensions?.code, `'${error.message}'`);
    }
  }

  // response.errors = null;

  // console.warn('forward:', forward);
  // console.error('errorLink', 'response', response);
  // console.error('errorLink', 'data', response?.data);
  // if (response.errors) {
  //   console.error(`${response.errors.length} errors`);
  //   response.errors.forEach(elem => {
  //     console.error(elem.extensions.code, elem.message);
  //     // console.error(elem.locations); // [ { line: 2, column: 3 } ]
  //     // console.error(elem.path); // [ 'confirmResetPassword' ]
  //     // console.error(elem.extensions);
  //     // console.error(elem.extensions.code); // 'UNAUTHENTICATED'
  //     // console.error(elem.extensions?.exception);
  //     // console.error(elem.extensions?.exceptions?.stacktrace);
  //   });
  // }
  // console.error('errorLink', 'graphQLErrors', graphQLErrors);
  // console.error('errorLink', 'networkError', networkError);

  // Invariant Violation: mutation option is required. -- this probably means the requested mutation is not exported from the Client Schema...

  // https://stackoverflow.com/questions/57902650/apollo-error-link-catching-errors-and-rendering-a-custom-react-component
  // const { cache } = operation.getContext();
  // if (graphQLErrors || networkError) {
  //   cache.writeQuery({
  //     query: GET_ERROR,
  //     data: {
  //       error: {
  //         __typename: 'error',
  //         message: graphQLErrors[0].message,
  //         statusCode: networkError.statusCode,
  //       },
  //     },
  //   });
  // }

  // // networkError called when the error comes below errorLink in the link chain -- is that correct?
  // if (networkError) {
  //   console.info('  ----- networkError -----');
  //   console.error(`[Network error]: ${networkError}`);
  //   console.warn(Object.keys(networkError));
  //   // console.info('getOwnPropertyNames', Object.getOwnPropertyNames(networkError));
  //   // console.info('statusCode', networkError.statusCode); // e.g. '502'
  //   // console.info('name', networkError.name); // e.g. 'ServerParseError'
  //   // console.info('message', networkError.message); // e.g. 'ServerParseError'
  //   // console.info('bodyText', networkError.bodyText); // ??
  //   // console.info('response', networkError.response);

  //   if (networkError.name === 'ServerParseError') // aka 502
  //     console.warn(`${networkError.statusCode}: This is probably due to a typo in your graphQL schema. Make sure to check for spelling mistakes and/or missing punctuation like ':'`);
  //   else if (networkError.name === 'TypeError' && networkError.message === 'Failed to fetch') {
  //     console.warn(`This is probably a failure to reach or connect to the server`);
  //   }

  //   // if (graphQLErrors)
  //   //   networkError.response.errors = graphQLErrors;

  //   console.info('  ---------');
  // }

  // if (graphQLErrors) {
  //   console.info('  ----- graphQLErrors -----');

  //   if (graphQLErrors[0].message === 'Context creation failed: Session not found') {
  //     // response = {success: true, data: 'scooby', error: graphQLErrors[0] };
  //     console.log('response', response);
  //     // throw graphQLErrors[0]; // doesn't work
  //     // response = graphQLErrors[0]; // doesn't work
  //     // response.errors = graphQLErrors; // doesn't work
  //     // response = {
  //     //   errors: graphQLErrors,
  //     // };
  //   }

  //   graphQLErrors.forEach(error => {
  //     let propertyNames = Object.getOwnPropertyNames(error);
  //     console.info('graphQLErrors', 'getOwnPropertyNames', propertyNames);
  //     propertyNames.forEach(property => {
  //       console.info(property, error[property]);
  //     });
  //   });
  //   graphQLErrors.map(({ message, locations, path, extensions }) => {
  //     // console.error(`[GraphQL error]: Path: ${path}, Message: ${message}, Location: ${JSON.stringify(locations)}`);
  //     console.error(`[GraphQL error]: ${path} ${extensions.code}: ${message}`,);
  //     // console.log('*** test ***', message === 'Cannot return null for non-nullable field Mutation.'+path);
  //     // console.log('*** test ***', message, 'Cannot return null for non-nullable field Mutation.'+path);

  //     if (extensions.code === 'BAD_USER_INPUT') // ApolloError
  //       // errorResponse({ path, error: graphQLErrors });
  //       // return errorResponse({ path, error: { path, message, ...extensions } });
  //       return forward(operation);

  //     if (extensions.code === 'UNAUTHENTICATED') { // AuthenticationError
  //       // response = { success: false, data: null, error: { message, code: extensions.code } };
  //       // return errorResponse({ path, error: { path, message, ...extensions } });
  //       return forward(operation);
  //     }

  //     if (extensions.code === 'GRAPHQL_VALIDATION_FAILED') {
  //       if (message.startsWith('Cannot query field')) {
  //         console.warn('This is most likely due to an invalid client schema.');
  //         // response = errorResponse({ path, error: graphQLErrors });
  //       } else if (message.startsWith('Variable')) {
  //         console.warn('This is most likely due to an incorrect schema input definition in the client schema.');
  //         // response = errorResponse({ path, error: graphQLErrors });
  //       } else
  //         console.warn(`errorLink: ${path} '${message}' not found in 'GRAPHQL_VALIDATION_FAILED'.`);
  //       // switch(message) {
  //       //   // case 'Cannot query field "groups" on type "ConfirmSignUpResponse".':
  //       //   case message.startsWith('Cannot query field'):
  //       //     response = errorResponse({ path, error: graphQLErrors });
  //       //     break;
  //       //   default:
  //       //     console.warn(`errorLink: '${message}' not found in 'GRAPHQL_VALIDATION_FAILED'`);
  //       // }
  //       // return forward(operation);
  //     }

  //     if (extensions.code === 'INTERNAL_SERVER_ERROR') {
  //       switch(message) {
  //         case 'This email has not started the sign up process.':
  //           response = errorResponse({ path, error: graphQLErrors });
  //           break;
  //         case 'You have already started the sign up process but the confirm code has expired. Please request a new code.':
  //           response = errorResponse({ path, error: graphQLErrors });
  //           break;
  //         case 'This email is already registered.':
  //           response = errorResponse({ path, error: graphQLErrors });
  //           break;
  //         default:
  //           console.warn(`errorLink: '${message}' not found in 'INTERNAL_SERVER_ERROR'`);
  //       }
  //     } else
  //       console.error('errorLink', 'graphQLErrors', graphQLErrors);
  //     // if (extensions.code !== 'INTERNAL_SERVER_ERROR') {
  //     //   switch(message) {
  //     //     case 'Cannot return null for non-nullable field Mutation.'+path+'.':
  //     //       console.warn(`This is likely because the Mutation ${path} is not defined in the API schema.`);
  //     //       break;
  //     //     default:
  //     //       console.warn(`'${message} not found in errorLink`);
  //     //   }
  //     // }
  //     if (message === 'Cannot return null for non-nullable field Query.'+path+'.')
  //       console.warn(`This is likely because the Query ${path} is not defined in the API schema.`);
  //     else if (message === 'String cannot represent value: { message: "validationError" }')
  //       console.warn(`This is likely because the wrong data type was passed from (or to?) ${path}.`);

  //     // return forward(operation);
  //   });
  //   console.info('  ---------');
  //   console.info('---------');
  // }
  // console.warn('----- END ErrorLink -----');
  // console.info('\n');


  // console.warn('errorLink:', operation.operationName, code, message);

  // forward(operation);
});
