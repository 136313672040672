import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { default as MuiCollapse } from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
// import { makeStyles } from '@material-ui/core/styles';

// const location = 'client.components.core';

// http://reactcommunity.org/react-transition-group/transition
// https://material-ui.com/components/transitions/#transitions
// https://material-ui.com/components/app-bar/#usescrolltrigger-options-trigger

// const useStyles = makeStyles((theme) => ({
//   scrollTop: {
//     position: 'fixed',
//     // bottom: theme.spacing(10.75),
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   upIcon: {
//     backgroudColor: 'rgb(220, 0, 78)',
//   }
// }));

// export function OnClick(props) {
//   const trigger = useScrollTrigger({
//     disableHysteresis: true,
//     threshold: props.threshold,
//   });
//   return props.children({ trigger, ...props });
// }

export default function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect(); };
  }, []);

  return isIntersecting;
}

// This works!!! How can I use IntersectionObserver to make my animation smoother?
export const AmOnScreen = () => {
  
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  
  return <div ref={ref}>{isVisible && `Yep, I'm on screen`}</div>;
};

// export function ScrollOn(props) {
//   let { y, start, stop } = props;
//   let dist = stop - 
//   let ty = (y < stop) ? y : y - ();
//   let y1 = (y <= 293) ? y : y - (y - 293);
//   let ty
//   console.log(s, y, y1);
//   return(
//     <div style={{ transform: `translateY(${props.y}px)` }} >
//       {props.children}
//     </div>
//   );
// }

export function OnScroll(props) {
  const [ isOn, setOn ] = useState(false);
  let { sticky, ...rest } = props;

  sticky = sticky ? sticky : false;

  const trigger = useScrollTrigger({
    disableHysteresis: false,
    threshold: props.threshold,
  });

  // if (!isOn)
  //   console.log('onScroll', sticky, !isOn, trigger, sticky && !isOn && trigger);

  if (sticky) {
    if (trigger && !isOn)
      setOn(true);
    // trigger = isOn
  }
  // else if (trigger)
  //   setOn(true);
  // else
  //   setOn(false);

  return props.children({ trigger: sticky ? isOn : trigger, ...rest });
}

export function CollapseIn(props) {
  let { trigger, ...rest } = props;
  return(
    <MuiCollapse in={trigger} {...rest} >
      {props.children}
    </MuiCollapse>
  );
}

CollapseIn.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function CollapseOut(props) {
  let { trigger, ...rest } = props;
  return(
    <MuiCollapse in={!trigger} {...rest} >
      {props.children}
    </MuiCollapse>
  );
}

CollapseOut.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function FadeIn(props) {
  // const [ isOn, setOn ] = useState(false);
  let { trigger, sticky, ...rest } = props;
  // console.log(isOn, trigger);

  // if (sticky) {
  //   if (isOn)
  //     trigger = true;
  //   else if(!isOn && trigger === true)
  //     setOn(true);
  // }

  // console.log('FadeIn', trigger);

  return(
    <Fade in={trigger} {...rest} >
      {props.children}
    </Fade>
  );
}

FadeIn.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function FadeOut(props) {
  let { trigger, ...rest } = props;
  return(
    <Fade in={!trigger} {...rest} >
      {props.children}
    </Fade>
  );
}

FadeOut.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function GrowIn(props) {
  let { trigger, ...rest } = props;
  return(
    <Grow in={trigger} {...rest} >
      {props.children}
    </Grow>
  );
}

GrowIn.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function GrowOut(props) {
  let { trigger, ...rest } = props;
  return(
    <Grow in={!trigger} {...rest} >
      {props.children}
    </Grow>
  );
}

GrowOut.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

// direction='up' | 'down' | 'left' | 'right'
export function SlideIn(props) {
  // const [ isOn, setOn ] = useState(false);
  let { trigger, sticky, ...rest } = props;

  // if (trigger && sticky && !isOn) {
  //   setOn(true);
  // }
  // console.log('trigger', trigger);
  // if (sticky) {
  //   if (isOn)
  //     trigger = true;
  //   else if(!isOn && trigger === true)
  //     setOn(true);
  // }
  // console.log('isOn', isOn);

  return(
    <Slide in={trigger} direction={props.direction ? props.direction : 'right'} {...rest} >
      {props.children}
    </Slide>
  );
}

SlideIn.propTypes = {
  direction: PropTypes.string,
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function SlideOut(props) {
  let { trigger, ...rest } = props;

  return(
    <Slide in={!trigger} direction={props.direction ? props.direction : 'right'} {...rest} >
      {props.children}
    </Slide>
  );
}

SlideOut.propTypes = {
  direction: PropTypes.string,
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function ZoomIn(props) {
  let { trigger, ...rest } = props;
  return(
    <Zoom in={trigger} {...rest} >
      {props.children}
    </Zoom>
  );
}

ZoomIn.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export function ZoomOut(props) {
  let { trigger, ...rest } = props;
  return(
    <Zoom in={!trigger} {...rest} >
      {props.children}
    </Zoom>
  );
}

ZoomOut.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.element.isRequired,
};
