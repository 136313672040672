import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  FormUp2, PrimaryButton, TextField, EmailField, TextArea, SmallLinkButton, AddFab,
} from '@org/client-components-core';
import { 
  FlexRow, Heading, FormLayout, FormMessage, FormError,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { AuthenticatedHandler } from '@org/client-graphql';
import { InvitationClass } from '@org/common-classes/Invitation';
import { firstNameShape, lastNameShape, emailShape } from '@org/common-yup';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';

let invitationClass;

let initialValues;

let requiredValues;

let validationShape = {
  firstName: firstNameShape,
  lastName: lastNameShape,
  email: emailShape,
    // .required("Email is required"),
  message: yup.string().trim(),
};
const validationSchema = yup.object().shape(validationShape);

let authenticatedHandler;

const useStyles = makeStyles(styles);

let isMounted = false;

export function CreateApplication(props) {
  // console.log('CreateApplication', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: 'Coming soon...', formError: null,
  });

  let classes = useStyles();

  if (!authenticatedHandler)
  authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  if (state.isLoading) {
    let input = {
      message: "",
    };
    invitationClass = new InvitationClass(input);

    initialValues = invitationClass.values();
    // console.log('CreateInvitation', 'initialValues', initialValues);
    requiredValues = invitationClass.requiredValues;
  }

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});

    return () => { isMounted = false; };
  }, []);

  function handleAddClick(props) {
    // console.log('handleAddClick', props);

    props.resetForm();
    setState({ ...state, isCompleted: false, formMessage: null, formError: null });
  }

  async function handleSubmit(_event, _form) {
    // console.log('CreateInvitation', 'handleSubmit', event, form);
    setState({ ...state, isSubmitting: true, formError: null });

    try {
      let input = invitationClass.getInput();
      // console.log('CreateInvitation', 'handleSubmit', 'input', input);

      // let response = await authorizedHandler.initiateInvitation(input);
      // console.log('CreateInvitation', 'handleSubmit', 'response', response);
      // await authenticatedHandler.initiateInvitation(input);

      setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Invitation successfully created', formError: null });
    } catch (error) {
      // console.error('CreateInvitation', error);
      setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  return (
    <>
    <FormUp2
      id='create-application-form'
      {...props}
      initialValues={initialValues}
      requiredValues={requiredValues}
      validationSchema={validationSchema}
      // calculateValues={calculateValues}
    >
      {formProps => {
      {/* let enabled = (props.isValidated && !state.isSubmitting && !state.isCompleted); */}
      let { fieldProps } = formProps;
      {/* console.log('CreateInvitation', 'fieldProps', fieldProps); */}
      let fieldEnabled = !state.isLoading && !state.isSubmitting && !state.isCompleted;
      {/* let addEnabled = state.isCompleted; */}
      {/* let addEnabled = true; */}
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          {/* <FlexRow style={{ justifyContent: 'space-between', margin: theme.spacing(0, 0, 1, 0) }} >
            <SmallLinkButton {...props} title='Back' to='/partner' />
            <AddFab onClick={() => handleAddClick(formProps)} disabled={!addEnabled} />
          </FlexRow> */}

          <Heading>{'Loan Application'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField
                { ...fieldProps }
                name='firstName'
                type='string'
                label='First Name'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <TextField
                { ...fieldProps }
                name='lastName'
                type='string'
                label='Last Name'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <EmailField
                { ...fieldProps }
                name='email'
                // type='email'
                // label='Email'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <TextArea
                { ...fieldProps }
                name='message'
                // type='string'
                label='Message'
                multiline={true}
                // rows={3}
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.formButtonSpan)} >
              <PrimaryButton {...props.submitProps} title='Submit' disabled={!submitEnabled}
                onClick={(event) => handleSubmit(event, formProps)} />
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: formProps }} open={true} />
        </>
      );
      }}
    </FormUp2>
    </>
  );
}

export default CreateApplication;

CreateApplication.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  resetForm: PropTypes.func,
};
