import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import {
  Form, TextField, EmailField, PasswordFieldTooltip,
  ButtonBar, PrimaryButton,
  BackdropLoader
} from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { handleError } from '@org/common-errors';
import { styles } from '@org/client-libs-styles';
import { PublicHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

let defaultValues = {
    email: "",
    confirmCode: "",
    password: "",
  };

let stateValues = {};

let isMounted = false;
let publicHandler;

export function ConfirmResetPassword(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, isCompleted: false, formError: null, formMessage: 'Please check your email and enter the code here' });
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) {
        window.scrollTo(0, 0);
        if (isMounted) {
          if (props?.location?.state)
            stateValues = props.location.state;
          setState({ ...state, isLoading: false });
        }
      }
    })();
    
    return () => { isMounted = false; };
  }, []);

  async function handleResendCode(event, form) {
    event.preventDefault();
    let { email } = form.values;

    try {
      if (!form.validated['email'])
        throw new Error('Please enter a valid email address');

      let input = { email };

      let response = await publicHandler.resendResetPassword(input);
      console.log('ConfirmResetPassword', response);

      if (response.success)
        if (state.formMessage === 'New code sent')
          setState({ ...state, formMessage: 'Don’t forget to check your spam folder', formError: null });
        else
          setState({ ...state, formMessage: 'New code sent', formError: null });
      if (response.error)
        throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, formError: handleError({ error }) });
    }
  }

  async function handleSubmit(event, form) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });

    try {
      let { email, password, confirmCode } = form.values;
      let input = { email, password, confirmCode };

      let response = await publicHandler.confirmResetPassword(input);

      await publicHandler.handleAuthentication(response);

      // if (isMounted)
      //   setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Your password has been successfully reset', formError: null});
      props.history.push({ pathname: '/success', state: { title: 'Reset Password', message: 'Your password has been successfully reset' } });
    
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    {!state.isLoading &&
    <Form defaultValues={defaultValues} stateValues={stateValues} validationSchema={authSchema} {...props} >
      {props => {
      let enabled = props.isValidated && !state.isSubmitting;
      return (
        <>
        <FormLayout id='confirm-reset-password' >

          <Heading>{'Confirm Reset'}</Heading>

          <Grid id='confirm-reset-password' style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {!state.isCompleted && !stateValues.email && // show email if it was not passed in from props.location.state
            <Grid item >
              <EmailField {...props.fieldProps} name='email' required={true} />
            </Grid>}

            {!state.isCompleted &&
            <>
            <Grid item >
              <TextField {...props.fieldProps}
                name='confirmCode'
                label='Confirm Code'
                required={true}
              />
            </Grid>

            <Grid item >
              <PasswordFieldTooltip name='password' {...props.passwordProps} required={true} text="New Password" />
            </Grid>

            <Grid item >
              <ButtonBar>
              <PrimaryButton
                {...props.submitProps}
                name='confirm-reset-password'
                title='Submit'
                disabled={!enabled}
                onClick={(event) => handleSubmit(event, props)}
              />
              </ButtonBar>
            </Grid>

            <Grid item className={classes.bottomLink} >
              <Link variant='body2' component="button" onClick={(event) => handleResendCode(event, props)} >
                {'Resend confirmation code'}
              </Link>
            </Grid>
            </>}

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ local: state, defaultValues, stateValues, form: props.stateProps }} open={true} />
        </>
      );
      }}
    </Form>}
    </>
  );
}

ConfirmResetPassword.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  passwordProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
};
