// let location = 'common.errors';
// copied here from server-libs-aws for client
// URGENT Replace the version used in the server code with this one and remove the version in server-libs-aws
// export const handleError = function(location, thrown, _message) {
export const handleError = function(props) {
  // console.error(location, 'handleError', 'props', props);
  // let { location, error, _message } = props;
  if (!props.error) {
    console.warn(`***** Improper usage of handleError: must be passed an error inside props object: handleError({ error })`);
    console.warn(`handleError props: ${props}`);
    return {
      message: `***** Improper usage of handleError: must be passed an error inside props object: handleError({ error })`,
    };
  }
  let { error } = props;
  let plain = {};
  Object.getOwnPropertyNames(error)?.forEach(function(key) {
    plain[key] = error[key];
  });
  // console.error(location, 'handleError', plain);
  return plain;
};
