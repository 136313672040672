import React, { useEffect } from 'react';
import { ExLink } from '@org/client-components-core';
import {
  LearnTabs, Page, Heading, PageHeadingWrapper,
  // PageHeadingLeft, PageHeadingCenter, PageHeadingRight,
  Section, SectionHeading, SectionBody,
} from '@org/client-components-custom';

export function Learn() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <>
    <LearnTabs>

      <Page id='learn' >

        <Section>
          <PageHeadingWrapper id='who' >
            <Heading>{'A Financial Services Platform Offering\nNontraditional Credit Investments'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='Our Mission' />
            <SectionBody>
              {`${process.env.REACT_APP_LEGAL_NAME} was created to provide access for eligible individuals to credit investment return profiles which have historically been available only to large, institutional investors.`}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Our Solution' />
            <SectionBody>
              {'We create fractionalized credit investments in the form of Payment Dependent Notes through our web app and investment platform.'}
            </SectionBody>

            <SectionBody>
              {'Payment Dependent Notes reference direct investments owned by Cubit, and payments made on the reference assets are passed through to the Notes investors (less fees and expenses).'}
            </SectionBody>

            <SectionBody>
              {'Reference investments are typically loans purchased from, or originated in partnership with, experienced lenders across a variety of asset classes. Loans typically have maturities between 6 and 36 months, pay a monthly coupon, and are secured by real estate or other collateral and/or payment assurances.'}
            </SectionBody>
          </Section>

        </Section>

        <Section>
          <PageHeadingWrapper id='what' >
            {/* <PageHeadingLeft />
            <PageHeadingCenter>{'Decades of Experience in Alternative Credit\nCombined with Cutting Edge Technology'}</PageHeadingCenter>
            <PageHeadingRight /> */}
            <Heading>{'Decades of Experience in Alternative Credit\nCombined with Cutting Edge Technology'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='Investment Experience' />
            <SectionBody>
              {'Through our extensive alternative lending relationship network we have access to a variety of different asset types and credit profiles. In addition, due to the breadth of our asset class experience and depth of our credit markets experience, we can introduce new asset types and limit acquisitions of existing asset types based on economic, market, and product-specific factors.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Due Diligence' />
            <SectionBody>
              {'Once identified, potential investment assets and/or new originators undergo extensive research and analysis, commonly referred to as due diligence, before an investment is made.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Asset Acquisition' />
            <SectionBody>
              {'If the investment passes our due diligence process, we then work with the seller and/or lender partner to create the best asset acquisition process. This could be individual or bulk loan purchases, a forward flow origination program, or other custom-tailored solutions.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Asset Management' />
            <SectionBody>
              {'Cubit Investments monitors realtime investment performance, provides detailed performance reporting, and processes automated electronic payments. In some cases we may also act as the servicer or master servicer. In the event of an asset performance issue, we would work with the lender and/or borrower in order to cure the loan and, if necessary, rigorously pursue legal action on behalf of our note investors.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Loss Mitigation' />
            <SectionBody>
              {'Unlike the majority of Payment Dependent Notes issued on small ticket, unsecured consumer loans, we focus on larger loan sizes typically secured by real assets. Our target assets have numerous advantages for Payment Dependent Note investors, such as deterring potential strategic default, giving better negotiating leverage in a workout situation, and making collections on non-performing assets economically viable due to our ability to recover and sell the underlying asset.'}
            </SectionBody>
          </Section>

        </Section>

        <Section>
          <PageHeadingWrapper id='how' >
            {/* <PageHeadingLeft />
            <PageHeadingCenter>{'Understanding Payment Dependent Notes \n and How We Use Them'}</PageHeadingCenter>
            <PageHeadingRight /> */}
            <Heading>{'Understanding Payment Dependent Notes\nand How We Use Them'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='The Notes' />
            <SectionBody>
              {'Payment Dependent Notes are a novel credit instrument which pay investors based on the performance of a reference asset, yet are solely the obligation of the issuer, not the asset. While relatively new, well over $50 billion of these notes have been issued in the past 10 years. Payment Dependent Notes are categorized by the SEC as securities. '}{<ExLink variant='body1' title='Learn more about Payment Dependent Notes.' to='https://www.investopedia.com/terms/m/member-payment-dependent-note.asp' />}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Note Advantages' />
            <SectionBody>
              {'Credit investments and whole loan transactions are complex and expensive due the physical nature and legal costs of the transactions. In addition, institutional investors are reluctant to share direct ownership of loans due to potential legal complications. As a result, individual investors are virtually excluded from participation in the asset class.'}
            </SectionBody>
            <SectionBody>
              {'In contrast, Payment Dependent Notes are straightforward, passthrough participations and can be issued quickly and easily. And, because they do not share direct ownership of the loan, the Cubit Investments platform can manage and defend all investor interests equally.'}
            </SectionBody>
            <SectionBody>
              {'Our Payment Dependent Notes are registered securities and investors receive a 1099 rather than a K-1.'}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Note Considerations' />
            <SectionBody>
              {'While our Notes are not secured, they are issued from special purpose entities which are bankruptcy remote and issued pursuant to a trust agreement. Each physical Note is pledged and delivered to the indenture trustee for the benefit of the respective Note owners in order to perfect the investors’ security interest in the Note. In the event of a corporate event of default, the trustee will ensure payments continue to be made to the Notes as agreed upon. Finally, as an additional layer of protection and to complete the perfection of the security interest, a '}{<ExLink variant='body1' title='UCC financing statement' to='https://en.wikipedia.org/wiki/Uniform_Commercial_Code' />}{' is filed whenever possible.'}
            </SectionBody>
          </Section>

        </Section>

        <Section>
          <PageHeadingWrapper id='tech' >
            {/* <PageHeadingLeft />
            <PageHeadingCenter>{'Cutting Edge Technology'}</PageHeadingCenter>
            <PageHeadingRight /> */}
            <Heading>{'Cutting Edge Technology'}</Heading>
          </PageHeadingWrapper>

          <Section>
            <SectionHeading title='Progressive Web Apps' />
            <SectionBody>
              {'Platform independent, browser-rendered applications ('}{<ExLink variant='body1' title='SaaS' to='https://en.wikipedia.org/wiki/Software_as_a_service' />}{') offer the best of all worlds. Users have all the functionality and performance of a native application, the security provided by a modern web browser, real-time software updates, and all the infinite possibilities of an internet-connected, cloud-based platform. Our App is mobile-first by design and works in all modern browsers and operating systems. '}{<ExLink variant='body1' title='Learn more about Progressive Web Apps.' to='https://en.wikipedia.org/wiki/Progressive_web_application' />}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Serverless Computing' />
            <SectionBody>
              {'In the next evolution of cloud-based managed serivices, serverless web apps interact with provisioned functions accessed through an API gateway ('}{<ExLink variant='body1' title='FaaS' to='https://en.wikipedia.org/wiki/Function_as_a_service' />}{') rather than website pages delivered by a web server. None of our functions allow dynamic access or functionality. This means that there is no identifiable server to be hacked and other attack vectors are either eliminated or dramatically limited. '}{<ExLink variant='body1' title='Learn more about Serverless Computing.' to='https://en.wikipedia.org/wiki/Serverless_computing' />}
            </SectionBody>
          </Section>

          <Section>
            <SectionHeading title='Provisioned Services' />
            <SectionBody>
              {'Not only do we leverage serverless computing for the efficiency and security advantages, we have built an entirely provisioned services model ('}{<ExLink variant='body1' title='IaaS' to='https://en.wikipedia.org/wiki/Infrastructure_as_a_service' />}{').'}
            </SectionBody>
            <SectionBody>
              {'Among the many advantages of this model:'}
            </SectionBody>
            <SectionBody>
              <ul style={{ margin: 0 }} >
                <li>{'Dramatically lower startup and operating costs'}</li>
                <li>{'No infrastructure hardware needs to be purchased, housed, or maintained'}</li>
                <li>{'No IT staff are needed'}</li>
                <li>{'Infinitely scalable (effectively)'}</li>
              </ul>
            </SectionBody>
            <SectionBody>
              {'Without adding any infrastructure or hiring any personnel, our services are scalable to millions of users executing billions of transactions out of the box.'}
            </SectionBody>
          </Section>

        </Section>

      </Page>

    </LearnTabs>
    </>
  );
}

export default Learn;
