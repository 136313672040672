import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
// import { default as MuiCheckbox } from '@material-ui/core/Checkbox';
import { default as MuiSelect } from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    // margin: theme.spacing(1),
    // minWidth: 120,
    width: 'auto',
    minWidth: 'fit-content',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'flex-start',
    // margin: theme.spacing(0, 0, 0, 0),
    width: 'auto',
    minWidth: 'fit-content',
  },
  selectInput: {
    display: 'flex',
    flexDirection: 'row',
    // alignItems: 'flex-start',
    // margin: theme.spacing(0, 0, 0, 0),
    width: 'auto',
    minWidth: 'fit-content',
  },
  check: {
    padding: '0 9px 0px 0'
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0.5, 2, 0, 2),
  }
}));

export function Select(props) {
  // console.log('Select', 'props', props);
  const classes = useStyles();
  let { name, values, errors, label, options, handleChange, handleBlur, ...rest } = props;
  let value = (values && name && values[name] ? values[name] : "");
  let error = (name && errors[name]) ? true : false;
  let helperText = errors[name];
  // console.log('Checkbox', error, helperText);
  let type = 'select';
  return(
    <div name={name} className={classes.root} >
      {/* <FormControlLabel
        className={classes.agree}
        control={<MuiCheckbox
          value={value}
          color='primary'
          className={classes.check}
          // onChange={(event) => handleChange(event, type)}
          // error={error}
          // helperText={error && errors[name]}
        />}
        label={label}
        {...props}
      /> */}
      <FormControl /* className={classes.formControl} */ variant='outlined' type='select' >
        <InputLabel /* className={classes.selectLabel} id="select-label"*/ id={`${name}-label`} >{props.label}</InputLabel>
        <MuiSelect
          {...rest}
          // className={classes.selectInput}
          // labelId='select-label'
          // id='select'
          labelId={`${name}-label`}
          id={name}
          name={name}
          // label="Age"
          value={value}
          type='select' // this one or the one above?
          onChange={(event) => { console.log('xxx', name, type); handleChange(event, type); } }
          onBlur={(event) => handleBlur(event, name)}
          required={props.required ? props.required : false}
          disabled={props.disabled}
          // style={{minWidth: 'fit-content'}} // doesn't work
          // autoWidth={true} // doesn't work
        >
          <MenuItem value="">
            <em>{label}</em>
          </MenuItem>
          {/* <MenuItem value='residential' >{'Residential Real Estate'}</MenuItem>
          <MenuItem value='multifamily' >{'Multifamily Real Estate'}</MenuItem>
          <MenuItem value='commercial' >{'Commercial Real Estate'}</MenuItem> */}
          {options.map((elem, index) => {
            return (
              <MenuItem key={index} value={elem.value} >{elem.text}</MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
      {error && 
      <FormHelperText className={classes.error} >{helperText}</FormHelperText>}
    </div>
  );
}

Select.propTypes = {
  name:  PropTypes.string,
  value:  PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
};
