import React from "react";
import PropTypes from 'prop-types';
// import SvgIcon from '@material-ui/core/SvgIcon';
// import { Loader } from 'react-feather';
// import { ReactComponent as LoaderIcon } from './loader.svg';
// import { SvgIcon } from '@org/client-components-core';
import { LoaderIcon } from '@org/client-components-custom';
import theme from '@org/client-libs-themes';
import './loader.css';

const loaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '4rem',
  color: theme.palette.primary.main,
  animation: 'spin 2s infinite linear',
};

const centeredDivStyle = {
  width: 'auto',
  height: 'auto',
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  // top: 'calc(50% - 2.5rem + window.pageYOffset / 2)',
  top: 'calc(50% - 2rem)',
  left: 'calc(50% - 2rem)',
  // marginLeft: '-(10/2)rem',
  // marginTop: '-(10/2)rem',
  //backgroundColor: 'white',
  //borderRadius: '50%',
  zIndex: '9999',
};

// export function Loader(props) {
//   return (
//     <LoaderIcon viewBox="0 0 500 500" fontSize='large' {...props} />
//   );
// }

  //   {/* {(props.pageLoading || props.isLoading) && */}
  //   <div style={centeredDivStyle} >
  //   {/* <Loader style={spinnerStyle} /> */}
  //   {/* <div style={spinnerStyle} > */}
  //     {/* <img src={process.env.PUBLIC_URL + '/loader.svg'} alt='loader.svg' height={50} style={spinnerStyle} /> */}
  //     {/* <SvgIcon component={LoaderIcon} viewBox="0 0 500 500" height={50} style={spinnerStyle} /> */}
  //   {/* </div> */}
  //   <LoaderIcon viewBox="0 0 500 500" fontSize='large' {...props} style={{loaderStyle}} />
  // </div>
  // {/* } */}

export function Loader() {
  // console.log(window, window.pageYOffset);
  return(
    // <div>
    <div style={centeredDivStyle} >
      <LoaderIcon style={loaderStyle} />
      {/* <LoaderIcon style={loaderStyle} /> */}
    </div>
  );
}

Loader.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  pageLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
};

export default Loader;
