import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import { userListItems } from './userListItems';
import { UserDrawerLinkList } from './UserDrawerLinkList';
import { makeStyles } from '@material-ui/core/styles';
import { appBarStyles } from './appBarStyles';

const useStyles = makeStyles(appBarStyles);

export function UserDrawer(props) {
  const classes = useStyles();

  let { open, handleCloseDrawer } = props;

  // const drawer = (
  //   <>
  //     <div
  //       id='user-drawer-header'
  //       className={clsx(classes.drawerHeader, classes.drawerHeaderRight)}
  //     >
  //       <IconButton name='user-drawer-icon-button' onClick={handleCloseDrawer} >
  //         <ChevronRightIcon fontSize='large' />
  //       </IconButton>
  //     </div>
  //     <Divider />
  //     <UserDrawerLinkList onClick={handleCloseDrawer} />
  //   </>
  // );

  return (
    <Drawer
      id='user-drawer'
      className={classes.drawer}
      // className={classes.drawerRight}
      // variant="persistent"
      // variant='temporary'
      anchor='right'
      open={open}
      classes={{ paper: classes.drawerPaper }} // deeper element access
      // ModalProps={{
      //   keepMounted: true // Better open performance on mobile.
      // }}
    >
      {/* {drawer} */}
      <div className={clsx(classes.drawerHeader, classes.drawerHeaderRight)} >
        {/* <CssBaseline /> */}
        <IconButton onClick={handleCloseDrawer} >
          <ChevronRightIcon fontSize='large' />
        </IconButton>
      </div>
      <Divider />
      <UserDrawerLinkList onClick={handleCloseDrawer} />
    </Drawer>
  );
}

export default UserDrawer;

UserDrawer.propTypes = {
  open: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
};
