import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import { Form, PasswordFieldTooltip, ExLink, PrimaryButton, SecondaryButton, LinkButton } from '@org/client-components-core';
import { PrimaryButton } from '@org/client-components-core';
import { 
  Heading, // HeadingWrapper, HeadingLeft, HeadingCenter, HeadingRight, HeadingTitle,
  FormMessage, FormError, // FormHeading, 
  // BackgroundLoader
} from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';
// import { publicResolvers } from '@org/client-graphql';
import { PublicHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

// let response;
let publicHandler;

export function VerifyInvitation(props) {

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    let isMounted = true;

    (async () => {
      // props.setState({ ...props.state, isLoading: true });

      try {
        let input = {
          messageId: props.state.data.invitation,
          confirmCode: props.state.data.c,
        };

        // response = await publicResolvers.verifyInvitation(input);
        let response = await publicHandler.verifyInvitation(input);
        // await publicHandler.verifyInvitation(input);
        console.log('VerifyInvitation', 'response', response);

        if (isMounted)
          props.setState({ ...props.state, values: { ...input, password: "" }, isLoading: false });

      } catch (error) {
        console.error('VerifyInvitation', error);
        props.setState({ ...props.state, isLoading: false, error });
      }
    })();

    return () => { isMounted = false; };
  }, []);

  // console.log('VerifyInvitation', 'props.state', props.state);

  // if (!response)
  if (props.state.isLoading)
    return (
      <VerifyingInvitation {...props} />
    );
  else if (props.state.error)
    return (
      <InvitationNotVerified {...props} />
    );
  else
    return (
      <InvitationVerified {...props} />
    );
}

export function VerifyingInvitation(_props) {

  return(
    <>
    <Heading>{'Verifying Invitation'}</Heading>

    <Grid style={{width: '100%'}} >

      <Grid item >
        <FormMessage message={'Just a moment...'} />
      </Grid>

    </Grid>
    </>
  );
}

InvitationVerified.propTypes = {
  state: PropTypes.shape(),
  setState: PropTypes.func,
};

VerifyInvitation.propTypes = {
  state: PropTypes.shape(),
  setState: PropTypes.func,
};

export function InvitationVerified(props) {
  let classes = useStyles();

  let buttonProps = { title: 'Begin', validated: true };

  return(
    <>
    <Heading>{'Invitation Verified'}</Heading>

    <Grid style={{width: '100%'}} >

      <Grid item >
        <FormMessage message='Just 3 quick steps to get you started' />
      </Grid>

      <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
        <PrimaryButton {...buttonProps}
          onClick={() => props.setState({ ...props.state, activeStep: 'changePassword', isLoading: true })} />
      </Grid>

    </Grid>
    </>
  );
}

InvitationVerified.propTypes = {
  state: PropTypes.shape(),
  setState: PropTypes.func,
};

export function InvitationNotVerified(props) {
  return(
    <>
    <Grid style={{width: '100%'}} >

      <Grid item >
        <Heading>{'Invitation Not Verified'}</Heading>
      </Grid>

      <Grid item >
        <FormError message={props?.state?.error?.message} />
      </Grid>

    </Grid>
    </>
  );
}

InvitationNotVerified.propTypes = {
  input: PropTypes.shape(),
  error: PropTypes.shape(),
  state: PropTypes.shape(),
};
