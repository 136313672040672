import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiIconButton } from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: theme.spacing(0.5),
  }
}));

export function IconButton(props) {
  let classes = useStyles();
  let { name, onClick, disabled, className, color, ariaLabel, icon } = props;
  // console.log('IconButton', name, className, typeof className);
  if (className && typeof className !== 'string')
    console.log('***IconButton Error***', name, className, typeof className);

  return(
    <MuiIconButton
      // ref={props.forwardRef}
      id={name}
      name={name}
      edge={false}
      size={props.size ? props.size : 'medium'}
      color={color ? color : 'inherit'}
      aria-label={ariaLabel ? ariaLabel : 'icon-button'}
      onClick={onClick}
      disabled={disabled ? true : false}
      className={className ? className : classes.iconButton}
      {...props}
    >
      {icon}
    </MuiIconButton>
  );
}

export default IconButton;
// export default React.forwardRef((props, ref) => <IconButton {...props} forwardedRef={ref} />);

IconButton.propTypes = {
  icon: PropTypes.shape(),
  onClick: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
};
