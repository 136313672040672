import theme from '@org/client-libs-themes';

export const styles = {
  // moved to FormFormat formLayout style
  // children: { // Container
  //   display: 'flex',
  //   flexDirection: 'column',
  //   padding: theme.spacing(4, 0, 4, 0), // put padding all the way around
  //   // add media breaks
  //   width: '100%',
  // },
  // moved to FormFormat, made part of formLayout style
  // paper: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // padding: theme.spacing(0, 4, 0, 4),
    // // margin: theme.spacing(0, 2, 0, 2),
    // // padding: theme.spacing(0, 4, 0, 4),
    // borderRadius: '4px',
    // // turn off shadow in xs
    // [theme.breakpoints.up('sm')]: {
    //   boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    // },
    // [theme.breakpoints.only('xs')]: {
    //   // padding: theme.spacing(2),
    //   boxShadow: '0px',
    // },
  // },
  // form: {
  //   width: '100%', // Fix IE 11 issue.
  // },
  formButtonSpan: {
    // margin: theme.spacing(4, 0, 5, 0),
    margin: theme.spacing(4, 0, 2, 0),
  },
  stepper: {
    backgroundColor: '#fafafa',
  },
  // centered: {
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 1, 0),
  },
  // passwordRequirements: {
  //   fontSize: '24px',
  //   margin: theme.spacing(0, 0, 0, 0),
  //   padding: theme.spacing(0, 0, 0, 0),
  // },
  // buttonSpan: {
  //   // margin: theme.spacing(4, 0, 5, 0),
  //   margin: theme.spacing(4, 0, 4, 0),
  // },
  // signUp: {
  //   margin: theme.spacing(0, 0, 3, 0),
  //   cursor: 'pointer',
  // },
  // bottomLink: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   // margin: theme.spacing(0, 0, 4, 0),
  //   margin: theme.spacing(0),
  // },
};
