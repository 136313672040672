import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextField } from '@material-ui/core/TextField';
import { CheckAdornment } from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';

// https://material-ui.com/components/text-fields/#integration-with-3rd-party-input-libraries
// https://www.npmjs.com/package/react-number-format
// https://codepen.io/s-yadav/pen/bpKNMa

const useStyles = makeStyles(styles);

function NumberFormatCustom(props) {
  // console.log('NumberFormatCustom', props);
  const { inputRef, onChange, ...rest } = props;

  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator // default: ','
      // decimalScale={2} // passed in ...other
      allowLeadingZeros={false} // default: false?
      // isNumericString={true} // default: false
      // prefix="$" // passed in ...other
      // suffix='%' // passed in ...other
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function NumberField(props) {
  let { name, values, errors, validated, autoFocus, required, handleChange, handleBlur, ...rest } = props;

  const classes = useStyles();

  // console.log('NumberField', 'validated', validated);
  let isValid = validated[name] && !props.disabled;

  if (name == null)
    return(<div>{`Missing 'name' prop`}</div>);

  if (values == null)
    return(<div>{`Missing 'values' prop`}</div>);

  if (values[name] == null && props.value == null)
    return(<div>{`Number field must have ${name} in 'values' prop or a 'value' prop override`}</div>);

  // if (values[name] && typeof values[name] !== 'number')
  //   return(<div>{`values[${name}] must be a number, received type ${typeof values[name]}`}</div>);

  // if (props.value && typeof props.value !== 'number')
  //   return(<div>{`value must be a number, received type ${typeof props.value} for ${name}`}</div>);

  let error = (errors && errors[name] ? true : false);

  let type = 'float';

  let value = props.value ? props.value : values[name];
  // console.log('NumberField', value);

  return(
    <MuiTextField
      // {...props} // Warning: React does not recognize the `handleChange` prop on a DOM element.
      {...rest}
      className={classes.inputField}
      variant="outlined"
      margin="normal"
      fullWidth
      label={props.label}
      //id={id}
      name={name}
      // type={type ? type : 'text'}
      type={type} // TODO Why do I need this?
      // value={values[name]}
      value={value}
      inputProps={props.inputProps} // these are passed to InputProps
      InputProps={{
        inputComponent: NumberFormatCustom,
        endAdornment: <CheckAdornment position="end" show={isValid} />,
      }}
      onChange={(event) => handleChange(event, name)}
      // onBlur={(event) => handleBlur(event, name)}
      onBlur={(event) => {
        event.target = { ...event.target, name, floatValue: value };
        handleBlur(event, name);
      }}
      error={error} // boolean
      helperText={error && errors[name]}
      required={required ? required : false}
      disabled={props.disabled}
      autoFocus={autoFocus ? autoFocus : false}
    />
  );
}

export default NumberField;

NumberField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  inputProps: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export function PhoneField(props) {
  let format = "";
  if (props.countryCode === '1') {
    format = "(###) ###-####";
  }
  return(<NumberField {...props} displayType={'text'} format={format} />);
}

PhoneField.propTypes = {
  countryCode: PropTypes.string.isRequired,
};

export function AmountField(props) {
  let inputProps = {
    fixedDecimalScale: true,
    decimalScale: 2,
    prefix:'$',
  };
  return(<NumberField {...props} inputProps={inputProps} />);
}

export function PercentField(props) {
  let inputProps = {
    suffix:'%',
  };
  return(<NumberField {...props} inputProps={inputProps} />);
}
