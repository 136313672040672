import React from 'react';
import PropTypes from 'prop-types';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { MainDrawerLinkList } from './MainDrawerLinkList';
import { makeStyles } from '@material-ui/core/styles';
import { appBarStyles } from './appBarStyles';

const useStyles = makeStyles(appBarStyles);

export function MainDrawer(props) {
  const classes = useStyles();
  let { open, handleCloseDrawer } = props;

  return (
    <Drawer
      id='main-drawer'
      className={classes.drawer}
      // className={classes.drawerPaper}
      // variant="persistent"
      // variant='temporary'
      anchor='left'
      open={open}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.drawerHeader}>
        {/* <CssBaseline /> */}
        <IconButton onClick={handleCloseDrawer} >
          <ChevronLeftIcon fontSize='large' />
        </IconButton>
      </div>

      <Divider />

      <MainDrawerLinkList onClick={handleCloseDrawer} />
    </Drawer>
  );
}

export default MainDrawer;

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
};
