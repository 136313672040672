import React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  scrollTop: {
    position: 'fixed',
    // bottom: theme.spacing(10.75),
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  upIcon: {
    backgroudColor: 'rgb(220, 0, 78)',
  }
}));

export function ScrollTopTrigger(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger} >
      <div onClick={handleClick} role="presentation" className={classes.scrollTop} >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTopTrigger.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export function ScrollTop(props) {
  const classes = useStyles();
  return(
    <ScrollTopTrigger {...props} >
      <Fab color="secondary" size="small" aria-label="scroll back to top" className={classes.upIcon} >
        <KeyboardArrowUpIcon  />
      </Fab>
    </ScrollTopTrigger>
  );
}

export default ScrollTop;
