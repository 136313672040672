export const fundInfo = {
  fundName: 'Cubit Principal Capital Fund',
  dated: 'November 2020',
  description: `Fund capital is used to purchase and/or fund the investment assets referenced in the payment dependent notes which are distributed via the ${process.env.REACT_APP_COMPANY_NAME} platform. A portion of the platform’s management fees are rebated to Fund investors.`,
  fundShortName: 'CPCF',
  fundType: '3(c)(1)',
  minInvestment: 250000,
  managementFee: 0.02, // percent
  incentiveFee: 0, // percent
  hurdle: 0, // percent
  lockup: 0, // months
  liquidity: '[ Quarterly ]',
  notice: 90, // days
  noticeDays: '[ 90 Days Notice ]',
  redemptionType: '[ Side Pocket ]',
  // managerName: `Lonesome Dove Capital Management, LLC`,
  managerName: `Cubit Capital Management, LLC`,
  manager: `Cubit Capital Management, a wholly-owned subsidiary of the ${process.env.REACT_APP_COMPANY_NAME} platform.`,
  administrator: '[ U.S. Bank ]',
  counsel: 'TBD',
  auditor: 'TBD',
  custodian: 'TBD',
  trustee: 'TBD',
};

// export default fundInfo;
