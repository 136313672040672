import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { default as MuiCheckbox } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  agree: {
    alignItems: 'flex-start',
    margin: theme.spacing(0, 0, 0, 0),
  },
  check: {
    padding: '0 9px 0px 0'
  },
  error: {
    color: theme.palette.error.main,
    margin: theme.spacing(0.5, 2, 0, 2),
  }
}));

export function Checkbox(props) {
  // console.log('Checkbox', 'props', props);
  const classes = useStyles();
  let {
    name, values, errors, label, disabled, handleChange,
    ...rest
  } = props;
  let value = (values && name && values[name] ? values[name] : false);
  // console.log('Checkbox', name, value);

  if (typeof value !== 'boolean')
    throw new Error(`Checkbox 'checked' value much be a boolean`);

  let error = (name && errors && errors[name]) ? true : false;
  let helperText = (errors && name && errors[name]) ? errors[name] : "";
  // console.log('Checkbox', values, name, value);
  return(
    // <div name={name} className={classes.root} >
    <div name={name} className={classes.root} >
      <FormControlLabel
        className={classes.agree}
        control={<MuiCheckbox
          // {...props}
          // {...rest}
          name={name}
          // value={value}
          checked={value}
          // onChange={props.handleChange}
          // onChange={(event) => handleChange(event, name)}
          onChange={handleChange}
          color='primary'
          className={classes.check}
          // onChange={(event) => handleChange(event, type)}
          // error={error}
          // helperText={error && errors[name]}
          disabled={disabled ? disabled : false}
        />}
        label={label}
        // {...props}
        // {...rest}
      />
      {error && 
      <FormHelperText className={classes.error} >{helperText}</FormHelperText>}
    </div>
  );
}

Checkbox.propTypes = {
  name:  PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  label: PropTypes.oneOfType([ PropTypes.string, PropTypes.shape() ]),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
};

