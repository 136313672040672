import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';

// import theme from '@org/client-libs-themes';

// https://material-ui.com/customization/default-theme/
// https://material-ui.com/customization/palette/#default-values
// https://material-ui.com/customization/breakpoints/
// https://material-ui.com/components/use-media-query/

// xs, extra-small: 0-599px
// sm, small: 600-959px
// md, medium: 960-1279px
// lg, large: 1280-1919px
// xl, extra-large: 1920+px

let theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      presentation: 900,
      card: 780,
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
      // main: '#2196f3',
    },
    secondary: {
    //   // main: red[500],
      main: '#dc004e', // 'rgb(220, 0, 78)',
    //   // main: red.A400,
    //   //main: '#19857b',
    },
    error: {
      main: '#f44336',
    //   main: red.A400,
    },
    warning: {
      main: '#ffb74d',
    //   // main: '#19857b',
    //   main: yellow[500],
    },
    success: {
      main: '#4caf50',
      // main: '#19857b',
      // contrastText: '#fff',
    },
    // gray: '#6c757d',
    // gray: 'rgba(0, 0, 0, 0.54)',
    grey: {
      main: grey[500],
    },
    green: {
      main: green,
    },
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
    h1: {
      fontSize: '6rem',
      fontWeight: '400',
      // marginBottom: '2rem',
    },
    h2: {
      fontSize: '5rem',
      fontWeight: '400',
    },
    h3: {
      fontSize: '4rem',
      fontWeight: '400',
    },
    h4: {
      fontSize: '3rem',
      fontWeight: '400',
    },
    h5: {
      fontSize: '2.4rem',
      fontWeight: '400',
    },
    h6: {
      fontSize: '2.0rem',
      fontWeight: '400',
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: '500',
    },
    subtitle2: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    body1: {
      fontSize: '1.6rem',
    },
    body2: {
      fontSize: '1.4rem',
    },
    button: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: '400',
    },
    overline: {
      fontSize: '1.2rem',
      fontWeight: '400',
    },
  },
  //[theme.breakpoints.down('sm')]: {
  //  //backgroundColor: theme.palette.secondary.main,
  //  backgroundColor: red,
  //},
  overrides: {
    // https://stackoverflow.com/questions/54236623/cant-remove-padding-bottom-from-card-content-in-material-ui/54239043
    // MuiLink: {
    //   root: {
    //     fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
    //   }
    // },
    // MuiButton: {
    //   fontSize: '1rem', // style does not exist???
    // }
    // MuiList: {
    //   root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //   },
    // },
    MuiCardContent: {
      root: {
        padding: 16,
        '&:last-child': {
          paddingBottom: 16, // default: 24
        },
      },
    },
  },
});

export const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
