import React from 'react';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
import { authorizedSchema, handleResponse } from '@org/client-graphql';

export function GetUser(inputs) {
  // let id = 'ZbOraWCaRKO3yzfb8mUUfA';
  let { id } = inputs;

  const { loading, data, error } = useQuery(authorizedSchema.GET_USER, { variables: {
    input: { id },
  }});

  if (loading)
    return <div>GetUser: {'Loading...'}</div>;
  if (error)
    return <div>GetUser: {error.message}</div>;
  if (data) {
    let response = handleResponse({ data });
    if (response.success) {
      return <div>GetUser: {response.data.id}</div>;
    } else if (response.error)
      return <div>GetUser: {response.error.message}</div>;
  }
  return null;
}

export default GetUser;
