import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import theme from '@org/client-libs-themes';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export function BasicDropZone(props) {
  // const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

  const {
    acceptedFiles, // getRootProps, getInputProps,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  // } = useDropzone({accept: 'image/*'});
  } = useDropzone();

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
    {/* <div className="container" > */}
    <div style={{ width: '100%', margin: theme.spacing(0, 0, 1, 0) }} >
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag files here, or click to select files</p>
      </div>
      {acceptedFiles.length > 0 &&
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>}
    </div>
    {/* <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag files here, or click to select files</p>
      </div>
      <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside>
    </section> */}
    </>
  );
}
