import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthorizedHandler } from '@org/client-graphql';
import { DataGrid as MuiDataGrid } from '@material-ui/data-grid';
import { PrimaryButton, SelectTextField, BackdropLoader } from '@org/client-components-core';
import { Page, Heading, FlexRow, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import theme from '@org/client-libs-themes';

import {
  columns as emailColumns, getRows as getEmails, handleRowClick as handleEmail,
} from './manage/Emails';
import {
  columns as invitationColumns, getRows as getInvitations, handleRowClick as handleInvitation,
} from './manage/Invitations';
import {
  columns as loanColumns, getRows as getLoans, handleRowClick as handleLoan,
} from './manage/Loans';
import {
  columns as noteColumns, getRows as getNotes, handleRowClick as handleNote,
} from './manage/Notes';
import {
  columns as sessionColumns, getRows as getSessions, handleRowClick as handleSession,
} from './manage/Sessions';
import {
  columns as userColumns, getRows as getUsers, handleRowClick as handleUser,
} from './manage/Users';

let authorizedHandler;

let rows = [], columns = [], handler;
let handleRowClick;

const PADDING_WIDTH = 2 * 16;
const SORT_WIDTH = 26;
const NUM_ROWS = 5;
const ROW_HEIGHT = 52;
const SCROLL_HEIGHT = 15;
const BODY_HEIGHT = NUM_ROWS * ROW_HEIGHT + SCROLL_HEIGHT;
// border + header + body + footer
const TABLE_HEIGHT = 2 + 56 + BODY_HEIGHT + 52;

const actionTypes = [
  // { label: 'Edit', value: 'LOAN' },
  { label: 'Delete', value: 'DELETE' },
  // { label: 'Download', value: 'INVITATION' },
];

const dataTypes = [
  { label: 'Emails', value: 'EMAIL' },
  { label: 'Invitations', value: 'INVITATION' },
  { label: 'Loans', value: 'LOAN' },
  { label: 'Notes', value: 'NOTE' },
  { label: 'Sessions', value: 'SESSION' },
  { label: 'Users', value: 'USER' },
];

function DataGrid(props) {
  return (
    <>
    <div style={{ height: TABLE_HEIGHT, width: '100%' }} >
      <MuiDataGrid
        {...props}
        rows={props.rows}
        columns={props.columns}
        pageSize={NUM_ROWS}
        onRowClick={(event) => props.handleRowClick(event)}
      />
    </div>
    </>
  );
}

DataGrid.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  handleRowClick: PropTypes.func,
};

function getCellWidth(length) {
  let width = (length * 8) + PADDING_WIDTH + SORT_WIDTH;
  // console.log('getCellWidth', length, width);
  return width;
}

// function getColumnWidths(columns, rows) {
function getColumnWidths() {

  let row, col;
 
  // only including the fields shown (in columns)
  for (let c in Object.values(columns)) {
    col = Object.values(columns)[c];

    // start with the headers
    col.width = getCellWidth(col.headerName.length);

    // then go throuch each row
    for (let r in rows) {
      row = rows[r];
      // console.log('row', row);

      let value = row[col.field];
      // console.log('value', col.field, value);

      let length = 0;

      if (!value) // null
        value = '';

      if (col.type === 'date')
        // length = (dayjs(value).format('MM/DD/YY hh:mm a')).length;
        length = 16;
      else if (col.type === 'boolean')
        // length = (value.toString()).length;
        length = 3;
      else if (col.type === 'number')
        length = (value.toString()).length;
      else
        length = value.length;

      let width = getCellWidth(length);

      col.width = Math.max(col.width, width);
      col.minWidth = Math.max(col.width, width);
      // console.log(r, c, length, col.width);
    }

  }

  // console.log(columns, rows);

  // return rows;
}

export function Manage(props) {
  const [ state, setState ] = useState({ isLoading: true, values: {}, formMessage: null, formError: null,
  });
  const [ actionEnabled, setActionEnabled ] = useState(false);

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);
    rows = [];
    columns = [];

    if (isMounted)
      setState({ ...state, isLoading: false});

    return () => { isMounted = false; };
  }, []);

  async function handleRowSelected(event) {
    console.log('handleRowSelected', event);
    setActionEnabled(true);
  }

  async function handleAction(event) {
    console.info('handleSelect', event.target);
  }

  async function handleSubmit(event) {
    console.info('handleSubmit', event.target);
  }

  async function handleData(event) {
    console.info('handleSelect', event.target);
    let name = event.target.name;
    let value = event.target.value;
    setState({ ...state, isLoading: true, values: { [name]: value } });

    try {
      if (value === 'EMAIL') {
        // rows = await getEmails(props);
        handler = getEmails;
        columns = emailColumns;
        handleRowClick = handleEmail;
      } else if (value === 'INVITATION') {
        // rows = await getInvitations(props);
        handler = getInvitations;
        columns = invitationColumns;
        handleRowClick = handleInvitation;
      } else if (value === 'LOAN') {
        // rows = await getLoans(props);
        handler = getLoans;
        columns = loanColumns;
        handleRowClick = handleLoan;
      } else if (value === 'NOTE') {
        // rows = await getNotes(props);
        handler = getNotes;
        columns = noteColumns;
        handleRowClick = handleNote;
      } else if (value === 'SESSION') {
        // rows = await getSessions(props);
        handler = getSessions;
        columns = sessionColumns;
        handleRowClick = handleSession;
      } else if (value === 'USER') {
        // rows = await getUsers(props);
        handler = getUsers;
        columns = userColumns;
        handleRowClick = handleUser;
      }

      rows = await handler(props);

      // await getColumnWidths(columns, rows);
      // getColumnWidths();
      // console.log(columns, rows);

      setState(prevState => ({ ...prevState, isLoading: false, formError: null }));
    } catch (error) {
      console.error(error);
      setState(prevState => ({ ...prevState, isLoading: false, formError: error }));
    }
  }

  async function handleRefresh() {
    setState({ ...state, isLoading: true });
    rows = await handler(props);

    // await getColumnWidths(columns, rows);
    // getColumnWidths();
    // console.log(columns, rows);

    setState(prevState => ({ ...prevState, isLoading: false, formError: null }));
  }

  let enabled = state.values['data-select'] != null ? true : false;
  // console.log(columns);

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <Page id='manage' >

      <Heading>{'Manage Records'}</Heading>

      <FlexRow style={{ justifyContent: 'center' }} >

        <SelectTextField
          name={'action-select'} label={'Select Action'} items={actionTypes} values={state.values}
          handleChange={handleAction}
          style={{ width: theme.spacing(24) }}
        />
        <PrimaryButton  title='Submit' onClick={handleSubmit} disabled={!actionEnabled} />

        <SelectTextField
          name={'data-select'} label={'Select Data'} items={dataTypes} values={state.values}
          handleChange={handleData}
          style={{ width: theme.spacing(24) }}
        />
        <PrimaryButton  title='Refresh' onClick={handleRefresh} disabled={!enabled} />

      </FlexRow>

      {state.formError &&
      <FlexRow style={{ justifyContent: 'center' }} >
        <FormError message={state.formError.message} />
      </FlexRow>}

      <DataGrid
        columns={columns} rows={rows} loading={state.isLoading}
        checkboxSelection onRowSelected={handleRowSelected}
        handleRowClick={(event) => handleRowClick(event, props)}
      />

      <DisplayState title='state' state={{ state, columns, rows }} open={true} />

    </Page>
    </>
  );
}

export default Manage;

Manage.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
