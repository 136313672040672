import React from 'react';
import { DataGrid as MuiDataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';

let styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
};

const useStyles = makeStyles(styles);

export function DataGrid(props) {
  let { rows, columns, handleRowClick, ...rest } = props;
  let classes = useStyles();

  if (!Array.isArray(rows))
    console.error('DataGrid', 'row not of type array!', typeof rows, rows);

  return (
    <>
    {/* <div className={classes.root} { ...rest } > */}
      <MuiDataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        autoHeight
        onRowClick={handleRowClick}
        className={classes.root}
        { ...rest } />
    {/* </div> */}
    </>
  );
}
