import React from 'react';
import PropTypes from 'prop-types';
import { default as MuiSvgIcon } from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/core/styles';

const localStyles = {
  root: {
    lineHeight: 1,
  },
};

const useStyles = makeStyles(localStyles);

export function SvgIcon(props) {
  // console.log('SvgIcon', 'props', props);
  let classes = useStyles();

  return (
    <MuiSvgIcon className={classes.root} {...props} >
      {props.children}
    </MuiSvgIcon>
  );
}

export default SvgIcon;

SvgIcon.propTypes = {
  children: PropTypes.any,
};
