import React from "react";
// import PropTypes from "prop-types";
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from '@org/client-libs-styles';
import { InLink } from '@org/client-components-core';
import { Page, Heading } from '@org/client-components-custom';

// Borrowed from: https://aws.amazon.com/terms/

const { REACT_APP_LEGAL_NAME, REACT_APP_COMPANY_NAME, REACT_APP_SHORT_NAME } = process.env;

const localStyles = {
  ...styles,
  smallLink: {
    display: 'inline',
  },
};

const useStyles = makeStyles(localStyles);

export function Terms() {
  let shortSiteName = `${REACT_APP_SHORT_NAME} Site`;

  let classes = useStyles();

  return(
    <>
    {/* <div className={classes.pageRoot} > */}
    <Page id='terms-of-use' >
      {/* <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
        {'Terms of Use'}
      </Typography> */}
      <Heading>{'Terms of Use'}</Heading>
      <p>Effective: November 17, 2020</p>

      <div>
        <section>
          <h4 style={{textTransform: 'uppercase'}} ><strong>WELCOME TO {REACT_APP_COMPANY_NAME}</strong></h4>
          <p>
            The {REACT_APP_COMPANY_NAME} site (the “{shortSiteName}”), {REACT_APP_LEGAL_NAME} and/or its affiliates (collectively, “{REACT_APP_SHORT_NAME}™”) provides the {REACT_APP_COMPANY_NAME} Site to you subject to the following terms of use (“Site Terms”). By visiting the {REACT_APP_COMPANY_NAME} Site, you accept all of the Site Terms. Please read them carefully. In addition, when you use any current or future {REACT_APP_COMPANY_NAME} services, content or other materials, you also will be subject to the {REACT_APP_COMPANY_NAME}’ Customer Agreement or other agreement governing your use of our services (the “Agreement”).
          </p>
        </section>

        <section>
          <h4><strong>PRIVACY</strong></h4>
          <p>Please review our <InLink to='/legal/privacy' title='Privacy Policy' />, which also governs your visit to the {shortSiteName}, to understand our practices.</p>
        </section>

        <section>
          <h4><strong>ELECTRONIC COMMUNICATIONS</strong></h4>
          <p>When you visit the {REACT_APP_SHORT_NAME} Site or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on the {shortSiteName}. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>
        </section>

        <section>
          <h4><strong>COPYRIGHT</strong></h4>
          <p>All content included on the {shortSiteName}, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of {REACT_APP_SHORT_NAME} or its content suppliers and protected by United States and international copyright laws. The compilation of all content on the {shortSiteName} is the exclusive property of {REACT_APP_COMPANY_NAME} and protected by U.S. and international copyright laws. All software used on the {shortSiteName} is the property of {REACT_APP_SHORT_NAME} or its software suppliers and protected by United States and international copyright laws.</p>
        </section>

        <section>
          <h4><strong>TRADEMARKS</strong></h4>
          <p>“{REACT_APP_COMPANY_NAME}”, “{REACT_APP_SHORT_NAME}”, and other {REACT_APP_SHORT_NAME} graphics, logos, page headers, button icons, scripts, and service names are trademarks, registered trademarks or trade dress of {REACT_APP_COMPANY_NAME} in the U.S. and/or other countries. {REACT_APP_SHORT_NAME}’s trademarks and trade dress may not be used in connection with any product or service that is not {REACT_APP_SHORT_NAME}’s, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits {REACT_APP_SHORT_NAME}. All other trademarks not owned by {REACT_APP_SHORT_NAME} that appear on this Site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by {REACT_APP_SHORT_NAME}.</p>
        </section>

        <section>
          <h4><strong>PATENTS</strong></h4>
          <p>One or more patents owned by {REACT_APP_COMPANY_NAME} or its affiliates may apply to the {shortSiteName} and to the features and services accessible via the {shortSiteName}. Portions of the {shortSiteName} may operate under license of one or more patents. {/* Click here to see a non-exhaustive list of applicable {REACT_APP_SHORT_NAME} patents and applicable licensed patents.*/}</p> 
        </section>

        <section>
          <h4><strong>LICENSE AND SITE ACCESS</strong></h4>
          <p>{REACT_APP_SHORT_NAME} grants you a limited license to access and make personal use of the {shortSiteName} and not to download (other than page caching) or modify it, or any portion of it, except with express written consent of {REACT_APP_SHORT_NAME}. This license does not include any resale or commercial use of the {shortSiteName} or its contents; any derivative use of the {shortSiteName} or its contents; any downloading or copying of account information; or any use of data mining, robots, or similar data gathering and extraction tools. Unless otherwise specified by {REACT_APP_SHORT_NAME} in a separate license, your right to use any software, data, documentation or other materials that you access or download through the {shortSiteName} is subject to these Site Terms or, if you have an {REACT_APP_SHORT_NAME} account, the Agreement.</p>
          <p>The {shortSiteName} or any portion of the {shortSiteName} may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of {REACT_APP_SHORT_NAME}. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of {REACT_APP_SHORT_NAME} without express written consent. You may not use any meta tags or any other “hidden text” utilizing {REACT_APP_SHORT_NAME}’s name or trademarks without the express written consent of {REACT_APP_SHORT_NAME}. Any unauthorized use terminates the permission or license granted by {REACT_APP_SHORT_NAME}. You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of the {shortSiteName}, so long as the link does not portray {REACT_APP_SHORT_NAME}, or its products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any {REACT_APP_SHORT_NAME} logo or other proprietary graphic or trademark as part of the link without express written permission.</p>
        </section>

        <section>
          <h4><strong>YOUR ACCOUNT</strong></h4>
          <p>If you use the {shortSiteName}, you are responsible for maintaining the confidentiality of your {REACT_APP_SHORT_NAME} account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. {REACT_APP_SHORT_NAME} reserves the right to refuse service, terminate accounts, remove or edit content in its sole discretion.</p>
        </section>

        {/*<section>
          <h4><strong>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</strong></h4>
          <p>Visitors may post reviews, comments and other content; and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of “spam.” You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. {REACT_APP_SHORT_NAME} reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content. If you do post content or submit material, and unless we indicate otherwise, you grant {REACT_APP_SHORT_NAME} a nonexclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant {REACT_APP_SHORT_NAME} and sublicensees the right to use the name that you submit in connection with such content, if they choose.</p>

          <p>You and third parties may be permitted to upload certain software (including machine images), data, text, audio, video, images or other content (“Third Party Content”) to community areas of the {shortSiteName}. You acknowledge that (a) {REACT_APP_SHORT_NAME} has not tested or screened Third Party Content, (b) you use any Third Party Content is at your sole risk, and© Third Party Content may be subject to separate license terms as determined by the person posting such content.</p>

          <p>You represent and warrant that you own or otherwise control all of the rights to the content, including any Third Party Content, that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify {REACT_APP_SHORT_NAME} for all claims resulting from content you supply. {REACT_APP_SHORT_NAME} has the right but not the obligation to monitor and edit or remove any activity or content. {REACT_APP_SHORT_NAME} takes no responsibility and assumes no liability for any content posted by you or any third party.</p>
        </section>*/}

        <section>
          <h4><strong>COPYRIGHT COMPLAINTS</strong></h4>
          <p>{REACT_APP_SHORT_NAME} respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please follow our Notice and Procedure for Making Claims of Copyright Infringement.</p>
        </section>

        <section>
          <h4><strong>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</strong></h4>
          <p style={{textTransform: 'uppercase'}}>THE {shortSiteName} AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) AND SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE ARE PROVIDED BY {REACT_APP_SHORT_NAME} ON AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN THE AGREEMENT. {REACT_APP_SHORT_NAME} MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE {shortSiteName}, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, {REACT_APP_SHORT_NAME} DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. {REACT_APP_SHORT_NAME} DOES NOT WARRANT THAT THIS SITE; INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING ANY SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE {REACT_APP_SHORT_NAME} SITE; ITS SERVERS; OR E-MAIL SENT FROM {REACT_APP_SHORT_NAME} ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. {REACT_APP_SHORT_NAME} WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE {shortSiteName} OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE {shortSiteName}, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN THE AGREEMENT. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.</p>
        </section>

        <section>
          <h4><strong>APPLICABLE LAW</strong></h4>
          <p>By visiting the {shortSiteName}, you agree that the laws of the state of Washington, without regard to principles of conflict of laws, will govern these Site Terms and any dispute of any sort that might arise between you and {REACT_APP_SHORT_NAME}.</p>
        </section>

        <section>
          <h4><strong>DISPUTES</strong></h4>
          <p>Any dispute relating in any way to your visit to the {shortSiteName} or to services provided by {REACT_APP_SHORT_NAME} or through the {shortSiteName} in which the aggregate total claim for relief sought on behalf of one or more parties exceeds $7,500 shall be adjudicated in any state or federal court in King County, Washington, and you consent to exclusive jurisdiction and venue in such courts.</p>
        </section>

        <section>
          <h4><strong>SITE POLICIES, MODIFICATION, AND SEVERABILITY</strong></h4>
          <p>Please review our other policies on the {shortSiteName}. These policies also govern your visit to the {shortSiteName}. We reserve the right to make changes to the {shortSiteName}, policies, and these Site Terms at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.</p>
        </section>

        <section>
          <h4><strong>NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT INFRINGEMENT</strong></h4>
          <p>If you believe that your work has been copied in a way that constitutes copyright infringement, please provide our copyright agent the written information specified below. Please note that this procedure is exclusively for notifying {REACT_APP_SHORT_NAME} that your copyrighted material has been infringed.</p>

          <ul>
            <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
            <li>A description of the copyrighted work that you claim has been infringed upon;</li>
            <li>A description of where the material that you claim is infringing is located on the Site;</li>
            <li>Your address, telephone number, and e-mail address;</li>
            <li>A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
            <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
          </ul>

          <p>{REACT_APP_COMPANY_NAME}’s Copyright Agent for notice of claims of copyright infringement can be reached:</p>
          <p style={{marginBottom: '0rem'}} >By mail, send to Our Address above:</p>
          <p style={{marginBottom: '0rem'}} >Attention: Copyright Agent</p>
          <p style={{marginBottom: '0.25rem'}} >Care of: Legal Department</p>
          <p style={{marginBottom: '0.25rem'}} >Phone: (917) 555-1212</p>
          <p style={{}} >Email: copyright@{origin}</p>
        </section>

        <section>
          <h4><strong>SITE POLICIES, MODIFICATION, AND SEVERABILITY</strong></h4>
          <p>Please review our other policies on the {shortSiteName}. These policies also govern your visit to the {shortSiteName}. We reserve the right to make changes to the {shortSiteName}, policies, and these Site Terms at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.</p>
        </section>
      </div>
      </Page>
      {/* </div> */}
    </>
  );
}

// PropTypes.Terms = {
//   system: PropTypes.shape(),
// };
