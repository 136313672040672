// import * as yup from "yup";
import * as yup from 'yup/es';

const cashflowShape = {
  id: yup.string().length(26),
  itemType: yup.string().length(8), // 'CASHFLOW'
  userId: yup.string().length(26),
  referenceItemType: yup.string().required('Reference Item Type is required'),
  referenceItemId: yup.string().length(26).required('Reference Item ID is required'),
  eventDate: yup.string(),
  interestAmount: yup.number(),
  principalAmount: yup.number(),
  paymentAmount: yup.number(),
};

export const cashflowSchema = yup.object().shape(cashflowShape);
