import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
// import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

const styles = {
  stepper: {
    margin: theme.spacing(0, 0, 2, 0),
    backgroundColor: '#fafafa',
  },
};

const useStyles = makeStyles(styles);

let signUpSteps = {
  names: [ 'changePassword', 'investorStatus', 'verifyIdentity' ],
  labels: [ 'Change Password', 'Investor Status', 'Verify Identity' ],
};

export function InvitationStepper(props) {
  let activeStepNum = signUpSteps.names.indexOf(props.activeStep);
  activeStepNum = activeStepNum >= 0 ? activeStepNum : null;
  activeStepNum = props.activeStep === 'invitationComplete' ? 3 : activeStepNum;

  let classes = useStyles();
  return (
    <Stepper alternativeLabel activeStep={activeStepNum} className={classes.stepper} >
      {signUpSteps.labels.map((label) => (
        <Step key={label} >
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

InvitationStepper.propTypes = {
  activeStep: PropTypes.string,
};