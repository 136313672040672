import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AuthenticatedHandler } from '@org/client-graphql';
import {
  FormUp2, TextField, Checkbox, SelectTextField, PrimaryButton, SecondaryButton, BackdropLoader
} from '@org/client-components-core';
import { Heading, FormLayout, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { styles } from '@org/client-libs-styles';
import { firstNameShape, lastNameShape } from '@org/common-yup';
// import { UserClass } from '@org/common-classes/User';
// import { shallowEqual } from '@org/common-tools/compare';

const useStyles = makeStyles(styles);

let investorStatusTypes = [
  { value: 'NOT_ACCREDITED', label: 'Non-Accredited Investor' },
  { value: 'ACCREDITED', label: 'Accredited Investor' },
];

let defaultValues = {
  firstName: "",
  lastName: "",
  investorStatus: "",
  doNotContact: false,
};

let updatedValues;
let setValues;

let values;

// let requiredValues = {
//   firstName: true,
//   lastName: true,
//   investorStatus: true,
// };

let validationShape = {
  firstName: firstNameShape,
  lastName: lastNameShape,
  investorStatus: yup.string(),
  doNotContact: yup.boolean(),
};
const validationSchema = yup.object().shape(validationShape);

// function calculateValues(values) {
//   return values;
// }

let authenticatedHandler;

export function Personal(props) {
  console.log('');
  console.log('Personal', 'props', props);
  let isMounted = true;
  const [ state, setState ] = useState({
    isLoading: true, isUpdated: false, isSubmitting: false, formMessage: null, formError: null, // isEditing: false,
  });
  // const [ form, setForm ] = useState({
  //   origValues: {},
  //   values: {},
  //   touched: {},
  //   validated: {},
  //   errors: {},
  // });
  let classes = useStyles();

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    (async () => {
      try {
        // console.log('Personal', 'useEffect', 'state', state);
        let response = await authenticatedHandler.getMe();
        updatedValues = response;
        console.log('Personal', 'updatedValues1', updatedValues);

        // for (let index in Object.keys(defaultValues)) {
        //   let name = Object.keys(defaultValues)[index];
        //   if (response[name] && response[name] !== defaultValues[name])
        //     setValue(name, response[name]);
        // }
        await setValues(response);

        if (isMounted) {
          // setForm({ ...form, origValues: response, values: response });
          setState({ ...state, isLoading: false });
        }
        // console.log('Personal', 'useEffect', 'state', state);

      } catch (error) {
        if (isMounted)
          setState({ ...state, isLoading: false, formError: error });
      }
    })();

    return () => { isMounted = false; };
  }, []);
  console.log('Personal', 'updatedValues2', updatedValues);

  useEffect(() => {
    if (isMounted) {
      console.log('Personal', 'useEffect [ state ]', 'updatedValues', updatedValues);
      // let updatedValues;
      if (updatedValues) {
        updatedValues = null;
        setState({ ...state, isUpdated: true }); // w/o this updatedValues will not be updated in FormUp2
      }
      console.log('Personal', 'useEffect [ state ]', 'updatedValues', updatedValues);
    }
  }, [ state ]);

  async function handleSubmit(event) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });

    try {
      let input = {};
      input = values;

      // Object.keys(touched).forEach(elem => {
      //   console.warn(elem);
      //   if (form.touched[elem])
      //     input[elem] = form.values[elem];
      // });

      await authenticatedHandler.updateMe(input);

      if (isMounted) {
        // console.log(form.values, form.origValues);
        // setForm({ ...form, origValues: form.values, touched: {}, validated: {}, errors: {} });
        setState({ ...state, isSubmitting: false, formMessage: 'Update successful', formError: null });
      }

    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: error, formMessage: null });
    }
  }

  function handleCancel() {
    // setForm({ ...form, values: form.origValues, touched: {}, validated: {}, errors: {} });
  }

  let firstNameProps = { name: 'firstName', label: 'First Name', type: 'string', required: true };
  let lastNameProps = { name: 'lastName', label: 'Last Name', type: 'string', required: true };
  let investorStatusProps = { name: 'investorStatus', label: 'Investor Status', required: true };
  let doNotContactProps = { name: 'doNotContact', label: 'Please do not contact me with marketing messages or product updates' };

  // let isUpdated = !shallowEqual(form.values, form.origValues);

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <FormUp2
      id='user-personal-form'
      {...props}
      // state={form}
      // setState={setForm}
      // validationType={'update'}
      // validationSchema={authSchema}
      initialValues={defaultValues}
      // updatedValues={updatedValues}
      // requiredValues={requiredValues}
      validationSchema={validationSchema}
      // calculateValues={calculateValues}
    >
      {formProps => {
      console.log('Personal', 'formProps', formProps);
      let { fieldProps, checkboxProps } = formProps;
      setValues = formProps.setValues;
      {/* if (updatedValues != null)
        updatedValues = null; */}
      {/* let submitEnabled = formProps.isValidated && !state.isSubmitting && isUpdated; */}
      let submitEnabled = formProps.isValidated && !state.isSubmitting;
      {/* let cancelEnabled = !state.isSubmitting && isUpdated; */}
      {/* if (updatedValues != null) {
      } */}
      values = formProps.values;
      console.log('Personal', 'values', values);
      return (
        <>
        <FormLayout>

          <Heading>{'Personal'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField {...fieldProps} {...firstNameProps} />
              {/* <TextField {...formProps.fieldProps} {...firstNameProps} /> */}
            </Grid>

            <Grid item >
              <TextField {...fieldProps} {...lastNameProps} />
            </Grid>

            <Grid item >
              <SelectTextField {...fieldProps} {...investorStatusProps} items={investorStatusTypes} disabled={true} />
            </Grid>

            <Grid item >
              <Checkbox {...checkboxProps} {...doNotContactProps} />
            </Grid>

            {/* {isUpdated && */}
            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                aria-label='submit button'
                title='Submit'
                disabled={!submitEnabled}
                onClick={handleSubmit}
              />
              <SecondaryButton
                aria-label='cancel button'
                title='Cancel'
                // disabled={!cancelEnabled}
                onClick={handleCancel}
              />
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, formProps }} open={true} />
        </>
      );}}
    </FormUp2>
    </>
  );
}

Personal.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  checkboxProps: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
};
