import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloProvider } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import theme from '@org/client-libs-themes';
import {
  LinkButton, // ButtonBar, PrimaryButton,
  OnScroll, // AmOnScreen,
  // CollapseIn, CollapseOut,
  FadeIn, // FadeOut,
  // GrowIn, GrowOut,
  SlideIn, // SlideOut,
  // ZoomIn, ZoomOut
} from '@org/client-components-core';
// import { FadeOutSection } from '@org/client-components-core';
// import { TriggerZoom, TriggerCollapse, TriggerFade, TriggerGrow, TriggerSlide } from '@org/client-components-core';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';
import { FlexRow, FlexColumn } from '@org/client-components-custom';
// import { publicClient } from '@org/client-graphql';

let localStyles = {
  ...styles,
  homeRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    // maxWidth: '100rem',
  },
  homeTitle: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    padding: theme.spacing(2),
  },
  homeTitle4: {
    height: '96px',
  },
  homeTitleSmall: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    padding: theme.spacing(0),
    margin: 0,
    // width: 'fit-content',
    width: '100%',
    textAlign: 'left',
  },
  homeTitleSmall4: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    padding: theme.spacing(0, 0, 1, 0),
    overflowWrap: 'normal',
  },
  fadeOut: {
    transition: 'opacity 3s',
  },
  header: { 
    overflow: 'hidden',
    // box-shadow: inset 0 -4em 4em -4em #2b110e;   
  },
  parallax: {
    position: 'relative',
    /* //  aspect ratio sizing */
    width: '100vw',
    height: '50vw',
    /* //  transform */
    transformOrigin: 'bottom center',
    willChange: 'transform',
    // transform: //  translate down //  scale to create zoom effect
    transform: `translate3d(0, calc(var(--pct) * 35%), 0) scale3d(calc(var(--pct)/2 + 1), calc(var(--pct)/2 + 1), 1)`,
  },
  layers: {
    /* //  stack and make layers fit to wrapper÷ */
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    /* //  transform */
    willChange: 'transform',
    transformOrigin: 'bottom center',
    transform: `translate3d(0, 0, 0)`,
  },
  p1: { transform: `translate3d(0, calc(var(--pct) * -20.0%), 0)`, },
  p2: { transform: `translate3d(0, calc(var(--pct) * -17.5%), 0)`, },
  p3: { transform: `translate3d(0, calc(var(--pct) * -15.0%), 0)`, },
  p4: { transform: `translate3d(0, calc(var(--pct) * -12.5%), 0)`, },
  p5: { transform: `translate3d(0, calc(var(--pct) * -10.0%), 0)`, },
  p6: { transform: `translate3d(0, calc(var(--pct) *  -7.5%), 0)`, },
  p7: { transform: `translate3d(0, calc(var(--pct) *  -5.0%), 0)`, },
  p8: { transform: `translate3d(0, calc(var(--pct) *  -2.5%), 0)`, },
};

const useStyles = makeStyles(localStyles);

const speeds = [98, 95, 89, 84, 74, 64, 51, 31, 0];
// let opacity = 1;

let y = 0;

let learnButton2;

let isMounted = false;

let hasScrolledDown = false;

export function Home() {
  // const ref = useRef();
  let top = window.pageYOffset;
  // const [ y, setY ] = useState(window.pageYOffset);
  const [ opacity, setOpacity ] = useState(1);
  const [ , setLayers ] = useState(speeds.map(s => s * top / 100));
  // let [show, setShow] = useState(true);
  let [ s, setS ] = useState(1);
  // let visibility = 'hidden';
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  function handleScroll() {
    // console.log(window.innerHeight, document.height, window.screen.height);
    // const y = window.pageYOffset;
    // setY(window.pageYOffset);
    // let sy = window.scrollY;
    // setY(sy);
    y = window.scrollY;
    let layers = speeds.map(s => s * y / 100);
    setLayers(layers);

    if (y > 20 && y < 41) {
      // opacity = Math.max(1 - (y / 20), 0);
      setOpacity(Math.max(1 - (y / 20), 0));
    } else if (y <= 20 && opacity !== 1)
      setOpacity(1);
    // if (y <= 30) setShow(true);
    // if (y > 30) setShow(false);
    if (y > 20 && y < 100 && s != 3) setS(3);

    // if (y > 20)
    //   visibility = 'visible';

    // console.log('sy', sy, 'y', y, 'opacity', opacity, 's', s, 'scrollY', window.scrollY, 'pageYOffset', window.pageYOffset, 'visibility', visibility);

    // const height =  document.querySelector('.parallax').getBoundingClientRect().height;
    const height = document.getElementById('parallax').getBoundingClientRect().height;
    const percentage = Math.min(Math.max(window.pageYOffset/height, 0),1);
    document.documentElement.style.setProperty('--pct',percentage);

    learnButton2 = document.getElementById('learn-button2');
    // console.log('learnButton', learnButton);
  }

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll, { passive: true });

    // const observer = new IntersectionObserver(entries => {
    //   entries.forEach(entry => {
    //     if (isMounted)
    //       // setVisible(entry.isIntersecting);
    //       console.log(entry, 'isVisible!!!!!');
    //     // console.log(entry.id, entry.isIntersecting, isVisible);
    //   });
    // });
    
    // observer.observe(learnButton2);
  
    return () => {
      isMounted = false;
      window.removeEventListener("scroll", handleScroll);
      // observer.unobserve(learnButton2);
    };
  }, []);
  
  // useEffect(() => {
  //   // window.addEventListener("scroll", handleScroll);
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  let classes = useStyles();

  // let pageHeight
  // let scrollStart = 400;
  // let y1 = (y <= scrollStart) ? y : y - (y - scrollStart); // keeps the top sectoin from scrolling
  // let y2 = (y <= 293) ? 0 : y - (y - 293); // keeps the top section from shrinking
  // y1 = Math.min(y1, 620);
  // console.log(y, y1, y2);
  // console.log(y, y1);

  // var element = document.getElementById('app-bar');
  var appBarHeight = 0;
  if (document.getElementById('app-bar'))
    appBarHeight = document.getElementById('app-bar').offsetHeight;
  var imageHeight = 0;
  if (document.getElementById('nyc'))
    imageHeight = document.getElementById('nyc').offsetHeight;
  // console.log(element, element.offsetHeight);
  let vh = window.innerHeight;
  // console.log('vh', vh);

  // let homeHeight = Math.max(900, window.innerHeight);
  // Access Earn Diversify section height
  let aedHeight = vh - appBarHeight;
  if (isXs)
    aedHeight -= imageHeight;

  // let learnButtonShowing = true;
  // if (learnButton2) {
  //   // learnButtonShowing = window.getComputedStyle(learnButton).display;
  //   learnButtonShowing = learnButton2.style.visibility !== 'hidden' ? true : false;
  //   console.log('learnButtonShowing', learnButtonShowing, learnButton2.offsetParent, y);
  // }

  // console.log(1, hasScrolledDown, y, y >= 450, !hasScrolledDown && y >= 450);
  // hasScrolledDown = (y >= 450 && !hasScrolledDown) ? true : false;
  if (y >= 450 && hasScrolledDown === false) {
    hasScrolledDown = true;
  }
  let showButton = hasScrolledDown && y < 165 && !isXs;
  // console.log(2, hasScrolledDown, showButton);

  return(
    // <ApolloProvider client={publicClient} >
    <>

    <div id='nyc' style={{ height: '100%', minHeight: 'fit-content', width: '100%' }} >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 240" style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0, overflow: 'hidden' }} focusable="false" aria-hidden="true">
        <svg id="bg" >
          {/* <image width="480" height="240" href="https://s3-us-west-2.amazonaws.com/s.cdpn.io/68939/ny.jpg" /> */}
          <image width="480" height="240" href="/images/ny.jpg" />
        </svg>
        <svg id="p8"><use mask="url(#m8)" href="#bg" /></svg>
        <svg id="p7"><use mask="url(#m7)" href="#bg" /></svg>
        <svg id="p6"><use mask="url(#m6)" href="#bg" /></svg>
        <svg id="p5"><use mask="url(#m5)" href="#bg" /></svg>
        <svg id="p4"><use mask="url(#m4)" href="#bg" /></svg>
        <svg id="p3"><use mask="url(#m3)" href="#bg" /></svg>
        <svg id="p2"><use mask="url(#m2)" href="#bg" /></svg>
        <svg id="p1"><use mask="url(#m1)" href="#bg" /></svg>
      </svg>

      <div>
        <div className={classes.header} >
          {/* <!-- 
          The Parallax
          Note! unlike DOM elements SVG's will cause repaints even if 
          animating transforms only. To better animation performance 
          we wrap each parallax part in a div and animate that 
          --> */}
          <div id='parallax' className={classes.parallax} >
          {/* <div className={classes.parallax} > */}
            <div className={classes.layers} >
              <div className={classes.bg} ><svg viewBox="0 0 480 240"><use href="#bg" /></svg></div>
              <div className={classes.p8} ><svg viewBox="0 0 480 240"><use href="#p8" /></svg></div>
              <div className={classes.p7} ><svg viewBox="0 0 480 240"><use href="#p7" /></svg></div>
              <div className={classes.p6} ><svg viewBox="0 0 480 240"><use href="#p6" /></svg></div>
              <div className={classes.p5} ><svg viewBox="0 0 480 240"><use href="#p5" /></svg></div>
              <div className={classes.p4} ><svg viewBox="0 0 480 240"><use href="#p4" /></svg></div>
              <div className={classes.p3} ><svg viewBox="0 0 480 240"><use href="#p3" /></svg></div>
              <div className={classes.p2} ><svg viewBox="0 0 480 240"><use href="#p2" /></svg></div>
              <div className={classes.p1} ><svg viewBox="0 0 480 240"><use href="#p1" /></svg></div>
            </div>
          </div>
        </div>

        <main></main>
      </div>
    </div>
  
    <div style={{ display: 'flex', flexDirection: 'Column', height: aedHeight, width: '100%', paddingTop: theme.spacing(isXs ? 2 : 6) }} >

      {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: 'auto', maxWidth: '650px' }} > */}
      {!isXs &&
      <div style={{ display: 'flex', justifyContent: 'center', width: 'auto', maxWidth: '100%' }} >
        <Typography component='h1' variant='h1' className={classes.homeTitle} >
          {'Access.'}
        </Typography>
        <Typography component='h1' variant='h1' className={classes.homeTitle} >
          {'Earn.'}
        </Typography>
        <Typography component='h1' variant='h1' className={classes.homeTitle} >
          {'Diversify.'}
        </Typography>
      </div>}
      {isXs &&
      <FlexColumn style= {{
        width: '80%',
        alignSelf: 'center',
        }} >
        {/* <FlexRow style={{
          // flexWrap: 'wrap',
          // width: '80%',
          // whiteSpace: '',
          // wordWrap: '',
          // overflowWrap: 'normal'
        }} > */}
          <Typography component='h1' variant='h1' className={classes.homeTitleSmall} >
            {'Access'}
          </Typography>
          <Typography component='h2' variant='h4' className={clsx(classes.homeTitleSmall4)} >
            {'institutional quality credit investment return profiles'}
          </Typography>
        {/* </FlexRow> */}
        {/* <FlexRow> */}
          <Typography component='h1' variant='h1' className={classes.homeTitleSmall} >
            {'Earn'}
          </Typography>
          <Typography component='h2' variant='h4' className={clsx(classes.homeTitleSmall4)} >
            {'institutional quality credit investment return profiles'}
          </Typography>
        {/* </FlexRow> */}
        {/* <FlexRow> */}
          <Typography component='h1' variant='h1' className={classes.homeTitleSmall} >
            {'Diversify'}
          </Typography>
          <Typography component='h2' variant='h4' className={clsx(classes.homeTitleSmall4)} >
            {'your portfolio while enhancing your risk-adjusted return'}
          </Typography>
        {/* </FlexRow> */}
        {/* <div style={{ position: 'fixed', top: 100, right: 50, height: 'fit-content', width: 'fit-content' }} > */}
        <FlexRow style={{ justifyContent: 'center', padding: theme.spacing(2, 0, 3, 0) }} >
          <div style={{ height: 'fit-content', width: 'fit-content' }} >
            <LinkButton name='learn-button-bottom' title='Learn More' to='/learn' color='default' style={{ whiteSpace: 'nowrap', }} />
          </div>
        </FlexRow>
      </FlexColumn>}

      {!isXs &&
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gridGap: '1rem', padding: 0, width: '100%' }} >

        <div style={{ gridColumn: '3 / 7', padding: theme.spacing(6, 0, 6, 0) }} >

          <OnScroll threshold={200} sticky >
            {props => {
            let { trigger } = props;
            return (
              <>
              {trigger &&
              <SlideIn id='slide-in' trigger={trigger} sticky={true} { ... { timeout: 500 } } >
                <Typography component='h2' variant='h4' className={clsx(classes.homeTitle, classes.homeTitle4)} >
                  {'Access institutional quality credit investment return profiles'}
                </Typography>
              </SlideIn>}
              </>
            );}}
          </OnScroll>

          <OnScroll threshold={300} sticky >
            {props => {
            let { trigger } = props;
            return (
              <>
              {trigger &&
              <SlideIn id='slide-in' trigger={trigger} {...(trigger ? { timeout: 500 } : {})} >
                <Typography component='h2' variant='h4' className={clsx(classes.homeTitle, classes.homeTitle4)} >
                  {'Earn passive interest rates of 5-12+% on short term investments'}
                </Typography>
              </SlideIn>}
              </>
            );}}
          </OnScroll>

          <OnScroll threshold={400} sticky >
            {props => {
            let { trigger } = props;
            return (
              <>
              {trigger &&
              <SlideIn id='slide-in' trigger={trigger} { ... { timeout: 500 } } >
                <Typography component='h2' variant='h4' className={clsx(classes.homeTitle, classes.homeTitle4)} >
                  {'Diversify your portfolio while enhancing your risk-adjusted return'}
                </Typography>
              </SlideIn>}
              </>
            );}}
          </OnScroll>

          {/* <AmOnScreen /> */}

        </div>
      </div>}

      {/* <OnScroll threshold={450} >
        {props => {
        let { trigger } = props;
        return ( */}
          <>
          <div style={{ position: 'fixed', top: 100, right: 50, height: 'fit-content', width: 'fit-content' }} >
            {/* <FadeIn trigger={trigger} > */}
            <FadeIn trigger={showButton} >
              <LinkButton name='learn-button-top' title='Learn More' to='/learn' color='default' style={{ whiteSpace: 'nowrap' }} />
            </FadeIn>
          </div>
          </>
        {/* );}}
      </OnScroll> */}

      {!isXs &&
      <OnScroll threshold={450} sticky >
        {props => {
        let { trigger } = props;
        return (
          <>
          <div style={{ height: 'fit-content', width: '100%', textAlign: 'center' }} >
            <FadeIn trigger={trigger} >
              <LinkButton name='learn-button2' title='Learn More' to='/learn' color='default' style={{ whiteSpace: 'nowrap', }} />
            </FadeIn>
          </div>
          </>
        );}}
      </OnScroll>}

    </div>
    </>
    // </ApolloProvider>
  );
}

export default Home;

Home.propTypes = {
  classes: PropTypes.any,
  trigger: PropTypes.bool,
};
