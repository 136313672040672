import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import * as yup from 'yup';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import {
  FormUp2, ButtonBar, PrimaryButton, // Breadcrumbs, // DatePicker,
  TextField, NumberField, SelectTextField, AmountField, PercentField,
  Checkbox,
  // InLink, 
} from '@org/client-components-core';
import { Heading, FormLayout, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
// import { values } from 'lodash';
// import values from 'lodash/values';
// import { formSchema } from './yup';
// import { checkboxShape } from '@org/common-yup';

let selectItems = [
  { label: 'Option 1', value: 'OPTION_ONE' },
  { label: 'Option 2', value: 'OPTION_TWO' },
];

// Or use a custom class
let defaultValues = {
  // dateField: now,
  textField: "",
  numberField: 0,
  amountField: 0,
  percentField: 0,
  calculatedField: 0,
  checkbox: false,
};

let initialValues;

let updatedValues;

let values;

// optional?
let requiredValues = {
  textField: true,
  // selectTextField: true,
};

let validationShape = {
  textField: yup.string()
    .required(),
  selectTextField: yup.string(),
  numberField: yup.number(),
  amountField: yup.number(),
  percentField: yup.number(),
  calculatedField: yup.number(),
  checkbox: yup.boolean(),
};
const validationSchema = yup.object().shape(validationShape);

// Calculated values used for default values and rerenders
function calculateValues(values) {
  values = {
    ...values,
    calculatedField: values?.textField?.length,
  };
  return values;
}

// Derived values are set in render props to display outside FormUp2
// (e.g. a form message or error message)
let derivedValue; // example value (should this be a function like calculatedValues?)

// let authenticatedHandler;

export function UpdateForm2(props) {
  console.info('UpdateForm2', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false,
    formMessage: 'I am a form', formError: null,
  });

  // if (!authenticatedHandler)
  //   authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  // if (!initialValues) // This threw errors in invitation where initialValues cached
  initialValues = calculateValues({ ...defaultValues, ...props.location?.state?.data });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    // event.preventDefault();
    console.info('handleSubmit', event, form);
    alert(JSON.stringify(form, null, ' '));
  }

  let selectTextFieldProps = { name: 'selectTextField', label: 'Select Text Field' };

  return (
    <>
    <FormUp2
      id='update-form'
      {...props}
      initialValues={initialValues}
      requiredValues={requiredValues}
      validationSchema={validationSchema}
      calculateValues={calculateValues}
    >
      {formProps => {
      // This code is only reached if we are in the render process so,
      // if we need access to information that is not in FormUp2 we can get it here.
      // We don't want use setState here because it will trigger a superfluous re-render.
      // If we need to keep the value, use a global variable.
      {/* if (!formProps.values?.textField?.length)
        derivedValue = 'Text Field is required!';
      else
        derivedValue = null; */}
      values = formProps.values;
      derivedValue = 'Derived Value: ' + formProps.values?.textField + 'Boo!';
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <Heading>{'Update Form 2'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {derivedValue &&
            <Grid item >
              <FormError message={derivedValue} />
            </Grid>}

            <Grid item >
              <TextField {...formProps.fieldProps} name='textField' label='Text Field' required={true} />
            </Grid>

            <Grid item >
              <SelectTextField {...formProps.fieldProps} {...selectTextFieldProps} items={selectItems} />
            </Grid>

            <Grid item >
              <NumberField {...formProps.fieldProps} name='numberField' label='Number Field' />
            </Grid>

            <Grid item >
              <AmountField {...formProps.fieldProps} name='amountField' label='Amount Field' />
            </Grid>

            <Grid item >
              <PercentField {...formProps.fieldProps} name='percentField' label='Percent Field' />
            </Grid>

            <Grid item >
              <NumberField {...formProps.fieldProps} name='calculatedField' label='Calculated Field' disabled={true} />
            </Grid>

            <Grid item >
              <Checkbox {...formProps.checkboxProps} name='checkbox' label={'Checkbox'} />
            </Grid>

            <Grid item >
              <ButtonBar>
                <PrimaryButton title='Submit' disabled={!submitEnabled}
                  onClick={(event) => handleSubmit(event, formProps)} />
              </ButtonBar>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: formProps }} open={true} />
        </>
      );}}

    </FormUp2>
    </>
  );
}

UpdateForm2.propTypes = {
  location: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  state: PropTypes.shape(),
};
