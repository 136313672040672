import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import gql from 'graphql-tag';
import { gql } from '@apollo/client';
// import { ApolloProvider, useQuery } from '@apollo/react-hooks';
// import { ApolloProvider, useQuery } from '@apollo/client';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import theme from '@org/client-libs-themes';
// import { styles } from '@org/client-libs-styles';
// import { PrimaryButton, InLink, TextField } from '@org/client-components-core';
import { PrimaryButton } from '@org/client-components-core';
import {
  Page, PageHeadingWrapper, PageHeadingLeft, PageHeadingCenter, PageHeadingRight,
  // SectionHeading,
  // Online, Authenticated, ViewerAgent, PingAuthenticated,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import {
  // query,
  publicResolvers,
  // authenticatedClient,
  // authenticatedSchema,
  // authorizedClient, authorizedSchema,
  authenticatedResolvers,
  publicHandler, authenticatedHandler, authorizedHandler,
  authorizedResolvers,
  authorizedClient,
  // handleClientError,
} from '@org/client-graphql';
// import { testSignInProcess, testSignUpProcess } from './tests/signInProcess';
// import { testSignUpProcess } from './tests/signInProcess';

// const location = 'client.pages.dev.Test';

// const localStyles = {
//   ...styles,
//   // root: {
//   //   padding: theme.spacing(2),
//   //   display: 'flex',
//   //   flexDirection: 'column',
//   //   justifyContent:'flex-start',
//   //   alignItems: 'center',
//   // },
// };

// const useStyles = makeStyles(localStyles);

function DevSection(props) {
  return (
    <section style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', margin: theme.spacing(1), padding: theme.spacing(1), border: '1px solid', borderRadius: '4px', width: 'auto', minWidth: 'fit-content'}}>
      {props.children}
    </section>
  );
}

DevSection.propTypes = {
  children: PropTypes.node,
};

// export function PingAuthenticated2() {
//   let response = query(authenticatedSchema.PING_AUTHENTICATED);
//   // console.log('ping', typeof response, response);
//   return <div>PingAuthenticated: {response}</div>;
// }

// URGENT Create a suite of test data
const testData = {
  loanId: 'Xpn4Fd09QrGlglrZaQ2ztg',
  messageId: 'ABChfQYMRhCmJ-NijFhHWQ',
  noteId: 'SkXn4XjkR0K0C24qQ6aiQA',
  sessionId: 'CK0RsjnOR664tuz2O5hwsQ',
  transactionId: 'Tmro-QB9T0OXIT4-dbM9PA',
};
let testUser = {
  id: 'AbOraWCaRKO3yzfb8mUUfA',
  firstName: 'Test',
  lastName: 'User',
  email: 'ztcooper+test@gmail.com',
};

function checkError(resolver) {
  throw new Error(`handleCheckResponse: Response does not match check: ${resolver}`);
}

function handleCheckResponse(response) {
  // console.log('handleCheckResponse', 'response', response);
  // let { resolver, success, data, error } = response;
  let { resolver, data } = response;
  switch(resolver) {
    // case 'pingAuthorized':
    //   if (data !== 'pong!') checkError(resolver);
    //   break;
    // case 'getAuth':
    //   if (data.id !== testData.userId) checkError(resolver);
    //   break;
    // case 'getUser':
    //   if (data.id !== testData.userId) checkError(resolver);
    //   break;
    default:
      // throw new Error(`handleCheckResponse: Test check not found for: ${resolver}`);
      console.log(resolver, 'needs a data test...');
      return;
  }
  // console.log(resolver, 'ok');
}

let isMounted = false;

export function Test() {
  let [ state, setState ] = useState({ isLoading: true, isSubmitting: false, error: null, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
  // let classes = useStyles();
  // const [ error, setError ] = useState();
  // let [ state, publicResolvers ] = useState({ isLoading: true, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
  // let [ state, authenticatedResolvers ] = useState({ isLoading: true, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
  // let [ state, authorizedResolvers ] = useState({ isLoading: true, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });

  // let disabled = state.isLoading || state.isSubmitting;
  let disabled = state.isSubmitting;
  // let response = {};

  // try {
  //   // response = useQuery(authenticatedSchema.PING_AUTHENTICATED);
  //   response = query(authenticatedSchema.PING_AUTHENTICATED);
  //   console.log(response);
  //   // if (response.error) handleClientError(response.error);
  //   // else console.log(response);
  //   // response = useQuery(authorizedSchema.PING_AUTHORIZED);
  //   // if (response.error) handleClientError(response);
  //   // else console.log(response);
  // } catch (error) {
  //   handleClientError({ error });
  // }

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) { // only do this on the initial page load
        window.scrollTo(0, 0);
        if (isMounted)
            setState({ ...state, isLoading: false });
      }
    })();

    return () => { isMounted = false; };
  }, []);

  function handleTestResponse(resolvers, test, response) {
    console.log('handleTestResponse', response);
    if (response.error && response.error != null)
      // throw response.error;
      return `failed: ${response.error.message}`;
    
    // if (!response.success)
    //   // throw new Error(test, 'failed!', 'No error was returned.');
    //   // throw new Error(`${response.resolver} failed! No error was returned.`);
    //   return `failed: But no error was returned`;

    if (typeof response === 'string') { // ping, getVersion
      handleCheckResponse(response);
      setState(prevState => ({ ...prevState, [resolvers]: { ...prevState[resolvers], [test]: 'passed!' } }));
    } else if (response.success) {
      handleCheckResponse(response);
      setState(prevState => ({ ...prevState, [resolvers]: { ...prevState[resolvers], [test]: 'passed!' } }));
    } else if (!response.success && !response.error)
      setState(prevState => ({ ...prevState, [resolvers]: { ...prevState[resolvers], [test]: `failed. No error returned.` } }));
    else
      setState(prevState => ({ ...prevState, [resolvers]: { ...prevState[resolvers], [test]: `failed. ${response.error.message}` } }));

    // setState(prevState => ({ ...prevState, publicResolvers: { ...prevState['publicResolvers'], [test]: response } }));
    
    // return 'passed!';
    return response;
  }

  // async function handleRunTest({ resolver, test, input }) {
  //   let response = await resolver[test](input ? input : null);
  //   console.log(response);
  //   // let { resolver: resolverName, success, data, error } = response;
  //   // console.log(resolverName);
  // }

  async function publicTest(test, input) {
    console.log('publicTest', 'input', input);
    // let response = await publicResolvers[test](input ? input : null);
    let response = await publicHandler[test](input ? input : null);
    console.log('publicTest', 'response', response);
    response = handleTestResponse('publicResolvers', test, response);
    // setState(prevState => ({ ...prevState, publicResolvers: { ...prevState['publicResolvers'], [test]: response } }));
    return response;
  }

  async function authenticatedTest(test, input) {
    // let response = await authenticatedResolvers[test](input ? input : null);
    let response = await authenticatedHandler[test](input ? input : null);
    console.log('authenticatedTest', response);
    // response = handleTestResponse('authenticatedResolvers', test, response);
    handleTestResponse('authenticatedResolvers', test, response);
    // setState(prevState => ({ ...prevState, authenticatedResolvers: { ...prevState['authenticatedResolvers'], [test]: response } }));
    return response;
  }

  async function authorizedTest(test, input) {
    console.log('authorizedTest', test, input);
    // let response = await authorizedResolvers[test](input ? input : null);
    let response = await authorizedHandler[test](input ? input : null);
    console.log(test, response);
    response = handleTestResponse('authorizedResolvers', test, response);
    // setState(prevState => ({ ...prevState, authorizedResolvers: { ...prevState['authorizedResolvers'], [test]: response } }));
    return response;
  }

  async function handleTestInvitation() {
    setState({ ...state, isSubmitting: true, error: null, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
    let response = {};
    try {
      let invitation = {
        id: 'RS50ngiZT8S8ECa_bpLHTA',
        confirmCode: '648084',
      };
      await publicTest('verifyInvitation', invitation);
    } catch (error) {
      // setState(prevState => ({ ...prevState, isSubmitting: false, error: handleClientError({ error }).message }));
      setState(prevState => ({ ...prevState, isSubmitting: false, error }));
    }
  }

  // async function handleTestSignIn() {
  //   setState({ ...state, isSubmitting: true, error: null, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
  //   try {
  //     await testSignInProcess();
  //     setState({ ...state, isSubmitting: false });
  //   } catch (error) {
  //     setState(prevState => ({ ...prevState, isSubmitting: false, error: handleClientError({ error }).message }));
  //     setState(prevState => ({ ...prevState, isSubmitting: false, error }));
  //   }
  // }

  async function handleTestSignUp() {
    setState({ ...state, isSubmitting: true, error: null, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
    try {
      await testSignUpProcess();
      setState({ ...state, isSubmitting: false });
    } catch (error) {
      // setState(prevState => ({ ...prevState, isSubmitting: false, error: handleClientError({ error }).message }));
      setState(prevState => ({ ...prevState, isSubmitting: false, error }));
    }
  }

  async function handleRunTests() {
    // alert('ha-ya!');
    setState({ ...state, isSubmitting: true, error: null, publicResolvers: {}, authenticatedResolvers: {}, authorizedResolvers: {} });
    let response = {};
    try {

      const GET_SCHEMA = gql`
      {
        __schema {
          types {
            name
          }
        }
      }
      # {
      #   __schema {
      #     queryType {
      #       name
      #     }
      #   }
      # }
      `;
      let params = {
        query: GET_SCHEMA,
      };
      response = await authorizedClient.query(params);
      console.log(JSON.stringify(response.data.__schema, null, ' '));

      // check connections
      await publicTest('getVersion');
      await publicTest('pingPublic'); // works
      await authenticatedTest('pingAuthenticated'); // works
      await authorizedTest('pingAuthorized'); // works

      // check create
      let testLoan = {
        // id: '' // returned by createLoan()
        // itemType: 'LOAN', // returned by createLoan()
        assetType: 'REAL_ESTATE',
        assetSubType: 'RESIDENTIAL',
        interestRate: 12.99,
        servicingRate: 0.50,
        // netRate: 12.49, // returned by createLoan()
        originalAmount: 1000000.00,
        originalTerm: 12,
        firstPaymentOn: '2020-11-01',
      };
      // response = await authorizedTest('createLoan', testLoan); // works
      // console.log(location, 'response', response);
      // testLoan = { ...testLoan, ...response.data };
      // await authorizedTest('updateLoan', { id: testLoan.id, originalAmount: testLoan.originalAmount + 0.99 });
      // response = await authorizedTest('getLoan', { id: testData.loanId }); // works
      // response = await authorizedTest('getLoan', { id: testLoan.id }); // works
      // console.log(location, 'response', response);

//      let testNote = {
//        itemName: 'Test Note 1',
//        description: 'I am a test note...',
//        managementRate: 1.0,
//        minimumAmount: 5000.00,
//      };
//      testNote = {
//        ...testNote,
//        coupon: testLoan.netRate - testNote.managemenRate,
//        originalAmount: testLoan.originalAmount,
//        referenceItemId: testLoan.id,
//        referenceItemType: testLoan.itemType,
//        referenceItemAssetType: testLoan.assetType,
//        referenceItemAssetSubType: testLoan.assetSubType,
//      };
      // console.log(location, 'testNote', testNote);
      // response = await authorizedTest('createNote', testNote);
      // console.log(location, 'response', response);
      // testNote = { ...testNote, ...response.data };
      // await authorizedTest('updateNote', { id: testNote.id, originalAmount: testNote.originalAmount + 0.99 });
      // response = await authorizedTest('getNote', { id: testData.noteId }); // works
      // response = await authorizedTest('getNote', { id: testNote.id }); // works
      // console.log(location, 'response', response);

      // response = await authenticatedTest('createTransaction', { itemType: 'NOTE' itemId: testNote.id, amount: 25001.99 });
      // response = await authenticatedTest('createTransaction', { itemType: 'NOTE', itemId: testData.noteId, amount: 25001.99 }); // works
      // console.log(location, 'response', response);
      // await authorizedTest('getTransaction', { id: response.data.id }); // works
      // await authorizedTest('getTransaction', { id: testData.transactionId }); // works

      // await authorizedTest('getUser', { id: testUser.id }); // works
      // await authorizedTest('getEmail', { email: testUser.email }); // works
      // await authorizedTest('getAuth', { id: testUser.id }); // works
      // await authorizedTest('getSession', { id: testData.sessionId }); // works
      // await authorizedTest('getMessage', { id: testData.messageId }); // works

      // await authorizedTest('listUsers'); // works
      // await authorizedTest('listSessions'); // works
      // await authorizedTest('listLoans'); // works
      // await authorizedTest('listMessages'); // createMessage first!!!
      await authorizedTest('listNotes'); // works
      // await authorizedTest('listConnections'); // works
      // await authorizedTest('listTables'); // works
      // await authorizedTest('listTransactions'); // works

      // await authorizedTest('updateUser', { id: testUser.id, firstName: 'Test' }); // works

      // await authenticatedTest('getAuth'); // works
      // await authenticatedTest('getMe'); // works
      // await authenticatedTest('getLoan', { id: testData.loanId }); // works
      // await authenticatedTest('getLoan', { id: testLoan.id });
      // await authenticatedTest('getNote', { id: testData.noteId }); // works
      // await authenticatedTest('getNote', { id: testNote.id });
      // await authenticatedTest('getSession'); // do I need this?
      // await authenticatedTest('getTransaction', { id: testData.transactionId }); // works
      // await authenticatedTest('listNotes'); // works
      // await authenticatedTest('listTransactions'); // works
      // changeEmail,
      // changePassword,
      // createTransaction,
      // signOut,
      // updateMe,

      // await publicTest('listNotes'); // works

      // await authorizedTest('deleteNote', { id: testNote.id }); // works
      // await authorizedTest('deleteLoan', { id: testLoan.id }); // works
      setState(prevState => ({ ...prevState, isSubmitting: false }));
    } catch (error) {
      // console.error('here', error);
      // handleClientError({ error });
      // setError(error);
      // setState({ ...state, isSubmitting: false, error });
      // setState(prevState => ({ ...prevState, isSubmitting: false, error }));
      setState(prevState => ({ ...prevState, isSubmitting: false, error: handleClientError({ error }).message }));
    }
  }

  return(
    <Page id='dev-test'>

      <PageHeadingWrapper>
        <PageHeadingLeft />
        <PageHeadingCenter>{'Dev Test'}</PageHeadingCenter>
        <PageHeadingRight />
      </PageHeadingWrapper>

      {/* <PingAuthenticated />
      <PingAuthenticated2 /> */}

      {/* <PrimaryButton name='test-email' title='Test Email' onClick={() => handleSomething()} validated={true} /> */}
      <PrimaryButton name='run-tests' title='Run Tests' disabled={disabled}
        onClick={() => handleRunTests()} validated={true} />
      <PrimaryButton name='test-invitation' title='Test Invitation' disabled={disabled}
        onClick={handleTestInvitation} validated={true} />
      <PrimaryButton name='test-invitation' title='Test Sign In' disabled={disabled}
        onClick={handleTestSignIn} validated={true} />
      <PrimaryButton name='test-invitation' title='Test Sign Up' disabled={disabled}
        onClick={handleTestSignIn} validated={true} />

      <DisplayState title='state' state={state} open={true} />
    </Page>
  );
}

export default Test;
