import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
// import AppBar from '@material-ui/core/AppBar';
// import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const drawerWidth = 240;

// const useStyles = makeStyles((theme) => ({
const useStyles = makeStyles({
  // root: {
  //   display: 'flex',
  // },
  // appBar: {
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  // content: {
  //   flexGrow: 1,
  //   padding: theme.spacing(3),
  // },
});
// }));

export function ClippedDrawer(props) {
  let { open } = props;
  // const [ state, setState = useState({ open: true });
    // const [ open, setOpen ] = useState(true);
  const classes = useStyles();

  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }
  
  //   setState({ ...state, [anchor]: open });
  // };

  // function toggleDrawer() {
  //   setOpen(!open);
  // }

  return (
    <>
    {/* <Button onClick={toggleDrawer}>{'anchor'}</Button> */}
    <Drawer
      className={classes.drawer}
      variant="permanent"
      // variant="temporary"
      // role="presentation"
      classes={{
        paper: classes.drawerPaper,
      }}
      elevation={0}
      anchor={'left'}
      open={open}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
    </>
  );
}
