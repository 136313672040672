import React from 'react';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
// import gql from 'graphql-tag';
import { publicSchema } from '@org/client-graphql';
import Typography from '@material-ui/core/Typography';

// http://www.petecorey.com/blog/2019/05/13/is-my-apollo-client-connected-to-the-server/

// export async function toggleConnection() {
//   let response = await publicResolvers.isConnected();
//   publicClient.writeData({ data: { isConnected: !response } });
//   publicClient.writeQuery({ data: { isConnected: !response } });
// }

// export async function isAuthenticated() {
//   let response = await publicResolvers.isConnected();
//   return response;
// }

// const IS_CONNECTED = gql`{ isConnected @client }`;

export function Connected() {
  // const { loading, error, data } = useQuery(IS_CONNECTED);
  const { loading, error, data } = useQuery(publicSchema.IS_CONNECTED);
  let isConnected = null;
  if (loading) isConnected = 'Loading...';
  if (error) isConnected = `Error! ${error.message}`;
  if (data) isConnected = `API v${data.connected}`;
  return (
    <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }} >
      {isConnected}
    </Typography>
  );
}

export default Connected;
