import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
// import * as yup from 'yup';
import * as yup from 'yup/es';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  FormUp2, BackdropLoader, Modal, DatePicker,
  TextField, AmountField, SelectTextField,
  PrimaryButton, SecondaryButton,
} from '@org/client-components-core';
import {
  // Page,
  FormLayout, Heading, FlexRow, FlexColumn, FormMessage, FormError,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';
import { itemTypeShape, ulidShape } from '@org/common-yup';
import { AuthorizedHandler, AuthenticatedHandler } from '@org/client-graphql';
import { ConfirmTradeDialog } from './ConfirmTradeDialog';
import { TradeClass } from '@org/common-classes/Trade';
import { dollarAmount } from '@org/common-formatters';

// const useStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  ...styles,
  formWrapper: {
    width: '100%',
    maxWidth: '100% !important',
    [theme.breakpoints.up('sm')]: {
      minHeight: '80vh',
      padding: theme.spacing(4, 0, 4, 0),
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: '60vh',
      // padding: theme.spacing(2, 0, 2, 0),
      padding: theme.spacing(1),
    },
  },
  formLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // add media breaks
    // minHeight: '80vh',
    [theme.breakpoints.up('sm')]: {
      width: 'fit-content',
      // margin: theme.spacing(4, 4, 4, 4),
      padding: theme.spacing(4),
      borderRadius: '4px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      maxWidth: '100% !important',
      // padding: theme.spacing(2),
      // margin: theme.spacing(0),
      padding: theme.spacing(0),
      boxShadow: '0px', // turn off shadow in xs
    },
  },
}));

let tradeTypeItems = [ { label: 'Buy', value: 'BUY' }, { label: 'Sell', value: 'SELL' } ];

// export function createYupSchema(schema, config) {
//   const { id, validationType, validations = [] } = config;
//   if (!yup[validationType]) {
//     return schema;
//   }
//   let validator = yup[validationType]();
//   validations.forEach(validation => {
//     const { params, type } = validation;
//     if (!validator[type]) {
//       return;
//     }
//     console.log(type, params);
//     validator = validator[type](...params);
//   });
//   schema[id] = validator;
//   return schema;
// }

let defaultValues = {
  // accountBalance: 0.0,
  id: "",
  itemType: "",
  tradeType: "BUY",
  eventDate: dayjs().format('YYYY-MM-DD'),
  originalBalance: 0.0,
  availableBalance: 0.0,
  // currentFactor: 1.0,
  factor: 1.0,
  currentBalance: 0.0,
  minimumAmount: 0.0,
  amount: 0.0,
  price: 100.0,
  investedAmount: 0.0,
};

let initialValues;

// let requiredValues = {
//   amount: true,
// };

let validationShape = {
  // accountBalance: yup.number().positive().required('Trade Amount is required'),
  // accountBalance: yup.lazy((value) => {
  //   return yup.number().positive().required('Trade Amount is required');
  // }),
  itemType: itemTypeShape,
  itemId: ulidShape
    .required('Item ID is required'),
  eventDate: yup.string(),
  amount: yup.number().positive().required('Trade Amount is required'),
  referenceType: yup.string(),
  referenceId: yup.string(),
  tradeType: yup.string(),
  price: yup.number(),
  factor: yup.number(),
  investedAmount: yup.number().positive().required('Invested Amount is required'),
};
// yup.lazy((value) => validationShape)
const validationSchema = yup.object().shape(validationShape);

function calculateValues(values) {
  // console.log('calculateValues', values);

  let investedAmount = values.amount * values.price / 100 * values.factor;

  values = {
    ...values,
    investedAmount,
  };

  return values;
}

function InsufficientFunds() {
  return (
    <div style={{height: 'auto', width: 'auto', maxWidth: '300px', padding: '10px', whiteSpace: 'pre-wrap', backgroundColor: 'white'}}>
      {'You had insufficient funds to make this transactions so we have made a deposit to your account for this demo. Ordinarily you would be required to have the funds in your account prior to entering a trade.'}
    </div>
  );
}

let authenticatedHandler;
let authorizedHandler;

let trade;
let accountBalance = 0;

export function EnterTrade(props) {
  // console.log('EnterTrade', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null
  });
  const [ open, setOpen ] = useState(false);
  const [ confirmOpen, setConfirmOpen ] = useState(false);
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  let classes = useStyles();

  if (!trade) {
    trade = new TradeClass();
  }

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  initialValues = calculateValues({ ...defaultValues, ...props.location?.state?.note });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    (async () => {
      accountBalance = props.location?.state?.accountBalance;
      if (!accountBalance && !open) {
        // console.warn(!Math.max(accountBalance, 0), !open, !state.isLoading, !Math.max(accountBalance, 0) && !open && !state.isLoading);
        let depositAmount = 100000.0;
        let input = { eventDate: dayjs().format('YYYY-MM-DD'), amount: depositAmount };
        try {
          await authorizedHandler.createDeposit(input);
          accountBalance += depositAmount;
          // console.log('useEffect', accountBalance);
          if (isMounted) {
            setOpen(true);
            setState({ ...state, isLoading: false });
          }
        } catch (error) {
          console.error('EnterTrade', 'useEffect', 'createDeposit', error);
          setState({ ...state, isLoading: false, formError: error });
        }
      } else
        setState({ ...state, isLoading: false });
    })();

    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    // console.log(location, event, form);
    // event.preventDefault();
    setState({ ...state, isLoading: true, isSubmitting: true, formError: null });

    window.scrollTo(0, 0);

    let { values } = form;

    let inputs = {
      referenceType: values.itemType,
      referenceId: values.id,
      eventDate: values.eventDate,
      tradeType: 'BUY',
      amount: values.amount,
      price: values.price,
      investedAmount: values.investedAmount,
    };

    try {
      await authenticatedHandler.createTrade(inputs);

      setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Transaction successfully created', formError: null });
    } catch (error) {
      console.error('EnterTransaction', error);
      setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  function handleConfirm(event, props) {
    // console.log('handleConfirm', event, props);
    // if (confirmed)
    handleSubmit(event, props);
    setConfirmOpen(false);
  }

  // open/close the dialog
  function handleShowConfirm() {
    setConfirmOpen(true);
  }

  function handleCancel() {
    props.history.push({
      pathname: '/invest/detail',
      state: { note: props.location.state.note },
    });
  }

  function handleClose() {
    setOpen(false);
  }

  let confirmDialogProps = { open: confirmOpen, setConfirmOpen, handleConfirm };

  let tradeTypeProps = { id:'tradeType', name: 'tradeType', label: 'Type', required: true };

  if (state.isCompleted) {
    return (
      <FormLayout>

        <Heading>{'Enter Trade'}</Heading>

        <FormMessage message={state.formMessage} />

      </FormLayout>
    );
  }

  // console.log('about to render', accountBalance);
  let maxWidth = isXs ? 'xs' : 'md';
  console.log('EnterTrade', 'maxWidth', maxWidth);

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <FormUp2
      id='enter-trade-form'
      {...props}
      initialValues={initialValues}
      // requiredValues={requiredValues}
      validationSchema={validationSchema}
      calculateValues={calculateValues}
      style={{ maxWidth: '100% !important' }}
    >
      {formProps => {
      let { minimumAmount, availableBalance, investedAmount } = formProps.values;
      {/* console.log('formProps', accountBalance, minimumAmount, availableBalance, investedAmount); */}
      let tradeError;
      if (investedAmount < minimumAmount)
        tradeError = `The minimum investment is ${dollarAmount.format(minimumAmount)}`;
      else if (investedAmount > accountBalance)
        tradeError = `You're account accountBalance is ${dollarAmount.format(accountBalance)}. Please reduce the Trade Amount or deposit additional funds.`;
      else if (investedAmount > availableBalance)
        tradeError = `There is only ${dollarAmount.format(availableBalance)} available.`;

      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated && !tradeError;
      return (
        <>
        {/* <Page> */}
        <div id='container-wrapper' className={classes.formWrapper} >

          {/* <Container component="main" maxWidth={maxWidth} className={classes.formLayout} > */}
          <Container component="main" className={classes.formLayout} >

          <Heading>{'Enter Trade'}</Heading>
          {/* <p>{accountBalance}</p>
          <p>{tradeError}</p> */}
          
          {state.formMessage &&
          <FormMessage message={state.formMessage} />}

          {state.formError &&
          <FormError message={state.formError.message} />}

          {tradeError &&
          <FormError message={tradeError} />}

          {!state.isCompleted &&
          <>
          <FlexRow style={{justifyContent: 'center', flexWrap: 'wrap'}} >

            <FlexColumn style={{width: 'auto', maxWidth: '100%', padding: theme.spacing(0, 2, 0, 2)}} >

              <Grid style={{ width: '100%', maxWidth: '100% !important' }} >

                <Grid item >
                  <TextField
                    {...formProps.fieldProps}
                    name='itemType'
                    label='Item Type'
                    required={true}
                    disabled={true}
                  />
                </Grid>

                <Grid item >
                  <TextField
                    {...formProps.fieldProps}
                    name='itemName'
                    label='Name'
                    required={true}
                    disabled={true}
                  />
                </Grid>

                <Grid item >
                  <SelectTextField {...tradeTypeProps} {...formProps.fieldProps} items={tradeTypeItems} disabled={true} />
                </Grid>

                <Grid item >
                  <AmountField {...formProps.fieldProps} name='originalBalance' label='Original Balance' disabled={true} />
                </Grid>

                <Grid item >
                  <AmountField {...formProps.fieldProps} name='availableBalance' label='Available Balance' disabled={true} />
                </Grid>

              </Grid>

            </FlexColumn>

            <FlexColumn style={{width: 'auto', maxWidth: '100%', padding: theme.spacing(0, 2, 0, 2)}} >

              <Grid style={{ width: '100%', maxWidth: '100% !important' }} >

                <Grid item >
                  <AmountField {...formProps.fieldProps} name='minimumAmount' label='Minimum Trade Amount' disabled={true} />
                </Grid>

                <Grid item >
                  <DatePicker {...formProps.fieldProps} name='eventDate' label='Trade Date' required={true} minDate={dayjs().format('YYYY-MM-DD')} disabled={true} />
                </Grid>

                <Grid item >
                  <AmountField {...formProps.fieldProps} name='amount' label='Trade Amount' required={true} />
                </Grid>

                <Grid item >
                  <AmountField {...formProps.fieldProps} name='price' label='Price' required={true} disabled={true} />
                </Grid>

                <Grid item >
                  <AmountField {...formProps.fieldProps} name='investedAmount' label='Invested Amount' disabled={true} />
                </Grid>

              </Grid>

            </FlexColumn>

          </FlexRow>

          <FlexRow className={clsx(classes.centered, classes.formButtonSpan)} >
            <PrimaryButton {...props.submitProps} title='Submit' disabled={!submitEnabled}
              onClick={handleShowConfirm} />
            <SecondaryButton title='Cancel' onClick={handleCancel} />
          </FlexRow>

          </>}

          </Container>

        </div>
        {/* </Page> */}

        <Modal id='insufficient-funds-modal' open={open} handleClose={handleClose} className={classes.presentationModal} >
          <InsufficientFunds />
        </Modal>

        <ConfirmTradeDialog {...confirmDialogProps} form={formProps} />

        <DisplayState title='state' state={{ state, formProps }} open={true} />
        </>
      );}}

    </FormUp2>
    </>
  );
}

EnterTrade.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
};
