import React from 'react';
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloProvider } from '@apollo/client';
// import { authenticatedClient } from '@org/client-graphql';
import { LinkButton } from '@org/client-components-core';
// import { GetMe } from '@org/client-components-custom';
import {
  Page, Heading, FlexRow, FlexColumn,
  // PageHeadingWrapper, PageHeadingLeft, PageHeadingCenter, PageHeadingRight,
  Section, SectionBody, Bullet
} from '@org/client-components-custom';
// import theme from '@org/client-libs-themes';
// import { styles } from '@org/client-libs-styles';
// import { Chart } from './Chart';

// https://material-ui.com/getting-started/templates/

// const location = 'client.pages.user.Welcome';

// const localStyles = {
//   ...styles,
//   root: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent:'flex-start',
//     alignItems: 'center',
//   },
//   paper: {
//     padding: theme.spacing(2),
//     display: 'flex',
//     overflow: 'auto',
//     flexDirection: 'column',
//     width: '100%',
//   },
//   fixedHeight: {
//     height: 240,
//   },
// };

// const useStyles = makeStyles(localStyles);

export function Welcome() {
  // let classes = useStyles();

  return(
    <>
    <Page id='user-welcome' >

      {/* <PageHeadingWrapper>
        <PageHeadingLeft />
        <PageHeadingCenter>{'Welcome Demo User'}</PageHeadingCenter>
        <PageHeadingRight />
      </PageHeadingWrapper> */}
      <Heading>{'Welcome Demo User'}</Heading>

      {/* <Modal id='the-fund-modal' open={open} handleClose={handleClose} className={classes.presentationModal} >
        <Suspense fallback={<BackdropLoader loading={true} />} >
          <FundPresentation {...presentationProps} />
        </Suspense>
      </Modal> */}
      <Section>

        <SectionBody>
          {/* {`Welcome to the demo version of our web app. In future versions, this page will serve as your dashboard which will show personal summary information, personalized content, and other pertinent information such as new features and updates.`} */}
          {`Welcome to Cubit Investments web app demo release!`}
        </SectionBody>

        <SectionBody>
          {'You have mostly likely received this invitation because you have expertise in one or more of the following: software development, web design, securities law, structured credit, and/or lending. As such, I would really appreciate it if you would check out the app, execute transactions, etc. Depending on your version, you may find some initial transactions in your holdings with respective cashflows, statements, etc. The purpose of this is to show you some of the functionality you would not see otherwise in the demo (and this will not be part of the production release).'}
        </SectionBody>

        <SectionBody>
          {'In general:'}<br/>
          <Bullet>{'Nothing in this version is binding (other than the User Agreements) so please feel free to do whatever you want (although I may delete your transactions or make other changes to create a better demo experience for all).'}</Bullet>
          <Bullet>{'Security has already been implemented but, in this version, you will not be required to enter certain private information (e.g. tax ID, bank account number, etc.) and I will not use any information you enter other than your email address.'}</Bullet>
          <Bullet>{'Notwithstanding the above, all inputs are screened (or will be shortly) for, among other things, invalid data, invalid formats, and potential security risks. As a result, if entering fake information, please enter it properly formatted or you will receive an error message or, in certain cases, your access will be suspended.'}</Bullet>
        </SectionBody>

        <SectionBody>
          {`I would also really appreciate your feedback. However, as a demo version, not everything will work all the time and certain things may break from time to time. I am working at a rapid pace, pushing out new features daily, so please keep that in mind while you explore and test the app.`}
        </SectionBody>

        <SectionBody>
          {'Regarding feedback:'}<br/>
          <Bullet>{'If something is inactive (e.g. grayed out), it is in development and coming soon.'}</Bullet>
          <Bullet>{'Active elements (e.g. you can click the link or button), it should be working. If not, please let me know.'}</Bullet>
          <Bullet>{'Nothing should be clearly incorrect at this point (e.g. User, Note, and/or Transaction information.) If so, please let me know.'}</Bullet>
        </SectionBody>

        <SectionBody>
          {'Finally, please don’t share the site with anyone else for the time being. Access is current by invitation only, and I am trying to limit the amount of time I spend away from code development and legal documents for now.'}<br/>
        </SectionBody>

        <SectionBody>
          {'I hope to be live soon and thanks for your help!'}
        </SectionBody>

        <SectionBody>
          {'Zach'}
        </SectionBody>

      </Section>
      <FlexRow>
        <FlexColumn>
          <LinkButton name='learn-more-button' title='Learn More' to='/learn' color='default' style={{ whiteSpace: 'nowrap', }} />
        </FlexColumn>
      </FlexRow>

      {/* <Chart /> */}

    </Page>
    </>
  );
}

export default Welcome;
