// import * as yup from "yup";
import * as yup from 'yup/es';

const exampleShape = {
  textField: yup
    .string().trim('No leading or trailing spaces allowed')
    .max(24),
  emailField: yup
    .string()
    .min(7, "Please check the email address, it doesn't appear to be a valid format")
    .email("Please check the email address, it doesn't appear to be a valid format"),
  passwordField: yup
    .string()
    .min(8, "Passwords must be at least 8 characters")
    .max(16, "Passwords must be no more than 16 characters")
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&_=+-])/, "Passwords must contain at least: 1 capital, 1 number, and 1 of these symbols: !?@#$%^&_=+-"),
  numberField: yup.number(),
  percentField: yup.number(),
  amountField: yup.number(),
  selectTextField: yup.string(),
  checkboxField: yup.boolean().oneOf([true], "You must check!"),
  datePickerField: yup.string(),
};

export const exampleSchema = yup.object().shape(exampleShape);

// const assetTypes = [ 'REAL_ESTATE', 'COMMERCIAL', 'MULTIFAMILY', 'FINE_ART' ];

// const loanShape = {
//   // automatically generated
//   id: yup.string().length(22),
//   createdAt: yup.string().length(24),
//   createdBy: yup.string().length(22),
//   updatedAt: yup.string().length(24),
//   updatedBy: yup.string().length(22),
//   // user entered
//   assetType: yup.string().oneOf(assetTypes).required(),
//   interestRate: yup.number().required('Interest Rate is required'),
//   servicingRate: yup.number().required('Servicing Rate is required'),
//   originalAmount: yup.number().required('Original Amount is required'),
//   currentAmount: yup.number().required('Current Amount is required'),
//   loanTerm: yup.number().required('Term is required'),
//   paymentFrequency:  yup.string().length(16).required('Payment Frequency is required'), // DAILY, MONTHLY, QUARTERLY, etc
//   paymentType: yup.string().length(16).required('Payment Type is required'), // DISCOUNT, FIXED, FIXED_IO, ARM, ARM_IO
//   closedOn: yup.date().required('Closing Date is required'),
//   firstPaymentDate: yup.date().required('First Payment Date is required'),
//   status: yup.string().length(16),
// };

// export const loanSchema = yup.object().shape(loanShape);

// const residentialLoanShape = {
//   ...loanShape,
// };

// export const residentialLoanSchema = yup.object().shape(residentialLoanShape);

// https://stackoverflow.com/questions/15491894/regex-to-validate-date-format-dd-mm-yyyy
// const transactionShape = {
//   itemId: yup.string().length(22).required('Item ID is required'),
//   itemType: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Type is required'),
//   // itemName: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Name is required'),
//   xType: yup.string(),
//   xDate: yup.string(),
//   xPrice: yup.number(),
//   xAmount: yup.number().required('Amount is required'),
// };

// export const transactionSchema = yup.object().shape(transactionShape);
