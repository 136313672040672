import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from '@org/client-libs-styles';
import { CheckAdornment } from '@org/client-components-custom';

const useStyles = makeStyles(styles);

function PasswordAdornment(props) {
  let { showPassword, handleShowPassword } = props;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return(
    <InputAdornment position='end' >
      <IconButton
        name='showPassword'
        aria-label='toggle password visibility'
        edge='end'
        onClick={() => handleShowPassword(!showPassword)}
        onMouseDown={handleMouseDownPassword}
      >
        {showPassword ? <VisibilityOff />: <Visibility />}
      </IconButton>
    </InputAdornment>
  );
}

PasswordAdornment.propTypes = {
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
};

export function PasswordField(props) {
  let [ showPassword, setShowPassword ] = useState(false);
  let { name, values, errors, validated, handleChange, handleBlur, required, /* showPassword, handleShowPassword, */ text } = props;
  let value = (values && name && values[name] ? values[name] : "");
  let error = (errors && name && errors[name] ? true : false);
  let type = showPassword ? 'text' : 'password';
  let errorStyle = error ? { color: '#ff1744' } : {};
  text = text ? text : 'Password';
  let isValid = validated && validated[name] && !props.disabled;

  function handleShowPassword(show) {
    setShowPassword(show);
  }
  let adornmentProps = { showPassword, handleShowPassword };

  const classes = useStyles();

  let adornment;
  if (isValid)
    adornment = <><PasswordAdornment {...adornmentProps} /><CheckAdornment show={isValid} /></>;
  else
    adornment = <PasswordAdornment {...adornmentProps} />;
  // console.log('adornment', adornment);

  return(
    <>
    <FormControl
      className={classes.passwordInputField}
      variant='outlined' fullWidth margin='normal' required={required} >
      {/* <InputLabel htmlFor='outlined-adornment-password' style={errorStyle} >{text}</InputLabel> */}
      <InputLabel htmlFor={name} style={errorStyle} >{text}</InputLabel>
      <OutlinedInput
        // className={classes.inputField}
        // id="outlined-adornment-password"
        id={name}
        name={name}
        type={type}
        value={value}
        label={required ? text + ' *' : text} // this is only used to calculate the label length, InputLabel handles the style
        // labelWidth={70} // This is ignored if label is specified
        //onChange={handleChange(name)}
        onChange={(event) => handleChange(event, type)}
        onBlur={(event) => handleBlur(event, name)}
        error={error}
        // endAdornment={<PasswordAdornment {...adornmentProps} />}
        endAdornment={adornment}
        disabled={props.disabled}
        required={required}
      />
      {error && <FormHelperText error={true} >{errors[name]}</FormHelperText>}
    </FormControl>
    </>
  );
}

export default PasswordField;

PasswordField.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showPassword: PropTypes.bool,
  handleShowPassword: PropTypes.func,
  handleMouseDownPassword: PropTypes.func,
  children: PropTypes.any,
};
