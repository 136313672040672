import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/core/styles';
import { shallowEqual } from '@org/common-tools/compare';
// import { isDayJsObject } from './helpers';

// let location = 'client.components.core.Form';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
};

let stateValues = {};

// function handleEvent(event, value) {
//   let name = "";
//   let value;

//   if (isDayJsObject(event)) {
//     let value = dayjs(event).format('YYYY-MM-DD');
//     let name = eventValue;
//   } else if (event?.target?.floatValue)
//     return 'float';
//   else if (event?.target?.type === 'checkbox')
//     return 'checkbox';
// }

// export function FormWrapper(props) {
//   return (
//     <>
//     <Form {...props} />
//     </>
//   );
// }

// TODO Rename Form to FormWrapper?
/**
 * Component for creating forms using my MUI custom component libs with minimal code repetition.
 * Manages everything in the form but `handleSubmit` which must me managed locally.
 * 
 * The form is a controlled component and needs to have initial values passed to it.
 * 
 * Initial values can be passed in the following ways:
 * 
 * `defaultValues` (required) -- Form is a Controlled Component and must include a value for all inputs.
 *  - Set during component construction
 *  - Not affected by `valuationType`
 *  - Are not marked `touched` or `validated`
 * 
 * stateValues (optional) -- This allows values to be set after the Form has already rendered with defaultValues, e.g. after data is fetched in componentDidMount of useEffect, or if a field is computed in render props.
 *  - `stateValues` set before `componentDidMount` with valitationType `default` -- Override `defaultValues` 
 * 
 * Usage patterns:
 * 
 * - Blank form where all values need to be touched in order for the Form (`isValidated`) to be `true` -- ???
 * - Update form for existing objects -- Set `stateValues` before `componentDidMount` with `validationType = 'update'`
 * 
 * 
 * @component
 */

class StyledForm extends PureComponent {
  _validationSchema = this.props.validationSchema;

  constructor(props) {
    console.log('Form constructor', props);
    super(props);

    this.validateForm = this.validateForm.bind(this);
    this.validate = this.validate.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.resetForm = this.resetForm.bind(this);
    // this.setState = this.setState.bind(this);
    this.setValues = this.setValues.bind(this);

    this.state = {
      validationType: props.validationType ? props.validationType : 'default',
      values: { ...this.props.defaultValues, ...this.props.stateValues },
      touched: {},
      validated: {},
      errors: {},
      isValidated: false,
      isSubmitting: false,
    };
  }
  
  async componentDidMount() {
    // let validationType = (this.props.validationType) ? this.props.validationType : 'default'; // 'default | 'update'
    let { validationType } = this.state;
    // If stateValues were passed to Form prior to component mounting
    // if (this.props.stateValues && Object.keys(this.props.stateValues).length) {
    if (this.props.stateValues && !shallowEqual(stateValues, this.props.stateValues)) {
      console.warn('Form', 'componentDidMount', 'stateValues have changed', stateValues, '->', this.props.stateValues);

      // Get the changes
      let changedValues = {};
      Object.keys(this.props.stateValues).forEach(elem => {
        if (this.props.stateValues[elem] !== stateValues[elem]) {
          console.warn(elem, stateValues[elem], '->', this.props.stateValues[elem])
          changedValues = { ...changedValues, [elem]: this.props.stateValues[elem] };
        }
      });

      let values = {};
      Object.keys(changedValues).forEach(elem => { values[elem] = true; });
      // If validationType === 'update', set validated = true and touched = false
      // stateValues are assumed to be valid but we don't want to mark untouched values in update mode
      if (validationType === 'update') {
        this.setState({
          touched: { ...this.state.touched, ...values },
          validated: { ...this.state.validated, ...values },
          validationType,
        }, function() {
          this.validateForm();
          // console.log(this.state);
        });
      } else {
        Object.keys(changedValues).forEach(elem => {
          this.handleChange(elem, changedValues[elem]);
        });
      }
      stateValues = { ...stateValues, ...changedValues };
    } else {
      console.info('Form', 'componentDidMount', 'Nothing changed in stateValues');
    }
  }

  /**
   * Values passed in via `stateValues` will be updated in `this.state.values`.
   * A copy of `stateValues` is also kept and only those values that have changed will be updated.
   * This allows values to be repeatedly updated via data requests in the parent component
   * while also allowing the for values to be manually edited.
   */
  componentDidUpdate(prevProps, _prevState) {
    console.log('Form', 'componentDidUpdate', 'this.props', this.props);
    // if (this.state.values != null || this.props.stateValues != null)
      // console.log('Form', 'componentDidUpdate',this.state.values, this.props.stateValues);
    // if (this.props !== prevProps) { // This doesn't work!!! Need to compare the objects...
      // console.log('componentDidUpdate', 'props', this.props, shallowEqual(this.state.values, this.props.stateValues));
      // console.log('***** PROPS CHANGED *****', prevProps, '->', this.props);
      // Check if the stateValues have changed (most likely in response to a query response)
      // if (this.props.stateValues && !shallowEqual(this.state.values, this.props.stateValues)) {
      // console.log('Form', 'componentDidUpdate', prevProps.stateValues, stateValues, this.props?.stateValues);
      console.log('Form', 'componentDidUpdate', stateValues, this.props?.stateValues);
      if (this.props.stateValues && !shallowEqual(stateValues, this.props.stateValues)) {
      // if (this.props.stateValues && !shallowEqual(this.state.values, this.props.stateValues)) {
        // console.log('Form', 'componentDidUpdate', stateValues, this.props?.stateValues);
        console.warn('stateValues have changed...');
        // for (let elem in this.props.stateValues) {
        //   console.log(elem);
        //   if (stateValues[elem] !== this.props.stateValues[elem])
        //     console.log('componentDidUpdate', elem, stateValues[elem], '=>', this.props.stateValues[elem]);
        // }

        // console.log(this.props.stateValues && !shallowEqual(this.state.values, this.props.stateValues));
        // let keys = Object.keys(this.props.stateValues);
        // console.log('keys', typeof keys, keys);
        // console.log('***** props.stateValues changed...', this.state.values, '->', this.props.stateValues);
        // this.setState({ values: { ...this.state.values, ...this.props.stateValues } });
        // let values = {}, touched = {}, validated = {};
        // Object.keys(this.props.stateValues).forEach(elem => {
        for (let elem in this.props.stateValues) {
          // values = { ...values, [elem]: this.props.stateValues[elem] };
          // touched = { ...values, [elem]: true };
          // validated = { ...values, [elem]: true };
          // console.log(elem, this.props.stateValues[elem]);
          // this.handleChange(elem, this.props.stateValues[elem]);
          if (this.props.stateValues[elem] !== this.state.values[elem]) {
          // if (this.props.stateValues[elem] !== stateValues[elem]) {
            console.log('componentDidUpdate', elem, stateValues[elem], '=>', this.props.stateValues[elem]);
            this.setState((prevState) => ({
              values: { ...prevState.values, [elem]: this.props.stateValues[elem] },
              touched: { ...prevState.touched, [elem]: true },
              validated: { ...prevState.validated, [elem]: true },
            }), function() {
              this.validateForm();
              // console.log(this.state);
            });
          }
        }
        stateValues = this.props.stateValues;
      } else {
        console.info('Nothing changed in stateValues...')
      }
    // }
    // if (this.state !== prevState) {
    //   console.log('***** STATE CHANGED *****', prevState, '->', this.state);
    //   // this.setState({ values: { ...this.state.values, ...this.props.stateValues } });
    // }
  }

  validateForm() {
    console.log('validateForm', this.state);
    let { values, touched, validated, isValidated } = this.state;
    let validationType = (this.props.validationType) ? this.props.validationType : 'default'; // 'default | 'update'

    if (validationType === 'update') {
      // Use type 'update' to only validate the Form using only changed values.
      // This is used for updating information
      let i = 0;
      // if (Object.keys(touched).length === Object.keys(validated).length) {
      //   let keys = Object.keys(touched);
      //   for (; i < keys.length; i++) { // use a for loop so we can break at first false
      //     console.log(validationType, 'validateForm', validated[keys[i]], isValidated, !validated[i] && isValidated);
      //     if (!validated[keys[i]] && isValidated) {
      //       this.setState({ isValidated: false });
      //       break;
      //     }
      //   }
      // }
      if (Object.keys(touched).length) {
        let keys = Object.keys(touched);
        for (; i < keys.length; i++) { // use a for loop so we can break at first false
          // console.log(validationType, 'validateForm', keys[i], validated[keys[i]], isValidated, !validated[i] && isValidated);
          if (!validated[keys[i]] && isValidated) {
            // this.setState({ isValidated: false });
            break;
          }
        }
      }
      // if the loop didn't break early the form is validated
      console.log('validateForm', Object.keys(touched).length, i, isValidated);
      console.log('validateForm', i === Object.keys(touched).length && !isValidated);
      if (i === Object.keys(touched).length && !isValidated)
        this.setState({ isValidated: true });
      else if (i !== Object.keys(touched).length && isValidated)
        this.setState({ isValidated: false });
    } else if (validationType === 'default') {
      // check all values
      const reducer = (accumulator, currentValue) => accumulator && currentValue;
      // console.log(Object.keys(values).length === Object.keys(validated).length);
      // console.log(Object.values(validated));
      // console.log(Object.values(validated).reduce(reducer));
      // console.log('validateForm', Object.keys(values).length, Object.keys(validated).length);
      if (Object.keys(values).length === Object.keys(validated).length && Object.values(validated).reduce(reducer))
        this.setState({ isValidated: true });
      else
        this.setState({ isValidated: false });
    } else
      throw new Error('Unknown vaidationType', validationType);
  }

  async validate(name) {
    let value = this.state.values[name];
    console.log('validate', name, value);
    let { validated, errors } = this.state;
    try {
      await this._validationSchema.validateAt(name, { [name]: value });
      if (errors[name])
        delete errors[name];
      this.setState((prevState) => ({
        validated: { ...prevState.validated, [name]: true },
      }),
        async function() {
          await this.validateForm();
        });
      // console.log(location, 'validate success', name, value);
    } catch (error) {
      // console.log(location, 'validate error', name, value, error);
      this.setState({
        isValidated: false,
        validated: { ...validated, [name]: false },
        errors: { ...errors, [name]: error.message },
      });
    }
  }

  async handleBlur(event, name) {
    event.persist();
    console.log('handleBlur', name, event);

    if (typeof value === 'string') {
        let value = this.state.values[name];
        value.trim();
        this.setState({ values: { ...this.state.values, [name]: value } },
          async function() {
            await this.validate(name);
          });
    } else
      await this.validate(name);
  }

  // Called by Form input components
  // This only parses the input values then passes them to handleChange()
  async handleChangeEvent(event, name) {
    // console.log('handleChangeEvent', event, name);
    let value;
    if (typeof event?.toDate === 'function' && event?.toDate() instanceof Date) {
      // DatePicker (dayjs formatted object)
      value = dayjs(event).format('YYYY-MM-DD');
      // console.info('Form', 'handleChangeEvent', 'DatePicker', name, value);
      this.handleChange(name, value);
    } else if (event?.target?.type === 'checkbox') {
      name = event.target.name;
      value = event.target.checked;
      // console.info('Form', 'handleChangeEvent', 'Checkbox', name, value);
    } else if (name === 'float') {
      name = event.target.name;
      value = event.target.floatValue
      // console.info('Form', 'handleChangeEvent', 'Number TextField', name, value);
    } else {
      name = event.target.name;
      value = event.target.value
      // console.info('Form', 'handleChangeEvent', 'Select/TextField', name, value);
    }

    // This stops unknown or invalid input values
    // E.g. non-numeric values in NumberField/PercentField
    if (value != null)
      this.handleChange(name, value);
    else
      console.info('Form', 'handleChangeEvent', 'Unknown input type', name, event);
  }

  // Called by componentDidMount when state is passed from another component
  // using history.push() which does not generate an event
  async handleChange(name, value) {
    // console.log('Form', 'handleChange', name, value, typeof value);
    let { values, touched, errors, validated } = this.state;

    let validate = false;
    if (!touched[name] && typeof value === 'string' && value.length > 1) // pasted values & password keepers
      validate = true;
    else if (typeof value === 'boolean')
      validate = true;
    else if (errors[name]) // try to fix existing errors on each keystroke
      validate = true;
    else if (touched[name] && validated[name]) // so the user can fix a typo
      validate = true;

    if (validate) {
      this.setState({
        values: { ...values, [name]: value},
        touched: { ...touched, [name]: true},
      }, async function() {
          await this.validate(name);
        });
    } else {
      this.setState({
        values: { ...values, [name]: value},
        touched: { ...touched, [name]: true},
      });
    }
  }

  // // All values entered through this method should be validated
  // setState(props) {
  //   let keys = Object.keys(props);
  //   let touched = {};
  //   keys.forEach(elem => {
  //     touched[elem] = true;
  //   })
  //   this.setState({
  //     values: { ...this.state.values, ...props },
  //     touched: { ...this.state.touched, ...touched },
  //     validated: { ...this.state.validated, ...touched },
  //   });
  // }

  // This doesn't work for Numbers because handleChange doesn't validate Numbers
  // Numbers are validated in handleBlur. How can I fix this?
  setValues(values) {
    console.log('Form', 'setValues', values);
    Object.keys(values).forEach(async (elem) => {
      console.log('Form', 'setValues', elem, values[elem]);
      try {
        await this.handleChange(elem, values[elem]);
      } catch (error) {
        console.error('Form', 'setValues', error);
      }
      console.log('here');
      await this.validate(elem);
    });
  }

  resetForm() {
    this.setState({
      values: {},
      touched: {},
      validated: {},
      errors: {},
      isValidated: false,
      isSubmitting: false,
    });
  }

  render() {
    let { values, errors, validated, isSubmitting, isValidated } = this.state;
    const { classes } = this.props;
    let stateProps = this.state;
    // let setState = this.setState;
    // console.log('Form', 'RENDERING...', 'stateProps', stateProps, this.props);
    let handleChange = this.handleChangeEvent;
    let handleBlur = this.handleBlur;

    // {React.cloneElement(this.props.children, {
    return (
      <>
      <form className={classes.form} noValidate autoComplete='off' >
        {this.props.children({ // works
          ...this.props,
          ...this.state, // Do I need this? 
          stateProps,
          // setState,
          handleChange,
          handleBlur,
          setValues: this.setValues,
          // setIsValidated: this.setIsValidated,
          // setDefaultValues: this.setDefaultValues,
          // disabled: (isSubmitting || !isValidated),
          //
          resetTouched: this.resetTouched,
          resetValidated: this.resetValidated,
          resetForm: this.resetForm,
          //
          fieldProps: { values, errors, validated, handleChange, handleBlur },
          checkboxProps: { values, errors, handleChange },
          passwordProps: { values, errors, validated, handleChange, handleBlur },
          submitProps: { ...this.state, setValues: this.setValues, history: this.props.history },
        })}
      </form>
      </>
    );
  }
}
// });

// export default Form;
// export withStyles(styles)(Form);
// export default withStyles(styles)(Form);
export const Form = withStyles(styles)(StyledForm);

StyledForm.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  //** Default form values */
  defaultValues: PropTypes.shape({}),
  /** Initial form values */
  stateValues: PropTypes.shape({}),
  validationType: PropTypes.string,
  /** Yup validation schema for the form values */
  validationSchema: PropTypes.shape({}),
  /** A form component using the custom MUI components designed to work with Form */
  children: PropTypes.any, // TODO What PropType should children be?
};
