import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from '@org/client-components-custom';

export function UserNotAuthorized() {
  return(
    <Page id='sign-out' style={{ justifyContent: 'center', alignItems: 'center' }} >
      <Typography variant="h4" style={{ height: '100%', textAlign: 'center', whiteSpace: 'pre-line' }} >
        {`User Not Authorized\n(This can also happen when authenticated user attempts to access a page for uauthenticated users only.)`}
      </Typography>
    </Page>
  );
}

export default UserNotAuthorized;
