import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextField } from '@material-ui/core/TextField';
import { styles } from '@org/client-libs-styles';

const useStyles = makeStyles(styles);

export function StandardTextField(props) {
  let { name, values, disabled, handleChange, handleBlur, ...rest } = props; // eslint-disable-line
  let value = values[name];

  const classes = useStyles();

  return(
    // <TextField id="standard-basic" label="Standard" />
    <MuiTextField
      {...rest}
      className={classes.inputField}
      value={value}
      disabled={disabled ? disabled : true}
    />
  );
}
