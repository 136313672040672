import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { PrimaryButton, SecondaryButton } from '@org/client-components-core';
import { 
  Heading, FormMessage, FormError,
  // BackgroundLoader
} from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';

const useStyles = makeStyles(styles);

export function VerifyIdentity(props) {
  console.log('VerifyIdentity', 'props', props);
  let skipButtonProps = { title: 'Skip', validated: true };
  // let enabled = (props.isValidated && !props.isSubmitting);

  let classes = useStyles();

  // useEffect(() => {
  //   console.log('VerifyIdentity', 'useEffect');
  //   // stateValues = { ... };
  //   if (isMounted) {
  //     props.resetForm();
  //     props.setState({ ...props.state, isLoading: false });
  //   }
  // }, []);

  async function handleVerifyIdentity(event) {
    console.log('handleVerifyIdentity', event, props);
    // props.history.push('/welcome');
    props.setState({ ...props.state, activeStep: 'invitationComplete' });
  }

  return(
    <>
    <Heading>{'Verify Identity'}</Heading>

    <Grid style={{width: '100%'}} >

      <Grid item >
        <FormMessage message='The production release will require users to verify their identity via credit check, bank account verification, etc' />
        </Grid>

      {/* <Grid item >
        <FormError message={error.message} />
      </Grid> */}

      <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
        <PrimaryButton
          // {...buttonProps}
          name='change-password'
          title='Submit'
          // disabled={!enabled}
          disabled={true}
          onClick={(event) => handleVerifyIdentity(event, props)}
        />
        <SecondaryButton {...skipButtonProps} onClick={() => props.setState({ ...props.state, activeStep: 'invitationComplete' })} />
        {/* <SecondaryButton {...skipButtonProps} onClick={handleVerifyIdentity} /> */}
      </Grid>

    </Grid>
    </>
  );
}

VerifyIdentity.propTypes = {
  history: PropTypes.shape(),
  state: PropTypes.shape(),
  setState: PropTypes.func,
  resetForm: PropTypes.func,
  // isValidated: PropTypes.bool,
  // isSubmitting: PropTypes.bool,
  // setActiveStep: PropTypes.func,
};