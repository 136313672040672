import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryButton, CircularProgress, LinearProgressWithLabel } from '@org/client-components-core';
import { FlexRow, FlexColumn } from '@org/client-components-custom';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';
import { dollarAmount, percentage } from '@org/common-formatters';
// import Avatar from '@material-ui/core/Avatar';

const localStyles = {
  ...styles,
  // root: {
  //   padding: theme.spacing(2),
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent:'flex-start',
  //   alignItems: 'center',
  // },
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    // margin: theme.spacing(1),
    // height: 'auto',
    height: '100%',
    width: '100%',
    // width: 'auto',
    // maxWidth: 'fit-content',
    // padding: theme.spacing(4, 8, 4, 8),
    [theme.breakpoints.down('xs')]: {
      // padding: theme.spacing(2),
      // width: 'auto',
      width: '100%',
      // maxWidth: 'fit-content',
      margin: theme.spacing(0, 0, 1, 0),
    },
    [theme.breakpoints.only('sm')]: {
      // padding: theme.spacing(2, 4, 2, 4),
      width: '100%',
      margin: theme.spacing(0, 0, 2, 0),
    },
    [theme.breakpoints.up('md')]: {
      // padding: theme.spacing(4, 8, 4, 8),
      width: '100%',
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
  // cardRootOpen: {
  //   display: 'flex',
  //   // flexDirection: 'row',
  //   margin: theme.spacing(1),
  //   width: '80%',
  // },
  content: {
    // width: '100%',
    // maxWidth: `calc(100% - 150px)`,
    [theme.breakpoints.down('xs')]: {
      // padding: theme.spacing(2),
      // width: 'auto',
      // maxWidth: 'fit-content',
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  mediaColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
    [theme.breakpoints.only('sm')]: {
      height: 150,
    },
    [theme.breakpoints.up('md')]: {
      height: 263,
    },
  },
  cardMedia: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    // margin: theme.spacing(0, 0, 0.5, 0),
    // height: 100,
    // width: 100,
    [theme.breakpoints.down('xs')]: {
      height: 100,
      width: 100,
    },
    [theme.breakpoints.only('sm')]: {
      height: 150,
      width: 150,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
      width: 200,
    },
  },
  // imageOpen: {
  //   display: 'flex',
  //   height: 150,
  //   width: 150,
  //   minHeight: 'fit-content',
  //   minWidth: 'fit-content',
  // },
  headingSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    // flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0, 0),
    // width: '100%',
    width: 'auto',
    // minWidth: 'fit-content',
    // maxWidth: 'fit-content',
  },
  // wrapText: { // this doesn't work.
  //   display: 'flex',
  //   // whiteSpace: 'nowrap',
  //   [theme.breakpoints.only('xs')]: {
  //     flexWrap: 'wrap',
  //     whiteSpace: 'normal',
  //   },
  // },
  // progressSection: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   // flexGrow: 1,
  // },
  descriptionSection: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minWidth: '50%',
  },
  detailSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [theme.breakpoints.up('card')]: {
      flexWrap: 'nowrap',
    },
  },
  // loanDetails: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   flexGrow: 1,
  //   minWidth: '50%',
  // },
  detailColumn: {
    // display: 'flex',
    // flexDirection: 'column',
    flexGrow: 1,
    // minWidth: '50%',
      // margin: theme.spacing(0, 5, 0, 0),
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
      margin: theme.spacing(0, 1, 0, 0),
    },
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      padding: theme.spacing(1, 2, 0, 0),
    },
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    margin: theme.spacing(0, 0, 0, 0.5),
    width: 'auto',
    minWidth: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(0, 0, 2, 0),
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
};

const useStyles = makeStyles(localStyles);

function ResidentialLoanDetails(props) {
  // console.log('ResidentialLoanDetails', 'props', props);
  const classes = useStyles();
  return(
    <div className={classes.loanDetails} >
      <FlexRow>
        <Label>{'Amount:'}</Label>
        <Typography variant='subtitle1' color='textSecondary' >
          {dollarAmount.format(props.originalBalance)}
        </Typography>
      </FlexRow>
    </div>
  );
}

ResidentialLoanDetails.propTypes = {
  // itemName: PropTypes.string,
  // description: PropTypes.string,
  originalBalance: PropTypes.number,
  // coupon: PropTypes.number,
  // originalTerm: PropTypes.number,
  // minimumAmount: PropTypes.number,
  // handleTransaction: PropTypes.func,
};

// function Row(props) {
//   return(
//     <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%'}} >
//       {props.children}
//     </div>
//   );
// }

// Row.propTypes = {
//   children: PropTypes.any,
// };

function Label(props) {
  return(
    // <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '10rem'}} >
    <Typography variant='subtitle1' color='textSecondary' style={{ width: theme.spacing(18) }} >
      {props.children}
    </Typography>
    // </div>
  );
}

Label.propTypes = {
  children: PropTypes.any,
};

export function NoteCard(props) {
  // console.log('NoteCard', 'props', props);
  const [ enabled, setEnabled ] = useState(true);
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  // const card = useMediaQuery(theme.breakpoints.up("card"));
  // const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  // const [ open, setOpen ] = useState(true);
  let { note } = props;
  const classes = useStyles();

  // Why did I do this? why not just call handleTransaction directly?
  function handleTransactionEvent(event, item) {
    setEnabled(false);
    // props.handleTransaction({...item});
    props.handleTransaction(item.note);
  }

  function DetailsButton(props) {
    // console.log('DetailsButton', props);
    return(
      <div className={classes.buttonDiv} >
        <PrimaryButton
          title='Details'
          onClick={(event) => handleTransactionEvent(event, props)}
          disabled={!enabled}
          /* style={{height: 'auto', maxHeight: 'fit-content'}} */
          style={{width: 'auto', minWidth: 'fit-content'}}
        />
      </div>
    );
  }

  // function handleClick() {
  //   setOpen(!open);
  //   // console.log('Click!', open);
  // }

  // let referenceItem = {}, loanItem = {};
  // if (props.referenceItem)
  //   referenceItem = props.referenceItem;
  // let referenceItem = 
  // let referenceItemType = props.referenceItemType;
  let assetType = 'Undefined Asset Type';
  // let image = `/images/${props.assetType}_${props.assetType}.jpeg`;
  let image = '/images/undefined.jpeg';
  // let { itemType, referenceItem, referenceItemAssetType, referenceItemAssetSubType } = props.note;
  let { itemType, referenceItem } = props.note;

  if (itemType === 'NOTE') {

    if (referenceItem.assetType === 'REAL_ESTATE') {
      if (referenceItem.assetSubType === 'RESIDENTIAL') {
        assetType = 'Residential Real Estate';
        image = '/images/residential.jpeg';
      } else if (referenceItem.assetSubType === 'COMMERCIAL') {
        assetType = 'Commercial Real Estate';
        image = '/images/commercial.jpeg';
      } else if (referenceItem.assetSubType === 'MULTIFAMILY') {
        assetType = 'Multifamily Real Estate';
        image = '/images/multifamily.jpeg';
      } else {
        referenceItem.assetSubType = 'Unknown';
        image = '/images/multifamily.jpeg';
      }
    } else {
      referenceItem.assetType = 'Unknown';
      referenceItem.assetSubType = 'Unknown';
      image = '/images/multifamily.jpeg';
    }
  }
  // console.log(itemType, assetType, image);

  // console.log('NoteCard', 'referenceItem', referenceItem, 'image', image);
  // console.log('NoteCard', 'props', props);

  // let rootClass = "";
  // let imageClass = "";
  // if (open) {
  //   rootClass = classes.cardRootOpen;
  //   imageClass = classes.imageOpen;
  // } else {
  //   rootClass = classes.cardRoot;
  //   imageClass = classes.image;
  // }

  // let percentAvailable = note.originalBalance / note.availableBalance;
  let progress = (1 - ((note.originalBalance - note.availableBalance) / note.originalBalance)) * 100;
  // console.log(percentAvailable, note.originalBalance, note.availableBalance, progress)
  // console.log('card', card);

  return(
    // <Card id='note-card' className={classes.cardRoot} onClick={handleClick} >
    <Card id='note-card' className={classes.cardRoot} >

      <FlexRow style={{ flexGrow: 1, height: '100%', minHeight: '100%' }} >

        {!isXs &&
        <div className={classes.mediaColumn} >
        {/* <div style={{
          display: 'flex', flexDirection: 'column', flexGrow: 1, flexShrink: 0, alignItems: 'stretch',
          // height: '100%', minHeight: '100%', maxHeight: '100%',
          // width: 'auto',
        }} > */}
        {/* <FlexColumn name='note-card-media-container' style={{ flexGrow: 1, height: '100%', minHeight: '100%', width: 'auto' }} > */}

          {/* <span> */}
            <CardMedia
              className={classes.cardMedia}
              // className={imageClass}
              // image='/static/images/cards/live-from-space.jpg'
              // title='Live from space album cover'
              // image='/images/${props.assetType}.jpeg'
              image={image}
              // image='/images/commercial.jpeg'
              title='Residential Real Estate'
            />
          {/* </span> */}

          {/* <FlexRow style={{ alignItems: 'center', height: '100%' }} > */}
          {/* <span style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1,
            height: '100%', width: '100%',
          }} > */}
          {/* <FlexColumn name='progress-container' style={{ flexGrow: 1, height: '100%', minHeight: '100%', width: '100%' }} > */}
          <div style={{
            display: 'flex', flexDirection: 'column',
            // justifyContent: 'space-around',
            justifyContent: 'center',
            alignItems: 'center', // alignSelf: 'strech',
            flexGrow: 1, flexShrink: 0,
            // height: '100%', minHeight: '100%',
          }} >
            <div>
            <LinearProgressWithLabel value={progress} label={'Remaining'} color="secondary" />
            </div>
          </div>
          {/* <div style={{ display: 'flex', ustifyContent: 'center', alignItems: 'center', flexGrow: 1, }} >
            <p>{'test'}</p>
          </div>
          <div style={{ display: 'flex', ustifyContent: 'center', alignItems: 'center', flexGrow: 1, }} >
            <p>{'test'}</p>
          </div> */}

        </div>}
        {/* </FlexColumn>} */}

      <CardContent className={classes.content} >

        <div className={classes.headingSection} >

          <div className={classes.heading} >
            <Typography component='h5' variant='h5' >
              {note.itemName}
            </Typography>
            {/* <Typography variant='subtitle1' color='textSecondary' className={classes.wrapText} > */}
            <Typography variant='subtitle1' color='textSecondary' >
              {'Payment Dependent Note'}
            </Typography>
            <Typography variant='subtitle1' color='textSecondary' >
              {assetType}
            </Typography>
            <FlexRow>
              <Label>{'Launch Date:'}</Label>
              <Typography variant='subtitle1' color='textSecondary' >
                {dayjs(note.launchDate).format('MM/DD/YY')}
              </Typography>
            </FlexRow>
          </div>

          {/* {isXs &&
          <div className={classes.progressSection} >
            <CircularProgress id={`${note.itemName}-id`} value={progress} />
          </div>} */}

          {isXs &&
          <CircularProgress id={`${note.itemName}-id`} value={progress} />}

          {/* <div className={classes.progressSection} > */}
          {/* <LinearProgressWithLabel value={progress} color="secondary" /> */}

          {!isXs &&
            <FlexRow>

              <FlexColumn style={{ justifyContent: 'flex-start', alignItems: 'flex-start', flexGrow: 1, padding: theme.spacing(0, 0, 0, 1), }} >
                <Typography variant='subtitle1' color='textSecondary' noWrap={false} >
                  {note.description}
                </Typography>
              </FlexColumn>

              <FlexColumn style={{ width: 'fit-content' }} >
                <DetailsButton {...props} />
                {/* <CircularProgress value={progress} /> */}
              </FlexColumn>

            </FlexRow>}

        </div>

        <div id='note-detail' className={classes.detailSection} >

          <div className={classes.descriptionSection} >

            {/* <FlexRow>
              <Label>{'Launch Date:'}</Label>
              <Typography variant='subtitle1' color='textSecondary' >
                {dayjs(note.launchDate).format('MM/DD/YY')}
              </Typography>
            </FlexRow> */}

            {/* {!isXs &&
            <FlexRow>
              <Typography variant='subtitle1' color='textSecondary' noWrap={false} >
                {note.description}
              </Typography>
            </FlexRow>} */}

            <FlexRow className={classes.detailSection} >

              {/* <FlexColumn id='note-details' className={classes.detailColumn} > */}
              <FlexColumn id='note-details' >

                <FlexRow>
                  <Typography variant='subtitle1' color='textSecondary' style={{textDecoration: 'underline'}} >
                    {'Note Details'}
                  </Typography>
                </FlexRow>
                <FlexRow>
                  <Label>{'Original Balance:'}</Label>
                  <Typography variant='subtitle1' color='textSecondary' >
                    {dollarAmount.format(note.originalBalance)}
                  </Typography>
                </FlexRow>
                <FlexRow>
                  <Label>{'Available Balance:'}</Label>
                  <Typography variant='subtitle1' color='textSecondary' >
                    {dollarAmount.format(note.availableBalance)}
                  </Typography>
                </FlexRow>
                <FlexRow>
                  <Label>{'Coupon:'}</Label>
                  <Typography variant='subtitle1' color='textSecondary' >
                    {percentage({ value: note.coupon / 100, places: 2 })}
                  </Typography>
                </FlexRow>

              </FlexColumn>

              {/* <FlexColumn id='loan-details' className={classes.detailColumn} > */}
              <FlexColumn id='loan-details' >

                <FlexRow>
                  <Typography variant='subtitle1' color='textSecondary' style={{textDecoration: 'underline'}} >
                    {'Loan Details'}
                  </Typography>
                </FlexRow>
                {/* {loanItem && loanItem.originalTerm && */}
                <FlexRow>
                  <Label>{'Original Balance:'}</Label>
                  <Typography variant='subtitle1' color='textSecondary' >
                    {dollarAmount.format(referenceItem.originalBalance)}
                  </Typography>
                </FlexRow>
                <FlexRow>
                  <Label>{'Original Term:'}</Label>
                  <Typography variant='subtitle1' color='textSecondary' >
                    {referenceItem.originalTerm} Months
                  </Typography>
                </FlexRow>
                <FlexRow>
                  <Label>{'Loan Rate:'}</Label>
                  <Typography variant='subtitle1' color='textSecondary' >
                    {percentage({ value: referenceItem.netRate / 100, places: 2 })}
                  </Typography>
                </FlexRow>

              </FlexColumn>

            </FlexRow>

          </div>

          {/* {open && loanItem && */}
          {/* {!isXs && loanItem &&
          <ResidentialLoanDetails {...loanItem} />} */}

        </div>

      </CardContent>
      </FlexRow>
      {/* {isXs &&
      <div className={classes.detailsButtonDiv} >
        <PrimaryButton title='Details' onClick={(event) => handleTransactionEvent(event, props)} disabled={false} style={{width: 'auto', minWidth: 'fit-content'}} />
      </div>} */}
      {isXs &&
      <DetailsButton {...props} />}

    </Card>
  );
// }
}

NoteCard.propTypes = {
  // itemName: PropTypes.string,
  // description: PropTypes.string,
  // originalBalance: PropTypes.number,
  // coupon: PropTypes.number,
  // originalTerm: PropTypes.number,
  // minimumAmount: PropTypes.number,
  // referenceItemType: PropTypes.string,
  // referenceItem: PropTypes.shape(),
  note: PropTypes.shape(),
  handleTransaction: PropTypes.func,
};
