import { ulid } from 'ulid';
// import * as yup from 'yup';
// import { dateShape, emailShape, firstNameShape, ulidShape, lastNameShape } from '@org/common-yup';
// import { UserTable } from '@org/server-libs-data';
import { MS_IN_7D } from '@org/common-formatters';
// import { generateConfirmCode } from '@org/server-libs-aws';
import { generateConfirmCode } from '@org/common-security';
import { RecordClass } from './Record';

/**
 * InvitationClass
 * 
 */

// const invitationRecordShape = {
//   id: yup.string(), // URGENT Create a processShape...
//   email: emailShape,
//   firstName: firstNameShape
//     .required(`A valid firstName is required`),
//   lastName: lastNameShape
//     .required(`A valid lastName is required`),
//   message: yup.string()
//     .required(`A valid message is required`),
//   invitedBy: ulidShape
//     .required(`A valid ULID is required for invitedBy`),
//   expirationDate: dateShape
//     .required(`A valid date is required for expirationDate`),
//   viewDates: yup.array().of(yup.number()),
//   // We don't know the following until confirmation
//   confirmationDate: dateShape,
//   userId: ulidShape,
// };

// const invitationRecordSchema = yup.object().shape(invitationRecordShape);

export class InvitationClass extends RecordClass {
  constructor(input) {
    super(input);

    // this.table = UserTable;

    // this.schema = invitationRecordSchema;

    let { id, messageId, confirmCode } = input;
    if (!id && !messageId && !confirmCode) {
      let messageId = ulid();
      let confirmCode = generateConfirmCode();
      id = `${confirmCode}-${messageId}`;
    } else if (confirmCode && messageId) {
      id = `${confirmCode}-${messageId}`;
    }

    this.keys = {
      itemType: 'INVITATION',
      id,
    };

    let expirationDate = (new Date()).getTime() + MS_IN_7D;

    this.attributes = {
      invitedBy: "",
      email: "",
      firstName: "",
      lastName: "",
      message: "",
      expirationDate,
      viewDates: [],
      // confirmationDate: 0, // URGENT What can I use as a default for milliseconds date values???
      // userId: "", // !!! URGENT Cannot restore the table with empty string in GSI key value !!!
    };

    this.requiredValues = {
      email: true,
      firstName: true,
      lastName: true,
      message: true,
    };

    if (input)
      this.initialize(input);
  }

  getInput() {
    console.log('InvitationClass', 'this.attributes', this.attributes);
    let { firstName, lastName, email, message } = this.attributes;
    console.log('InvitationClass', firstName, lastName, email, message);
    // message.replace(/[\r\n]+/g, 'x');
    // message: message.replace(/[\r\n]+/g, '<br>'), // doesn't work?
    message = message.split('\n').join('<br>');
    return { firstName, lastName, email, message };
  }

}
