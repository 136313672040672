import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextareaAutosize } from '@material-ui/core/TextField';
// import { default as MuiTextareaAutosize } from '@material-ui/core/TextareaAutosize';
// import { green } from '@material-ui/core/colors';
// import theme from '@org/client-libs-themes';
import { CheckAdornment } from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles((theme) => ({
// // const localStyles = {
//   root: {
//     margin: theme.spacing(0, 0, 2, 0),
//     '& input:valid + fieldset': {
//       // borderColor: 'green',
//       borderColor: theme.palette.success.main,
//       borderWidth: 2,
//     },
//     '& input:invalid + fieldset': {
//       margin: theme.spacing(0, 0, 0, 0), // TODO Fix invalid spacing
//     },
//     // '& input:invalid + fieldset': {
//     //   borderColor: 'red',
//     //   borderWidth: 2,
//     // },
//     // '& input:valid:focus + fieldset': {
//     //   borderLeftWidth: 6,
//     //   padding: '4px !important', // override inline-style
//     // },
//     // '&:focus': {
//     //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     // },
//   },
// }));

// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//   },
// });

// const useStyles = makeStyles((theme) => (localStyles));

const useStyles = makeStyles(styles);

/**
 * The MUI TextField wrapper component is a complete form control.
 * It supports standard, outlined and filled styling.
 * 
 * <FormControl >
 *   <InputLabel />
 *   <Input /> | <OutlinedInput /> | <FilledInput />
 *   <FormHelperText />
 * <FormControl >
 */

export function TextArea(props) {
  let { name, label, values, errors, validated, handleChange, handleBlur, required, autoFocus, ...rest } = props;

  let value = (values && name && values[name] != null ? values[name] : "");
  let error = (errors && name && errors[name] ? true : false);
  let isValid = validated[name] && !props.disabled;

  const classes = useStyles();
  return(
    <MuiTextareaAutosize
      // {...props} // Warning: React does not recognize the `handleChange` prop on a DOM element.
      {...rest}
      className={classes.inputField}
      variant="outlined"
      margin="normal"
      fullWidth
      label={label}
      id={name}
      name={name}
      // type={type ? type : 'text'} // A valid HTML5 input type
      // value={value}
      initialValue={value} // fixes jumping cursor bug on edits
      defaultValue={value} // fixes missing initial value when using initialValue
      onChange={handleChange}
      onBlur={(event) => handleBlur(event, name)}
      error={error}
      helperText={error && errors[name]}
      required={required ? required : false}
      disabled={props.disabled}
      autoFocus={autoFocus ? autoFocus : false}
      InputProps={{
        endAdornment: <CheckAdornment position="end" show={isValid} />,
      }}
      // multiline={true}
      // maxRows={100}
      // rowsMin={3}
    />
  );
}

export default TextArea;

TextArea.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleChangeEvent: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
};
