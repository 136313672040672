import React from 'react';
import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { InLink } from '@org/client-components-core';
import { mainListItems as listItems } from './mainListItems';
import { publicSchema } from '@org/client-graphql';
import { makeStyles } from '@material-ui/core/styles';
import { appBarStyles } from './appBarStyles';

const useStyles = makeStyles(appBarStyles);

export function MainAppBarLinkList(props) {
  let classes = useStyles();
  const { data } = useQuery(publicSchema.IS_AUTHENTICATED);
  let isAuthenticated = false;
  if (data) isAuthenticated = data.isAuthenticated;
  return(
    <span className={classes.appBarLinkSpan} >

      {listItems.map((item, key) => (
      <InLink key={key} variant="h5" color="inherit" noWrap className={clsx(classes.appBarLink, (props.pathname === item.to) && classes.appBarActiveLink)} underline={props.pathname !== item.to ? 'hover' : 'always'} to={item.to} title={item.text} />))}

      {isAuthenticated &&
      <InLink variant="h5" color="inherit" noWrap className={clsx(classes.appBarLink, (props.pathname === '/the-fund') && classes.appBarActiveLink)} underline={props.pathname !== '/the-fund' ? 'hover' : 'always'} to='/the-fund' title='The Fund' />}

    </span>
  );
}

export default MainAppBarLinkList;

MainAppBarLinkList.propTypes = {
  pathname: PropTypes.string,
};
