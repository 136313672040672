export const assetTypes = [
  {
    value: 'REAL_ESTATE',
    label: 'Real Estate',
  },
  {
    value: 'ART',
    label: 'Art',
  },
];

export const realEstateSubTypes = [
  {
    value: 'RESIDENTIAL',
    label: 'Residential',
  },
  {
    value: 'MULTIFAMILY',
    label: 'Multifamily',
  },
  {
    value: 'COMMERCIAL',
    label: 'Commercial',
  },
];

export const artSubTypes = [
  {
    value: 'FINE_ART',
    label: 'Fine Art',
  },
];

export const referenceItemTypes = [
  {
    value: 'LOAN',
    label: 'Loan',
  },
  {
    value: 'NOTE',
    label: 'Note',
  },
];
