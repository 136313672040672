import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { appBarStyles } from './appBarStyles';

/**
 * LearnTabs is here not because it's functionally related but it shares some styles, namely the spacing for the fixed appBar.
 */

const linkTabs = [
  { name: 'who', title: 'Who We Are' },
  { name: 'what', title: 'What We Do' },
  { name: 'how', title: 'How It Works' },
  { name: 'tech', title: 'Our Technology' },
];

const learnTabsHeight = 48;

const useStyles = makeStyles((theme) => ({
  ...appBarStyles,
  learnTabsRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    textTransform: 'none !important', // doesn't work here, but works inline...?
  },
  learnTabsOffset: {
    paddingTop: learnTabsHeight,
    height: learnTabsHeight,
  },
}));

function a11yProps(index) {
  // return {
  //   id: `scrollable-auto-tab-${index}`,
  //   'aria-controls': `scrollable-auto-tabpanel-${index}`,
  // };
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  let [ top, setTop ] = useState();
  let [ height, setHeight ] = useState(); // URGENT Use this to improve the transition between tabs when scrolling...
  let [ bottom, setBottom ] = useState();
  let [ selected, setSelected ] = useState(false); // This minimizes rerenders. Replace with activeIndex prop from parent?
  let { scrollY, handleChange, ...rest } = props;

  useEffect(() => {
    const anchor = (document).querySelector(`#${props.name}`);
    if (anchor) {
      setTop(anchor.offsetTop - 152);
      setBottom(anchor.offsetTop + anchor.offsetHeight - 152);
      setHeight(anchor.offsetHeight);
    }
  });

  function handleScrollY() {
    if (scrollY >= top && scrollY < bottom && !selected) {
      setSelected(true);
      // props.handleSetValue(props.index);
      handleChange(null, props.index);
    } else if ((scrollY < top || scrollY >= bottom) && selected) {
      setSelected(false);
      // console.log(top, scrollY, bottom, selected);
    }
  }

  const handleClick = (event, props) => {
    // console.log('handleClick', event.target, props);
    // const anchor = (event.target.ownerDocument || document).querySelector('#what');
    // const anchor = (event.target.ownerDocument || document).querySelector(`#${props.name}`);
    const anchor = (document).querySelector(`#${props.name}`);
    const topPos = anchor.offsetTop - 152;

    /**
     * For some reason, the generic local link (e.g. <a href='#intra-page' />) scrolls to the wrong place, I think due to making position='fixed' in the tab bar. The magic number 152 is 120 for the appbar (72) + tab bar (48) + 32 for the padding in pageRoot.
     * 
     */

    if (anchor) {
      window.scrollTo({top: topPos, behavior: 'smooth'});
    }
  };

  handleScrollY();

  return (
    <Tab
      component="a"
      // component={InLink} // doesn't work?
      onClick={(event) => {
        event.preventDefault();
        handleClick(event, props);
      }}
      // style={{fontSize: theme.typography.h4}}
      {...rest}
    />
  );
}

LinkTab.propTypes = {
  name: PropTypes.string,
  index: PropTypes.number,
  scrollY: PropTypes.number,
  handleChange: PropTypes.func,
};

export function LearnTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  let [ y, setY ] = useState();

  function handleScroll() {
    let scrollY = window.scrollY;
    // console.log('scrollY', scrollY);
    if (scrollY !== y)
      setY(scrollY);
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // function handleSetValue(index) {
  //   console.log('handleSetValue', 'index', index);
  //   setValue(index);
  // }

  // const handleClick = (event, props) => {
  //   console.log('handleClick', event.target, props);
  //   const anchor = (event.target.ownerDocument || document).querySelector('#what');

  //   if (anchor) {
  //     anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // };

  let linkTabProps = { scrollY: y, handleChange };

  return (
    <>
    <div id='learnTabsRoot' className={classes.learnTabsRoot} >

      <AppBar id='learnAppBar' position='fixed' color='default' >
        <div className={classes.offset} />
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className={classes.learnTabsRoot}
        >
          {linkTabs.map((elem, index) => {
            {/* console.log(elem, index); */}
            return (
              <LinkTab key={index} name={elem.name} label={elem.title} index={index} {...a11yProps(index)} {...linkTabProps} />
            );
          })}
        </Tabs>
      </AppBar>

      <div className={classes.learnTabsOffset} />

      {props.children}

    </div>
    </>
  );
}

export default LearnTabs;

LearnTabs.propTypes = {
  children: PropTypes.any.isRequired,
  handleSetValues: PropTypes.func,
};
