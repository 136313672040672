// import { makeStyles } from '@material-ui/core/styles';
import theme from '@org/client-libs-themes';

// URGENT Centralize styles

// https://developers.google.com/style/link-text
// https://ourcodeworld.com/articles/read/140/top-5-best-code-syntax-highlighter-javascript-plugins

export const styles = {
  // root: {}, // DO NOT USE HERE! Many pages have a local root...
  centered: {
    display: 'flex',
    justifyContent: 'center',
  },
  // URGENT Make sure width: '100%' is only used for row containers or the items will not wrap properly...
  fullWidth: {
    width: '100%',
    flexGrow: 1,
  },
  // pageRoot: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-start',
  //   alignItems: 'center',
  //   height: '100%',
  //   width: '100%',
  //   maxWidth: '100rem',
  //   // padding: theme.spacing(4, 8, 4, 8),
  //   [theme.breakpoints.down('xs')]: {
  //     padding: theme.spacing(2),
  //   },
  //   [theme.breakpoints.only('sm')]: {
  //     padding: theme.spacing(2, 4, 2, 4),
  //   },
  //   [theme.breakpoints.up('md')]: {
  //     padding: theme.spacing(4, 8, 4, 8),
  //   },
  // },
  footerRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // padding: theme.spacing(0, 8, 2, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(2, 4, 2, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 8, 2, 8),
    },
    height: '100%',
    width: '100%',
    // maxWidth: '100rem',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  footerContent: {
    height: '100%',
    width: '100%',
    maxWidth: '100rem',
    // [theme.breakpoints.up('md')]: {
    //   height: '100%',
    //   width: '100%',
    //   maxWidth: '100rem',
    // },
    // [theme.breakpoints.down('sm')]: {
    // },
  },
  disclaimers: {
    height: '100%',
    width: '100%',
    maxWidth: '100rem',
    padding: theme.spacing(0 , 1, 0 , 1),
  },
  // pageTitle: {
  //   fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  //   margin: theme.spacing(0, 0, 4, 0),
  //   // fontWeight: theme.typography.fontWeightRegular,
  //   textAlign: 'center',
  // },
  // pageTitleVariant: 'h3',
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(1, 0, 1, 0),
  },
  sectionTitle: {
    margin: theme.spacing(1, 0, 1, 0),
    textAlign: 'center',
  },
  sectionTitleVariant: 'h4',
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 0, 4, 0),
    width: '100%',
  },
  formPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 4, 0, 4),
    borderRadius: '4px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  formButtonSpan: {
    margin: theme.spacing(2, 0, 4, 0),
  },
  inputField: {
    width: theme.spacing(47.5),
    // maxWidth: theme.spacing(47.5),
    // maxWidth: '100%',
    maxWidth: '100% !important',
    // maxWidth: 'fit-content',
    margin: theme.spacing(0, 0, 2, 0),
    // '& input:valid + fieldset': {
    //   // borderColor: 'green',
    //   // borderColor: theme.palette.success.main,
    //   borderWidth: 2,
    // },
    // '& input:invalid + fieldset': {
    //   margin: theme.spacing(0, 0, 0, 0), // TODO Fix invalid spacing
    // },
  },
  passwordInputField: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  forgotPassword: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 1, 0),
  },
  // Below is used on Note and Transact forms
  headingGrid: {
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
    justifyContent: 'center',
    padding: theme.spacing(4, 0, 4, 0),
    height: '100%',
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    // margin: theme.spacing(4, 0, 4, 0),
    // textAlign: 'center',
    // height: '100%',
  },
  headingSpan: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    width: '100%',
  },
  headingSpanLeft: {
    justifyContent: 'flex-start',
    width: '20%',
  },
  headingSpanRight: {
    justifyContent: 'flex-end',
    width: '20%',
  },
  // moved to button bar
  // buttonBar: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'flex-end',
  //   padding: theme.spacing(1),
  //   height: 'auto',
  //   minHeight: 'fit-content',
  //   width: '100%',
  // },
  iconButton: {
    margin: theme.spacing(0.0),
  },
  raisedIconButton: {
    margin: theme.spacing(0.0),
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  buttonSpan: {
    // margin: theme.spacing(4, 0, 5, 0),
    // margin: theme.spacing(4, 0, 4, 0),
    margin: theme.spacing(2, 0, 2, 0),
  },
  bottomLink: {
    display: 'flex',
    justifyContent: 'center',
    // margin: theme.spacing(0, 0, 4, 0),
    margin: theme.spacing(0),
    padding: theme.spacing(2, 0, 0, 0),
  },
};

export default styles;
