import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {
  // publicClient, authenticatedClient, authorizedClient,
  PublicHandler,
  AuthenticatedHandler,
} from '@org/client-graphql';
import { Page } from '@org/client-components-custom';

/**
 *                          signOut on:
 * Permutations             server  flag security
 * 1) User initiated          Y       on server, if session is already signed out
 * 2) Session expired         N       Y
 * 3) Cookie not found        N       ?
 * 4) Session not found       N       Y
 * 5) User not authorized -- sign out user for this?
 * 6) App refreshed while on /sign-out -- user should already be signed out?
 */

let publicHandler;
let authenticatedHandler;

export function SignOut(props) {
  // console.log('SignOut', props);
  const [ state, setState ] = useState({ isLoading: true, message: "" });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    publicHandler = new PublicHandler({ location: props.location, history: props.history });
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

    let error = props.location?.state?.error ? props.location?.state?.error : null;
    console.warn('SignOut', 'error passed in from', props.history.location.pathname);
    let code, message;

    (async () => {
      // the user must be authenticated to access this page but they can stay on
      // it after signing out (to see their sign out message, if any)
      // URGENT How do we get rid of the cookie, if any?

      // let isAuthenticated = await publicHandler.isAuthenticated();
      // console.warn('SignOut', 'isAuthenticated', isAuthenticated);

      // if (!isAuthenticated) // URGENT Why redirect to sign-in if user is authenticated in sign-out...???
      //   props.history.push('/sign-in');

      if (!error) {
        // sign out initiated by user
        try {
          await authenticatedHandler.signOut();
          message = 'You have successfully signed out';
        } catch (error) {
          console.error(error.message);
          if (error.message === 'Network error: Failed to fetch' && process.env.REACT_APP_IS_OFFLINE)
            message = 'Failed to fetch: Is the offline API running?';
          console.warn('This should not be happening under normal circumstances because the session cookie should have been cleared. Perhaps you are developing offline and the session no longer exists?');
        }
      } else {
        // either invalid session or cookie not found
        // console.warn('SignOut', 'error', error);
        // console.warn('SignOut', Object.getOwnPropertyNames(error));
        // console.warn('SignOut', Object.keys(error));
        // console.warn(props.history.location.pathname);

        if (error?.extensions?.code)
          code = error.extensions.code;
        else if (error?.code)
          code = error.code;
        else
          console.warn('SignOut', 'No error code found!');

        // let previousPath = props.history.location.pathname;
        // if (previousPath === '/sign-out' && code === 'SESSION_NOT_FOUND')
        //   props.history.push('/sign-in');

        if (code === 'COOKIE_NOT_FOUND')
          message = 'You have been signed out because a session cookie was not found in the request headers\n(This can happen when a new version of the software has been deployed)';
        else if (code === 'SESSION_NOT_FOUND')
          message = "You have been signed out because your session id was not found\n(This should only happen in testing when the session id has been deleted from the database)";
        else if (code === 'REQUEST_IP_ADDRESS_MISMATCH')
          message = "You have been signed out because your session ip address does not match the request\n(This will happen when your network is not using a static address, e.g. you are using a proxy server)";
        else
          message = "You have been signed out due to a session error";
      }

      await publicHandler.handleClearAuthentication();

      if (isMounted)
        setState({ ...state, isLoading: false, message });

    })();
    
    return () => { isMounted = false; };
  }, []);

  return(
    <Page id='sign-out' style={{ justifyContent: 'center', alignItems: 'center' }} >
      <Typography variant="h4" style={{ height: '100%', textAlign: 'center', whiteSpace: 'pre-line' }} >
      {state.message}
      </Typography>
    </Page>
  );
}

SignOut.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
