// import * as yup from "yup";
import * as yup from 'yup/es';

const assetTypes = [ 'REAL_ESTATE', 'COMMERCIAL', 'MULTIFAMILY', 'ART' ];
const paymentFrequencyTypes = [ 'MONTHLY', 'QUARTERLY', 'SEMI', 'ANNUAL' ];
const interestTypes = [ '30_360' ];

const loanShape = {
  // automatically generated
  // id: yup.string().length(22), // UUID
  id: yup.string().length(26), // ULID
  // createdAt: yup.string().length(24),
  // createdBy: yup.string().length(22),
  // updatedAt: yup.string().length(24),
  // updatedBy: yup.string().length(22),
  // user entered
  borrowerName: yup.string(),
  propertyAddress: yup.string(),
  assetType: yup.string().oneOf(assetTypes).required(),
  assetSubType: yup.string().required(),
  originalAmount: yup.number().required('Original Amount is required'),
  currentAmount: yup.number().required('Current Amount is required'),
  originalTerm: yup.number().required('Original Term is required'),
  amortizationTerm: yup.number().required('Original Term is required'),
  paymentFrequency:  yup.string().oneOf(paymentFrequencyTypes).required('Payment Frequency is required'), // DAILY, MONTHLY, QUARTERLY, etc
  interestRate: yup.number().required('Interest Rate is required'),
  servicingRate: yup.number().required('Servicing Rate is required'),
  netRate: yup.number(),
  interestType: yup.string().oneOf(interestTypes).required('Interest Type is required'),
  paymentAmount: yup.number().required('Payment Amount is required'),
  originationDate: yup.date().required('Origination Date is required'),
  firstPaymentDate: yup.date().required('First Payment Date is required'),
  perDiemPayment: yup.number(),
  perDiemDays: yup.number(),
  perDiemAmount: yup.number(),
  // status: yup.string().length(16),
  // paymentType: yup.string().length(16).required('Payment Type is required'), // DISCOUNT, FIXED, FIXED_IO, ARM, ARM_IO
};

export const loanSchema = yup.object().shape(loanShape);
