import React from 'react';
import { BorderLinearProgress, CircularProgress, LinearProgressWithLabel } from '@org/client-components-core';
import {
  Page, Heading,
} from '@org/client-components-custom';

export function Progress() {

  return (
    <Page>
      <Heading>{'Components'}</Heading>
      <CircularProgress value={51} />
      {/* <br /> */}
      <BorderLinearProgress variant="determinate" value={50} />
      {/* <br /> */}
      <LinearProgressWithLabel value={51} />
    </Page>
    // </div>
  );
}
