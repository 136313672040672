import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';

export function ConfirmTradeDialog(props) {
  // console.log('ConfirmTradeDialog', props);
  let { open, setConfirmOpen, handleConfirm, form } = props;
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        aria-label={'Trade Confirm Dialog'}
      >
        <DialogTitle style={{textAlign: 'center'}} >{"Confirm Trade"}</DialogTitle>
        <DialogContent>
          <DialogContentText style={{textAlign: 'center'}} >
            {'Are you sure?'}<br/>
            {'(Offical trade confirm coming soon...)'}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}} >
          <Button id='confirm' onClick={() => handleConfirm(event, form)} color="primary" >
            {'Confirm'}
          </Button>
          <Button id='cancel' onClick={() => setConfirmOpen(false)} color="primary" >
            {'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmTradeDialog.propTypes = {
  open: PropTypes.bool,
  setConfirmOpen: PropTypes.func,
  handleConfirm: PropTypes.func,
  form: PropTypes.shape(),
};
