import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ButtonBar, LinkButton } from '@org/client-components-core';
import { FormLayout, Heading, FormMessage } from '@org/client-components-custom';
// import { styles as authStyles } from './authStyles';

// const useStyles = makeStyles(authStyles);

export function Success(props) {
  // console.log('Success', 'props', props);

  let title = "", message = "";
  title = props.location.state?.title;
  message = props.location.state?.message;

  // let classes = useStyles();

  return(
    <>
    <FormLayout>

      <Heading>{title}</Heading>

      <Grid id='confirm-reset-password' style={{width: '100%'}} >

        <Grid item >
          <FormMessage message={message} />
        </Grid>

        <Grid item >
          <ButtonBar>
            <LinkButton title='Continue' color='primary' style={{textTransform: 'upper'}} validated={true} loading={false} to='/user' />
          </ButtonBar>
        </Grid>

      </Grid>

    </FormLayout>
    </>
  );
}

Success.propTypes = {
  location: PropTypes.shape(),
  state: PropTypes.shape(),
};
