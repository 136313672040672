import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
// import clsx from 'clsx';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { publicClient, publicSchema } from '@org/client-graphql';
import { publicSchema } from '@org/client-graphql';
import { InLink, ExLink } from '@org/client-components-core';
import { FlexRow } from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

const { REACT_APP_LEGAL_NAME, REACT_APP_COMPANY_NAME, REACT_APP_SHORT_NAME, REACT_APP_CLIENT_VERSION } = process.env;

const useStyles = makeStyles((theme) => ({
  ...styles,
  // footer: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   padding: theme.spacing(2, 0, 2, 0),
  //   marginTop: 'auto',
  //   width: '100%',
  //   // maxWidth: '100rem', // not here or the background color does not extend all the way across
  //   backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  // },
  // root: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   flexGrow: 1,
  //   // width: '100%',
  //   // maxWidth: '100rem',
  //   // maxWidth: '100rem',
  //   maxWidth: '70rem',
  //   // maxWidth: 'fit-content',
  // },
  // footerParallaxForHome: {
  //   transform: `translateY(${y1}px)`,
  // },
  gridRow: {
    display: 'flex',
    flexDirection: 'row', // direction prop in Grid does not work?
    justifyContent: 'flex-start',
    width: '100%',
    margin: theme.spacing(0),
  },
  gridCol: {
    display: 'flex',
    flexDirection: 'column', // direction prop in Grid does not work?
    flexGrow: 1,
    // width: 'auto',
    width: '100%',
    minWidth: 'fit-content',
    // margin: theme.spacing(2),
  },
  // paper: {
  //   maxWidth: 400,
  //   margin: `${theme.spacing(1)}px auto`,
  //   padding: theme.spacing(2),
  // },
  divider: {
    width: '100%',
    // maxWidth: '70rem',
    // maxWidth: '100rem',
    margin: theme.spacing(0.5, 0, 1, 0),
    // padding: theme.spacing(0, 0, 3, 0),
    maxWidth: '120rem',
  },
  // p: {
  //   marginBottom: theme.spacing(0, 0, 1, 0),
  // },
  copyright: {
    textAlign: 'center',
  },
  version: {
    textAlign: 'center',
  },
}));

export function Copyright() {
  let year = new Date().getFullYear();
  // console.log(`2020-${year.toString()}`);
  if (year !== 2020)
    year = `2020-${year.toString()}`;
  return (
    // <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }} >
    <Typography variant="caption" color='textSecondary' className='copyright' >
      {REACT_APP_LEGAL_NAME}
      {' © '}
      {year}
      {'.'}
      {/* {'. All rights reserved. Unauthorized access is prohibited. Usage will be monitored.'} */} {/* URGENT Why does Schwab have this? */}
      {'All rights reserved.'}
    </Typography>
  );
}

function Version() {
  const { loading, error, data } = useQuery(publicSchema.GET_VERSION);
  let version = null;
  if (loading) version = 'Loading...';
  if (error) version = `Error! ${error.message}`;
  if (data) version = `Client v${REACT_APP_CLIENT_VERSION} | API v${data.getVersion}`;
  return (
    // <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }} >
    <Typography variant="caption" color='textSecondary' className='copyright' >
      {version}
    </Typography>
  );
}

function P(props) {
  // const classes = useStyles();
  return(
    // <Typography variant="caption" {...props} style={{marginBottom: '0.8rem', width: '100%', maxWidth: '70rem'}} >
    <span style={{display: 'flex', marginBottom: theme.spacing(1)}} >
      <Typography variant="caption" {...props} >
      {/* <Typography variant="caption" color="textSecondary" {...props} className={classes.p} > */}
        {props.children}
      </Typography>
    </span>
  );
}

P.propTypes = {
  children: PropTypes.any,
};

function Bar() {
  return (<>&nbsp;{'|'}&nbsp;</>);
}

let y = 0;

export function Footer(props) {
  // console.log('Footer', 'props', props);
  const [ updated, setUpdated ] = useState(true);
  // const [ y, setY ] = useState(0);
  // let y1 = 0;
  const classes = useStyles();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  // function Col() {
  //   return(
  //     <>
  //       <Grid item xs={4}>
  //         <p>One</p>
  //         <p>two</p>
  //         <p>three</p>
  //       </Grid>
  //     </>
  //   );
  // }
  // let irrLink = <ExLink to='https://en.wikipedia.org/wiki/Internal_rate_of_return' title='Click here for a more detailed explanation of IRR.' variant='caption' />;

  // URGENT Optimize Footer for different media sizes....

  // function handleScroll() {
  //   // let y = window.scrollY;
  //   // y1 = (props.pathname === '/' && y <= 293) ? y : y - (y - 293);
  //   // setY(y1);
  //   let scrollY = window.scrollY;
  //   y = (props.pathname === '/' && y <= 293) ? y : y - (y - 293);
  //   setUpdated(!updated);
  //   console.log(props.pathname, scrollY, y);
  // }

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // });

  // console.log(props.pathname, y);
  // console.log('isXs', isXs);

  return (
    // <ApolloProvider client={publicClient} >
      // <footer className={clsx(classes.footerRoot, props.pathname === '/' ? classes.footerParallaxForHome : {})} >
      // <footer className={classes.footerRoot} style={{transform: `translateY(${y}px)`}} >
      <footer className={classes.footerRoot} >

        <div id='footer-content' className={classes.footerContent} >

          {isXs &&
          <div style={{padding: theme.spacing(0, 0, 2, 0)}} >
            <FlexRow style={{justifyContent: 'center', alignItems: 'center'}} >
              <InLink to='/company/about' title='About Us' variant='body2' /><Bar /><InLink to='/company/blog' title='Blog' variant='body2' /><Bar /><InLink to='/company/news' title='News' variant='body2' /><Bar /><InLink to='/company/careers' title='Careers' variant='body2' />
            </FlexRow>
            <FlexRow style={{justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}} >
              <InLink to='/legal/terms' title='Terms of Use' variant='body2' /><Bar />
              <InLink to='/legal/privacy' title='Privacy Policy' variant='body2' /><Bar />
              <InLink to='/legal/cookies' title='Cookie Policy' variant='body2' /><Bar />
              <InLink to='/legal/brochure' title='Form ADV Part 2A' variant='body2' />
            </FlexRow>
            <FlexRow style={{justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}} >
              <Typography variant="body2" >{'Contact: coming soon...'}</Typography>
            </FlexRow>
          </div>}

          {!isXs &&
          <Grid className={classes.gridRow} container spacing={3} wrap='nowrap' justify='flex-start' >
            <Grid id='company' className={classes.gridCol} container item xs={4} spacing={1} >
              <Typography variant='body2' >{'Company'}</Typography>
              <InLink to='/company/about' title='About Us' variant='body2' />
              <InLink to='/company/blog' title='Blog' variant='body2' />
              <InLink to='/company/news' title='News' variant='body2' />
              <InLink to='/company/careers' title='Careers' variant='body2' />
            </Grid>
            <Grid id='col1' className={classes.gridCol} container item xs={4} spacing={1} >
              <Typography variant='body2' >{'Resources'}</Typography>
              {/* <ExLink to='https://html.spec.whatwg.org/' title='HTML5' /> */}
              <Typography variant='body2' >{'Data Security'}</Typography>
              {/* <InLink to='/legal/terms' title='Data Security' /> */}
              {/* <ExLink to='https://www.pcisecuritystandards.org/' title='PCI DSS' variant='body2' />
              <ExLink to='https://en.wikipedia.org/wiki/California_Consumer_Privacy_Act' title='CCPA' variant='body2' />
              <ExLink to='https://en.wikipedia.org/wiki/General_Data_Protection_Regulation' title='GDPR' variant='body2' />
              <ExLink to='https://en.wikipedia.org/wiki/HTTP_cookie' title='HTTP Cookies' variant='body2' /> */}
              <Typography variant='body2' >{'Investing'}</Typography>
              {/* <InLink to='/legal/terms' title='Investing' /> */}
              {/* <ExLink to='https://www.sec.gov/smallbusiness/exemptofferings/rule506b' title='SEC Rule 506(b)' variant='body2' />
              <ExLink to='https://www.nasaa.org/about/' title='NASAA' alt='North American Securities Administrators Association' variant='body2' />
              <ExLink to='https://en.wikipedia.org/wiki/Internal_rate_of_return' title='IRR' variant='body2' /> */}
              {/* <Typography variant='body1' >{'Lending'}</Typography> */}
              {/* <InLink to='/legal/terms' title='Lending' /> */}

            </Grid>
            <Grid id='legal' className={classes.gridCol} container item xs={4} spacing={1} >
              <Typography variant='body2' >{'Legal'}</Typography>
              <InLink to='/legal/terms' title='Terms of Use' variant='body2' />
              <InLink to='/legal/privacy' title='Privacy Policy' variant='body2' />
              <InLink to='/legal/cookies' title='Cookie Policy' variant='body2' />
              <InLink to='/legal/brochure' title='Form ADV Part 2A' variant='body2' />
              {/* <p style={{display: 'inline', minWidth: 'fit-content'}} >Investment Agreement</p> */}
            </Grid>
            <Grid id='legal' className={classes.gridCol} container item xs={4} spacing={1} >
              <Typography variant='body2' >{'Contact'}</Typography>
              {/* <p style={{display: 'inline', minWidth: 'fit-content'}} >{'Coming soon...'}</p> */}
              <Typography variant='body2' >{'Coming soon...'}</Typography>
            </Grid>
          </Grid>}
        </div>

        <div className={classes.divider} >
          <Divider className={classes.divider} />
        </div>

        <div className={classes.disclaimers} >

          {/* <P>{`Past performance is no guarantee of future results. Any historical returns, expected returns, or probability projections shown on the site or in other materials may not reflect actual future performance. All securities involve risk and may result in significant losses.`}</P> */}

          {/* <P>{`Fluctuations in the value of the assets that are the subject of any investment are to be expected. Additional risks may exist due to a variety of factors, including, but not limited to, leverage, property operations, business risks, management and environmental liabilities. There is a potential for loss of part or ALL of the investment capital, and each investor should understand that all capital invested may be lost. Investors should only consider these investments if they have no need for liquidity and can bear the risk of losing their entire investment.`}</P> */}

          <P>{`This website is only suitable, intended, and available for accredited investors who are registered users of ${REACT_APP_COMPANY_NAME} and familiar with, and willing to, accept the risks associated with private investments. ${REACT_APP_COMPANY_NAME} does not endorse any of the opportunities that appear on this website, nor does it make any recommendations regarding the appropriateness of particular opportunities for any Investor. Each investor must review and evaluate the investments in the investor’s own discretion and determine the suitability of making the particular investment.`}</P>

          <P>{`No communication by ${REACT_APP_LEGAL_NAME} or any of its affiliates (collectively, “${REACT_APP_SHORT_NAME}™”), through this website or any other medium, should be construed as or is intended to be a recommendation to purchase, sell, or hold any security or otherwise to be investment, tax, financial, accounting, legal, regulatory, or compliance advice. Nothing on this website is intended as an offer to extend credit, an offer to purchase or sell securities, or a solicitation of any securities transaction.`}</P>

          <P>{`Neither the Securities and Exchange Commission nor any federal or state securities commission or any other regulatory authority has recommended or approved of the investment or the accuracy or inaccuracy of any of the information or materials provided by or through the website. Investments are not bank deposits, are not insured by the FDIC or by any other Federal Government Agency, are not guaranteed by ${REACT_APP_LEGAL_NAME}, and may lose value.`}</P>

          {/* <P>{`Expected yield represents a net estimated, unrealized annualized internal rate of return (IRR) and is based on the expected effective distribution dates and amounts to and from the investments, as well as any outstanding principal and accrued and unpaid interest as of the current date, after deduction of management fees and all other expenses charged to the investments. `}{irrLink}{` The unrealized and/or estimated returns reflected herein may not be realized in the future, which would materially and adversely affect your actual IRRs. Therefore, the performance information presented herein should not be interpreted as an indicator of actual future fund returns. Past performance is not a reliable indicator of future results and should not be relied upon as the basis for making an investment decision. All securities involve risk and may result in significant losses, including the loss of principal invested.`}</P> */}

          {/* <P>{`"Annual interest" represents an annual target rate of interest and "term" represents the estimated term of the investment. Such target returns and estimated term are projections of the returns or term and may ultimately not be achieved. Actual returns and term may be materially different from such projections. These targeted returns and estimated term are based on the underlying agreement between the SPV and borrower or originator, as applicable.`}</P> */}

          {/* <P>{`Represents the sum of the interest accrued in the statement period plus the interest paid in the statement period.`}</P> */}

          {/* <P>{`The internal rate of return ("IRR") represents an average net realized IRR with respect to all matured investments weighted by the investment size of each individual investment, made by private investment vehicles managed by ${REACT_APP_LEGAL_NAME} from July 1, 2015 through and including November 10th, 2020, after deduction of management fees and all other expenses charged to investments.`}</P> */}

          {/* <P>{`Investors should carefully consider the investment objectives, risks, charges and expenses of the ${REACT_APP_LEGAL_NAME} before investing. The prospectus for the $ contains this and other information about the Fund and can be obtained by emailing investments@yieldstreetprismfund.com or by referring to www.yieldstreetprismfund.com. The prospectus should be read carefully before investing in the Fund. Investments in the Fund are not bank deposits (and thus not insured by the FDIC or by any other federal governmental agency) and are not guaranteed by ${REACT_APP_LEGAL_NAME} or any other party.`}</P> */}

          {/* <P>{`Any financial projections or returns shown on the website are estimated predictions of performance only, are hypothetical, are not based on actual investment results and are not guarantees of future results. Estimated projections do not represent or guarantee the actual results of any transaction, and no representation is made that any transaction will, or is likely to, achieve results or profits similar to those shown. In addition, other financial metrics and calculations shown on the website (including amounts of principal and interest repaid) have not been independently verified or audited and may differ from the actual financial metrics and calculations for any investment. Any investment information contained herein has been secured from sources that ${REACT_APP_SHORT_NAME} believes are reliable, but we make no representations or warranties as to the accuracy of such information and accept no liability therefor.`}</P> */}

          {/* <P>{`Private placement investments are NOT bank deposits (and thus NOT insured by the FDIC or by any other federal governmental agency), are NOT guaranteed by ${REACT_APP_SHORT_NAME} or any other party, and MAY lose value. Neither the Securities and Exchange Commission nor any federal or state securities commission or regulatory authority has recommended or approved any investment or the accuracy or completeness of any of the information or materials provided by or through the website. Investors must be able to afford the loss of their entire investment.`}</P> */}

          {/* <P>{`Investments in private placements are speculative and involve a high degree of risk and those investors who cannot afford to lose their entire investment should not invest. Additionally, investors may receive illiquid and/or restricted securities that may be subject to holding period requirements and/or liquidity concerns. Investments in private placements are highly illiquid and those investors who cannot hold an investment for the long term (at least 5-7 years) should not invest.`}</P> */}

          {/* <P>{`Alternative investments should only be part of your overall investment portfolio. Further, the alternative investment portion of your portfolio should include a balanced portfolio of different alternative investments.`}</P> */}

          {/* <P>{`Articles or information from third-party media outside of this domain may discuss ${REACT_APP_SHORT_NAME} or relate to information contained herein, but ${REACT_APP_SHORT_NAME} does not approve and is not responsible for such content. Hyperlinks to third-party sites, or reproduction of third-party articles, do not constitute an approval or endorsement by ${REACT_APP_SHORT_NAME} of the linked or reproduced content.`}</P> */}

          <P>{`Investing in securities (the "Securities") listed on ${REACT_APP_SHORT_NAME}™ pose risks, including but not limited to credit risk, interest rate risk, and the risk of losing some or all of the money you invest. Before investing you should: (1) conduct your own investigation and analysis; (2) carefully consider the investment and all related charges, expenses, uncertainties and risks, including all uncertainties and risks described in offering materials; and (3) consult with your own investment, tax, financial and legal advisors. Such Securities are only suitable for accredited investors who understand and willing and able to accept the high risks associated with private investments.`}</P>

          {/* <P>{`Investing in private placements requires long-term commitments, the ability to afford to lose the entire investment, and low liquidity needs. This website provides preliminary and general information about the Securities and is intended for initial reference purposes only. It does not summarize or compile all the applicable information. This website does not constitute an offer to sell or buy any securities. No offer or sale of any Securities will occur without the delivery of confidential offering materials and related documents. This information contained herein is qualified by and subject to more detailed information in the applicable offering materials. ${REACT_APP_SHORT_NAME}™ is not registered as a broker-dealer. ${REACT_APP_SHORT_NAME}™ does not make any representation or warranty to any prospective investor regarding the legality of an investment in any ${REACT_APP_SHORT_NAME} Securities.`}</P> */}

          {/* <P>{`Banking services are provided by Evolve Bank & Trust, Member FDIC.`}</P> */}

          {/* <P>{`Investment advisory services are provided by ${REACT_APP_ASSET_MANAGER}, an investment advisor registered with the Securities and Exchange Commission.`}</P> */}
          {/* https://www.strictlybusinesslawblog.com/guide-state-investment-adviser-registration-requirements-for-private-fund-advisers/#:~:text=State%2Dregistered%20investment%20advisers%20that,venture%20capital%20fund%20adviser%20exemption. */}

          {/* <P>{`Investment advisory services are provided by ${REACT_APP_ASSET_MANAGER}, a registered investment advisor and a wholly owned subsidiary of ${REACT_APP_LEGAL_NAME}. ${REACT_APP_LEGAL_NAME} is currently only registered at the state level and will register with the Securities and Exchange Commission when it no longer meets the federal registration exemptions under Regulation D of the Investment Advisers Act of 1940.`}</P> */}

          {/* <P>{`investments@meetcubit.com`}</P> */}

          </div>

        <div className={classes.divider} >
          <Divider className={classes.divider} />
        </div>

        <Copyright />
        {/* <Version /> */}

      </footer>
    // </ApolloProvider>
  );
}
