import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { default as MuiButton } from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none', // 'turns off' auto-uppercase
  },
  // linkButton: {
  //   margin:
  // }
  successButton: {
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
}));

export function Button(props) {
  let { title, name, variant, color, size, className, disabled, ...rest } = props;
  let classes = useStyles();
  // console.log('style', typeof props.style, props.style);

  if (props.handleClick)
    console.warn('Buttons must be passed onClick function handler!');

  title = title ? title : 'Submit';

  return(
    <MuiButton
      id={name}
      name={name}
      variant={variant ? variant : 'contained'}
      color={color ? color : 'default'}
      size={size ? size : 'medium'}
      className={className ? className : classes.button}
      // style={props.style ? props.style : null}
      // onClick={props.onClick}
      disabled={disabled ? disabled : false}
      {...rest}
    >
      {title}
    </MuiButton>
  );
}

// https://github.com/mui-org/material-ui/issues/3565
// https://material-ui.com/guides/composition/#routing-libraries
export function LinkButton(props) {
  let classes = useStyles();
  let { name, title, className, color, size, disabled, ...rest } = props;

  return (
    <MuiButton
      {...rest}
      id={name}
      name={name}
      variant={props.variant ? props.variant : 'contained'}
      color={color ? color : 'default'}
      size={size ? size : 'medium'}
      className={className ? className : classes.button}
      style={props.style ? props.style : null}
      // onClick={onClick}
      disabled={disabled ? disabled : false}
      // {...props}
      component={RouterLink}
      to={props.to}
    >
      {title}
    </MuiButton>
  );
}

export function SmallLinkButton(props) {
  return (
    <Button // LinkButton???
      title={props.title}
      variant='outlined'
      size='small'
      onClick={() => props.history.push({
        // pathname: '/admin/create-note',
        pathname: props.to,
        // state: { ...props.values },
        state: props.state,
      })}
    />
  );
}

SmallLinkButton.propTypes = {
  history: PropTypes.shape(),
  title: PropTypes.string,
  to: PropTypes.string,
  state: PropTypes.shape(),
};

export function PrimaryButton(props) {
  props = { ...props, color: 'primary' };
  return(
    <Button {...props} />
  );
}

export function SecondaryButton(props) {
  props = { ...props, color: 'secondary' };
  return(
    <Button {...props} />
  );
}

export function SuccessButton(props) {
  let classes = useStyles();
  return(<Button { ...props } className={ classes.successButton } />);
}

export function OutlinedButton(props) {
  return(<Button variant='outlined' { ...props } />);
}

export function TextButton(props) {
  return(<Button variant='text' { ...props } />);
}

// export function WarningButton(props) {
//   props = {...props, color: theme.palette.warning.main};
//   return(
//     <Button {...props} />
//   );
// }

// export function ErrorButton(props) {
//   props = {...props, color: theme.palette.error.main};
//   return(
//     <Button {...props} />
//   );
// }

export default Button;

Button.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape(),
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
};

LinkButton.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape(),
  color: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  to: PropTypes.string,
};
