// import * as yup from "yup";
import * as yup from 'yup/es';

const paymentShape = {
  id: yup.string().length(26),
  itemType: yup.string().length(8),
  userId: yup.string().length(26),
  referenceItemType: yup.string().required('Reference Item Type is required'),
  referenceItemId: yup.string().length(26).required('Reference Item ID is required'),
  eventDate: yup.string(),
  amount: yup.number(),
};

export const paymentSchema = yup.object().shape(paymentShape);
