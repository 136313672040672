import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
  FormUp2, PrimaryButton, EmailField, PasswordField, ButtonLink, BackdropLoader,
} from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { emailShape, passwordShape } from '@org/common-yup';
import { styles } from '@org/client-libs-styles';
import { PublicHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

let defaultValues = {
  email: "",
  password: "",
};

// let initialValues;

let validationShape = {
  email: emailShape,
  password: passwordShape,
};
const validationSchema = yup.object().shape(validationShape);

let isMounted = false;

let publicHandler;

export function SignIn(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formMessage: null, formError: null });
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});

    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    if (isMounted)
      setState({ ...state, isSubmitting: true });

    try {
      let { email, password } = form.values;
      let input = { email, password };

      await publicHandler.signIn(input);
      props.history.push('/user');

    } catch (error) {
      console.error('SignIn', 'handleSubmit', error.code, error.message);
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  function handleForgot(event, form) {
    event.preventDefault(); // Do I need this?

    let { email } = form.values;
    let success = {
      pathname: '/reset-password',
      state: {
        email,
      },
    };
    props.history.push(success);
  }

  // console.log('SignIn', 'props', props);

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <FormUp2
      id='sign-in-form'
      {...props}
      initialValues={defaultValues}
      validationSchema={validationSchema}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <Heading>{'Sign In'}</Heading>

          <Grid style={{width: '100%'}} >

            <Grid item >
              <FormMessage message='Welcome back!' />
            </Grid>

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <EmailField {...formProps.fieldProps} name='email' required={true} disabled={!fieldEnabled} />
            </Grid>

            <Grid item >
              <PasswordField {...formProps.passwordProps} name='password' required={true} disabled={!fieldEnabled} />
            </Grid>

            <Grid item >
              <div className={classes.forgotPassword} >
                <ButtonLink title='Forgot Password?' variant={'body2'} onClick={(event) => handleForgot(event, formProps)} />
              </div>
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                {...formProps.submitProps}
                name='sign-in-button'
                title='Submit'
                disabled={!submitEnabled}
                onClick={(event) => handleSubmit(event, formProps)}
              />
            </Grid>

            <Grid item className={classes.bottomLink} >
              <Link variant='body2' component={RouterLink} to='/sign-up' >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ isMounted, state, form: props.stateProps }} open={false} />
        </>
      );
      }}
    </FormUp2>
    </>
  );
}

SignIn.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  passwordProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
};
