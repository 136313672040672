import React from 'react';
import PropTypes from 'prop-types';

const { NODE_ENV, REACT_APP_STAGE } = process.env;

let style ={
  // maxWidth: '80%',
  // width: '100%',
  width: 'auto',
  minWidth: '400px',
  maxWidth: '600px',
  marginTop: '1rem', 
  marginBottom: '1rem', 
  padding: '1rem',
  borderRadius: '3px',
  boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
};

export function DisplayState(props) {
  // console.log('DisplayState', props);
  let open = props.open ? props.open : false;
  return(
    <>
      {(NODE_ENV === 'development' && REACT_APP_STAGE === 'dev') &&
      <details open={open} style={style} >
        <summary style={{ cursor: 'pointer'}} >{props.title}</summary>
        <pre>{JSON.stringify(props.state, null, 2)}</pre>
      </details>}
    </>
  );
}

DisplayState.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  state: PropTypes.shape({}),
};
