import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  FormUp, ButtonBar, PrimaryButton, // Breadcrumbs, // DatePicker,
  TextField, NumberField, SelectTextField, // AmountField, PercentField,
  // InLink, 
} from '@org/client-components-core';
import { Heading, FormLayout, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { formSchema } from './yup';

let defaultValues = {
  // dateField: now,
  textField: "",
  numberField: 0,
  calculatedField: 0,
};

// calculated values used for default values and rerenders
const calculatedValues = [ 'calculatedField' ];
function calculateValues(values) {
  values = {
    ...values,
    calculatedField: values?.textField?.length,
  };
  return values;
}

export function UpdateForm(props) {
  // console.log('UpdateForm', 'props', props);
  let isMounted = true;
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, formError: null,
    formMessage: 'Use this form on existing, validated data to only transmit updated values',
  });
  const [ form, setForm ] = useState({
    values: calculateValues({ ...defaultValues, ...props.location?.state }),
    touched: {},
    validated: {},
    errors: {},
  });

  useEffect(()=>{
    // console.log('UpdateForm', 'useEffect', form);
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    event.preventDefault();
    console.info('handleSubmit', event, form);
  }

  // Default Values
  let selectTextFieldProps = { name: 'selectTextField', label: 'Select Text Field', required: true };
  let actions = [ { label: 'Option 1', value: 'OPTION_ONE' }, { label: 'Option 2', value: 'OPTION_TWO' } ];

  // Calculated Values
  // use a function to share code with setting default values
  let values = calculateValues(form.values);
  if (values.calculatedField !== form.values.calculatedField) {
    // only trigger a new render if the values have changed...
    console.warn('UpdateForm', 'Calculated Values', values.calculatedField, '!==', form.values.calculatedField);
    setForm({
      ...form,
      values,
      // touched: { ...form.touched, calculatedField: true },
      validated: { ...form.validated, calculatedField: true },
    });
  }

  return (
    <>
    <FormUp id='update-form' {...props} state={form} setState={setForm} calculatedValues={calculatedValues} validationType={'update'} validationSchema={formSchema} >
      {props => {
      {/* console.log('UpdateForm', 'RP', 'form', form); */}
      let enabled = props.isValidated && !state.isSubmitting;
      return (
        <>
        {/* <Breadcrumbs>
          <InLink color='inherit' title='Home' to='/' />
          <InLink color='inherit' title='Dev' to='/dev' />
        </Breadcrumbs> */}

        <FormLayout>

          <Heading>{'Update Form'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField {...props.fieldProps} name='textField' label='Text Field' required={true} />
            </Grid>

            <Grid item >
              <NumberField {...props.fieldProps} name='numberField' label='Number' disabled={false} />
            </Grid>

            <Grid item >
              <NumberField {...props.fieldProps} name='calculatedField' label='Calculated Field' disabled={false} />
            </Grid>

            <Grid item >
              <SelectTextField {...selectTextFieldProps} {...props.fieldProps} items={actions} disabled={false} />
            </Grid>

            <Grid item >
              <ButtonBar>
                {/* <PrimaryButton {...props.buttonProps} title='Submit' disabled={!enabled} */}
                <PrimaryButton title='Submit' disabled={!enabled}
                onClick={(event) => handleSubmit(event, props)} />
              </ButtonBar>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: { isValidated: props.isValidated, ...form } }} open={true} />
        </>
      );}}

    </FormUp>
    </>
  );
}

UpdateForm.propTypes = {
  location: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
};
