import dayjs from 'dayjs';
// import { ulid, decodeTime, encodeTime, replaceCharAt } from 'ulid';
// import { ulid, decodeTime } from 'ulid';
// import { decodeTime } from 'ulid';
import { ulid } from 'ulid';
import {
  // authenticatedResolvers,
  AuthorizedHandler,
} from '@org/client-graphql';
// import { authorizedResolvers, newLoanInputs, newNoteInputs, } from '@org/common-test-data';
import { LoanClass } from '@org/common-classes/Loan';
import { NoteClass } from '@org/common-classes/Note';
import { CashflowClass } from '@org/common-classes/Cashflow';
import { UPDATE_LOAN } from '@org/client-graphql/authorized/schema';

let authorizedHandler;

let loanInput1 = {
  id: '01EKXCEKR0VJQB85SDHMBXE80R', // 2020-10-05 2:00pm PST
  borrowerName: 'Ben Borrowin',
  propertyAddress: '123 Main Street, AnyTown, ST 99999',
  // assetType: 'REAL_ESTATE',
  // assetSubType: 'RESIDENTIAL',
  originalBalance: 350000.0,
  // originalTerm: 12,
  // amortizationTerm: 0,
  // paymentFrequency: 'MONTHLY',
  interestRate: 8.99,
  // servicingRate: 0.5,
  // interestType: "30_360",
  originationDate: '2020-10-05',
  appraisalValue: 1100000.0,
  // appraisalDate: '2020-09-23',
  // appraisal: {
  //   value: 1100000.0,
  //   date: '2020-09-23',
  //   appraiser: 'An appraisal company'
  // },
  // broker: '',
};

let noteInput1 = {
  id: '01EP21Q3R0H3DTW2BQSTFMGZGN', // 2020-11-01 9:00 am EST
  itemName: 'RRE PDN I',
  description: 'This is the 1st example note. The note receives cashflows based on payments made on a residential real estate-backed bridge loan. The loan has a 12 month term and the property is located somewhere in America.',
  // originalBalance: loanValues.originalBalance,
  // availableBalance: loanValues.originalBalance,
  // factor: 1.0,
  // currentBalance: loanValues.currentBalance,
  // managementRate: 1.0,
  // coupon: loanValues.netRate - 1.0,
  // price: 100.0,
  // launchDate,
  // minimumAmount: 5000.0,
};

let tradeInput1 = {
  amount: 350000.00,
};

let loanInput2 = {
  id: '01F7PG0QYGMR0VM8W56P7H3RSP', // 2021-02-26 2:00 pm PST
  borrowerName: 'Smore Borrowin',
  propertyAddress: '234 Main Street, AnyTown, ST 99999',
  originalBalance: 605000.0,
  interestRate: 8.99,
  originationDate: '2021-02-26',
  appraisalValue: 1090000.0,
  // appraisalDate: '2021-02-18',
};

let noteInput2 = {
  id: '01EZQ1DBR0XSGXVKVFC8BNEEK3', // 2021-03-01 9:00 am EST
  itemName: 'RRE PDN II',
  description: 'This is the 2nd example note. The note receives cashflows based on payments made on a residential real estate-backed bridge loan. The loan has a 12 month term and the property is located somewhere in America.',
};

let tradeInput2 = {
  amount: 100000.00,
};

let loanInput3 = {
  id: '01F0CG20R083PZA64VGX4P89R4', // 2021-03-09 2:00 pm PST
  borrowerName: 'Lucky Borrowin',
  propertyAddress: '345 Main Street, AnyTown, ST 99999',
  originalBalance: 210000.0,
  interestRate: 8.99,
  originationDate: '2021-03-09',
  appraisalValue: 400000.0,
  // appraisalDate: '2021-02-25',
};

let noteInput3 = {
  id: '01F26VQMR040BB4FJBMHZRC1Z2', // 2021-04-01 9:00 am EST
  itemName: 'RRE PDN III',
  description: 'This is the 3rd example note. The note receives cashflows based on payments made on a residential real estate-backed bridge loan. The loan has a 12 month term and the property is located somewhere in America.',
};

let tradeInput3 = {
  amount: 25000.00,
};

async function deleteData() {
  console.info('Deleting data...');
  console.info('');

  let cashflows = (await authorizedHandler.listCashflows());
  for (let index in cashflows) {
    await authorizedHandler.deleteCashflow({ id: cashflows[index].id });
  }

  let loans = await authorizedHandler.listLoans();
  for (let index in loans) {
    await authorizedHandler.deleteLoan({ id: loans[index].id });
  }

  let notes = await authorizedHandler.listNotes();
  for (let index in notes) {
    await authorizedHandler.deleteNote({ id: notes[index].id });
  }

  let payments = (await authorizedHandler.listPayments());
  for (let index in payments) {
    await authorizedHandler.deletePayment({ id: payments[index].id });
  }

  // if (process.env.REACT_APP_IS_OFFLINE === 'true') {
  if (true | process.env.REACT_APP_STAGE === 'dev') {
    // Note id's do not change with each run so we don't want to delete trades
    // Also, we want them to be backdated which we cannot do from the trade entry page...

    let trades = (await authorizedHandler.listTrades());
    for (let index in trades) {
      await authorizedHandler.deleteTrade({ id: trades[index].id });
    }

    let deposits = (await authorizedHandler.listDeposits());
    for (let index in deposits) {
      await authorizedHandler.deleteDeposit({ id: deposits[index].id });
    }
  }

  let transactions = (await authorizedHandler.listTransactions());
  for (let index in transactions) {
    // This should have already been taken care of in deletePayment
    if (transactions[index].itemType === 'PAYMENT')
      await authorizedHandler.deleteTransaction({ itemType: 'PAYMENT', id: transactions[index].id });

    // This should have already been taken care of in deleteTrade
    if (transactions[index].itemType === 'TRADE')
      await authorizedHandler.deleteTransaction({ itemType: 'TRADE', id: transactions[index].id });

    // This should have already been taken care of in deleteTrade
    if (transactions[index].itemType === 'DEPOSIT')
      await authorizedHandler.deleteTransaction({ itemType: 'DEPOSIT', id: transactions[index].id });
  }
}

async function createLoan(input) {
  console.info('Creating loan...');
  console.info('');

  let originationDate = new Date(input.originationDate + ' 2:00 pm PST');
  input.id = ulid(originationDate.getTime());

  let loanClass = new LoanClass(input);
  // console.log('loanClass', loanClass.values());

  let loanResponse = await authorizedHandler.createLoan({ ...loanClass.attributes, id: loanClass.keys.id }); // eslint-disable-line
  // console.log('loanResponse', loanResponse);

  return loanClass;
}

async function updateLoan(input) {
  console.info('Updating loan...');
  let loanResponse = await authorizedHandler.updateLoan(input); // eslint-disable-line
  // console.log('loanResponse', loanResponse);
}

async function createNote(input) {
  console.info('Creating note...', input);
  console.info('');

  let originationDate = new Date(input.launchDate + ' 9:00 am EST');
  input.id = ulid(originationDate.getTime());

  let noteClass = new NoteClass(input);
  // console.log('noteClass', noteClass);

  let noteResponse = await authorizedHandler.createNote({ ...noteClass.attributes, id: noteClass.keys.id }); // eslint-disable-line
  // console.log('noteResponse', noteResponse);

  return noteClass;
}

async function createTrade(note, trade) {
  let tradeValues = {
    referenceType: note.itemType,
    referenceId: note.id,
    eventDate: note.launchDate,
    tradeType: 'BUY',
    amount: trade.amount,
    price: note.price,
    investedAmount: trade.amount,
  };
  let noteResponse = await authorizedHandler.createTrade(tradeValues); // eslint-disable-line
}

async function updateNote(input) {
  console.info('Updating note...');
  console.info('');

  console.log('noteInput', input); 
  let noteResponse = await authorizedHandler.updateNote(input);
  console.log('noteResponse', noteResponse); 
}

// async function createLoanNote(loanInput, noteInput) {

//   // loan1
//   let loanClass = await createLoan(loanInput);
//   // let loanValues = loanClass.values();
//   // console.info('loanValues', loanValues);

//   // note1
//   // console.info('noteInput', noteInput);
//   let noteClass = await createNote({ ...noteInput, ...loanClass.noteInput()});
//   // noteValues = noteClass1.values();
//   // console.info('noteValues', noteValues);

//   return { loanClass, noteClass };
// }

// async function createHistoricalCashflows() {
  
// }


async function createClosingLoanCashflow(loanClass) {
  let cashflowInput = loanClass.getClosingCashflow();
  let originationDate = new Date(cashflowInput.originationDate + ' 2:00 pm PST');
  cashflowInput.id = ulid(originationDate.getTime());
  // console.log('cashflowInput', cashflowInput);
  let cashflowResponse = await authorizedHandler.createCashflow(cashflowInput);
  // console.log('cashflowResponse', cashflowResponse);
  console.info('');
  return cashflowResponse;
}

async function createLoanCashflow(loanClass) {
  let cashflowInput = loanClass.getNextCashflow();
  // console.log('cashflowInput', cashflowInput);
  console.log('createLoanCashflow', cashflowInput.referenceId, cashflowInput.eventDate);
  let cashflowResponse = await authorizedHandler.createCashflow(cashflowInput);
  // console.log('cashflowResponse', cashflowResponse);
  loanClass.applyNextCashflow(cashflowInput);
  await updateLoan(loanClass.getCashflowUpdates());
  // console.log('cashflowResponse', cashflowResponse);
  console.info('');
  return cashflowInput;
}

async function createNoteCashflow(cashflowInput, noteClass) {
  cashflowInput = noteClass.applyCashflow(cashflowInput);
  // console.log('cashflowInput', cashflowInput);
  console.log('createNoteCashflow', cashflowInput.referenceId, cashflowInput.eventDate);
  let cashflowResponse = await authorizedHandler.createCashflow(cashflowInput);
  // console.log('cashflowResponse', cashflowResponse);
  await updateNote(noteClass.getCashflowUpdates());
  // console.log('cashflowResponse', cashflowResponse);
  console.info('');
  return cashflowResponse;
}

async function createNotePayments(cashflowInput, cashflowResponse) {
  console.log('createNotePayments', cashflowInput.id, cashflowInput.eventDate);

  try {
    await authorizedHandler.createPayments({ cashflowId: cashflowResponse.id });
    // console.log('createPayments', response);
  } catch (error) {
    console.error(error.message);
  }
  console.info('');
}

export async function seedData() {
  console.info('Seeding data...');
  console.info('');

  let cashflowInput, cashflowResponse;

  // let { loanClass: loanClass1, noteClass: noteClass1 } = await createLoanNote(loanInput1, noteInput1);
  let loanClass1 = await createLoan(loanInput1);
  let noteClass1 = await createNote({ ...noteInput1, ...loanClass1.noteInput()});

  let loanClass2 = await createLoan(loanInput2);
  let noteClass2 = await createNote({ ...noteInput2, ...loanClass2.noteInput() });

  let loanClass3 = await createLoan(loanInput3);
  let noteClass3 = await createNote({ ...noteInput3, ...loanClass3.noteInput() });

  // if (process.env.REACT_APP_IS_OFFLINE === 'true') {
  if (true | process.env.REACT_APP_STAGE === 'dev') {
    await authorizedHandler.createDeposit({ id: '01EKWV99M0065MCR12RTMBC96K', eventDate: '2020-10-05', amount: 475000.0 });
    await createTrade(noteClass1.values(), tradeInput1);
    await createTrade(noteClass2.values(), tradeInput2);
    await createTrade(noteClass3.values(), tradeInput3);
  }

  // cashflows

  console.info('Creating cashlows...');
  console.info('');

  // console.info('loan1 -- closing loan cashflow');
  await createClosingLoanCashflow(loanClass1);

  // console.info('1st cashflow'); // 2020-12-01
  cashflowInput = await createLoanCashflow(loanClass1);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('2nd cashflow'); // 2021-01-01
  cashflowInput = await createLoanCashflow(loanClass1);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('3rd cashflow'); // 2021-02-01
  cashflowInput = await createLoanCashflow(loanClass1);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('4th cashflow'); // 2021-04-01
  cashflowInput = await createLoanCashflow(loanClass1);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('5th cashflow'); // 2021-04-01
  cashflowInput = await createLoanCashflow(loanClass1);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('6th cashflow'); // 2021-05-01
  cashflowInput = await createLoanCashflow(loanClass1);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('7th cashflow'); // 2021-06-01
  cashflowInput = await createLoanCashflow(loanClass1);
  // cashflowResponse = await createNoteCashflow(cashflowInput, noteClass1);
  // await createNotePayment(cashflowInput, cashflowResponse);

  // #2

  // console.info('loan2 -- closing cashflow'); // 2021-02-26
  await createClosingLoanCashflow(loanClass1);
  
  // console.info('1st cashflow'); // 2021-04-01
  cashflowInput = await createLoanCashflow(loanClass2);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass2);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('loan2 -- 2nd cashflow'); // 2021-05-01
  cashflowInput = await createLoanCashflow(loanClass2);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass2);
  await createNotePayments(cashflowInput, cashflowResponse);
  
  // console.info('loan2 -- 3rd cashflow'); // 2021-06-01
  cashflowInput = await createLoanCashflow(loanClass2);
  // cashflowResponse = await createNoteCashflow(cashflowInput, noteClass2);
  // await createNotePayments(cashflowInput, cashflowResponse);

  // #3

  // console.info('loan3 -- closing cashflow'); // 2021-03-09
  await createClosingLoanCashflow(loanClass3);

  // console.info('1st cashflow'); // 2021-05-01
  cashflowInput = await createLoanCashflow(loanClass3);
  cashflowResponse = await createNoteCashflow(cashflowInput, noteClass3);
  await createNotePayments(cashflowInput, cashflowResponse);

  // console.info('2nd cashflow');  // 2021-06-01
  cashflowInput = await createLoanCashflow(loanClass3);
  // cashflowResponse = await createNoteCashflow(cashflowInput, noteClass3);
  // await createNotePayments(cashflowInput, cashflowResponse);

  // cashflowResponse = await authorizedHandler.listCashflows();
  // console.info('cashflowResponse', cashflowResponse);
  // console.info('');

  console.info('Done.');
}

export async function handleSeed(props) {
  console.log('handleSeed', props);

  console.clear();

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler(props);

  try {

    await authorizedHandler.closeConnections();

    await deleteData();

    // await authorizedHandler.createDeposit({ id: '01EKWV99M0065MCR12RTMBC96K', eventDate: '2020-10-05', amount: 100000.0 });

    await authorizedHandler.closeConnections();

    await seedData();

    await authorizedHandler.closeConnections();

  } catch (error) {
    console.error(error);
  }
}