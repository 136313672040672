import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Form, LinkButton } from '@org/client-components-core';
import { Heading, FormLayout, FormMessage } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { styles } from '@org/client-libs-styles';

import {
  EnterManually, InvestorStatus, InvitationStepper, VerifyIdentity, VerifyInvitation,
} from '@org/client-pages-auth-invitation';
import { ChangePassword } from './ChangePassword';

const useStyles = makeStyles(styles);

let data = {}; // passed to Invitation in props.location.state.data;

let defaultValues = {};
// let stateValues = {};  

let isMounted = false;

function InvitationComplete(_props) {
  // console.log(location, 'InvitationComplete', 'props', props);
  let classes = useStyles();

  return(
    <>
    <Heading>{'Sign Up Complete'}</Heading>

    <Grid style={{width: '100%'}} >
      <Grid item >
        {/* <FormMessage message='Thank you for taking the time to sign up. Click Continue below to go to your user home or feel free to explore the site.' /> */}
        <FormMessage message='Thank you for taking the time to sign up! Please click continue to learn more about the site.' />
      </Grid>

      <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
        <LinkButton title='Continue' color='primary' style={{textTransform: 'upper'}} validated={true} loading={false} to='/user/welcome' />
      </Grid>
    </Grid>
    </>
  );
}

InvitationComplete.propTypes = {
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setActiveStep: PropTypes.func,
};

export function Invitation(props) {
  console.log('Invitation', 'props', props);
  const [ state, setState ] = useState({
    activeStep: '',
    // activeStep: 'changePassword',
    isLoading: true,
    isSubmitting: false,
    values: {},
    error: null,
  });

  // URGENT If a user is signed in, sign them out now
  // cleanUp();
  // URGENT Ask the user if they want to sign out and proceed with the invitation. Why would this happen?

  useEffect(() => {
    console.log('Invitation', 'useEffect', window);
    isMounted = true;
    window.scrollTo(0, 0);
    document.querySelector('body').scrollTo(0,0);

    if (isMounted) {
      if (!props?.location?.state?.data)
        setState({ ...state, isLoading: false, activeStep: 'enterManually' });
      else {
        data = props.location.state.data;
        setState({ ...state, data, isLoading: true, activeStep: 'verifyInvitation' });
      }
    }
    // let data = { confirmCode: '674837', messageId: '01F67ZX03ZWA419SKT4YDS2YQT' };
    // setState({ ...state, data, isLoading: true, activeStep: 'verifyInvitation' });

    return () => {
      isMounted = false;
    };
  }, []);

  if (state.activeStep === 'verifyInvitation') {
    let invitationProps = { ...props, state, setState };
    return(
      <>
      <FormLayout>
        <VerifyInvitation {...invitationProps} />
      </FormLayout>

      <DisplayState title='state' state={invitationProps} open={true} />
      </>
    );
  }

  // if (state.activeStep === 'enterManually') {
  //   let invitationProps = { ...props, state, setState };
  //   return(
  //     <FormLayout>
  //       <EnterManually {...invitationProps} />
  //     </FormLayout>
  //   );
  // }

  if (state.activeStep === 'changePassword') {
    let invitationProps = { ...props, state, setState };
    return(
      <ChangePassword {...invitationProps} />
    );
  }

  if (state.activeStep === 'investorStatus') {
    let invitationProps = { ...props, state, setState };
    return(
      <InvestorStatus {...invitationProps} />
    );
  }

  return(
    <>
    <Form id='invitation' defaultValues={defaultValues} stateValues={state.values} validationSchema={authSchema} {...props} >
      {props => {
      let invitationProps = { ...props, state, setState };
      {/* console.log('Invitation', invitationProps); */}
      let showStepper = ['changePassword', 'investorStatus', 'verifyIdentity'].includes(state.activeStep);
      return (
        <>
        <FormLayout>

          {showStepper &&
          <InvitationStepper activeStep={state.activeStep} />}

          {/* <ApolloProvider client={publicClient} > */}
            {state.activeStep === 'enterManually' &&
            <EnterManually {...invitationProps} />}
            {/* {state.activeStep === 'verifyInvitation' &&
            <VerifyInvitation {...invitationProps} />} */}
            {/* {state.activeStep === 'changePassword' &&
            <ChangePassword {...invitationProps} />} */}
            {/* {state.activeStep === 'investorStatus' &&
            <InvestorStatus {...invitationProps} />} */}
            {state.activeStep === 'verifyIdentity' &&
            <VerifyIdentity {...invitationProps} />}
            {state.activeStep === 'invitationComplete' &&
            <InvitationComplete {...invitationProps} />}
          {/* </ApolloProvider> */}

        </FormLayout>

        <DisplayState title='state' state={{ isMounted, data, state, defaultValues, form: { ...props.stateProps } }} open={true} />
        </>
      );}}
    </Form>
    </>
  );
}

Invitation.propTypes = {
  location: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  isValidated: PropTypes.bool,
  resetForm: PropTypes.func,
};
