import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Form, PrimaryButton, TextField, EmailField } from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { styles } from '@org/client-libs-styles';
import { publicHandler } from '@org/client-graphql';

let defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
};

const useStyles = makeStyles(styles);

let isMounted = false;

export function SignUp(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formError: null });
  let classes = useStyles();

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted) // trigger a render
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });

    try {
      let { firstName, lastName, email } = form.values;
      let input = { firstName, lastName, email };

      await publicHandler.initiateSignUp(input);

      let success = {
        pathname: '/confirm-sign-up',
        state: {
          data: {
            email,
          },
        },
      };
      props.history.push(success);

    } catch (error) {
      console.error(error);
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  let demo = process.env.REACT_APP_STAGE === 'demo' ? true : false;

  return (
    <>
    <Form id='sign-up' defaultValues={defaultValues} validationSchema={authSchema} {...props} >
      {props => {
      let fieldEnabled = !demo && !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && props.isValidated;
      return (
        <>
        <FormLayout id='sign-up' >

          <Heading>{'Sign Up'}</Heading>

          <Grid id='sign-up' style={{width: '100%'}} >

            <Grid item >
              {!demo &&
              <FormMessage message='Welcome!' />}
              {demo &&
              <FormMessage message='Access is currently by invitation only. We hope to allow uninvited user sign ups soon.' />}
            </Grid>

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField
                {...props.fieldProps}
                name='firstName'
                label='First Name'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <TextField
                {...props.fieldProps}
                name='lastName'
                label='Last Name'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <EmailField {...props.fieldProps} name='email' required={true} disabled={!fieldEnabled} />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                {...props.buttonProps}
                name='sign-up'
                title='Submit'
                disabled={!submitEnabled}
                onClick={(event) => handleSubmit(event, props)}
              />
            </Grid>

            <Grid item className={classes.bottomLink} >
              <Link variant="body2" component={RouterLink} to="/confirm-sign-up" >
                {"Already have your confirm code? Confirm Sign Up"}
              </Link>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: props.stateProps }} open={false} />
        </>
      );
      }}
    </Form>
    </>
  );
}

SignUp.propTypes = {
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
};
