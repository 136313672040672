import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiTextField } from '@material-ui/core/TextField';
// import { green } from '@material-ui/core/colors';
// import theme from '@org/client-libs-themes';
import { CheckAdornment } from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles((theme) => ({
// // const localStyles = {
//   root: {
//     margin: theme.spacing(0, 0, 2, 0),
//     '& input:valid + fieldset': {
//       // borderColor: 'green',
//       borderColor: theme.palette.success.main,
//       borderWidth: 2,
//     },
//     '& input:invalid + fieldset': {
//       margin: theme.spacing(0, 0, 0, 0), // TODO Fix invalid spacing
//     },
//     // '& input:invalid + fieldset': {
//     //   borderColor: 'red',
//     //   borderWidth: 2,
//     // },
//     // '& input:valid:focus + fieldset': {
//     //   borderLeftWidth: 6,
//     //   padding: '4px !important', // override inline-style
//     // },
//     // '&:focus': {
//     //   boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
//     // },
//   },
// }));

// const theme = createMuiTheme({
//   palette: {
//     primary: green,
//   },
// });

// const useStyles = makeStyles((theme) => (localStyles));

const useStyles = makeStyles(styles);

/**
 * The MUI TextField wrapper component is a complete form control.
 * It supports standard, outlined and filled styling.
 * 
 * <FormControl >
 *   <InputLabel />
 *   <Input /> | <OutlinedInput /> | <FilledInput />
 *   <FormHelperText />
 * <FormControl >
 */

export function TextField(props) {
  // console.log('TextField', props);
  let {
    name, type, label, values, errors, validated, autoFocus, disabled,
    handleChange, handleBlur, required, endAdornment,
    ...rest
  } = props;

  if (!props.name)
    console.warn('TextField must receive `name` prop');

  if (!props.handleChange)
    console.warn('TextField must receive `handleChange` prop', name);

  if (!values[name] && !values)
    console.warn('TextFields must have `values[name]` or a `value` prop `${name}`');

  let value = (values && values[name]) != null ? values[name] : "";
  let error = (errors && errors[name]) ? true : false;
  let isValid = validated && validated[name] && !props.disabled;

  endAdornment = !endAdornment ? <CheckAdornment position="end" show={isValid} /> : endAdornment;

  const classes = useStyles();

  // console.log('TextField', `'${name}'`, `'${value}'`);
  // console.log('TextField', 'rest', {...rest});

  return(
    <MuiTextField
      {...rest}
      className={classes.inputField}
      variant='outlined'
      margin='normal'
      // margin='dense'
      // fullWidth
      label={label}
      id={name}
      name={name}
      type={type ? type : 'text'} // A valid HTML5 input type
      value={value} // why was this commented out?
      // initialValue={value} // fixes jumping cursor bug on edits
      // defaultValue={value} // fixes missing initial value when using initialValue
      // onChange={handleChange ? handleChange : null}
      onChange={handleChange ? (event) => handleChange(event, name) : () => { console.info('handleChange not passed to TextField'); } }
      // onBlur={(event) => handleBlur(event, name)}
      onBlur={handleBlur ? (event) => handleBlur(event, name) : () => { console.info('handleBlur not passed to TextField'); } }
      error={error}
      helperText={error && errors[name]}
      required={required ? required : false}
      disabled={disabled ? disabled : false}
      autoFocus={autoFocus ? autoFocus : false}
      InputProps={{ endAdornment }}
    />
  );
}

export default TextField;

TextField.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleChangeEvent: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  endAdornment: PropTypes.node,
  autoFocus: PropTypes.bool,
};

export function EmailField(props) {
  // console.log('EmailField', props);
  return (
    <TextField
      {...props}
      // type='email' // !!! causes spaces to be ignored !!!
      name={props.name ? props.name : 'email'}
      label={props.label ? props.label : 'Email'}
      required={props.required ? props.required : true}
    />
  );
}

EmailField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  // fieldProps: PropTypes.shape(),
};
