import React from 'react';
import {
  query,
  publicSchema,
  authenticatedSchema,
  authorizedSchema,
} from '@org/client-graphql';

// export function query(schema) {
//   const { loading, data, error } = useQuery(schema);
//   let response;
//   if (loading)
//     response = 'Loading...';
//   if (data)
//     response = data[Object.keys(data)[0]];
//   if (error)
//     error.message;
//   return response;
// }

// export function PingAuthenticated() {
//   const { loading, data, error } = useQuery(authenticatedSchema.PING_AUTHENTICATED);
//   // console.log('AuthenticatedPing', loading, data, error);

//   if (loading)
//     return <div>Ping: {'Loading...'}</div>;
//   if (error)
//     return <div>Ping: {error.message}</div>;
//   if (data)
//     return <div>Ping: {data.ping}</div>;
//   return null;
// }

// export default AuthenticatedPing;

export function PingPublic() {
  let response;
  try {
    response = query(publicSchema.PING_PUBLIC);
  } catch (error) {
    response = error.message
  }
  return <div>PingPublic: {JSON.stringify(response, null, ' ')}</div>;
}

export function PingAuthenticated() {
  let response;
  try {
    response = query(authenticatedSchema.PING_AUTHENTICATED);
  } catch (error) {
    response = error.message
  }
  return <div>PingAuthenticated: {JSON.stringify(response, null, ' ')}</div>;
}

// async function asyncPing(resolver, operation) {
//   // let response = await resolver[operation];
//   let response = await authorizedResolvers.pingAuthorized();
//   console.log('asyncPing', resolver, operation, response, typeof response);
//   return response;
// }

export function PingAuthorized() {
  let response;
  try {
    response = query(authorizedSchema.PING_AUTHORIZED);
  } catch (error) {
    response = error.message
  }
  return <div>PingAuthorized: {JSON.stringify(response, null, ' ')}</div>;
}
