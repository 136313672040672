import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
// import { ulid } from 'ulid';
// import { decodeTime, encodeTime } from 'ulid';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloProvider } from '@apollo/client';
// import { makeStyles } from '@material-ui/core/styles';
import theme from '@org/client-libs-themes';
// import { styles } from '@org/client-libs-styles';
import {
  PrimaryButton, Button,
  InLink,
  SelectTextField,
  TextField, StandardTextField,
  ButtonLink,
} from '@org/client-components-core';
// import MenuItem from '@material-ui/core/MenuItem';
import {
  Page, Heading, SectionHeading, 
  FlexRow, FlexColumn,
  // Online, Authenticated, PingPublic, PingAuthenticated, PingAuthorized,
  ViewerAgent,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import {
  // publicResolvers,
  // publicClient,
  // authenticatedClient,
  // authorizedResolvers, authorizedClient,
  // handleResponseError,
  AuthorizedHandler,
  // authorizedHandler,
  PublicHandler,
} from '@org/client-graphql';
import { ulid, decodeTime } from 'ulid';
import { handleSeed } from './Seed';

// const useStyles = makeStyles(localStyles);

const recordTypes = [
  { label: 'Emails', value: 'EMAIL' },
  { label: 'Invitations', value: 'INVITATION' },
  { label: 'Trades', value: 'TRADE' },
  { label: 'Sessions', value: 'SESSION' },
  { label: 'Users', value: 'USER' },
  { label: 'Note IDs', value: 'NOTE_ID' },
  { label: 'User IDs', value: 'USER_ID' },
];

function DevSection(props) {
  return (
    <section style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', margin: theme.spacing(1), padding: theme.spacing(1), border: '1px solid gray', borderRadius: '4px', width: 'auto', minWidth: 'fit-content'}}>
      {props.children}
    </section>
  );
}

DevSection.propTypes = {
  children: PropTypes.node,
};

let isMounted = false;
let publicHandler;
let authorizedHandler;

let now = new Date();

let id = ulid();
let timestamp = decodeTime(id);
let date = new Date(timestamp);
let timePart = id.substring(0, 10);
let randomPart = id.substring(10, 26);

let file;

export function Dev(props) {
  // let classes = useStyles();
  let [ state, setState ] = useState({ isLoading: true });
  let [ values, setValues ] = useState({
    // Date
    dateValue: now.getTime(),
    dateToISOString: now.toISOString(),
    dateGetTime: now.getTime(),
    dateToUTCString: now.toUTCString(),
    dateToLocaleString: now.toLocaleString(),
    // ULID
    dateOrUlid: id,
    ulid: id,
    ulidMilliseconds: timestamp,
    ulidLocaleString: date.toLocaleString(),
    ulidIsoString: date.toISOString(),
    ulidTimePart: timePart,
    ulidRandomPart: randomPart,
  });
  let [ validated, setValidated ] = useState({});
  let errors = {};

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;

    // console.log('authorizedHandler', authorizedHandler);

    (async () => {
      // let response = await publicHandler.pingPublic();
      // alert(response);
      if (state.isLoading) { // only do this on the initial page load
        window.scrollTo(0, 0);
        if (isMounted)
            setState({ ...state, isLoading: false });

        // const GET_SCHEMA_NAMES = gql`
        //   query GetSchemaNames {
        //     __schema {
        //       types {
        //         name
        //       }
        //     }
        //   }
        // `;
        // let params = {
        //   query: GET_SCHEMA_NAMES,
        //   // query: APOLLO_INTROSPECTION_QUERY,
        //   // query: CODEGEN_INTROSPECTION_QUERY,
        //   fetchPolicy: "no-cache",
        // };
        // let response = await authorizedClient.query(params);
        // console.log(JSON.stringify(response.data.__schema, null, ' '));

        // let response;
        // try {
        //   response = await authorizedResolvers.pingAuthorized();
        // } catch (error) {
        //   console.error(error);
        //   handleResponseError({ error, location: props.location, history: props.history });
        // }
        // console.log('componentDidMount', JSON.stringify(response, null, ' '));
        // response = await authorizedResolvers.pingAuthorized2();
        // console.log(location, 'componentDidMount', JSON.stringify(response, null, ' '));
      }
    })();
    
    return () => { isMounted = false; };
  }, []);

  function handleChange(event) {
    // console.log('handleChange', event);
    let { name, value } = event.target;
    setValues({ ...values, [name]: value });
  }

  // function handleBlur(event) {
  //   // console.log('handleBlur', props);
  //   setValidated({ ...validated, [event.target.name]: true });
  // }

  // let fieldProps = { values, errors, validated, handleChange, handleBlur };

  function handleConvertDate() {
    let date = new Date(values.dateValue);
    console.info('handleConvertDate', values.dateValue, date);
    let dateToISOString = date.toISOString();
    let dateGetTime = date.getTime();
    let dateToUTCString = date.toUTCString();
    let dateToLocaleString = date.toLocaleString();
    setValues({ ...values, dateToISOString, dateGetTime, dateToUTCString, dateToLocaleString });
  }

  function handleConvertUlid() {

    let id;
    if (values.dateOrUlid.length === 26) { // ulid
      id = values.dateOrUlid;
    } else if (values.dateOrUlid.length === 24) { // iso8601
      let date = new Date(values.dateOrUlid);
      id = ulid(date.getTime());
    } else if (values.dateOrUlid.length === 13) { // milliseconds
      // id = ulid(values.dateOrUlid);
      let date = new Date(values.dateOrUlid);
      id = ulid(date.getTime());
    } else {
      let date = new Date(values.dateOrUlid);
      id = ulid(date.getTime());
    }

    if (id) {

      let ulidMilliseconds = decodeTime(id);
      let ulidLocaleString = (new Date(ulidMilliseconds)).toLocaleString();
      let ulidTimePart = id.substring(0, 10);
      let ulidRandomPart = id.substring(10, 26);

      setValues({ ...values, ulid: id, ulidMilliseconds, ulidLocaleString, ulidTimePart, ulidRandomPart });
    }
  }

  // async function handleTestEmail() {
  //   setState({ ...state, isLoading: true });
  //   let input = values.testEmailSelect;
  //   // await authorizedResolvers.testEmail(input);
  //   await authorizedHandler.testEmail(input);
  //   // let response = await authorizedResolvers.testEmail(input);
  //   // console.log('testEmail', response);
  //   setState({ ...state, isLoading: false });
  // }

  // async function handleTestError() {
  //   setState({ ...state, isLoading: true });
  //   let input = values.testErrorSelect;
  //   // let response = await authorizedResolvers.testError(input);
  //   // console.log('handleTestError', Object.getOwnPropertyNames(authorizedHandler));
  //   try {
  //     let response = await authorizedHandler.testError(input);
  //     console.info('testError', response);
  //   } catch (error) {
  //     console.error('testError', error.message);
  //   }
  //   // setState({ ...state, isLoading: false });
  // }

  // async function handlelistConnections() {
  //   // let response = await authorizedResolvers.listConnections();
  //   let response = await authorizedHandler.listConnections();
  //   console.info('listConnections', response);
  // }

  // async function handlelistTables() {
  //   // let response = await authorizedResolvers.listTables();
  //   let response = await authorizedHandler.listTables();
  //   console.info('listTables', response);
  // }

  // async function handleListTransactions() {
  //   // let response = await authorizedResolvers.listTransactions();
  //   let response = await authorizedHandler.listTransactions();
  //   console.info('listTransactions', response);
  // }

  // async function handleMigrateTransactionsTable() {
  //   console.info('migrateTransactionsTable');
  //   // let response = await authorizedResolvers.migrateTransactionsTable();
  //   let response = await authorizedHandler.migrateTransactionsTable();
  //   console.info('migrateTransactionsTable', response);
  // }

  // async function handleDescribeTransactionsTable() {
  //   console.info('describeTransactionsTable');
  //   // let response = await authorizedResolvers.describeTable();
  //   let response = await authorizedHandler.describeTable();
  //   console.info('describeTransactionsTable', response);
  // }

  // async function toggleIsAuthenticated() {
  //   // let isAuthenticated = await publicResolvers.isAuthenticated();
  //   let isAuthenticated = await publicHandler.isAuthenticated();
  //   // await publicResolvers.setAuthenticated(!isAuthenticated);
  //   await publicHandler.setAuthenticated(!isAuthenticated);
  // }

  // async function handleDeleteUser() {
  //   let inputs = { id: values.userId };
  //   // console.log('deleteUser', 'inputs', inputs);
  //   let response = await authorizedResolvers.deleteUser(inputs);
  //   console.info('deleteUser', 'response', response);
  // }

  // async function handleTestUser() {
  //   console.log('handleTestUser');
  // }

  // function handleClick(event) {
  //   event.preventDefault();
  //   alert('I love breadcrumbs!!!', event);
  // }

  function handleUpdateForm() {
    props.history.push({
      pathname: '/dev/update-form',
      state: { textField: 'location.state.textField' },
    });
  }

  // async function handleMigrateSessions() {
  //   try {
  //     await authorizedHandler.migrateSessions();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // async function handleMigrateUsers() {
  //   try {
  //     let response = await authorizedHandler.migrateUsers();
  //     console.info('handleMigrateUsers', response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  async function handleSelect(event) {
    console.info('handleSelect', event.target);
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  async function handleMigrateRecords() {
    let input = { itemType: values.migrateRecords };
    let response = await authorizedHandler.migrateRecords(input);
    console.info('handleMigrateRecords', response);
  }

  async function handleListFiles() {
    console.info('handleListFiles');
    let response = await authorizedHandler.listFiles();
    console.info('handleListFiles', response);
  }

  async function handleSelectFile(event) {
    event.persist();
    console.info('handleSelectFile', event);
    console.info('handleSelectFile', event.target.files);
    file = event.target.files[0];
    // console.log('file', file);
    // let fileObject = URL.createObjectURL(file);
    // console.log('fileObject', fileObject.length, fileObject);
    setValues({ ...values, file: { name: file.name } });
  }

  async function uploadFile(event) {
    let { result } = event.target;
    console.log('uploadFile', result.length, result);
    let input = { name: file.name, data: result };
    let response = await authorizedHandler.uploadFile(input);
    console.log('uploadFile', 'response', response);

    // // data:image/svg+xml;base64,PCEtLSBodHRwczovL3JlYWxmYXZpY29uZ2VuZXJhdG==
    // let [ typePart, dataPart ] = response.data.split(';');
    // let type = typePart.split('data:')[1];
    // let [ encoding, data ] = dataPart.split(',');
    // console.log(response.name, type, encoding, data);

    // fetch(response.data)
    //   .then(res => res.blob())
    //   .then(blob => saveAs(blob, 'xxx.svg'));
  }

  async function handleUploadFile() {
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    // fileReader.onloadend = uploadFile;
    fileReader.onload = async function(event) {
      let { result } = event.target;
      console.log('uploadFile', result.length, result);
      let input = { name: file.name, data: result };
      let response = await authorizedHandler.uploadFile(input);
      console.log('uploadFile', 'response', response);
    };
  }

  // async function handleListItems() {
  //   // let input = { itemType: values.migrateRecords };
  //   // let response = await authorizedHandler.migrateRecords(input);
  //   let { listItems } = values;
  //   if (listItems === 'INVITATION')
  //     await authorizedHandler.listInvitations();
  //   // console.info('handleMigrateRecords', response);
  // }

  async function handleDownloadFile(event) {
    let input = { id: file.name };
    console.log('handleDownloadFile', 'input', input);
    let response = await authorizedHandler.downloadFile(input);
    console.log('handleDownloadFile', 'response', response);

    // data:image/svg+xml;base64,PCEtLSBodHRwczovL3JlYWxmYXZpY29uZ2VuZXJhdG==
    let [ typePart, dataPart ] = response.data.split(';');
    let type = typePart.split('data:')[1];
    let [ encoding, data ] = dataPart.split(',');
    console.log(response.name, type, encoding, data);

    fetch(response.data)
      .then(res => res.blob())
      .then(blob => saveAs(blob, file.name));
  }

  let fieldProps = { values };

  return(
    <Page id='dev-home' >

      <Heading>{'Dev Home'}</Heading>

      <FlexRow>
        <PrimaryButton title='Seed' onClick={() => handleSeed(props)} validated={true} />
        <PrimaryButton title='List Files' onClick={handleListFiles} validated={true} />
      </FlexRow>

      <FlexRow style={{ alignItems: 'center' }} >
        {file &&
        <p style={{ margin: theme.spacing(1) }} >{file?.name}</p>}
        <input
          id="contained-button-file"
          // accept="image/*"
          // className={classes.input}
          multiple
          type="file"
          onChange={handleSelectFile}
          hidden
        />
        <label htmlFor="contained-button-file" >
          <PrimaryButton component='span' title='Select File' />
        </label>
        {/* <PrimaryButton
          containerElement='label' // <-- Just add me!
          label='My Label'>
          title='Select File' onClick={() => handleSelectFile()} validated={true} />
          <input type="file" /> */}
        <PrimaryButton title='Upload File' onClick={handleUploadFile} disabled={!file} />
        <PrimaryButton title='Download File' onClick={handleDownloadFile} disabled={!file} />
      </FlexRow>

      {/* <div>{ulid()}</div> */}
      {/* <PrimaryButton title='Migrate Sessions' onClick={() => handleMigrateSessions()} validated={true} /> */}
      {/* <PrimaryButton title='Migrate Users' onClick={() => handleMigrateUsers()} validated={true} /> */}
      {/* <FlexRow>
        <SelectTextField name={'listItems'} label={''} items={recordTypes} handleChange={handleSelect} values={values} style={{ width: theme.spacing(24) }} />
        <PrimaryButton name='list-items-button' title='List Items' disabled={!values.listItems} onClick={() => handleListItems()} />
      </FlexRow> */}

      {/* <div style={{display: 'flex', flexDirection: 'row'}} > */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', flexWrap: 'wrap' }}>

        <DevSection>
          <SectionHeading title='Platform' />
          {/* <Online /> */}
          {/* <Authenticated />
          <PrimaryButton title='Toggle' onClick={() => toggleIsAuthenticated()} validated={true} loading={false} /> */}
          {/* {process.env.REACT_APP_STAGE === 'dev' &&
          <>
            <ApolloProvider client={publicClient} >
              <PingPublic />
            </ApolloProvider>
            <ApolloProvider client={authenticatedClient} >
              <PingAuthenticated />
            </ApolloProvider>
            <ApolloProvider client={authorizedClient} >
              <PingAuthorized />
            </ApolloProvider>
          </>} */}
          <ViewerAgent />
        </DevSection>

        <DevSection>
          <FlexColumn>
            <SelectTextField name={'migrateRecords'} label={''} items={recordTypes} handleChange={handleSelect} values={values} style={{ width: theme.spacing(24) }} />
            <PrimaryButton name='migrate-records-button' title='Migrate Records' disabled={!values.migrateRecords} onClick={() => handleMigrateRecords()} />
          </FlexColumn>
        </DevSection>

        <DevSection>
          <FlexColumn style={{ width: 250, margin: 0 }} >
            <TextField
              {...fieldProps}
              name='dateValue'
              label='Date (Value or String)'
              handleChange={handleChange}
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='dateToISOString'
              label='toISOString'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='dateGetTime'
              label='getTime'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='dateToUTCString'
              label='toUTCString'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='dateToLocaleString'
              label='toLocaleString'
              style={{ margin: 8 }}
            />
            <FlexRow style={{ justifyContent: 'center' }} >
              {/* <PrimaryButton name='date-convert-button' title='Convert Date' onClick={(event) => handleConvertDate(event)} /> */}
              <PrimaryButton
                name='date-convert-button'
                title='Convert Date'
                onClick={handleConvertDate}
              />
              {/* <PrimaryButton
                name='set-now-button'
                title='Now'
                onClick={() => setValues({ ...values, date: (new Date()).toLocaleString() })}
              /> */}
            </FlexRow>
          </FlexColumn>
        </DevSection>

        <DevSection>
          <FlexColumn style={{ width: 300, margin: 0 }} >
            <TextField
              {...fieldProps}
              name='dateOrUlid'
              label='Date or ULID'
              handleChange={handleChange}
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='ulid'
              label='ulid'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='ulidMilliseconds'
              label='Milliseconds'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='ulidLocaleString'
              label='Local Time'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='ulidTimePart'
              label='Time Part'
              style={{ margin: 8 }}
            />
            <StandardTextField
              {...fieldProps}
              name='ulidRandomPart'
              label='Random Part'
              style={{ margin: 8 }}
            />
            <FlexRow style={{ justifyContent: 'center' }} >
              {/* <PrimaryButton name='date-convert-button' title='Convert Date' onClick={(event) => handleConvertDate(event)} /> */}
              <PrimaryButton
                name='date-convert-button'
                title='Convert Date'
                onClick={handleConvertUlid}
              />
              {/* <PrimaryButton
                name='new-ulid-button'
                title='New'
                onClick={() => setValues({ ...values, id: ulid() })}
              /> */}
            </FlexRow>
          </FlexColumn>
        </DevSection>

        {/* <DevSection>
          <SectionHeading title='Public Pages' />
          <InLink title='Sign In' to='/sign-in' />
          <InLink title='Sign Up' to='/sign-up' />
          <InLink title='Sign Out' to='/sign-out' />
          <InLink title='Confirm Email' to='/confirm-email' />
          <InLink title='Reset Password' to='/reset-password' />
          <InLink title='Confirm Reset' to='/confirm-reset-password' />
          <InLink title='Change Email' to='/change-email' />
          <InLink title='Change Password' to='/change-password' />
          <InLink title='Invitation' to='/invitation' />
        </DevSection> */}

        {/* <DevSection>
          <SectionHeading title='Authenticated Pages' />
          <InLink title='User' to='/user' />
          <InLink title='Welcome' to='/user/welcome' />
          <InLink title='Account' to='/user/account' />
          <InLink title='Personal' to='/user/personal' />
          <InLink title='Security' to='/user/security' />
          <InLink title='Activity' to='/user/activity' />
          <InLink title='Invest' to='/invest' />
          <InLink title='Note Detail' to='/invest/note-detail' />
          <InLink title='Transact' to='/invest/transact' />
        </DevSection> */}

        {/* <DevSection>
          <SectionHeading title='Admin Pages' />
          <InLink title='Admin' to='/admin' />
          <InLink title='Create Cashflow' to='/admin/create-cashflow' />
          <InLink title='Create Invitation' to='/admin/create-invitation' />
          <InLink title='Create Loan' to='/admin/create-loan' />
          <InLink title='Manage Loans' to='/admin/manage-loans' />
          <InLink title='Loan Detail' to='/admin/loan-detail' />
          <InLink title='Create Note' to='/admin/create-note' />
          <InLink title='Manage Notes' to='/admin/manage-notes' />
          <InLink title='Note Detail' to='/admin/note-detail' />
          <InLink title='Manage Users' to='/admin/manage-users' />
          <InLink title='Manage Sessions' to='/admin/manage-sessions' />
          <InLink title='Set User Password' to='/admin/set-user-password' style={{whiteSpace: 'nowrap'}} />
        </DevSection> */}

        <DevSection>
          <SectionHeading title='Dev Pages' />
          {/* <InLink title='Dev' to='/dev' /> */}
          <InLink title='RDS' to='/dev/rds' />
          <InLink title='Components' to='/dev/components' />
          <InLink title='Cache' to='/dev/cache' />
          <InLink title='Fonts' to='/dev/fonts' />
          <InLink title='SVG' to='/dev/svg' />
          <InLink title='Test' to='/dev/test' />
          <InLink title='Transitions' to='/dev/transitions' />
          <InLink title='Example Form' to='/dev/example-form' />
          <InLink title='Example Page' to='/dev/example-page' />
          <InLink title='Headings' to='/dev/headings' />
        </DevSection>

        <DevSection>
          <SectionHeading title='Form' />
          <InLink title='Inputs' to='/dev/inputs-form' />
          <InLink title='Update' to='/dev/form/update' />
          <InLink title='Update2' to='/dev/form/update2' />
          <ButtonLink title='Update' variant={'body2'} onClick={handleUpdateForm} />
        </DevSection>

        {/* <DevSection>
          <SectionHeading title='Client Libs' />
          <InLink title='...' to='/dev' />
        </DevSection> */}

        {/* <DevSection>
          <SectionHeading title='RDS' />
          <PrimaryButton name='list-connections' title='List RDS Connections' onClick={() => handlelistConnections()} validated={true} />
          <PrimaryButton name='list-tables' title='List RDS Tables' onClick={() => handlelistTables()} validated={true} />
          <PrimaryButton name='migrate-transactions-table' title='Migrate Transactions Table' onClick={() => handleMigrateTransactionsTable()} validated={true} />
          <PrimaryButton name='describe-transactions-table' title='Describe Transactions Table' onClick={() => handleDescribeTransactionsTable()} validated={true} />
          <PrimaryButton name='list-transactions' title='List Transactions' onClick={() => handleListTransactions()} validated={true} />
        </DevSection> */}
        
        {/* <DevSection>
          <SectionHeading>{'Email'}</SectionHeading>
          <FlexRow style={{ alignItems: 'center' }} >
            <TextField
              select
              name='testEmailSelect'
              label="Test Email"
              values={values}
              handleChange={handleChange}
              style={{ margin: 0, width: theme.spacing(30) }}
            >
              {[
                { value: 'testMessage', label: 'testMessage' },
                { value: 'testInvitation', label: 'testInvitation' },
                { value: 'testResetPassword', label: 'testResetPassword' },
                { value: 'testAddEmail', label: 'testAddEmail' },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value} >{option.label}</MenuItem>
              ))}
            </TextField>
            <PrimaryButton name='test-email' title='Test Email' onClick={() => handleTestEmail()} validated={true} style={{whiteSpace: 'nowrap', width: 'auto', minWidth: 'fit-content'}} />
          </FlexRow>
        </DevSection> */}

        {/* <DevSection>
          <SectionHeading>{'Error'}</SectionHeading>
          <FlexRow style={{ alignItems: 'center' }} >
            <TextField
              select
              name='testErrorSelect'
              label="Test Error"
              values={values}
              handleChange={handleChange}
              style={{ margin: 0, width: theme.spacing(30) }}
            >
              {[
                { value: 'CUSTOM_AUTH_ERROR', label: 'CustomAuthError' },
                { value: 'ERROR', label: 'Error' },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value} >{option.label}</MenuItem>
              ))}
            </TextField>
            <PrimaryButton name='test-error' title='Test Error' onClick={() => handleTestError()} validated={true} style={{whiteSpace: 'nowrap', width: 'auto', minWidth: 'fit-content'}} />
          </FlexRow>
        </DevSection> */}

        {/* <DevSection>
          <SectionHeading title='User' />
          <FlexRow style={{ alignItems: 'center' }} >
            <TextField
              {...fieldProps}
              name='userId'
              type='string'
              label='User Id'
              required={true}
              style={{ margin: 0 }}
            />
            <PrimaryButton name='delete-user' title='Delete User' onClick={() => handleDeleteUser()} validated={true} />
          </FlexRow>
        </DevSection> */}

      </div>

      <DisplayState title='state' state={{ state, values, file }} open={true} />
    </Page>
  );
}

export default Dev;

Dev.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};

