import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from '@material-ui/core/styles';
import { Page, Heading } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';

// const localStyles = {
//   ...styles,
// };

// const useStyles = makeStyles(localStyles);

let isMounted = false;

export function Blog() {
  const [ state, setState ] = useState({ isLoading: true });
  // let classes = useStyles();

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) { // only do this on the initial page load
        window.scrollTo(0, 0);
        if (isMounted)
            setState({ ...state, isLoading: false });
      }
    })();
    
    return () => { isMounted = false; };
  }, []);

  return (
    <Page id='blog' >
      <Heading>{'Blog'}</Heading>
      Coming soon...
      {/* - Serverless Security
      - Managed Services
      - Securities Law
      - Payment Dependent Notes
      - The State of Software Development -- less depth but MUCH more breadth...
      - Lockdowns are a regressive tax!
      */}
    </Page>
  );
}

// Blog.propTypes = {
//   system: PropTypes.shape(),
// };
