// import * as yup from "yup";
import * as yup from 'yup/es';

const noteShape = {
  id: yup.string().length(26).required('Id is required'),
  itemName: yup.string().max(24).required('Item Name is required'),
  createdAt: yup.string().length(24),
  createdBy: yup.string().length(26),
  updatedAt: yup.string().length(24),
  updatedBy: yup.string().length(26),
  description: yup.string().trim('No leading or trailing spaces allowed').max(256).required('Description is required'),
  coupon: yup.number().required('Coupon is required'),
  managementRate: yup.number().required('Management Fee is required'),
  originalAmount: yup.number().required('Original Amount is required'),
  availableAmount: yup.number().required('Current Amount is required'),
  minimumAmount: yup.number().required('Minimum Amount is required'),
  assetType: yup.string().max(24).required('Asset Type is required'),
  referenceItemId: yup.string().length(26).required('Ref Item Id is required'),
  referenceItemType: yup.string().max(24).required('Ref Item Type is required'),
};

export const noteSchema = yup.object().shape(noteShape);
