import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AuthenticatedHandler } from '@org/client-graphql';
import {
  SmallLinkButton, // InLink,
  PrimaryButton, BackdropLoader, ButtonBar
} from '@org/client-components-core';
import {
  Page, FlexRow,
  Heading, SubHeading, Section,
  LabelledFieldSpan,
  FlexColumn,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { percentage, dollarAmount } from '@org/common-formatters';
import { CashflowTable } from './CashflowTable';
import theme from '@org/client-libs-themes';

let authenticatedHandler;

let isMounted = false;

export async function loadInvestmentDetailData(props) {
  // console.log('loadInvestmentDetailData', props);
  // URGENT Create loader functions like this to allow the page being navigated away from
  // to pass all the data we need.
  let { authenticatedHandler, note } = props;

  let loan = note.referenceItem;

  let [ noteCashflows, loanCashflows ] = await Promise.all([
    authenticatedHandler.listCashflows({ itemType: note.itemType, id: note.id }),
    authenticatedHandler.listCashflows({ itemType: loan.itemType, id: loan.id }),
  ]);

  // console.log('loadInvetmentDetailData', noteCashflows, loanCashflows);

  return { noteCashflows, loanCashflows };
}

let noteCashflows = [];
let loanCashflows = [];

export function InvestmentDetail(props) {
  let [ state, setState ] = useState({ isLoading: true });
  let { note } = props.location?.state;
  let loan = note.referenceItem;
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    window.scrollTo(0, 0);
    isMounted = true;
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

    (async () => {
      let response = await Promise.all([
        authenticatedHandler.listCashflows({ itemType: note.itemType, id: note.id }),
        authenticatedHandler.listCashflows({ itemType: loan.itemType, id: loan.id }),
      ]);
      // console.log(response);
      noteCashflows = response[0];
      loanCashflows = response[1];
      // console.log(noteCashflows, loanCashflows);

      // console.log('useEffect', isMounted, state.isLoading);
      if (isMounted)
        // await setState({ ...state, isLoading: false });
        setState({ ...state, isLoading: false });
      // console.log('useEffect', isMounted, state.isLoading);
    })([]);

    return () => { isMounted = false; };
  }, []);

  async function handleSubmit() {
    // console.log('InvestmentDetail', 'handleSubmit', isMounted);
    if (isMounted)
        setState({ ...state, isLoading: true });

    try {
      let transactions = await authenticatedHandler.listTransactions();
      // console.log(transactions);
      let accountBalance = transactions.length ? transactions[transactions.length-1]?.balance : 0;
      // console.log('InvestmentDetail', accountBalance, note);

      props.history.push({
        pathname: '/invest/enter-trade',
        state: {
          accountBalance,
          note: {
            ...note,
            amount: note.minimumAmount
          },
        },
      });
    } catch (error) {
      console.error('InvestmentDetail', 'handleSubmit', error);
      if (isMounted)
          setState({ ...state, isLoading: false, formError: error });
    }
  }

  let coupon =  percentage({ value: (note.coupon ? note.coupon : 0) / 100, places: 2 });
  let managementRate =  percentage({ value: (note.managementRate ? note.managementRate : 0) / 100, places: 2 });
  let originalBalance = dollarAmount.format(note.originalBalance ? note.originalBalance : 0);
  let availableBalance = dollarAmount.format(note.availableBalance ? note.availableBalance : 0);
  let minimumAmount = dollarAmount.format(note.minimumAmount ? note.minimumAmount : 0);

  let assetType = "";
  if (loan.assetType === 'REAL_ESTATE') {
    if (loan.assetSubType === 'RESIDENTIAL')
      assetType = 'Residential Real Estate';
    else if (loan.assetSubType === 'COMMERCIAL')
      assetType = 'Commercial Real Estate';
    else if (loan.assetSubType === 'MULTIFAMILY')
      assetType = 'Multifamily Real Estate';
  }
  if (loan.itemType === 'LOAN')
    assetType = assetType + ' Loan';

  let enabled = note.availableBalance > 0 && !state.isLoading;

  // console.log('pre-render', isMounted, state.isLoading, noteCashflows, loanCashflows);

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    <Page id='investment-detail' >
      <>

      <FlexRow>
        {/* <InLink to='/invest' title='Back' /> */}
        <SmallLinkButton {...props} title='Back' to='/invest' />
      </FlexRow>
      
      <Heading>{'Note Details'}</Heading>

      {!note.availableBalance &&
      <FlexRow>
        <SubHeading>{'No Longer Available'}</SubHeading>
      </FlexRow>}

      <Section>
        <LabelledFieldSpan title='Name' value={note.itemName} />
        <LabelledFieldSpan title='Description' value={note.description} />
        <FlexRow>
          <FlexColumn>
            <LabelledFieldSpan title='Original Balance' value={originalBalance} />
            <LabelledFieldSpan title='Amount Available' value={availableBalance} />
            <LabelledFieldSpan title='Launch Date' value={dayjs(note.launchDate).format('M/D/YYYY')} />
            {/* <LabelledFieldSpan title='Next Payment Date' value={dayjs(note.nextPaymentDate).format('M/D/YYYY')} /> */}
          </FlexColumn>
          <FlexColumn>
            <LabelledFieldSpan title='Coupon' value={coupon} />
            <LabelledFieldSpan title='Management Fee' value={managementRate} />
            <LabelledFieldSpan title='Minimum Investment' value={minimumAmount} />
          </FlexColumn>
        </FlexRow>
      </Section>

      {!isXs &&
      <Section>
        <SubHeading>{'Note Cashflows'}</SubHeading>
        <CashflowTable rows={noteCashflows ? noteCashflows : []} />
      </Section>}

      <Section>
        <SubHeading>{'Reference Asset Details'}</SubHeading>
        <FlexRow>
          <FlexColumn>
            <LabelledFieldSpan title='Asset Type' value={assetType} />
            <LabelledFieldSpan title='Original Balance' value={dollarAmount.format(loan.originalBalance)} />
            <LabelledFieldSpan title='Current Balance' value={dollarAmount.format(loan.currentBalance)} />
            <LabelledFieldSpan title='Origination Date' value={dayjs(loan.originationDate).format('M/D/YYYY')} />
            <LabelledFieldSpan title='First Payment Date' value={dayjs(loan.firstPaymentDate).format('M/D/YYYY')} />
            <LabelledFieldSpan title='Next Payment Date' value={dayjs(loan.nextPaymentDate).format('M/D/YYYY')} />
          </FlexColumn>
          <FlexColumn>
            <LabelledFieldSpan title='LTV Ratio' value={percentage({ value: loan.appraisalValue/loan.originalBalance, places: 2 })} />
            <LabelledFieldSpan title='Interest Rate' value={percentage({ value: loan.interestRate/100, places: 2 })} />
            <LabelledFieldSpan title='Servicing Fee' value={percentage({ value: loan.servicingRate/100, places: 2 })} />
            <LabelledFieldSpan title='Net Rate' value={percentage({ value: loan.netRate/100, places: 2 })} />
            <LabelledFieldSpan title='Original Term' value={loan.originalTerm + ' months'} />
            <LabelledFieldSpan title='Current Term' value={loan.currentTerm + ' months'} />
          </FlexColumn>
        </FlexRow>
      </Section>

      {!isXs &&
      <Section>
        <SubHeading>{'Reference Asset Cashflows'}</SubHeading>
        <CashflowTable rows={loanCashflows ? loanCashflows : []} />
      </Section>}

      {/* <Section>
        <SubHeading>{'Projected Cashflows'}</SubHeading>
        <div>{'Coming soon...'}</div>
      </Section> */}

        {/* <FlexRow>
          <FlexColumn style={{flexGrow: 1}} >a</FlexColumn>
          <FlexColumn style={{flexGrow: 1}} >b</FlexColumn>
          <FlexColumn style={{flexGrow: 1}} >c</FlexColumn>
        </FlexRow> */}

        {/* {values && values.loan &&
        <>
        <FieldSpan title='Ref Asset' value={'LOAN'} />
        <FieldSpan title='Asset Type' value={values.loan.assetType} />
        <FieldSpan title='Asset Sub Type' value={values.loan.assetSubType} />
        <FieldSpan title='Interest Rate' value={percentage({ value: values.loan.interestRate / 100, places: 2 })} />
        <FieldSpan title='Ori Term' value={values.loan.originalTerm} />
        <FieldSpan title='First Payment Date' value={values.loan.firstPaymentOn} />
        </>} */}
      <ButtonBar>
        <PrimaryButton title='Buy Now' onClick={handleSubmit} disabled={!enabled} />
      </ButtonBar>
      </>
    </Page>

    <DisplayState title='state' state={{ state, note, noteCashflows, loanCashflows }} open={true} />
    </>
  );
}

InvestmentDetail.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  // stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
};
