import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// import { makeStyles } from '@material-ui/core/styles';
// import theme from '@org/client-libs-themes';
// import { styles } from '@org/client-libs-styles';
import { PrimaryButton, InLink, SelectTextField, Breadcrumbs } from '@org/client-components-core';
import {
  Page, Heading, FlexRow,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
// import { authenticatedResolvers, authorizedResolvers, AuthorizedHandler } from '@org/client-graphql';
import { authorizedResolvers, AuthorizedHandler, AuthenticatedHandler } from '@org/client-graphql';

// const useStyles = makeStyles(localStyles);

let authenticatedHandler;

const deposit = {
  eventDate: dayjs().format('YYYY-MM-DD'),
  amount: 100000.00,
  status: '',
};
const withdrawal = {
  eventDate: dayjs().format('YYYY-MM-DD'),
  amount: 2500.00,
  status: '',
};
const trade = {
  eventDate: dayjs().format('YYYY-MM-DD'),
  amount: 2500.00,
  referenceType: 'NOTE',
  referenceId: '01F0VC0TG8YAQZH532PCQDG9S7',
  // non-RDS values
  tradeType: 'BUY',
  price: 100.00,
  status: '',
};

const tableNames = [
  { label: 'Transactions', value: 'TRANSACTIONS' },
  { label: 'Activity', value: 'ACTIVITY' },
  { label: 'Cashflows', value: 'CASHFLOWS' },
];

const transactionTypes = [
  { label: 'Deposit', value: 'DEPOSIT' },
  { label: 'Withdrawal', value: 'WITHDRAWAL' },
  { label: 'Trade', value: 'TRADE' },
];

const extensionNames = [
  { label: 'UUID', value: 'uuid-ossp' }
];

let isMounted = false;
let authorizedHandler;

let initialValues = { tableName: "", itemType: "", extension: "" };

export function Rds(props) {
  // let classes = useStyles();
  let [ state, setState ] = useState({ isLoading: true, values: initialValues });

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

    (async () => {
      if (state.isLoading) {
        window.scrollTo(0, 0);
        if (isMounted)
            setState({ ...state, isLoading: false });
      }
    })();
    
    return () => { isMounted = false; };
  }, []);

  async function handleCloseConnections() {
    // let response = await authorizedResolvers.closeConnections();
    let response = await authorizedHandler.closeConnections();
    console.info('handleCloseConnections', response);
  }

  async function handlelistConnections() {
    // let response = await authorizedResolvers.listConnections();
    let response = await authorizedHandler.listConnections();
    console.info('listConnections', response);
  }

  async function handlelistTables() {
    // let response = await authorizedResolvers.listTables();
    let response = await authorizedHandler.listTables();
    console.info('listTables', response);
  }

  async function handleListTransactions() {
    // let response = await authorizedResolvers.listTransactions();
    let response = await authorizedHandler.listTransactions();
    console.info('listTransactions', response);
  }

  async function handleMigrateTransactionsTable() {
    console.info('migrateTransactionsTable');
    // let response = await authorizedResolvers.migrateTransactionsTable();
    let response = await authorizedHandler.migrateTransactionsTable();
    console.info('migrateTransactionsTable', response);
  }

  async function handleDescribeTransactionsTable() {
    console.info('describeTransactionsTable');
    // let response = await authorizedResolvers.describeTable();
    let response = await authorizedHandler.describeTable();
    console.info('describeTransactionsTable', response);
  }

  async function handleMigrateCashflowsTable() {
    console.info('handleMigrateCashflowsTable');
    let response = await authorizedResolvers.migrateCashflowsTable();
    console.info('handleMigrateCashflowsTable', response);
  }

  async function handleMigrateTable() {
    // console.info('handleMigrateCashflowsTable');

    let response;
    if (state.values.tableName === 'TRANSACTIONS')
      response = await authorizedHandler.migrateTransactionsTable();
    else if (state.values.tableName === 'ACTIVITY')
      response = await authorizedHandler.migrateActivityTable();
    else if (state.values.tableName === 'CASHFLOWS')
      response = await authorizedHandler.migrateCashflowsTable();

    console.info('handleMigrateTable', state.values.tableName, response);
  }

  async function handleDescribeTable() {
    // console.info('handleMigrateCashflowsTable');

    let response;
    // if (state.values.tableName === 'TRANSACTIONS')
    //   response = await authorizedResolvers.describeTransactionsTable();
    // else if (state.values.tableName === 'ACTIVITY')
    //   response = await authorizedResolvers.describeActivityTable();
    // else if (state.values.tableName === 'CASHFLOWS')
    //   response = await authorizedResolvers.describeCashglowsTable();
    response = await authorizedResolvers.describeTable(state.values.tableName);

    console.info('handleDescribeTable', state.values.tableName, response);
  }

  async function handleExtension() {
    let input = { extension: state.values.extension };
    // let response = await authorizedResolvers.createExtension(input);
    let response = await authorizedHandler.createExtension(input);
    console.info('handleExtensions', response);
  }

  async function handleCreateItem() {
    let response;

    if (state.values.itemType === 'DEPOSIT') {
      // response = await authorizedResolvers.createDeposit(deposit);
      response = await authorizedHandler.createDeposit(deposit);
    } else if (state.values.itemType === 'WITHDRAWAL') {
      // response = await authorizedResolvers.createWithdrawal(withdrawal);
      response = await authorizedHandler.createWithdrawal(withdrawal);
    } else if (state.values.itemType === 'TRADE') {
      // response = await authenticatedResolvers.createTrade(trade);
      response = await authenticatedHandler.createTrade(trade);
    }
    console.info('handleCreateItem', state.values.itemType, response);
  }

  async function handleListItems() {
    let response;
    if (state.values.tableName === 'TRANSACTIONS')
      // response = await authorizedResolvers.listTransactions(state.values.tableName);
      // response = await authorizedResolvers.listTransactions();
      response = await authorizedHandler.listTransactions();
    else if (state.values.tableName === 'ACTIVITY')
      // response = await authorizedResolvers.listActivity();
      response = await authorizedHandler.listActivity();
    else if (state.values.tableName === 'CASHFLOWS')
      // response = await authorizedResolvers.listCashflows();
      response = await authorizedHandler.listCashflows();
    console.info('handleListItems', state.values.tableName, response);
  }

  async function handleTableName(event) {
    console.info('handleTableName', event.target);
    setState({ ...state, values: { ...state.values, tableName: event.target.value } });
  }

  async function handleItemType(event) {
    console.info('handleTableName', event.target);
    setState({ ...state, values: { ...state.values, itemType: event.target.value } });
  }

  async function handleSelect(event) {
    console.info('handleSelect', event.target);
    setState({ ...state, values: { ...state.values, [event.target.name]: event.target.value } });
  }

  let tableButtonsEnabled = state.values.tableName;

  let itemTypes = [];
  if (state.values.tableName === 'TRANSACTIONS')
    itemTypes = transactionTypes;

  return(
      <Page id='dev-rds'>

        <Breadcrumbs>
          <InLink color='inherit' title='Home' to='/' />
          <InLink color='inherit' title='Dev' to='/dev' />
          {/* <Typography color='textPrimary' >Components</Typography> */}
        </Breadcrumbs>

        <Heading>{'RDS'}</Heading>

        <FlexRow>
          <PrimaryButton name='list-tables' title='List Tables' onClick={() => handlelistTables()} validated={true} />
          <PrimaryButton name='list-connections' title='List Connections' onClick={() => handlelistConnections()} validated={true} />
          <PrimaryButton name='clos-connections' title='Close Connections' onClick={() => handleCloseConnections()} validated={true} />
        </FlexRow>

        <FlexRow>
          <SelectTextField name={'extension'} label={'Create Extensions'} items={extensionNames} handleChange={handleSelect} values={state.values} />
          <PrimaryButton name='extension-button' title='Add Extension' disabled={state.values.extension === ""} onClick={() => handleExtension()} />
        </FlexRow>

        {/* <FlexRow>
          <PrimaryButton name='migrate-transactions-table' title='Migrate Transactions Table' onClick={() => handleMigrateTransactionsTable()} validated={true} />
          <PrimaryButton name='describe-transactions-table' title='Describe Transactions Table' onClick={() => handleDescribeTransactionsTable()} validated={true} />
          <PrimaryButton name='list-transactions' title='List Transactions' onClick={() => handleListTransactions()} validated={true} />
        </FlexRow> */}

        {/* <FlexRow>
          <PrimaryButton name='migrate-cashflows-table' title='Migrate Cashflows Table' onClick={() => handleMigrateCashflowsTable()} validated={true} />
        </FlexRow> */}

        <FlexRow>
          <SelectTextField name={'tableName'} label={'Table Name'} items={tableNames} handleChange={handleTableName} values={state.values} />
          <PrimaryButton name='migrate-table-button' title='Migrate Table' disabled={!tableButtonsEnabled} onClick={() => handleMigrateTable()} validated={true} />
          <PrimaryButton name='describe-table-button' title='Describe Table' disabled={!tableButtonsEnabled} onClick={() => handleDescribeTable()} validated={true} />
          <PrimaryButton name='list-items-button' title='List Items' disabled={!tableButtonsEnabled} onClick={() => handleListItems()} validated={true} />
        </FlexRow>

        <FlexRow>
          <SelectTextField name={'itemType'} label={'Item Type'} items={itemTypes} handleChange={handleItemType} values={state.values} disabled={state.values.tableName === ""} />
          <PrimaryButton name='create-item-button' title='Create Item' disabled={state.values.tableName === ""} onClick={() => handleCreateItem()} validated={true} />
        </FlexRow>

        <DisplayState title='state' state={{ state }} open={true} />
    </Page>

    //   </div>
    // </ApolloProvider>
  );
}

export default Rds;

Rds.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
};
