import { Handler } from '@org/client-libs-classes';
import { authenticatedClient } from './client';
import { authenticatedSchema } from './schema';

export class AuthenticatedHandler extends Handler {
  constructor(props) {
    super({ ...props, client: authenticatedClient });
    this.schema = authenticatedSchema;
  }

  async changeEmail(input) {
    let params = {
      mutation: this.schema.CHANGE_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async changePassword(input) {
    let params = {
      mutation: this.schema.CHANGE_PASSWORD,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async confirmAddEmail(input) {
    let params = {
      mutation: this.schema.CONFIRM_ADD_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createTrade(input) {
    let params = {
      mutation: this.schema.CREATE_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createWithdrawal(input) {
    let params = {
      mutation: this.schema.CREATE_WITHDRAWAL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async downloadFile(input) {
    let params = {
      query: this.schema.DOWNLOAD_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getAuth(input) {
    let params = {
      query: this.schema.GET_AUTH,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getLoan(input) {
    let params = {
      query: this.schema.GET_LOAN,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getMe(input) {
    let params = {
      query: this.schema.GET_ME,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getNote(input) {
    let params = {
      query: this.schema.GET_NOTE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getSession(input) {
    let params = {
      query: this.schema.GET_SESSION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getTrade(input) {
    let params = {
      query: this.schema.GET_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async initiateAddEmail(input) {
    let params = {
      query: this.schema.INITIATE_ADD_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listCashflows(input) {
    let params = {
      query: this.schema.LIST_CASHFLOWS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listEmails(input) {
    let params = {
      query: this.schema.LIST_EMAILS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listFiles(input) {
    let params = {
      query: this.schema.LIST_FILES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listLoans(input) {
    let params = {
      query: this.schema.LIST_LOANS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listNotes(input) {
    let params = {
      query: this.schema.LIST_NOTES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listTrades(input) {
    let params = {
      query: this.schema.LIST_TRADES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listTransactions(input) {
    let params = {
      query: this.schema.LIST_TRANSACTIONS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async resendAddEmail(input) {
    let params = {
      query: this.schema.RESEND_ADD_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async signOut(input) {
    let params = {
      mutation: this.schema.SIGN_OUT,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async updateMe(input) {
    let params = {
      mutation: this.schema.UPDATE_ME,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async uploadFile(input) { // Do not copy, data response is not typical
    let params = {
      mutation: this.schema.UPLOAD_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async watchListNotes() {
    let params = {
      query: this.schema.LIST_NOTES,
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    };
    return this.watchQuery(params);
  }
}

// for e2e offline tests
export const authenticatedHandler = new AuthenticatedHandler({ location: {}, history: {} });
