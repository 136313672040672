import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Heading, FormMessage, FormError } from '@org/client-components-custom';
import { TextField, EmailField, PrimaryButton } from '@org/client-components-core';
import { styles } from '@org/client-libs-styles';
// import { publicResolvers } from '@org/client-graphql';
import { InvitationVerified, InvitationNotVerified } from './index.js';
import { PublicHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

let publicHandler;

let response;

export function EnterManually(props) {
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  // useEffect(() => {
  //   console.log('Here2');
  //   window.scrollTo(0, 0);
  //   document.querySelector('body').scrollTo(0,0);
  // }, []);

  async function handleSubmit(event, form) {
    // console.log('EnterManually', 'handleSubmit', event, form);

    try {
      let input = {
        email: form.values.email,
        confirmCode: form.values.confirmCode,
      };
      // console.log(input);
      // response = await publicResolvers.verifyInvitation(input);
      response = await publicHandler.verifyInvitation(input);
      // console.log('EnterManually', 'handleSubmit', response);

      // if (response.success)
      //   props.setState({ ...props.state, data: { ...props.state.data, ...response.data }, isLoading: false });
      props.setState({ ...props.state, isLoading: false });

      // if (response.error)
      //   throw response.error;

    } catch (error) {
      props.setState({ ...props.state, isLoading: false, error });
    }
  }
  
  let enabled = (props.isValidated && !props.state.isSubmitting);

  // if we haven't tried to verifyInvitation yet
  if (!response)
    return(
      <>
      <Heading>{'Enter Invitation'}</Heading>

      <Grid style={{width: '100%'}} >

        {props.state.formError &&
        <Grid item >
          <FormError message={props.state.formError} />
        </Grid>}

        {props.state.formMessage &&
        <Grid item >
          <FormMessage message={props.state.formMessage} />
        </Grid>}

        {/* // TODO Replace with above */}
        <Grid item >
          <FormMessage message='Please enter your email address and confirm code' />
        </Grid>

        <Grid item >
          <EmailField {...props.fieldProps} name='email' required={true} />
        </Grid>

        <Grid item >
          <TextField {...props.fieldProps} name='confirmCode' label='Confirm Code' required={true} />
        </Grid>

        <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
          <PrimaryButton {...props.buttonProps} name='submit' title='Submit' onClick={(event) => handleSubmit(event, props)} disabled={!enabled} />
        </Grid>

      </Grid>
      </>
    );

  // if (response.success)
  if (response) // response = boolean
    return (
      <InvitationVerified {...props} />
    );
  else
    return (
      <InvitationNotVerified {...props} />
    );
}

EnterManually.propTypes = {
  state: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
};
