// import * as yup from "yup";
import * as yup from 'yup/es';

export const transactionShape = {
  itemId: yup.string().length(22).required('Item ID is required'),
  itemType: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Type is required'),
  // itemName: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Name is required'),
  eventDate: yup.string(),
  amount: yup.number().positive().required('Trade Amount is required'),
  description: yup.string(),
  referenceType: yup.string(),
  referenceId: yup.string(),
  tradeType: yup.string(),
  price: yup.number(),
  factor: yup.number(),
  // originalAmount: yup.number().positive().moreThan(1).required('Original Amount is required'),
  // currentAmount: yup.number().positive().required('Current Amount is required'),
  // availableAmount: yup.number().positive().required('Available Amount is required'),
  investedAmount: yup.number().positive().required('Invested Amount is required'),
  // minimumAmount: yup.number().positive().moreThan(5000),
  status: yup.string(),
};

export const transactionSchema = yup.object().shape(transactionShape);
