import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
  Form, InLink, Breadcrumbs,
  ButtonBar, Button, ButtonLink,
  TextField, EmailField, PasswordField, NumberField, PercentField, AmountField,
  SelectTextField, DatePicker, Checkbox,
} from '@org/client-components-core';
import {
  FormLayout, FormMessage, FormError,
  Heading,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { exampleSchema } from '../schemas';
import { styles } from '@org/client-libs-styles';

let items = [
  { label: 'Select A', value: 'A' },
  { label: 'Select B', value: 'B' },
  { label: 'Select C', value: 'C' },
];

/**
 * Use `defaultValues` for initial values in the inputs.
 * These values are only used in the initial render, prior to componentDidMount().
 * These values are just placeholders and will not be marked as touched or validated.
 */
let defaultValues = {
  textField: "",
  emailField: "",
  passwordField: "",
  numberField: 0,
  percentField: 0,
  amountField: 0,
  selectTextField: "",
  datePickerField: dayjs().format('YYYY-MM-DD'),
  checkboxField: false,
};

/**
 * Use `stateValues` for any values passed in from `props.location.state` or retrieved from data requests.
 * These values will be assumed to be valid and be marked touched and validated.
 */
let stateValues = {};

const useStyles = makeStyles(styles);

export function InputsForm(props) {
  let isMounted = true; // keep this here so that setState can be called anywhere in the function.
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formMessage: 'This is a blank form which demonstrates all the core inputs', formError: null });
  let classes = useStyles();

  useEffect(() => {
     window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: true });
    
    return () => { isMounted = false; };
  }, []);

  // Button(event.target: <span class="MuiButton-label">Button</span>)
  // SubmitButton(event, props)
  async function handleSubmit(event, form) {
    event.preventDefault();
    console.info('handleSubmit', event, form);
    console.info('handleSubmit', event.target);
    alert(`I'm a button!`);
  }

  // TextField(event.target: { type: 'text', name })
  // SelectTextField(event.target: { name, value }, undefined)
  // DatePicker(Date object, name)
  // Checkbox(event.target: { type: 'checkbox', name, checked })
  // function handleChange(event, form) {
  //   console.info('handleChange', event, form);
  //   console.info('handleChange', event.target);
  // }

  return (
    <>
    <Form id='example-form' defaultValues={defaultValues} stateValues={stateValues} validationSchema={exampleSchema} {...props} >
      {props => {
      {/* let enabled = (props.isValidated && !state.isSubmitting); */}
      let { fieldProps, passwordProps, buttonProps, stateProps } = props;
      {/* props.handleChange = handleChange; */}
      return (
        <>
        <FormLayout>

          <Breadcrumbs>
            <InLink color='inherit' title='Home' to='/' />
            <InLink color='inherit' title='Dev' to='/dev' />
          </Breadcrumbs>

          <Heading>{'Example Form'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField {...fieldProps} name='textField' label='TextField' />
            </Grid>

            <Grid item >
              <EmailField {...fieldProps} name='emailField' />
            </Grid>

            <Grid item >
              <PasswordField {...passwordProps} name='passwordField' />
            </Grid>
            
            <Grid item >
              <NumberField {...fieldProps} name='numberField' label='Number' />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='amountField' label='Amount' />
            </Grid>
            
            <Grid item >
              <PercentField {...fieldProps} name='percentField' label='Percent' />
            </Grid>

            <Grid item >
              <SelectTextField {...fieldProps} name='selectTextField' label='Select' items={items} />
            </Grid>

            <Grid item >
              <DatePicker {...fieldProps} name='datePickerField' label='Date Picker' />
            </Grid>

            <Grid item >
              <Checkbox {...props.checkboxProps} name='checkboxField' label='I am a checkbox with a wrapped line... ....... ...... ..... ... ..... .... .... ... .... .. .' />
            </Grid>

            <Grid item >
              <ButtonLink name='buttonLink' title='ButtonLink' variant={'body2'} onClick={handleSubmit} />
            </Grid>

            <Grid>
              <Link name='RouterLink' variant='body2' component={RouterLink} to='/dev' >
                {'RouterLink'}
              </Link>
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <ButtonBar>
                <Button {...buttonProps} name='button' title='Button' onClick={handleSubmit} />
                <Button {...buttonProps} name='submitButton' title='SubmitButton'
                onClick={(event) => handleSubmit(event, props)} />
              </ButtonBar>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={stateProps} open={true} />
        </>
        );
      }}
    </Form>
    </>
  );
}

InputsForm.propTypes = {
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  passwordProps: PropTypes.shape(),
  checkboxProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
};
