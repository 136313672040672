import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import { AuthorizedHandler } from '@org/client-graphql';
import { isDefined } from '@org/common-tools';

export const columns = [
  // { field: 'id', headerName: 'ID', width: 10 },
  { field: 'originationDate', headerName: 'Origination Date', width: 170 },
  { field: 'borrowerName', headerName: 'Borrower Name', width: 220 },
  { field: 'originalBalance', headerName: 'Ori Balance', width: 150, type: 'number' },
  { field: 'interestRate', headerName: 'Int Rate', width: 130, type: 'number' },
  { field: 'creationDate', headerName: 'Creation Date', width: 170, type: 'date' },
];

export async function getRows(props) {
  let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  try {
    let loans = await authorizedHandler.listLoans();

    loans.forEach(loan => {
      loan.creationDate = dayjs(decodeTime(loan.id)).format('YY-MM-DD hh:mm a');
      loan.originationDate = dayjs(loan.originationDate).format('YYYY-MM-DD');
    });

    return loans;
  } catch (error) {
    console.error(error.message);
    return [];
  }
}

export async function handleRowClick(event, props) {
  console.log('Loans', event, props);
  // alert(JSON.stringify(event.row, null, ' '));

  let data = event.row;

  if (isDefined(data.createdBy)) {
    console.warn(`'Loans: 'createdBy' found in row data. Please remove!`);
    delete data.createdBy;
    console.log('Loans', 'data:', data);
  }

  if (isDefined(data.creationDate)) {
    console.warn(`'Loans: 'creationDate' found in row data. Please remove!`);
    delete data.creationDate;
    console.log('Loans', 'data:', data);
  }

  props.history.push({ pathname: '/admin/create-loan', state: { data } });
}
