import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { FormUp, PrimaryButton, TextField, EmailField, BackdropLoader } from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
// import { handleError } from '@org/common-errors';
import { styles } from '@org/client-libs-styles';
// import { authenticatedResolvers } from '@org/client-graphql';
import { AuthenticatedHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

let defaultValues = {
    email: "",
    confirmCode: "",
  };

// let stateValues = {};

let isMounted = false;
let authenticatedHandler;

export function ConfirmAddEmail(props) {
  console.log('ConfirmAddEmail', props);
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, isCompleted: false, formError: null, formMessage: 'Please check your email and enter the code here' });
  const [ form, setForm ] = useState({
    // values: calculateValues({ ...defaultValues, ...props.location?.state }),
    values: defaultValues,
    touched: {},
    validated: {},
    errors: {},
  });

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  let classes = useStyles();

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    (async () => {
      if (isMounted) {
        // if (props?.location?.state)
        //   stateValues = props.location.state;
        setState({ ...state, isLoading: false });
        console.log('ConfirmAddEmail', 'about to update form values', form.values);
        if (props?.location?.state)
          setForm({
            ...form,
            values: { ...form.values, email: props?.location?.state?.email },
            touched: { email: true },
            validated: { email: true },
          });
      }
    })();

    return () => { isMounted = false; };
  }, []);

  async function handleResendCode(event) {
    event.preventDefault();
    console.log('handleResendCode', event, form);
    let { email } = form.values;

    try {
      if (!form.validated['email'])
        throw new Error('Please enter a valid email address');

      let input = { email };
      // let response = await authenticatedResolvers.resendAddEmail(input);
      // let response = await authenticatedHandler.resendAddEmail(input);
      await authenticatedHandler.resendAddEmail(input);
      // if (response.success)
      if (state.formMessage === 'New code sent')
        setState({ ...state, formMessage: 'Don’t forget to check your spam folder', formError: null });
      else
        setState({ ...state, formMessage: 'New code sent', formError: null });
      // if (response.error)
      //   throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, formError: error });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });

    try {
      let { email, confirmCode } = form.values;
      let input = { email, confirmCode };
      // let response = await authenticatedResolvers.confirmAddEmail(input);
      await authenticatedHandler.confirmAddEmail(input);

      // if (response.success) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Your new email has been successfully added', formError: null});
      // } else if (response.error)
      //   throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  console.log('ConfirmAddEmail', 'about to render...');

  return (
    <>
    {state.isLoading &&
    <BackdropLoader />}

    {/* <Form defaultValues={defaultValues} stateValues={stateValues} validationSchema={authSchema} {...props} > */}
    {!state.isLoading &&
    <FormUp id='confirm-add-email' {...props} state={form} setState={setForm} validationType={'default'} validationSchema={authSchema} >
      {props => {
      let enabled = props.isValidated && !state.isSubmitting;
      return (
        <>
        <FormLayout id='confirm-add-email' >

          <Heading>{'Confirm Add Email'}</Heading>

          <Grid id='confirm-add-email' style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {!state.isCompleted && !form.values.email && // show email if it was not passed in from props.location.state
            <Grid item >
              <EmailField {...props.fieldProps} name='email' required={true} />
            </Grid>}

            {!state.isCompleted &&
            <>
            <Grid item >
              <TextField {...props.fieldProps}
                name='confirmCode'
                label='Confirm Code'
                required={true}
              />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                {...props.submitProps}
                name='confirm-add-email'
                title='Submit'
                disabled={!enabled}
                // onClick={(event) => handleSubmit(event, props)}
                onClick={handleSubmit}
              />
            </Grid>

            <Grid item className={classes.bottomLink} >
              {/* <Link variant='body2' component="button" onClick={(event) => handleResendCode(event, props)} > */}
              <Link variant='body2' component="button" onClick={handleResendCode} >
                {'Resend confirmation code'}
              </Link>
            </Grid>
            </>}

          </Grid>

        </FormLayout>

        {/* <DisplayState title='state' state={{ local: state, defaultValues, stateValues, form: props.stateProps }} open={true} /> */}
        <DisplayState title='state' state={{ state, form: { isValidated: props.isValidated, ...form } }} open={true} />
        </>
      );}}
    </FormUp>}
    </>
  );
}

ConfirmAddEmail.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  // stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  // passwordProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
};
