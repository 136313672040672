import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import { AuthorizedHandler } from '@org/client-graphql';

export const columns = [
  // { field: 'id', headerName: 'ID', width: 10 },
  { field: 'itemName', headerName: 'Name', width: 150 },
  { field: 'coupon', headerName: 'Coupon', width: 130, type: 'number' },
  { field: 'originalBalance', headerName: 'Ori Balance', width: 150, type: 'number' },
  { field: 'creationDate', headerName: 'Creation Date', width: 170, type: 'date' },
  { field: 'launchDate', headerName: 'Launch Date', width: 170, type: 'date' },
];

export async function getRows(props) {
  let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let notes = await authorizedHandler.listNotes();

  notes.forEach(note => {
    note.creationDate = dayjs(decodeTime(note.id)).format('YY-MM-DD hh:mm a');
    note.launchDate = dayjs(note.launchDate).format('YYYY-MM-DD');
  });

  return notes;
}

export async function handleRowClick(event, props) {
  // alert(JSON.stringify(event.row, null, ' '));
  console.log('Notes', 'handleRowClick', event, props);
  // alert(JSON.stringify(event.row, null, ' '));
  let data = event.row;
  props.history.push({ pathname: '/admin/create-note', state: { data } });
}
