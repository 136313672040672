import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Form, PrimaryButton, SecondaryButton, EmailField, BackdropLoader } from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { styles } from '@org/client-libs-styles';
// import { authenticatedResolvers } from '@org/client-graphql';
import { AuthenticatedHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

let defaultValues = {
  email: "",
};

let isMounted = false;
let authenticatedHandler;

export function AddEmail(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formMessage: null, formError: null });
  let classes = useStyles();

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) { // only do this on the initial page load
        window.scrollTo(0, 0);
        if (isMounted) // trigger a render
          setState({ ...state, isLoading: false});
      }
    })();
    
    return () => { // componentDidUnmount
      isMounted = false;
    };
  }, []);

  async function handleSubmit(event, form) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });

    try {
      // let { password, email } = form.values;
      // let input = { password, email };
      let { email } = form.values;
      let input = { email };
      // let response = await authenticatedResolvers.initiateAddEmail(input);
      let response = await authenticatedHandler.initiateAddEmail(input);
      // console.log('AddEmail', response);
      props.history.push({
        pathname: '/user/confirm-add-email',
        state: { email, ...response },
      });
      // let success = {
      //   pathname: '/user/confirm-add-email',
      //   state: {
      //     email,
      //   },
      // };
      // if (response.success)
      //   props.history.push(success);
      // else if (response.error)
      //   throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  function handleCancel() {
    props.history.goBack();
  }

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    {!state.isLoading &&
    <Form id='add-email' defaultValues={defaultValues} validationSchema={authSchema} {...props} >
      {props => {
      let enabled = props.isValidated && !state.isSubmitting;
      return (
        <>
        <FormLayout>

          <Heading>{'Add Email'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {!state.isCompleted &&
            <>
            <Grid item >
              <EmailField {...props.fieldProps} name='email' required={true} />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                {...props.submitProps}
                // name='add-email'
                title='Submit'
                disabled={!enabled}
                onClick={(event) => handleSubmit(event, props)}
              />
              <SecondaryButton
                aria-label='cancel button'
                title='Cancel'
                // disabled={!enabled}
                onClick={handleCancel}
              />
            </Grid>
            </>}

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: props.stateProps }} open={true} />
        </>
      );
      }}
    </Form>}
    </>
  );
}

AddEmail.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
};
