import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
// import { Link as RouterLink } from 'react-router-dom';
import { Form, PasswordField, PasswordFieldTooltip, PrimaryButton, SecondaryButton, BackdropLoader } from '@org/client-components-core';
import {
  FormLayout, Heading, FormMessage, FormError
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
// import { styles as authStyles } from './authStyles';
import { styles } from '@org/client-libs-styles';
// import { authenticatedResolvers } from '@org/client-graphql';
import { AuthenticatedHandler } from '@org/client-graphql';

let authenticatedHandler;

// const useStyles = makeStyles(authStyles);
const useStyles = makeStyles(styles);

let defaultValues = {
    password: "",
    newPassword: "",
  };

export function ChangePassword(props) {
  let isMounted = true;
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null });
  let classes = useStyles();

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  useEffect(() => {
    (async () => {
      if (state.isLoading) {
        window.scrollTo(0, 0);
        if (isMounted)
          setState({ ...state, isLoading: false});
      }
    })();

    return () => {
      isMounted = false;
    };
  }, []);

  async function handleBlur(event, props) {
    // event.persist();

    if (props.stateProps.values.password === props.stateProps.values.newPassword && props.stateProps.values.password !== "") {
      if (isMounted)
        setState({ ...state, formError: { message: 'You cannot use the same password' } });
    } else {
      if (isMounted)
        setState({ ...state, formError: null });
      props.handleBlur(event, event.target.name);
    }
  }

  async function handleSubmit(event, form) {
    event.preventDefault(); // do I need this?
    setState({ ...state, isSubmitting: true });

    try {
      let { password, newPassword } = form.values;
      let input = { password, newPassword };
      // let response = await authenticatedResolvers.changePassword(input);
      let response = await authenticatedHandler.changePassword(input);

      if (response.success && isMounted)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Update successful', formError: null });

      if (response.error)
        throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: error });
    }

    // try {
    //   let success = {
    //     pathname: '/success',
    //     state: {
    //       successMessage: 'You password has been successfully changed',
    //       next: '/home',
    //     },
    //   };
    //   if (process.env.REACT_APP_TEST_MUI_MODE === 'true') {
    //     props.history.push(success);
    //   } else {
    //     let input = { password, newPassword };
    //     let response = await authenticatedResolvers.changePassword(input);

    //     if (response.success) 
    //       setSuccess(true);
    //     else if (response.error)
    //       setError(response.error);
    //   }
    // } catch (error) {
    //   // console.log('ChangePassword', 'Unknown error in handleSubmit', error);
    //   setError('Unknown error in handleSubmit');
    // }
  }

  // function renderForm() {
  //   return(
  //     <Form id='sign-in' initialValues={initialValues} validationSchema={authSchema} {...props} >
  //       {props => {
  //       let enabled = props.isValidated && !props.isSubmitting;
  //       return (
  //         <>
  //         <Container id='invitation' component="main" maxWidth="xs" className={classes.children} >

  //           <Grid className={classes.paper} >
  //             <form className={classes.form} noValidate >
  //               <Grid item >
  //                 <FormHeading title='Change Password' />
  //               </Grid>

  //               {error &&
  //               <Grid item >
  //                 <FormError message={error.message} />
  //               </Grid>}

  //               <Grid item >
  //                 <PasswordField name='password' {...props.passwordProps} required={true} />
  //               </Grid>
  //               <Grid item >
  //                 <PasswordFieldTooltip name='newPassword' {...props.passwordProps} required={true} text='New Password'
  //                 handleBlur={(event) => handleBlur(event, props)} />
  //               </Grid>
  //               <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
  //                 <PrimaryButton {...props.buttonProps} title='Submit' disabled={!enabled}
  //                   onClick={(event) => handleSubmit(event, props)} />
  //               </Grid>
  //             </form>
  //           </Grid>

  //           {NODE_ENV === 'development' &&
  //           <DisplayState title='state' state={props.stateProps} />}

  //         </Container>
  //         </>
  //       );
  //     }}
  //     </Form>
  //   );
  // }

  // function renderSuccess() {
  //   return(
  //     <Container id='change-email' component="main" maxWidth="xs" className={classes.children} >

  //       <Grid className={classes.paper} >
  //         {/* <form className={classes.form} noValidate > */}
  //           <Grid item >
  //             <FormHeading title='Change Password' />
  //           </Grid>
  //           {/* // TODO Why aren't these margins collapsing */}
  //           <Grid item >
  //             <FormMessage message='Your password has been successfully changed' />
  //           </Grid>
  //       </Grid>

  //       {NODE_ENV === 'development' &&
  //       <DisplayState title='state' state={props.stateProps} />}

  //     </Container>
  //   );
  // }

  function handleCancel() {
    props.history.goBack();
  }

  return(
    <>
    {state.isLoading &&
    <BackdropLoader />}

    {!state.isLoading &&
    <Form id='change-password' defaultValues={defaultValues} validationSchema={authSchema} {...props} >
      {props => {
      let enabled = props.isValidated && !state.isSubmitting;
      return (
        <>
        <FormLayout >

          {/* <FlexRow style={{ justifyContent: 'space-between'}} >
            <InLink to='/user/security' title='Back' />
            <div />
          </FlexRow> */}

          <Heading>{'Change Password'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            {!state.isCompleted &&
            <>
            <Grid item >
              <PasswordField name='password' {...props.passwordProps} required={true} />
            </Grid>

            <Grid item >
              <PasswordFieldTooltip name='newPassword' {...props.passwordProps} required={true} text='New Password'
              handleBlur={(event) => handleBlur(event, props)} />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
              <PrimaryButton
                {...props.submitProps}
                name='sign-in'
                title='Submit'
                disabled={!enabled}
                onClick={(event) => handleSubmit(event, props)}
              />
              <SecondaryButton
                aria-label='cancel button'
                title='Cancel'
                // disabled={!enabled}
                onClick={handleCancel}
              />
            </Grid>
            </>}

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: props.stateProps }} open={true} />
        </>
      );
      }}
    </Form>}
    </>
  );
}

ChangePassword.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  passwordProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  handleBlur: PropTypes.func,
};
