// import { makeStyles } from '@material-ui/core/styles';
import theme from '@org/client-libs-themes';
// import purple from "@material-ui/core/colors/purple";
// import green from "@material-ui/core/colors/green";

// export const useStyles = makeStyles((theme) => (appBarStyles));

export const appBarHeight = 72;
export const drawerWidth = 240;

export const appBarStyles = {
  // offset: theme.mixins.toolbar,
  offset: {
    height: appBarHeight,
  },
  appBar: {
    height: appBarHeight,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // position: 'absolute',
    position: 'fixed',
    // overflow: 'hidden', // does NOT stop the scrollbar from flashing when closing mainDrawer
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
  },
  appBarShiftRight: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginRight: drawerWidth,
  },
  appBarShiftBoth: {
    // width: `calc(100% - ${drawerWidth}px - ${drawerWidth}px)`,
    // marginRight: drawerWidth,
  },
  toolbar: {
    height: appBarHeight,
    padding: theme.spacing(0, 1.5, 0, 1.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
    ...theme.mixins.toolbar,
  },
  // menuButtonHidden: { // shared by main and user buttons
  //   // display: 'none', // not working in this project, TODO fix and remove !important
  //   display: 'none !important',
  // },
  // mainButton: {
  //   // marginRight: 0,
  //   marginLeft: 0,
  // },
  // userButton: {
  //   marginRight: 0,
  // },
  userIconButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    // width: '14rem'
    // width: 'auto',
    width: '100%',
    flexGrow: 1,
  },
  appBarBrandWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    // flexGrow: 1,
    // padding: theme.spacing(0, 1.5, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
    // width: '14rem'
    // width: 'auto',
    width: '100%',
    flexGrow: 1,
  },
  // appBarTitle: {
  //   fontWeight: '400',
  // },
  appBarLogo: {
    display: 'flex',
    // height: '3.5rem',
    // padding: theme.spacing(0, 1, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
  },
  appBarBrand: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0, 0, 1),
    },
    fontFamily: `"Orbitron", "Helvetica", "Arial", sans-serif;`,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  appBarLinkSpan: {
    display: 'flex',
    paddingTop: '0.4rem',
    '& > *': { // & = this elem, all the children of this elem
      margin: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  appBarLink: {
    fontWeight: '400',
    // [theme.breakpoints.down('md')]: {
    //   variant: 'h6',
    // },
  },
  appBarActiveLink: {
    // backgroundColor: theme.palette.action.selected,
  },
  signInLink: {
    display: 'flex',
    alignItems: 'center',
    // margin: theme.spacing(0, 2, 0, 0),
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(0, 2, 0, 0),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  // drawerRight: {
  //   // display: 'flex',
  //   // flexDirection: 'row',
  //   // justifyContent: 'flex-start',
  //   // alignItems: 'flex-end',
  //   flexShrink: 0,
  //   width: drawerWidth,
  // },
  drawerPaper: {
    position: 'fixed', // must be fixed for anchor='right'
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    flexShrink: 0,
    // overflow: 'auto', // does NOT stop the scrollbar from flashing when closing mainDrawer
    // overflow: 'hidden', // does NOT stop the scrollbar from flashing when closing mainDrawer
    // height: '100%', // does NOT stop the scrollbar from flashing when closing mainDrawer
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    height: appBarHeight,
  },
  drawerHeaderRight: {
    justifyContent: 'flex-start',
    // alignItems: 'flex-start',
  },
  // drawerListItem: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   // padding: theme.spacing(1, 2, 0.5, 2),
  // },
  // drawerListItemIcon: {
  //   display: 'flex',
  //   minWidth: `${theme.spacing(5)} !important`,
  // },
  // drawerListItemText: {
  //   // display: 'flex !important',
  //   // marginLeft: theme.spacing(1),
  // },
  // drawerLink: {
  //   // width: '100%',
  //   maxWidth: '100%',
  //   textDecoration: 'none',
  // },
};
