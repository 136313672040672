import theme from '@org/client-libs-themes';

export const styles = {
  children: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4, 0, 4, 0),
    width: '100%',
  },
  // paper: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   padding: theme.spacing(0, 4, 0, 4),
  //   borderRadius: '4px',
  //   boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  // },
  // form: {
  //   width: '100%', // Fix IE 11 issue.
  // },
  stepper: {
    backgroundColor: '#fafafa',
  },
  // centered: { // already in @org/client-libs-styles
  //   display: 'flex',
  //   justifyContent: 'center',
  // },
  passwordRequirements: {
    fontSize: '24px',
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(0, 0, 0, 0),
  },
  // buttonSpan: {
  //   margin: theme.spacing(4, 0, 5, 0),
  // },
  // signUp: {
  //   margin: theme.spacing(0, 0, 3, 0),
  //   cursor: 'pointer',
  // },
  bottomLink: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 0, 4, 0),
  },
  // headingGrid: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   // justifyContent: 'space-between',
  //   justifyContent: 'center',
  //   padding: theme.spacing(4, 0, 4, 0),
  // },
  // heading: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   fontFamily: `"Roboto Slab", "Times New Roman", serif`,
  //   // margin: theme.spacing(4, 0, 4, 0),
  //   // textAlign: 'center',
  // },
  // headingSpan: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'center',
  //   flexGrow: 1,
  //   width: '100%',
  // },
  // headingSpanLeft: {
  //   justifyContent: 'flex-start',
  //   width: '20%',
  // },
  // headingSpanRight: {
  //   justifyContent: 'flex-end',
  //   width: '20%',
  // },
  // iconButton: {
  //   margin: theme.spacing(0.0),
  // }
};
