import React, { Component } from "react";
import PropTypes from 'prop-types';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }

  static getDerivedStateFromError(error) {
    // console.error('ErrorBoundary', 'getDerivedStateFromError', error);

    // Update state so the next render will show the fallback UI.
    // this.setState({
    //   hasError: true,
    //   error: error,
    // });

    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // console.error('ErrorBoundary', 'componentDidCatch', 'error', error, 'info', info);
    // console.error('ErrorBoundary', 'componentDidCatch', 'error:', Object.getOwnPropertyNames(error), error);
    // console.error('ErrorBoundary', 'componentDidCatch', 'info:', Object.getOwnPropertyNames(info), info);
    // console.log('ErrorBoundary', 'componentDidCatch', 'state', this.state);
    // Display fallback UI
    this.setState({
      hasError: true,
      error: { message: error.message, stack: error.stack },
      info,
     });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    // console.log(error, info);
  }

  render() {
    // console.log('ErrorBoundary', this.state);
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
        <h1>Error Bounday Hit</h1>
        <div style={{padding: '5rem',}}>
          <span style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#f6f8fa',
            fontSize: '.7rem',
            padding: '2rem',
            border: '1px solid',
            borderRadius: '5px',
            margin: '0 auto',
            height: 'auto',
            width: 'auto',
          }}>
            <h1>Error Boundary</h1>
            {/* <pre>state:{JSON.stringify(this.state, null, ' ')}</pre> */}
            <pre>error:{JSON.stringify(this.state.error, null, ' ').replace(/\\n/g, '\n')}</pre>
            <pre>info: {JSON.stringify(this.state.info, null, ' ').replace(/\\n/g, '\n')}</pre>
          </span>
        </div>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  // children: PropTypes.shape(),
  children: PropTypes.any,
};