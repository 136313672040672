import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloProvider } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Form, PasswordFieldTooltip, ExLink, PrimaryButton, SecondaryButton, LinkButton } from '@org/client-components-core';
import { FormHeading, FormMessage, FormError, Loader } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
// import withStyles from "@material-ui/styles/withStyles";
import { styles } from './authStyles';
import { publicClient, publicResolvers, handleAuthentication } from '@org/client-graphql';
import { cleanUp } from './SignOut';

const location = 'client.pages.auth.Invitation';

// URGENT Delete the Local Storage after this process has been initiated. (or completed?)

const localStyles = {
  ...styles,
};

const useStyles = makeStyles(localStyles);

function EnterManually() {
  // let classes = useStyles();
  // let buttonProps = { title: 'Next', validated: true };
  return(
    <>
    <Grid item >
      <FormHeading title='Unsubscribe' />
    </Grid>
    <Grid item >
      <FormMessage message='Manual unsubscribe coming soon...' />
    </Grid>
    {/* <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
      <PrimaryButton {...buttonProps} onClick={() => props.setActiveStep('changePassword')} />
    </Grid> */}
    </>
  );
}

EnterManually.propTypes = {
  input: PropTypes.shape(),
  setActiveStep: PropTypes.func,
};

function VerifyUnsubscribe(props) {
  console.log(location, 'VerifyUnsubscribe', 'props', props);
  let { input } = props;
  let classes = useStyles();

  async function handleVerifyUnsubscribe(event, props) {
    console.log('handleVerifyUnsubscribe', event, props);
    try {
      let response = await publicResolvers.verifyUnsubscribe(input);
    } catch (error) {
      console.log(location, 'VerifyInvitation', error);
    }
    props.setActiveStep('unsubscribeComplete');
  }

  return(
    <>
    <Grid item >
      <FormHeading title='Verify Unsubscribe' />
    </Grid>
    <Grid item >
      <FormMessage message='Please confirm that you do not wish to be contacted by us at this address. You can always change this setting in your contact preferences.' />
      </Grid>
    {/* <Grid item >
      <FormError message={error.message} />
    </Grid> */}
    <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
      <PrimaryButton title={'Submit'} validated={true} onClick={(event) => handleVerifyUnsubscribe(event, props)} />
    </Grid>
    </>
  );
}

VerifyUnsubscribe.propTypes = {
  input: PropTypes.shape(),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setActiveStep: PropTypes.func,
};

function UnsubscribeComplete(props) {
  console.log(location, 'UnsubscribeComplete', 'props', props);
  let classes = useStyles();

  return(
    <>
    <Grid item >
      <FormHeading title='Unsubscribe Complete' />
    </Grid>
    <Grid item >
      <FormMessage message='We will no longer contact you at this address.' />
    </Grid>
    {/* <Grid item >
      <FormError message={error.message} />
    </Grid> */}
    <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
      {/* <LinkButton title='Continue' color='primary' style={{textTransform: 'upper'}} validated={true} loading={false} href='/user' /> */}
      {/* <PrimaryButton
        // {...buttonProps}
        name='change-password'
        title='Continue'
        disabled={!enabled}
        onClick={(event) => handleVerifyIdentity(event, props)}
      /> */}
      {/* <SecondaryButton {...skipButtonProps} onClick={() => props.setActiveStep('invitationComplete')} /> */}
    </Grid>
    </>
  );
}

UnsubscribeComplete.propTypes = {
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setActiveStep: PropTypes.func,
};

let unsubscribeProps = {};

export function Unsubscribe(props) {
  console.log('Unsubscribe', 'props', props);
  let { state } = props.location;
  let classes = useStyles();
  let [ defaultValues, setDefaultValues ] = useState({});
  let [ activeStep, setActiveStep ] = useState('');
  let [ pageLoading, setPageLoading ] = useState(false);
  let [ isSubmitting, setIsSubmitting ] = useState(false);

  // URGENT If a user is signed in, sign them out now
  // cleanUp();
  // URGENT Ask the user if they want to sign out and proceed with the invitation. Why would this happen?

  console.log(location, 'Unsubscribe', 'defaultValues', defaultValues);

  if (activeStep === '') {
    let input = {};
    unsubscribeProps = { setActiveStep, setDefaultValues };
    if (!state || !state.unsubscribe) {
      setActiveStep('enterManually');
    } else {
      setActiveStep('verifyUnsubscribe');
      input.id = state.unsubscribe;
      unsubscribeProps.input = input;
    }
  }
  console.log('Unsubscribe', 'activeStep', activeStep, 'invitationProps', unsubscribeProps);

  return(
    <>
    {(pageLoading || isSubmitting) &&
    <div style={{height: 585, width: '100%'}} >
      <Loader />
    </div>}

    {!pageLoading &&
    <Form id='invitation' defaultValues={defaultValues} validationSchema={authSchema} {...props} >
      {props => {
      let {
        isValidated, values, touched, validated, errors,
        handleChange, handleBlur, setIsValidated, setValues,
        } = props;
      let renderProps = {
        isValidated, values, touched, validated, errors,
        handleChange, handleBlur, setIsValidated, setValues,
      };
      renderProps = { ...renderProps, pageLoading, isSubmitting, };
      // stateProps for DisplayState
      // let stateProps = { state: { ...this.state }, form: { isValidated, values, touched, validated, errors } };
      let stateProps = { state, activeStep, setDefaultValues };
      return (
        <Container id='unsubscribe' component="main" maxWidth="xs" className={classes.children} >

          <Grid className={classes.paper} >
            <form className={classes.form} noValidate >

              <ApolloProvider client={publicClient} >
                {/* {activeStep === 'invitationVerified' &&
                <InvitationVerified {...invitationProps} />}
                {activeStep === 'changePassword' &&
                <ChangePassword {...invitationProps} {...renderProps} />}
                {activeStep === 'investorStatus' &&
                <InvestorStatus {...invitationProps} {...renderProps} />}
                {activeStep === 'verifyIdentity' &&
                <VerifyIdentity {...invitationProps} {...renderProps} />} */}
                {activeStep === 'enterManually' &&
                <EnterManually  {...unsubscribeProps} />}
                {activeStep === 'verifyUnsubscribe' &&
                <VerifyUnsubscribe  {...unsubscribeProps} />}
                {activeStep === 'unsubscribeComplete' &&
                <UnsubscribeComplete {...unsubscribeProps} {...renderProps} />}
              </ApolloProvider>
                
            </form>
          </Grid>

          <DisplayState title='state' state={{stateProps, renderProps}} open={true} />

        </Container>
      );}}
    </Form>}
    </>
  );
}

Unsubscribe.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape(),
  }),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  validated: PropTypes.shape(),
  errors: PropTypes.shape(),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  // handleSetSubmitting: PropTypes.func,
  setActiveStep: PropTypes.func,
  setValues: PropTypes.func,
  setIsValidated: PropTypes.func,
};
