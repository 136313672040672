import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '@org/client-libs-themes';
import { ErrorBoundary } from '@org/client-components-utils';
// // import App from './AppComponent';
import { App } from './AppComponent';
import * as serviceWorker from './serviceWorker';
// import { publicResolvers } from '@org/client-graphql';
import './index.css';

// import packageJson from '../package.json';

// // global.appVersion = packageJson.version;
// let date = (new Date()).getTime();
// let dateString = date.toString();
// global.appVersion = dateString;
// console.info(packageJson.version, dateString);

console.info('client.app.index', 'process.env', process.env);
console.info('window', window);
console.info('navigator', navigator);

if (window.File && window.FileReader && window.FileList && window.Blob) {
  // Great success! All the File APIs are supported.
} else {
  // TODO Handle this better: add badBrowser error?
  alert('The File APIs are not fully supported in this browser.');
}

// 
if (window.location.search && window.location.search.includes('?')) {
  localStorage.setItem('request', JSON.stringify(window.location));
}

// let supported = true;
// // const unsupported = [ 'MSIE', 'Trident', 'Edg', 'Chrome' ];
// const unsupported = [ 'MSIE', 'Trident', 'Chrome' ];
// for (let browser in unsupported) {
//   console.log(unsupported[browser]);
//   if (navigator.userAgent.includes(browser))
//     supported = false;
//   console.log(supported);
// }

// // let isAuthenticated = publicResolvers.isAuthenticated();

// if (!supported)
//   ReactDOM.render(
//     <React.StrictMode>
//       <div style={{ display: 'flex', fontSize: '20px', height: '100vh', width: '100%', textAlign: 'center', verticalAlign: 'middle' }} >
//         <div>{'Unsupported browser!'}</div>
//         <div>{navigator.userAgent}</div>
//         <div>{'It looks like you are using a version of Internet Explorer'}</div>
//       </div>
//     </React.StrictMode>,
//     document.getElementById('root')
//   );
// else
if (process.env.REACT_APP_STAGE === 'prod')
  ReactDOM.render(
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1, height: '100vh', width: '100vw' }} >
      <p style={{ fontSize: '24px' }}>{'Coming soon...'}</p>
    </div>,
    document.getElementById('root')
  );
else
  ReactDOM.render(
    // <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <ThemeProvider theme={theme} >
            <CssBaseline />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>,
    // </React.StrictMode>,
    document.getElementById('root')
  );

serviceWorker.unregister();
//serviceWorker.register();
