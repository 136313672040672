// import * as yup from "yup";
import * as yup from 'yup/es';

const formShape = {
  textField: yup.string(),
  numberField: yup.number(),
  calculatedField: yup.number(),
  selectTextField: yup.string(),
};

export const formSchema = yup.object().shape(formShape);
