export const generateConfirmCode = function(input) {
  // console.log('generateConfirmCode', input);

  let length = input?.length ? input.length : 6;

  let numbers = '0123456789';
  let items = numbers;
  let generateConfirmCode = [];
  for(let i=0; i < length; i++) {
    generateConfirmCode[i] = items[Math.floor(Math.random() * items.length)];
  }
  generateConfirmCode = generateConfirmCode.sort(function() {
    return 0.5-Math.random();
  }).join('');

  return generateConfirmCode;
};

export const generateUrlSafePassword = function(input) {
  // console.log('generateUrlSafePassword', input);

  // let { length } = input;
  // if (!length) length = 6;
  let length = input?.length ? input.length : 6;

  //let items = '0123456789abcdebghijklmoopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.~';
  let special = '-_.~';
  let numbers = '0123456789';
  let uppers = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let lowers = 'abcdebghijklmoopqrstuvwxyz';
  let items = special + numbers + uppers + lowers;
  console.log(items);

  let tempPassword = [];
  tempPassword[0] = special[Math.floor(Math.random() * special.length)];
  tempPassword[1] = numbers[Math.floor(Math.random() * numbers.length)];
  tempPassword[2] = uppers[Math.floor(Math.random() * uppers.length)];
  tempPassword[3] = lowers[Math.floor(Math.random() * lowers.length)];
  for(let i=4; i < length; i++) {
    tempPassword[i] = items[Math.floor(Math.random() * items.length)];
  }
  tempPassword = tempPassword.sort(function() {
    return 0.5-Math.random();
  }).join('');

  return tempPassword;
};
