// import { transform, isEqual, isObject } from 'lodash-es';
import transform from 'lodash-es/transform';
import isEqual from 'lodash-es/isEqual';
import isObject from 'lodash-es/isObject';

export function isDefined(input) {
  // console.log('isDefined', input, input === null);

  if (input === undefined)
    return false;

  // if (input === null) // !!! this is defined !!!
  //   return true;
  // if (input === '')
  //   return true;
  // if (input === 0)
  //   return true;
  // if (input === true)
  //   return true;
  // if (input === {})
  //   return true;
  // if (input === [])
  //   return true;
  if (input !== undefined)
    return true;

  throw new Error('isDefined', 'unable to determine for', input);
}

// export function isObject(object) {
//   return object != null && typeof object === 'object';
// }

export function shallowEqual(object1, object2) {

  if (!object1 || !object2)
    console.error('shallowEqual', 'cannot compare undefined or null objects', object1, object2);

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

// https://gist.github.com/Yimiprod/7ee176597fef230d1451

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */

export function deepDifference(object, base) {
  function changes(object, base) {
    return transform(object, function(result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
	return changes(object, base);
}
