import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import {
  Form, EmailField,
  ButtonBar, PrimaryButton,
} from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { PublicHandler } from '@org/client-graphql';
import { styles } from '@org/client-libs-styles';


let defaultValues = {
  email: "",
};

const useStyles = makeStyles(styles);

let isMounted = false;

let publicHandler;

export function ResetPassword(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formError: null });
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true });

    try {
      let { email } = form.values;
      let input = { email };

      let response = await publicHandler.initiateResetPassword(input);

      let success = {
        pathname: '/confirm-reset-password',
        state: {
          email,
        },
      };
      if (response.success)
        props.history.push(success);
      else if (response.error)
        throw response.error;
    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  return (
    <>
    <Form defaultValues={defaultValues} validationSchema={authSchema} {...props} >
      {props => {
      let enabled = props.isValidated && !state.isSubmitting;
      return (
        <>
        <FormLayout id='sign-in' >

          <Heading>{'Reset Password'}</Heading>

          <Grid id='reset-password' style={{width: '100%'}} >

            <Grid item >
              <FormMessage message='Please enter your email' />
            </Grid>

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <EmailField {...props.fieldProps} name='email' required={true} />
            </Grid>

            <Grid item >
              <ButtonBar>
                <PrimaryButton
                  {...props.submitProps}
                  name='sign-in'
                  title='Submit'
                  disabled={!enabled}
                  onClick={(event) => handleSubmit(event, props)}
                />
              </ButtonBar>
            </Grid>

            <Grid item className={classes.bottomLink} >
              <Link variant="body2" component={RouterLink} to="/confirm-reset-password" >
                {"Already have your confirm code? Confirm Reset Password"}
              </Link>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: props.stateProps }} open={true} />
        </>
      );
      }}
    </Form>
    </>
  );
}

ResetPassword.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
};
