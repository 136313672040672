import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  },
};

// TODO Rename Form to FormWrapper?
/**
 * Component for creating forms using my MUI custom component libs with minimal code repetition.
 * Manages everything in the form but `handleSubmit` which must me managed locally.
 * 
 * The form is a controlled component and needs to have initial values passed to it.
 * 
 * Initial values can be passed in the following ways:
 * 
 * `defaultValues` (required) -- Form is a Controlled Component and must include a value for all inputs.
 *  - Set during component construction
 *  - Not affected by `valuationType`
 *  - Are not marked `touched` or `validated`
 * 
 * stateValues (optional) -- This allows values to be set after the Form has already rendered with defaultValues, e.g. after data is fetched in componentDidMount of useEffect, or if a field is computed in render props.
 *  - `stateValues` set before `componentDidMount` with valitationType `default` -- Override `defaultValues` 
 * 
 * Usage patterns:
 * 
 * - Blank form where all values need to be touched in order for the Form (`isValidated`) to be `true` -- ???
 * - Update form for existing objects -- Set `stateValues` before `componentDidMount` with `validationType = 'update'`
 * 
 * 
 * @component
 */

class StyledForm extends PureComponent {
  _validationSchema = this.props.validationSchema;
  _validationType = this.props.validationType ? this.props.validationType : 'default';

  constructor(props) {
    console.log('FormUp constructor', props);
    super(props);

    this.validateForm = this.validateForm.bind(this);
    this.validate = this.validate.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.parseEvent = this.parseEvent.bind(this);

    this.state = {
      isValidated: false,
    };
  }

  validateForm() {
    console.log('validateForm', this.state);
    let { isValidated } = this.state;
    let { values, touched, validated } = this.props.state;
    let validationType = (this.props.validationType) ? this.props.validationType : 'default'; // 'default | 'update'

    if (validationType === 'update') {
      // Use type 'update' to validate the Form using only changed values.
      // This is used for updating information
      let i = 0;
      if (Object.keys(touched).length) {
        let keys = Object.keys(touched);
        for (; i < keys.length; i++) { // use a for loop so we can break at first false
          if (!validated[keys[i]] && isValidated) {
            this.setState({ isValidated: false });
            break;
          }
        }
      }
      // if the loop didn't break early the form is validated
      if (i === Object.keys(touched).length && !isValidated)
        this.setState({ isValidated: true });
      console.log('validateForm', Object.keys(touched).length, i, isValidated);
    } else if (validationType === 'default') {
      // check all values
      const reducer = (accumulator, currentValue) => accumulator && currentValue;
      // if (Object.keys(values).length === Object.keys(validated).length && Object.values(validated).reduce(reducer))
      let numValues = Object.keys(values).length;
      let numValidated = Object.values(validated).length;
      let allValidated = Object.values(validated).reduce(reducer);
      // console.log('validateForm', numValues, numValidated, allValidated, this.state);
      if (numValues === numValidated && allValidated) {
        if (!this.state.isValidated)
          this.setState({ isValidated: true });
      } else {
        // console.log('here');
        if (this.state.isValidated)
          this.setState({ isValidated: false });
      }
      // console.log('validateForm', numValues, numValidated, allValidated, this.state);
      // console.log('');
    } else
      throw new Error('Unknown vaidationType', validationType);
  }

  async validate(name, value) {
    console.log('FormUp', 'validate', `'${name}'`, `'${value}'`);
    let { errors } = this.props.state;
    try {
      await this._validationSchema.validateAt(name, { [name]: value });
      console.info('FormUp', 'validate', name, value, 'validated!');

      // await this.validateForm();

      if (errors[name])
        delete errors[name];

    } catch (error) {
      console.error('FormUp', 'validate', name, value, error);
      // throw error; // URGENT Why am I throwing an error here? (FormUp validate)
    }

    console.log('');
  }

  async handleBlur(event, name) {
    console.log('FormUp', 'handleBlur', event, name);
    let { values, touched, validated, errors } = this.props.state;

    // if (touched[name]) {
      // let value = this.parseEvent(event, name);
      let response = this.parseEvent(event, name);
      name = response.name;
      let value = response.value;
      console.log('handleBlur', `'${value}'`, typeof value);

      try {
        if (typeof value === 'string')
          value = value.trim(); // No leading or trailing spaces allowed

        await this.validate(name, value); // throws an error if invalid

        await this.props.setState({
          ...this.props.state,
          touched: { ...touched, [name]: true},
          validated: { ...validated, [name]: true},
          // errors: { ...errors, [name]: null},
        });

        await this.validateForm(); // don't validate form until validation is updated
      } catch(error) {
        console.error('FormUp', 'handleBlur', name, error);
        this.setState({ isValidated: false });
        this.props.setState({
          ...this.props.state,
          values: { ...values, [name]: value}, // just in case string was trimmed...
          validated: { ...validated, [name]: false},
          errors: { ...errors, [name]: error.message},
        });
      }
    // }
  }

  // Called by componentDidMount when state is passed from another component
  // using history.push() which does not generate an event
  async handleChange(name, value) {
    console.log('FormUp', 'handleChange', name, value, typeof value);
    // let { values, touched, errors, validated } = this.state;
    let { values, touched, errors, validated } = this.props.state;

    // Don't vaidate after every key stroke. The following cases are the
    // only one we want to validate on every change.
    let validate = false;
    if (!touched[name] && typeof value === 'string' && value.length > 1)
    // pasted values & password keepers
      validate = true;
    else if (typeof value === 'boolean')
    // always validate checkbox
      validate = true;
    else if (errors[name])
    // try to fix existing errors on each keystroke
      validate = true;
    else if (touched[name] && validated[name])
    // so the user can fix a typo, even if the input is valid
      validate = true;
    else if (this.props?.calculatedValues?.includes(name))
      validate = true;
    

    if (validate) {
      try {
        await this.validate(name, value); // throws an error if invalid

        await this.props.setState({
          ...this.props.state,
          values: { ...values, [name]: value},
          touched: { ...touched, [name]: true},
          validated: { ...validated, [name]: true},
          // errors: { ...validated, [name]: null},
        });

        await this.validateForm(); // don't validate form until validation is updated
      } catch (error) { // error message logged in validate
        console.error('FormUp', 'handleChange', error.message);
        this.setState({ isValidated: false });
        await this.props.setState({
          ...this.props.state,
          values: { ...values, [name]: value},
          touched: { ...touched, [name]: true},
          validated: { ...validated, [name]: false},
          errors: { ...validated, [name]: error.message},
        });
      }
    } else {
      this.setState({ isValidated: false });
      this.props.setState({
        ...this.props.state,
        values: { ...values, [name]: value},
        touched: { ...touched, [name]: true},
        validated: { ...validated, [name]: false},
      });
    }
    console.log('');
  }

  // parseEvent is shared by handleChangeEvent and handleBlur
  // For some reason, different MUI inputs pass different event objects
  // Some of the objects do not pass the value name so I pass that as the second argument
  // TextField(event.target: { type: 'text', name })
  // NumberField(event.target: { floatValue, name }) // typeof value === 'string'
  // SelectTextField(event.target: { name, value })
  // DatePicker(Date object, name)
  // Checkbox(event.target: { type: 'checkbox', name, checked }) // value === ""
  parseEvent(event, name) {
    console.log('parseEvent', event, name);
    // console.log(typeof event?.target?.floatValue);
    let value;
    if (typeof event?.toDate === 'function' && event?.toDate() instanceof Date) {
      // DatePicker (dayjs formatted object)
      value = dayjs(event).format('YYYY-MM-DD');
      console.info('FormUp', 'parseEvent', 'DatePicker', name, value);
      // this.handleChange(name, value);
    } else if (event?.target?.type === 'checkbox') {
      name = event.target.name;
      value = event.target.checked;
      console.info('FormUp', 'parseEvent', 'Checkbox', name, value);
    // } else if (name === 'float') {
    } else if (typeof event?.target?.floatValue === 'number') {
      name = event.target.name;
      value = event.target.floatValue
      console.info('FormUp', 'parseEvent', 'Number TextField', name, value);
    } else {
      name = event.target.name;
      value = event.target.value
      console.info('FormUp', 'parseEvent', 'Select/TextField', name, value);
    }
    return { name, value };
  }

  // Called by Form input components
  // This only parses the input values then passes them to handleChange()
  async handleChangeEvent(event, name) {
    // event.persist();
    console.log('handleChangeEvent', event, name);
    // name = name ? name : event.target.name;
    // console.log('handleChangeEvent', event.target.name);
    // let value = this.parseEvent(event, name);
    let response = this.parseEvent(event, name);
    name = response.name;
    let value = response.value;

    // This stops unknown or invalid input values
    // E.g. non-numeric values in NumberField/PercentField
    if (value != null)
      this.handleChange(name, value);
    else
      console.info('FormUp', 'handleChangeEvent', 'Unknown input type', name, event);
  }

  render() {
    // let { values, errors, validated, isSubmitting, isValidated } = this.state;
    console.log('FormUp', 'render', 'props', this.props);
    const { classes } = this.props;
    // let stateProps = this.state;
    let handleChange = this.handleChangeEvent;
    let handleBlur = this.handleBlur;
    let { state } = this.props;
    let { values, errors, validated } = state;
    console.log('FormUp', 'render', 'state', state);

    return (
      <>
      <form className={classes.form} noValidate autoComplete='off' >
        {this.props.children({
          ...this.props,
          ...this.state,
          handleChange,
          handleBlur,
          //
          fieldProps: { values, errors, validated, handleChange, handleBlur },
          checkboxProps: { values, errors, handleChange },
          passwordProps: { values, errors, validated, handleChange, handleBlur },
          submitProps: { ...this.state, handleSetSubmitting: this.handleSetSubmitting, history: this.props.history },
        })}
      </form>
      </>
    );
  }
}

export const FormUp = withStyles(styles)(StyledForm);

StyledForm.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  //** Default form values */
  defaultValues: PropTypes.shape({}),
  /** Initial form values */
  stateValues: PropTypes.shape({}),
  validationType: PropTypes.string,
  /** Yup validation schema for the form values */
  validationSchema: PropTypes.shape({}),
  /** A form component using the custom MUI components designed to work with Form */
  children: PropTypes.any, // TODO What PropType should children be?
};
