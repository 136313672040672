import React from 'react';
import { publicCache, authenticatedCache, authorizedCache } from '@org/client-graphql';
import { Breadcrumbs, InLink } from '@org/client-components-core';
import { Page, Heading } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';

export function Cache() {

  return(
    <Page id='dev-cache' >

      <Breadcrumbs>
        {/* <InLink color='inherit' title='Home' to='/' /> */}
        <InLink color='inherit' title='Dev' to='/dev' />
        {/* <Typography color='textPrimary' >GraphQL Cache Info</Typography> */}
      </Breadcrumbs>

      <Heading>{'GraphQL Cache Data'}</Heading>

      <DisplayState title='Public Cache' state={publicCache} /* open={true} */ />

      <DisplayState title='Authenticated Cache' state={authenticatedCache} /* open={true} */ />

      <DisplayState title='Authorized Cache' state={authorizedCache} /* open={true} */ />

    </Page>
  );
}

export default Cache;
