import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Page, Heading, Section } from '@org/client-components-custom';
import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  ...styles,
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  logo500: {
    fontFamily: `"Orbitron", "Helvetica", "Arial", sans-serif;`,
    fontWeight: '500',
  },
  logo600: {
    fontFamily: `"Orbitron", "Helvetica", "Arial", sans-serif;`,
    fontWeight: '600',
  },
  logo700: {
    fontFamily: `"Orbitron", "Helvetica", "Arial", sans-serif;`,
    fontWeight: '700',
  },
  roboto400: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
    fontWeight: '400',
  },
  slab500: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif;`,
    fontWeight: '500',
  },
  mono500: {
    fontFamily: `"Roboto Mono", monospace;`,
    fontWeight: '500',
  },
  noto400: {
    fontFamily: `"Noto Serif", serif;`,
    fontWeight: '400',
  },
  noto700: {
    fontFamily: `"Noto Serif", serif;`,
    fontWeight: '700',
  },
}));

export function Fonts() {
  let classes = useStyles();


// Orbitron
// CUBIT h3 700
// CUBIT h4 700
// Roboto body1 400
// Roboto body2 400
// Roboto Slab h1 500
// Roboto Slab h2 500
// Roboto Slab body1 500
// Noto Serif h1 500
// Noto Serif h2 500
// Noto Serif body1 500
// Roboto Mono body1 500
// Roboto Mono body2 500
  return (
    // <div className={classes.root} >
    // <Typography variant={styles.pageTitleVariant} component='h1' className={classes.pageTitle} >
    //   {'Fonts'}
    // </Typography>
    <Page id='dev-home'>

      <Heading>{'Fonts'}</Heading>

      <Section>
        {/* font-family: 'Orbitron', sans-serif; */}
        <Typography variant="h3" component="h1" className={classes.logo700} >{'Orbitron h3 700 CUBIT'}</Typography>
        <Typography variant="h4" component="h1" className={classes.logo700} >{'Orbitron h4 700 CUBIT'}</Typography>
      </Section>

      <Section>
        {/* font-family: 'Roboto', sans-serif; */}
        <Typography component="body1" className={classes.roboto400} >{'Roboto body1 400'}</Typography>
        <Typography variant="body2" component="body2" className={classes.roboto400} >{'Roboto body2 400'}</Typography>
      </Section>

      <Section>
        {/* font-family: 'Roboto Slab', serif; */}
        <Typography variant="h3" component="h1" className={classes.slab500} >{'Roboto Slab h1 400'}</Typography>
        <Typography variant="h4" component="h1" className={classes.slab500} >{'Roboto Slab h2 400'}</Typography>
        <Typography component="body1" className={classes.slab500} >{'Roboto Slab body1 500'}</Typography>
        <Typography variant="body2" component="body2" className={classes.slab500} >{'Roboto Slab body2 500'}</Typography>
      </Section>
      
      <Section>
        {/* font-family: 'Noto Serif', serif; */}
        <Typography variant="h3" component="h1" className={classes.noto400} >{'Noto Serif h1 400'}</Typography>
        <Typography variant="h4" component="h1" className={classes.noto400} >{'Noto Serif h2 400'}</Typography>
        <Typography component="body1" className={classes.noto400} >{'Noto Serif body1 400'}</Typography>
        <Typography variant="body2" component="body2" className={classes.noto400} >{'Noto Serif body2 400'}</Typography>
      </Section>

      <Section>
        {/* font-family: 'Roboto Mono', monospace; */}
        <Typography component="body1" className={classes.mono500} >{'Roboto Mono body1 500'}</Typography>
        <Typography variant="body2" component="body2" className={classes.mono500} >{'Roboto Mono body2 500'}</Typography>
      </Section>
    </Page>
    // </div>
  );
}

export default Fonts;
