import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Skeleton from '@material-ui/lab/Skeleton';
// import { ApolloProvider, useQuery } from '@apollo/react-hooks';
import { ApolloProvider, useQuery } from '@apollo/client';
import { AuthorizedHandler, authorizedClient, authorizedSchema, authorizedResolvers, handleResponse } from '@org/client-graphql';
import { DataGrid } from '@material-ui/data-grid';
// import Box from '@material-ui/core/Box';
// import clsx from 'clsx';
// import { ButtonBar, IconButton } from '@org/client-components-core';
import { Page, PageHeadingWrapper, PageHeadingLeft, PageHeadingCenter, PageHeadingRight } from '@org/client-components-custom';
// import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';
// import { assetTypes, assetSubTypes, referenceAssetTypes } from '@org/common-data-types';

const localStyles = {
  ...styles,
  // root: {
  //   padding: theme.spacing(2),
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent:'flex-start',
  //   alignItems: 'center',
  // },
};

const useStyles = makeStyles(localStyles);

let rows = [];

let columns = [
  { field: 'id', headerName: 'ID', width: 220 },
  { field: 'itemName', headerName: 'Name', width: 120 },
  { field: 'coupon', headerName: 'Coupon', type: 'number', width: 90 },
  { field: 'originalAmount', headerName: 'Original Amount', type: 'number', width: 140 },
  { field: 'referenceItemType', headerName: 'Ref Item', width: 100 },
];

let authorizedHandler;

function NotesTable(props) {
  let { loading, error, data } = useQuery(authorizedSchema.LIST_NOTES, { variables: {} });

  if (error) {
    console.log(error.message);
    return (
      <div>{error.message}</div>
    );
  }

  if (data) {
    rows = [];
    let response = handleResponse({ data }); // hooks needs response.data
    console.log(location, 'NotesTable', 'response', response);
    if (response.success) {
      // rows = response.data.items;
      response.data.items.forEach(item => {
        // console.log(item);
        // if (item.__typename)
        //   delete item.__typename;
        rows.push(item);
        // console.log(rows);
      });
    }
    console.log('rows', rows);
  }

  // function handleAddNote() {
  //   console.log('Add note...');
  //   props.history.push('/admin/create-note');
  // }

  let classes = useStyles();

  return (
    <>
      {/* <ButtonBar className={classes.buttonBar} >
        <IconButton name='add-note' aria-label="add note" icon={<AddIcon fontSize='default' />} onClick={handleAddNote} className={classes.raisedIconButton} />
      </ButtonBar> */}
      <div style={{ height: 400, width: '100%' }} >
        {loading &&
        <DataGrid rows={[]} columns={columns} pageSize={5} onRowClick={props.handleRowClick} loading={true} />}
        {data &&
        <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5, 10, 20]}
          onPageChange={props.handlePageChange}
          onRowClick={props.handleRowClick}
        />}
        {/* {data &&
        <DataGrid rows={rows} columns={columns} pageSize={5} onRowClick={props.handleRowClick} />} */}
        {/* {loading && */}
        {/* <Skeleton height={'100%'} width={'100%'} > */}
          {/* <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection /> */}
          {/* <Box style={{ height: 400, width: '100%', border: '1px solid' }} /> */}
        {/* </Skeleton> */}
      </div>
    </>
  );
}

NotesTable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  handleRowClick: PropTypes.func,
  handlePageChange: PropTypes.func,
};

export function ExamplePage(props) {
  const [page, setPage] = React.useState(1);
  let classes = useStyles();

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  function handleAddNote() {
    console.log('Add note...');
    props.history.push('/admin/create-note');
  }

  const handlePageChange = (params) => {
    console.log('handlePageChange', 'params', params);
    setPage(params.page);
  };

  async function handleRowClick(event) {
    console.log('ManageNotes', 'handleRowClick', event);
    // let response = await authorizedResolvers.getNote({ id: event.data.id });
    let response = await authorizedHandler.getNote({ id: event.data.id });
    console.log('ManageNotes', 'handleRowClick', response);
    if (response.success) {
      props.history.push({
        pathname: '/admin/note',
        state: {
          data: response.data,
        },
      });
    }
  }

  return(
    <>
    <Page id='manage-notes'>
      {/* <PageHeadingWrapper>
        <PageHeadingLeft />
        <PageHeadingCenter>{'Manage Notes'}</PageHeadingCenter>
        <PageHeadingRight />
      </PageHeadingWrapper>

      <NotesTable handleRowClick={handleRowClick} handlePageChange={handlePageChange} {...props} /> */}

    </Page>
    </>
  );
}

export default ExamplePage;

ExamplePage.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
