import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
// import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloProvider } from '@apollo/client';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { BackdropLoader } from '@org/client-components-core';
import {
  AppBar,
  appBarHeight, drawerWidth,
  Footer, // CubitLoader,
} from '@org/client-components-custom';
import { Routes } from './Routes';
import theme from '@org/client-libs-themes';
import {
  publicClient,
  // PublicHandler,
  // handleAuthentication,
  authenticatedClient,
  AuthenticatedHandler,
  authorizedClient,
  publicHandler,
  // handleResponseError, // authorizedResolvers,
} from '@org/client-graphql';
import { ErrorBoundary } from '@org/client-components-utils';

// let location = 'client.app.AppComponent';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: '1 1 auto',
    height: '100%',
    minHeight: '100vh',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: '1 1 auto',
    padding: theme.spacing(0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    width: '100%',
    height: `calc(100% - ${appBarHeight}px)`,
  },
  contentShift: {
    flex: '1 1 auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  contentShiftRight: {
    flex: '1 1 auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
};

let authenticatedHandler;

class AppComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.handleOpenMainDrawer = this.handleOpenMainDrawer.bind(this);
    this.handleCloseMainDrawer = this.handleCloseMainDrawer.bind(this);
    this.handleOpenUserDrawer = this.handleOpenUserDrawer.bind(this);
    this.handleCloseUserDrawer = this.handleCloseUserDrawer.bind(this);

    this.state = {
      isLoading: true,
      mainDrawerOpen: false,
      userDrawerOpen: false,
    };
  }

  async componentDidMount() {
    console.warn('AppComponent', 'componentDidMount', 'props.location', this.props.location);
    // console.info(location, 'componentDidMount', 'props.location', JSON.stringify(this.props.location, null, ' '));
    // console.info(location, 'componentDidMount');

    // publicHandler = new PublicHandler({ location: this.props.location, history: this.props.history });
    
    // let isAuthenticated = await publicHandler.isAuthenticated();
    // console.warn('AppComponent', 'componentDidMount', 'isAuthenticated', isAuthenticated);

    authenticatedHandler = new AuthenticatedHandler({ location: this.props.location, history: this.props.history });

    let response;

    let { pathname, search } = this.props.location;

    if (search) {
      // Search string provided! (for email links, etc)
      let fullpath = pathname + search;
      console.info('AppComponent', 'componentDidMount', fullpath);
      this.props.history.push(fullpath);
    } else if (pathname === '/cubit-brand.png') {
      console.info('AppComponent', 'componentDidMount', pathname);
      this.props.history.push(pathname);
    } else if (['/sign-in', '/sign-up', '/invitation'].includes(pathname)) {
      // We don't need to do anything for these routes
      // NotAuthenticatedRoutes should redirect to /user-not-authorized if isAuthenticated = true
      console.info('AppComponent', 'NotAuthenticatedRoute', pathname);
      this.props.history.push(pathname);
    } else {
      try {
        // We can't see if there is a session cookie so we call an authenticated resolver
        response = await authenticatedHandler.getAuth();
        // console.info('AppComponent', 'componentDidMount', 'getAuth', response);

        if (response) { // The user is authenticated
          // console.log('Here');
          await publicHandler.handleAuthentication(response);
        }
      } catch (error) {
        // The user is NOT authenticated, make sure the local state is reset
        // console.error('AppComponent', 'componentDidMount', error?.code, error?.message);
        
        await publicHandler.handleClearAuthentication();
      }
    }

    this.setState({ ...this.state, isLoading: false });

  }

  async componentDidUpdate(props) {
    console.info('AppComponent', 'componentDidUpdate', props);

    // what is this for? do I need it?
    // URGENT Confirm this is to not show disclaimers on the home page...
    this.setState({ pathname: props.history.location.pathname });

  }

  handleOpenMainDrawer() {
    this.setState({ mainDrawerOpen: true });
  }

  handleCloseMainDrawer() {
    this.setState({ mainDrawerOpen: false });
  }

  handleOpenUserDrawer() {
    this.setState({ userDrawerOpen: true });
  }

  handleCloseUserDrawer() {
    this.setState({ userDrawerOpen: false });
  }

  render() {
    const { classes } = this.props;
    let { mainDrawerOpen, userDrawerOpen } = this.state;
    let mainDrawerProps = { open: mainDrawerOpen, handleCloseDrawer: this.handleCloseMainDrawer };
    let userDrawerProps = { open: userDrawerOpen, handleCloseDrawer: this.handleCloseUserDrawer };
    let appBarProps = { mainDrawerOpen, handleOpenMainDrawer: this.handleOpenMainDrawer, userDrawerOpen, handleOpenUserDrawer: this.handleOpenUserDrawer, mainDrawerProps, userDrawerProps, history: this.props.history, location: this.props.location, pathname: this.props.location.pathname };

    let routeProps = {};

    if (this.state.isLoading)
      return (<BackdropLoader />);

    return (
      <div className={classes.root} >
        <ApolloProvider client={authorizedClient} >
          <ApolloProvider client={authenticatedClient} >
            <ApolloProvider client={publicClient} >

              <ErrorBoundary>
                <Suspense fallback={<BackdropLoader />}>

                <AppBar {...appBarProps} />
                <main id='content' className={clsx(classes.content, { [classes.contentShift]: mainDrawerOpen, }, { [classes.contentShiftRight]: userDrawerOpen, })} >
        {/* <div>{'hi'}</div> */}
                  <div id="back-to-top-anchor" />
                  <Routes {...routeProps} />
                  {this.state.pathname !== '/' &&
                  <Footer pathname={this.state.pathname} />}
                </main>

                </Suspense>
              </ErrorBoundary>

            </ApolloProvider>
          </ApolloProvider>
        </ApolloProvider>
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(AppComponent));

export const App = withStyles(styles)(withRouter(AppComponent));

AppComponent.propTypes = {
  classes: PropTypes.any,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
};
