import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
// import { Link as RouterLink } from 'react-router-dom';
import { Form, Button, TextField, PasswordField } from '@org/client-components-core';
import { FormHeading, FormError } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { styles } from './styles';
import { authorizedResolvers } from '@org/client-graphql';

// TODO Don't allow Authenticated User to Sign In or Sign Up again...

let location = 'pages.admin.ResetUserPassword';

let initialValues = {
  email: "",
  password: "",
};

const useStyles = makeStyles(styles);

export function SetUserPassword(props) {
  const [ error, setError ] = useState();
  let classes = useStyles();

  async function handleSubmit(event, form) {
    event.preventDefault();
    // form.handleSetSubmitting();
    try {
      let { email, password } = form.values;
      let input = { email, password };

      let response = await authorizedResolvers.setUserPassword(input);
      if (response.success) {
        // await publicResolvers.setAuthentication(response.data.authentication);
        // props.history.push('/user');
        console.log('success');
      }
      if (response.error) {
        console.log('SignIn', response.error);
        setError(response.error);
      }
    } catch (error) {
      console.log(location, 'Unknown error in handleSubmit', error);
      setError('Unknown error in handleSubmit');
    }
  }

  return (
    <>
      <Form id='set-user-password' initialValues={initialValues} validationSchema={authSchema} {...props} >
        {props => {
        let enabled = (props.isValidated && !props.isSubmitting);
        return (
          <>
          <Container id='set-user-password' component="main" maxWidth="xs" className={classes.children} >

            <Grid className={classes.paper} >
              <form className={classes.form} noValidate >
                <Grid item >
                  <FormHeading title='Set User Password' />
                </Grid>
                {/* <Grid item >
                  <FormMessage message='Admin Reset Password' />
                </Grid> */}

                {error &&
                <Grid item >
                  <FormError message={error.message} />
                </Grid>}

                <Grid item >
                  <TextField
                    name='email'
                    type='email'
                    label='Email'
                    {...props.fieldProps}
                    required={true}
                  />
                </Grid>
                <Grid item >
                  <PasswordField name='password' {...props.passwordProps} required={true} />
                </Grid>
                <Grid item className={clsx(classes.centered, classes.buttonSpan)} >
                  <Button {...props.buttonProps} title='Submit' disabled={!enabled}
                    onClick={(event) => handleSubmit(event, props)} />
                </Grid>
              </form>
            </Grid>

            {process.env.NODE_ENV === 'development' &&
            <DisplayState title='state' state={props.stateProps} />}

          </Container>
          </>
        );
      }}
      </Form>
    </>
  );
}

SetUserPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isValidated: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  fieldProps: PropTypes.shape(),
  passwordProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
};
