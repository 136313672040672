import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import queryString from 'query-string'; // TODO Why doesn't ES6 import work for 'query-string'
import querystring from 'querystring';
import {
  AuthenticatedRoute,
  NotAuthenticatedRoute,
  AuthorizedRoute
} from '@org/client-components-utils';
// import { isAuthenticated, PageNotFound } from '@org/client-components-custom';
// import { PageNotFound, UserNotAuthorized } from '@org/client-components-custom';
import {
  SignIn, SignOut, SignUp, ConfirmResetPassword, ConfirmSignUp, ResetPassword, Unsubscribe, Success,
  Invitation, // VerifyInvitation,
} from '@org/client-pages-auth';
import { Home } from '@org/client-pages-home';
import { Learn } from '@org/client-pages-learn';
import { Invest, InvestmentDetail, EnterTrade } from '@org/client-pages-invest';
import { Partner } from '@org/client-pages-partner';
import { TheFund } from '@org/client-pages-fund';
import {
  // Account,
  AddEmail, ChangePassword, ConfirmAddEmail,
  Personal, Security, Transactions, User, Welcome,
} from '@org/client-pages-user';
import { About, Blog, Careers, News } from '@org/client-pages-company';
import { Terms, Privacy, Cookies, Brochure } from '@org/client-pages-legal';
import { CreateApplication } from '@org/client-pages-borrow';
import {
  // ManageLoans, LoanDetail,
  // ManageLoans,
  // LoanDetail,
  // CreateNote,
  // ManageNotes,
  // NoteDetail,
  // ManageSessions, ManageUsers,
  UserDetail,
  // SetUserPassword,
} from '@org/client-pages-admin';
import {
  // ExamplePage, Fonts, Headings,
  // Test, Transitions,
  InputsForm, UpdateForm, UpdateForm2,
} from '@org/client-pages-dev';
// import { InputsForm, UpdateForm } from '@org/client-pages-dev/form';

// URGENT Routes: Lazy load as many of these pages as possible...
// !!! lazy imports MUST have a default export !!!
const PageNotFound = lazy(() => import('@org/client-components-custom/PageNotFound'));
const UserNotAuthorized = lazy(() => import('@org/client-components-custom/UserNotAuthorized'));

const Admin = lazy(() => import('@org/client-pages-admin/Admin'));
const CreateCashflow = lazy(() => import('@org/client-pages-admin/CreateCashflow'));
const CreateInvitation = lazy(() => import('@org/client-pages-admin/CreateInvitation'));
const CreateLoan = lazy(() => import('@org/client-pages-admin/CreateLoan'));
const CreateNote = lazy(() => import('@org/client-pages-admin/CreateNote'));
const CreatePayment = lazy(() => import('@org/client-pages-admin/CreatePayment'));
const Manage = lazy(() => import('@org/client-pages-admin/Manage'));

const Dev = lazy(() => import('@org/client-pages-dev/Dev'));
const Cache = lazy(() => import('@org/client-pages-dev/Cache'));
const Components = lazy(() => import('@org/client-pages-dev/Components'));
const Rds = lazy(() => import('@org/client-pages-dev/Rds'));
const SVG = lazy(() => import('@org/client-pages-dev/SVG'));

/**
 * URGENT Add security logging to routes for:
 * 1) /index.html attempts which to not have a querystring
 * 2) attempts to access protected routes
 * 3) Invitation & SignIn access attempts
 */

// let publicHandler;
// let isAuthenticated;

function RouteWrapper(props) {
  console.log('RouteWrapper', 'props', props);
  const parsed = querystring.parse(props.location.search.split('?')[1]);
  console.log('RouteWrapper', 'parsed', parsed, Object.keys(parsed));

  if (parsed.invitation) {
    // return <Redirect to={{ pathname: '/invitation', state: { invitation: parsed.invitation} }} />;
    parsed.messageId = parsed.invitation;
    parsed.confirmCode = parsed.c;
    return <Redirect to={{ pathname: '/invitation', state: { data: parsed } }} />;
  // } else if (parsed.unsubscribe)
  //   // return <Unsubscribe {...props} {...parsed} />;
  //   return <Redirect to={{ pathname: '/unsubscribe', state: { unsubscribe: parsed.unsubscribe} }} />;
  } else if (parsed?.invitation === '') {
    return <Redirect to={{ pathname: '/invitation' }} />;
  // } else if (parsed['sign-in']) {
  //   return <Redirect to={{ pathname: '/sign-in', state: { data: parsed } }} />;
  } else if (props.location.pathname === '/index.html' && Object.keys(parsed).length === 0)
    return <Redirect to='/' />;
  else
    return <PageNotFound />;
}

export function Routes(props) {
  // console.log('Routes', props);
  let routeProps = props;
 
  return(
    <Switch>
      {/* /index.html is only for passing a querystring when loading the model. */}
      <Route path='/index.html' component={RouteWrapper} {...props} />

      <Route path='/user-not-authorized' exact component={UserNotAuthorized} {...props} />

      {/* Public Routes */}
      <Route path='/' exact component={Home} />
      <NotAuthenticatedRoute path='/sign-in' exact component={SignIn} {...props} />
      {/* URGENT Shouldn't /sign-out be Authenticated??? */}
      <Route path='/sign-out' exact component={SignOut} {...props} />
      <NotAuthenticatedRoute path='/sign-up' exact component={SignUp} {...props} />
      <NotAuthenticatedRoute path='/confirm-reset-password' exact component={ConfirmResetPassword} {...props} />
      <NotAuthenticatedRoute path='/confirm-sign-up' exact component={ConfirmSignUp} {...props} />
      <NotAuthenticatedRoute path='/reset-password' exact component={ResetPassword} {...props} />
      <Route path='/success' exact component={Success} {...props} />
      <NotAuthenticatedRoute path='/unsubscribe' exact component={Unsubscribe} {...props} />

      <Route path='/invitation' exact render={(props) => (<Invitation {...props} {...routeProps} />)} />
      {/* <NotAuthenticatedRoute path='/invitation/verify' exact render={(props) => (<VerifyInvitation {...props} {...routeProps} />)} /> */}
      {/* <Route path='/invitation/verify' exact render={(props) => (<VerifyInvitation {...props} {...routeProps} />)} /> */}

      <Route path='/learn' exact component={Learn} {...props} />
      <Route path='/partner' exact component={Partner} {...props} />
      <AuthenticatedRoute path='/the-fund' exact component={TheFund} {...props} />

      <Route path='/company/about' exact component={About} {...props} />
      <Route path='/company/blog' exact component={Blog} {...props} />
      <Route path='/company/news' exact component={News} {...props} />
      <Route path='/company/careers' exact component={Careers} {...props} />

      <Route path='/legal/terms' exact component={Terms} {...props} />
      <Route path='/legal/privacy' exact component={Privacy} {...props} />
      <Route path='/legal/cookies' exact component={Cookies} {...props} />
      <Route path='/legal/brochure' exact component={Brochure} {...props} />

      <Route path='/invest' exact render={(props) => (<Invest {...props} {...routeProps} />)} />
      <AuthenticatedRoute path='/invest/detail' exact component={InvestmentDetail} {...props} />
      <AuthenticatedRoute path='/invest/enter-trade' exact component={EnterTrade} {...props} />

      <AuthenticatedRoute path='/user' exact component={User} {...props} />
      <AuthenticatedRoute path='/user/welcome' exact component={Welcome} {...props} />
      <AuthenticatedRoute path='/user/personal' exact component={Personal} {...props} />
      <AuthenticatedRoute path='/user/security' exact component={Security} {...props} />
      <AuthenticatedRoute path='/user/activity' exact component={Transactions} {...props} />

      <AuthenticatedRoute path='/user/add-email' exact component={AddEmail} {...props} />
      <AuthenticatedRoute path='/user/change-password' exact component={ChangePassword} {...props} />
      <AuthenticatedRoute path='/user/confirm-add-email' exact component={ConfirmAddEmail} {...props} />

      <AuthenticatedRoute path='/borrow/application' exact component={CreateApplication} {...props} />

      <AuthorizedRoute path='/admin' exact component={Admin} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-cashflow' exact component={CreateCashflow} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-invitation' exact component={CreateInvitation} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-loan' exact component={CreateLoan} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-note' exact component={CreateNote} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/create-payment' exact component={CreatePayment} {...props} allowed={['Admin', 'Dev']} />
      <AuthorizedRoute path='/admin/manage' exact component={Manage} {...props} allowed={['Admin', 'Dev']} />
      {/* <AuthorizedRoute path='/admin/manage-loans' exact component={ManageLoans} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/manage-notes' exact component={ManageNotes} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/loan-detail' exact component={LoanDetail} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/note-detail' exact component={NoteDetail} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/manage-users' exact component={ManageUsers} {...props}  allowed={['Admin', 'Dev']} /> */}
      <AuthorizedRoute path='/admin/user-detail' exact component={UserDetail} {...props} allowed={['Admin', 'Dev']} />
      {/* <AuthorizedRoute path='/admin/manage-sessions' exact component={ManageSessions} {...props} allowed={['Admin', 'Dev']} /> */}
      {/* <AuthorizedRoute path='/admin/set-user-password' exact component={SetUserPassword} {...props} allowed={['Admin', 'Dev']} /> */}

      <AuthorizedRoute path='/dev' exact component={Dev} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/components' exact component={Components} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/cache' exact component={Cache} {...props} allowed={['Dev']} />
      {/* <AuthorizedRoute path='/dev/fonts' exact component={Fonts} {...props} allowed={['Dev']} /> */}
      <AuthorizedRoute path='/dev/form/input' exact component={InputsForm} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/form/update' exact component={UpdateForm} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/form/update2' exact component={UpdateForm2} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/rds' exact component={Rds} {...props} allowed={['Dev']} />
      <AuthorizedRoute path='/dev/svg' exact component={SVG} {...props} allowed={['Dev']} />
      {/* <AuthorizedRoute path='/dev/transitions' exact component={Transitions} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/test' exact component={Test} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/example-page' exact component={ExamplePage} {...props} allowed={['Dev']} /> */}
      {/* <AuthorizedRoute path='/dev/headings' exact component={Headings} {...props} allowed={['Dev']} /> */}

      <Route component={PageNotFound} {...props}/>
    </Switch>
  );
}

export default Routes;
