import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
// import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import {
  FormUp2, DatePicker, InLink,
  ButtonBar, PrimaryButton,
  TextField, AmountField, SelectTextField,
} from '@org/client-components-core';
import {
  Heading, FlexRow,
  FormLayout, FormMessage, FormError,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
// import { cashflowSchema } from '@org/common-yup';s
import {
  // authorizedResolvers,
  AuthorizedHandler,
} from '@org/client-graphql';
// import { defaultLoanValues } from './inputs';
import { referenceItemTypes } from '@org/common-data-types';
import { LoanClass } from '@org/common-classes/Loan';
import { CashflowClass } from '@org/common-classes/Cashflow';
import {
  itemTypeShape, ulidShape, dateShape, currencyShape,
} from '@org/common-yup';

let cashflowClass;

let initialValues;

let requiredValues;

let validationShape = {
  referenceType: itemTypeShape,
  referenceId: ulidShape,
  eventDate: dateShape,
  interestAmount: currencyShape,
  principalAmount: currencyShape,
  paymentAmount: currencyShape,
};
const validationSchema = yup.object().shape(validationShape);

let authorizedHandler;

let isMounted = false;

let title;

export function CreateCashflow(props) {
  let isMounted = true;
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null,
  });

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  if (state.isLoading) {
    console.log('CreateCashflow', props.location?.state);
    if (props.location?.state?.data) {
      // let { loanClass } = props.location.state.data;
      // if (!loanClass.getNextCashflow)
      //  loanClass = new LoanClass({ ...loanClass.attributes, ...loanClass.keys });
      // let loanClass = new LoanClass(props.location.state.data);
      // let cashflowInput = loanClass.getNextCashflow();
      // console.log('CreateCashflow', loanClass, cashflowInput);
      // cashflowClass = new CashflowClass(cashflowInput);
      cashflowClass = new CashflowClass(props.location?.state?.data);
      // console.log('CreateCashflow', 'cashflowClass', cashflowClass);
      if (cashflowClass.referenceType === 'LOAN')
        title = 'Create Loan Cashflow';
      else if (cashflowClass.referenceType === 'NOTE')
        title = 'Create Note Cashflow';
      initialValues = cashflowClass.attributes;
      requiredValues = cashflowClass.requiredValues;
    } else
      props.history.push('/admin/manage');
  }

  useEffect(()=>{
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});

    console.log('CreateCashflow', 'componentDidMount');
    
    return () => { isMounted = false; };
  },[]);

  async function handleSubmit(event, form) {
    event.preventDefault();
    let { values } = form;
    setState({ ...state, isSubmitting: true });

    let input = {
      referenceItemType: values.referenceItemType,
      referenceItemId: values.referenceItemId,
      eventDate: values.eventDate,
      interestAmount: values.interestAmount,
      principalAmount: values.principalAmount,
      paymentAmount: values.paymentAmount,
    };
    console.log('CreateCashflow', input);
    try {
      // URGENT Write responses to the cache?
      // let response = await authorizedResolvers.createCashflow(input);
      await authorizedHandler.createCashflow(input);

      if (isMounted)
        setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: `Cashflow successfully created` });

    } catch (error) {
      console.error('handleSubmit', error);
      setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  console.log('CreateCashflow', 'initialValues', initialValues);

  return (
    <>
    <FormUp2
      id='create-cashflow-form'
      {...props}
      initialValues={initialValues}
      requiredValues={requiredValues}
      validationSchema={validationSchema}
      // calculateValues={calculateValues}
    >
      {formProps => {
      let { fieldProps } = formProps;
      let fieldEnabled = !state.isLoading && !state.isSubmitting && !state.isCompleted;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      console.log('CreateCashflow', fieldEnabled, submitEnabled, formProps.values);
      return (
        <>
        <FormLayout id='create-cashflow' >

          <FlexRow>
            <InLink to='/admin/create-loan' title='Back' />
          </FlexRow>

          <Heading>{title}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <SelectTextField {...fieldProps} name={'referenceType'} label={'Reference Type'} items={referenceItemTypes} required={true} />
            </Grid>

            <Grid item >
              <TextField {...fieldProps} name='referenceId' label='Reference ID' required={true} />
            </Grid>

            <Grid item >
              <DatePicker {...fieldProps} name='eventDate' label='Cashflow Date' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='interestAmount' label='Interest' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='principalAmount' label='Principal' required={true} />
            </Grid>

            <Grid item >
              <AmountField {...fieldProps} name='paymentAmount' label='Payment' required={true} />
            </Grid>

            {/* <Grid item className={clsx(classes.centered, classes.buttonSpan)} > */}
            <Grid item >
              <ButtonBar>
                <PrimaryButton {...formProps.submitProps} title='Submit' disabled={!submitEnabled}
                onClick={(event) => handleSubmit(event, formProps)} />
              </ButtonBar>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: formProps, router: props.location.state }} open={true} />
        </>
      );}}

    </FormUp2>
    </>
  );
}

export default CreateCashflow;

CreateCashflow.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  // values: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  buttonProps: PropTypes.shape(),
  stateProps: PropTypes.shape(),
  // amount: PropTypes.number(),
};
