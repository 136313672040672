import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloProvider } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import theme from '@org/client-libs-themes';
// import { InLink } from '@org/client-components-core';
// import { FadeOutSection } from '@org/client-components-core';
// import { TriggerZoom, TriggerCollapse, TriggerFade, FadeOut, FadeIn, TriggerGrow, TriggerSlide } from '@org/client-components-core';
import {
  OnScroll, CollapseIn, CollapseOut, FadeIn, FadeOut, GrowIn, GrowOut, SlideIn, SlideOut, ZoomIn, ZoomOut
} from '@org/client-components-core';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';
// import { publicClient } from '@org/client-graphql';

let localStyles = {
  ...styles,
  homeRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  homeTitle: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    padding: theme.spacing(2),
  },
  fadeOut: {
    transition: 'opacity 3s',
  }
};

const useStyles = makeStyles(localStyles);

function Title(props) {
  let classes = useStyles();
  return(
    <Typography component='h3' variant='h3' className={classes.homeTitle} >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.element,
};

export function Transitions() {

  let classes = useStyles();

  return(
    <div className={classes.pageRoot} >

      <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
        {'Transitions'}
      </Typography>
      <div className={classes.homeRoot} >

      <OnScroll threshold={10} >
          {props => {
          let { trigger } = props;
          return (
            <CollapseIn id='fade-in' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Collapse In'}
              </Typography>
            </CollapseIn>
          );}}
        </OnScroll>
        <OnScroll threshold={40} >
          {props => {
          let { trigger } = props;
          return (
            <CollapseOut id='fade-out' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Collapse Out'}
              </Typography>
            </CollapseOut>
          );}}
        </OnScroll>

        <OnScroll threshold={80} >
          {props => {
          let { trigger } = props;
          return (
            <FadeOut id='fade-out' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Fade Out'}
              </Typography>
            </FadeOut>
          );}}
        </OnScroll>
        <OnScroll threshold={75} >
          {props => {
          let { trigger } = props;
          return (
            <FadeIn id='fade-in' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Fade In'}
              </Typography>
            </FadeIn>
          );}}
        </OnScroll>

        <OnScroll threshold={100} >
          {props => {
          let { trigger } = props;
          return (
            <GrowOut id='grow-out' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Grow Out'}
              </Typography>
            </GrowOut>
          );}}
        </OnScroll>
        <OnScroll threshold={125} >
          {props => {
          let { trigger } = props;
          return (
            <GrowIn id='grow-in' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Grow In'}
              </Typography>
            </GrowIn>
          );}}
        </OnScroll>

        <OnScroll threshold={200} >
          {props => {
          let { trigger } = props;
          return (
            <SlideIn id='slide-in' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Slide In'}
              </Typography>
            </SlideIn>
          );}}
        </OnScroll>
        <OnScroll threshold={275} >
          {props => {
          let { trigger } = props;
          return (
            <SlideOut id='slide-out' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Slide Out'}
              </Typography>
            </SlideOut>
          );}}
        </OnScroll>

        <OnScroll threshold={300} >
          {props => {
          let { trigger } = props;
          return (
            <ZoomIn id='slide-in' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Zoom In'}
              </Typography>
            </ZoomIn>
          );}}
        </OnScroll>
        <OnScroll threshold={375} >
          {props => {
          let { trigger } = props;
          return (
            <ZoomOut id='zoom-out' trigger={trigger} >
              <Typography component='h1' variant='h1' className={classes.homeTitle} >
                {'Zoom Out'}
              </Typography>
            </ZoomOut>
          );}}
        </OnScroll>
      </div>
    </div>
  );
}

export default Transitions;

Transitions.propTypes = {
  classes: PropTypes.element,
  trigger: PropTypes.bool,
};
