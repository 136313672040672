import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';

export function CheckAdornment(props) {
  if (!props.show)
    return null;
  return(
    <InputAdornment position="end">
      <CheckIcon
        name='check'
        aria-label="input field valid"
        edge="end"
        // onClick={handleShowPassword}
        // onMouseDown={handleMouseDownPassword}
        style={{ color: green[500] }}
      />
    </InputAdornment>
  );
}

CheckAdornment.propTypes = {
  show: PropTypes.bool,
};
