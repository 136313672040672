import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
// import { ApolloClient } from 'apollo-client';
// import { ApolloLink } from 'apollo-link';
// import { createHttpLink } from 'apollo-link-http';
// import { RetryLink } from 'apollo-link-retry';
// import { onError } from "apollo-link-error";
// import { setContext } from 'apollo-link-context';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { getSessionCookie, errorLink } from '@org/client-graphql';
import { errorLink, customFetch } from '@org/client-graphql';
// import { customFetch } from '../common/fetch';

// const { REACT_APP_HTTP_URI, REACT_APP_IS_OFFLINE } = process.env;
const { REACT_APP_HTTP_URI } = process.env;

// const httpLink = createHttpLink({
const httpLink = new HttpLink({
  uri: REACT_APP_HTTP_URI + '/authorized',
  credentials: 'include',
  fetch: customFetch,
});

// https://www.apollographql.com/docs/react/v2.6/networking/authentication/

const contextLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      // TODO Can I set the cookie header here in setContext instead?
    }
  };
});

const authorizedLink = ApolloLink.from([
  contextLink,
  // retryLink,
  errorLink,
  // offlineLink,
  httpLink,
]);

export const authorizedCache = new InMemoryCache();

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    // fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
};

const authorizedConfig = {
  // link: authorizedLink.concat(httpLink),
  link: authorizedLink,
  cache: authorizedCache,
  defaultOptions,
  connectToDevTools: process.env.NODE_ENV === 'development' ? true : false,
};

export const authorizedClient = new ApolloClient(authorizedConfig);
