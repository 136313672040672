// import { ulid, decodeTime } from 'ulid';
import dayjs from 'dayjs';
// import { round } from '@org/common-formatters';
// import { isDefined } from '@org/common-tools';
// import { Item } from './Item';
import { RecordClass } from './Record';

export class TradeClass extends RecordClass {
  constructor(input) {
    super(input);

    // this.table = DataTable;

    // this.schema = tradeRecordSchema;

    this.keys.itemType = 'TRADE';

    let now = dayjs();

    this.defaultValues = {
      referenceType: "",
      referenceId: "",
      userId: "",
      eventDate: now.format('YYYY-MM-DD'),
      tradeType: 'BUY',
      amount: 0.0,
      factor: 1.0,
      price: 100.0,
      investedAmount: 0.0,
      itemStatus: "",
    };

    this.attributes = {
      ...this.defaultValues,
    };

    this.calculateValues(input);

    if (input)
      this.update(input);
  }

  calculateValues(input) {
    console.info(`${this.constructor.name} calculateValues input: ${JSON.stringify(input, null, ' ')}`);

    let values = {
      ...this.attributes, // allow not all values to be passed in during construction
      ...input
    };

    values.investedAmount = values.amount * values.factor * values.price / 100;

    // if (values.investedAmount !== input.investedAmount)
    //   throw new Error(`Invested Amount does not match. input: ${input.investedAmount} calculated: ${values.investedAmount}`);

    // if (investedAmount < input.minimumAmount)
    //   throw new TradeError(`Invested Amount ${input.investedAmount} id below minimum investment amount ${note.minimumAmount}`);


    this.attributes = { ...this.attributes, ...values };

    // console.log(this.attributes);

    // form FormUp2
    return values;
  }

  // migrate() {}
}
