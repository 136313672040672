import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Page } from '@org/client-components-custom';

export function PageNotFound() {
  return(
    <Page id='sign-out' style={{ justifyContent: 'center', alignItems: 'center' }} >
      <Typography variant="h4" style={{ height: '100%', textAlign: 'center', whiteSpace: 'pre-line' }} >
        {'Page Not Found\n(This stateful web app. Manually entering a route may result in unexpected behavior.'}
        {/* In some cases, it may also result in account suspension. */}
      </Typography>
    </Page>
  );
}

export default PageNotFound;
