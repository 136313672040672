import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

// https://material-ui.com/components/click-away-listener/

export const PasswordTooltipTitle = () => {
  return(
    <>
      <Typography variant='subtitle1' >Password Requirements:</Typography>
      <Typography variant='subtitle2' >* Minimum 8 characters</Typography>
      <Typography variant='subtitle2' >* At least 1 capital letter</Typography>
      <Typography variant='subtitle2' >* At least 1 number</Typography>
      <Typography variant='subtitle2' >* At least 1 of ! @ # $ % ^ & * , .</Typography>
    </>
  );
};

export const PasswordTooltip = withStyles((theme) => ({
  tooltip: {
    margin: theme.spacing(0),
    padding: theme.spacing(2, 3, 2, 3),
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(12),
    boxShadow: theme.shadows[1],
  },
  tooltipArrow: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  arrow: {
    color: '#f5f5f9',
  },
}))(Tooltip);
