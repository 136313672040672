import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { userListItems as listItems } from './userListItems';
// import { makeStyles } from '@material-ui/core/styles';
// import { appBarStyles } from './appBarStyles';

// const useStyles = makeStyles(appBarStyles);
// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
//   ...appBarStyles,
// }));

// const CustomRouterLink = (props) => {
//   return(
//     <RouterLink { ...props } style={{ display: 'block', width: '100%' }} />
//   );
// }

const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/getting-started/installation/" {...props} style={{maxWidth: '100%', textDecoration: 'none'}} />
));

export function UserDrawerLinkList(props) {
  // const classes = useStyles();
  let { onClick } = props;
  return(
    // <div> {/* drawer will persist on list item click */}
    <div onClick={onClick} role="presentation" > {/* drawer will close on list item click */}
      <List component="nav" aria-label="user drawer links" >
      {listItems.map((item, key) => (
        /* <Link key={key} component={LinkBehavior} to={item.to} style={{maxWidth: '100%', textDecoration: 'none'}} > */
        <Link key={key} component={LinkBehavior} to={item.to} >
          {/* <ListItem button className={classes.drawerListItem} style={{ width: '100%' }} > */}
          <ListItem button >
            {/* <ListItemIcon variant="h6" className={classes.drawerListItemIcon} > */}
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            {/* <ListItemText className={classes.drawerListItemText} style={{ width: '100%' }} */}
            <ListItemText
              primary={
                <>
                <Typography /* component="span" */ variant="h5" color="textPrimary" >
                  {item.text}
                </Typography>
                </>}
            />
          </ListItem>
        </Link>
      ))}
      {/* <Link key={1} component={LinkBehavior} to={'/'} style={{width: '100%', maxWidth: '100%', textDecoration: 'none'}} >
        <ListItem button >
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
              <Typography variant="h5" color="textPrimary" >
                {'Test'}
              </Typography>
              </>}
          />
        </ListItem>
      </Link> */}
      </List>
    </div>
  );
}

UserDrawerLinkList.propTypes = {
  onClick: PropTypes.func,
};
