import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiModal } from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: '0',
    // width: `calc(80% * ${window.innerWidth}px)`,
    // height: `calc(80% * ${window.innerWidth}px * 8.5 / 11)`,
    width: '100%',
    height: '100%',
  },
});

export function Modal(props) {
  const classes = useStyles();

  let { open, handleClose } = props;

  return (
    <>
      <MuiModal
        // fullWidth={true}
        // maxWidth={true}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} >
          {props.children}
        </Fade>
      </MuiModal>
    </>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  children: PropTypes.any,
};
