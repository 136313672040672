import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InLinkWrapper } from '@org/client-components-core';
// import { MainIconButton } from '@org/client-components-custom';
import { MainIconButton } from './MainIconButton';
import { appBarStyles } from './appBarStyles';

const useStyles = makeStyles(appBarStyles);

export function AppBarBrand(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  // const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  let { mainIconButtonProps } = props;

  let style = { margin: theme.spacing(0.5, 0, 0.5, 0.5), padding: theme.spacing(1.5, 1, 1, 1) };
  if (isXs)
    style = { margin: theme.spacing(0.5, 0, 0.5, 0.5), padding: theme.spacing(1.5, 1, 1, 1) };

  return(
    <span id='app-bar-brand' className={classes.appBarBrandWrapper} >
      <MainIconButton {...mainIconButtonProps} />
      <InLinkWrapper to='/' underline='none' style={{ display: 'flex', alignItems: 'center', color: 'white' }} >
        {!isXs &&
        <div style={style} >
          <img src={'/svg/cubit.svg'} alt='Logo...' height={40} color={'white'} className={classes.appBarLogo} />
        </div>}
        <Typography variant="h3" color="inherit" className={classes.appBarBrand} >
          {process.env.REACT_APP_SHORT_NAME}
        </Typography>
      </InLinkWrapper>
    </span>
  );
}

export default AppBarBrand;

AppBarBrand.propTypes = {
  mainIconButtonProps: PropTypes.shape(),
};
