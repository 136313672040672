import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import * as yup from 'yup';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import {
  FormUp2, ButtonBar, PrimaryButton, SecondaryButton,
  Checkbox, ExLink,
} from '@org/client-components-core';
import {
  Heading, FormLayout, FormMessage, FormError, FlexColumn,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { InvitationStepper } from '@org/client-pages-auth-invitation';
import { checkboxShape } from '@org/common-yup';
import theme from '@org/client-libs-themes';
import { AuthenticatedHandler } from '@org/client-graphql';

let defaultValues = {
  isAccredited: false,
};

let initialValues;

let requiredValues = {
  isAccredited: true,
};

let validationShape = {
  isAccredited: checkboxShape
    .oneOf([true], 'You must be an accredited investor to continue'),
};
const validationSchema = yup.object().shape(validationShape);

let authenticatedHandler;

function StatusCheckbox(props) {
  return (
    <Checkbox
      name='isAccredited'
      values={props.values}
      errors={props.errors}
      label={<p>Yes, I meet the definition of an Accredited Investor according to the Securities and Exchange Commission.</p>}
      handleChange={props.handleChange}
    />
  );
}

StatusCheckbox.propTypes = {
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleChange: PropTypes.func,
};

let isMounted = false;

export function InvestorStatus(props) {
  console.info('InvestorStatus', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false,
    formMessage: 'Please enter a new password', formError: null,
  });
  let { activeStep } = props.state;

  if (!authenticatedHandler)
    authenticatedHandler = new AuthenticatedHandler({ location: props.location, history: props.history });

  if (!initialValues)
    // initialValues = { ...defaultValues, ...props.location?.state?.data };
    initialValues = defaultValues;

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  }, []);

  async function handleSubmit() {
    // console.log('handleSubmit', form, event);
    if (isMounted)
      setState({ ...state, isSubmitting: true });

    try {
      let input = { investorStatus: 'ACCREDITED' };

      // let response = await authenticatedHandler.updateMe(input);
      await authenticatedHandler.updateMe(input);

      props.setState({ ...props.state, activeStep: 'verifyIdentity' });
    } catch (error) {
      console.error('handleSubmit', 'error', error);
      setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  return (
    <>
    <FormUp2
      id='invitation-investor-status-form'
      {...props}
      initialValues={initialValues}
      requiredValues={requiredValues}
      validationSchema={validationSchema}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <InvitationStepper activeStep={activeStep} />

          <Heading>{'Investor Status'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={'Are you an Accredited Investor?'} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid style={{ margin: theme.spacing(0, 0, 3, 0) }} >
              {/* <p variant='body1' style={{width: '100%', textAlign: 'center', marginTop: theme.spacing(2)}} >You must qualify as an Accredited Investor according to U.S. Securities and Exchange Commission in order to use this web app and related services</p> */}
              {/* <p variant='body1' style={{width: '100%', textAlign: 'center', marginTop: theme.spacing(2)}} >You must qualify as an Accredited Investor in order to use this web app and related services. You may be required to verify your status as an Accredited Investor prior to entering your first transaction.</p> */}
              <p variant='body1' style={{width: '100%', textAlign: 'center', marginTop: theme.spacing(2)}} >You must qualify as an Accredited Investor in order to use this web app and related services. You may be required to verify your status as an Accredited Investor prior to entering your first transaction.</p>
            </Grid>

            <Grid style={{ padding: theme.spacing(0, 0, 0, 2) }} >
              <StatusCheckbox {...formProps.checkboxProps} />
            </Grid>

            <Grid item >
              <ButtonBar style={{ padding: theme.spacing(3, 0, 3, 0) }} >
                <PrimaryButton title='Submit' disabled={!submitEnabled}
                  onClick={(event) => handleSubmit(event, formProps)} />
                <SecondaryButton title='Cancel' /* {...skipButtonProps} onClick={handleVerifyIdentity} */ disabled={true} />
              </ButtonBar>
            </Grid>

            <Grid item >
              <FlexColumn style={{ padding: theme.spacing(0, 0, 0, 0) }} >
                <p variant='body1' style={{width: '100%', textAlign: 'center', margin: '0'}} >Not sure?</p>
                {/* <ExLink variant="body2" to='https://www.sec.gov/news/press-release/2020-191' title='SEC Amended Definition' />
                <ExLink variant="body2" to='https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3' title='(Original Definition)' /> */}
                <ExLink variant="body2" to='https://www.investor.gov/introduction-investing/general-resources/news-alerts/alerts-bulletins/investor-bulletins/updated-3' title='SEC Updated Definition' />
              </FlexColumn>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: formProps }} open={true} />
        </>
      );}}

    </FormUp2>
    </>
  );
}

InvestorStatus.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  // fieldProps: PropTypes.shape(),
  // buttonProps: PropTypes.shape(),
  state: PropTypes.shape(),
  setState: PropTypes.func,
};
