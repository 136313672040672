// import React, { useState, useEffect } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { default as MuiTable } from '@material-ui/core/Table';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { dollarAmount } from '@org/common-formatters';
import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

const useStyles = makeStyles(styles);

export function CashflowTable(props) {
  // console.log('Table', props);
  let classes = useStyles();

  // let balance = props.balance;

  return (
    // <TableContainer component={Paper} >
    <TableContainer style={{ marginBottom: theme.spacing(2), width: 'auto' }} >
      <MuiTable className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{'Date'}</TableCell>
            <TableCell align="right" >{'Balance'}</TableCell>
            <TableCell align="right" >{'Principal'}</TableCell>
            <TableCell align="right" >{'Interest'}</TableCell>
            <TableCell align="right" >{'Payment'}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.rows.map((row) => {
          {/* balance -= row.principalAmount; */}
          return (
            /* <TableRow key={row.id} onClick={(event) => handleRowClick(event, row)} hover={true} > */
            <TableRow key={row.id} hover={true} >
              <TableCell component="th" scope="row" >{dayjs(row.eventDate).format('MM/DD/YYYY')}</TableCell>
              {/* <TableCell align="right" >{dollarAmount.format(balance)}</TableCell> */}
              <TableCell align="right" >{dollarAmount.format(row.balance)}</TableCell>
              <TableCell align="right" >{dollarAmount.format(row.principalAmount)}</TableCell>
              <TableCell align="right" >{dollarAmount.format(row.interestAmount)}</TableCell>
              <TableCell align="right" >{dollarAmount.format(row.paymentAmount)}</TableCell>
            </TableRow>
          );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

CashflowTable.propTypes = {
  rows: PropTypes.array,
  // handleConfirm: PropTypes.func,
};
