import { decodeTime } from 'ulid';
import dayjs from 'dayjs';
import { AuthorizedHandler } from '@org/client-graphql';

export const columns = [
  { field: 'firstName', headerName: 'First Name', width: 160 },
  { field: 'lastName', headerName: 'Last Name', width: 160 },
  { field: 'email', headerName: 'Email', width: 270 },
  { field: 'creationDate', headerName: 'Creation Date', width: 170, type: 'date' },
];

export async function getRows(props) {
  let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let users = await authorizedHandler.listUsers();

  users.forEach(user => {
    user.creationDate = dayjs(decodeTime(user.id)).format('YY-MM-DD hh:mm a');
  });

  return users;
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
