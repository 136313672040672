import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from '@org/client-libs-styles';
// import theme from '@org/client-libs-themes';

const useStyles = makeStyles((theme) => ({
  ...styles,
  formWrapper: {
    width: '100%',
    maxWidth: '100% !important',
    [theme.breakpoints.up('sm')]: {
      minHeight: '80vh',
      padding: theme.spacing(4, 0, 4, 0),
    },
    [theme.breakpoints.only('xs')]: {
      minHeight: '60vh',
      padding: theme.spacing(2, 0, 2, 0),
    },
  },
  formLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // add media breaks
    // minHeight: '80vh',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // margin: theme.spacing(4, 4, 4, 4),
      padding: theme.spacing(4, 4, 4, 4),
      borderRadius: '4px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    [theme.breakpoints.only('xs')]: {
      // padding: theme.spacing(2),
      // margin: theme.spacing(0),
      padding: theme.spacing(3),
      boxShadow: '0px', // turn off shadow in xs
    },
  },
  formTitleVariant: 'h4',
  formHeading: {
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    margin: theme.spacing(4, 0, 4, 0),
    textAlign: 'center',
  },
}));

export function FormLayout(props) {
  const classes = useStyles();
  return(
    <>
    <div className={classes.formWrapper} >
      <Container {...props} component="main" maxWidth="xs" className={classes.formLayout} >
        {props.children}
      </Container>
    </div>
    </>
  );
}

FormLayout.propTypes = {
  children: PropTypes.any,
};

export function FormHeading(props) {
  const classes = useStyles();
  return(
    <>
    <Typography variant={styles.formTitleVariant} component='h1' className={classes.formHeading} >
      {props.title}
    </Typography>
    </>
  );
}

FormHeading.propTypes = {
  title: PropTypes.string,
};
