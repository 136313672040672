// import * as yup from 'yup';
import * as yup from 'yup/es';
import { firstNameShape, lastNameShape } from './shapes';

// export const idShape = yup.string()
//   .length(26)
//   .matches(/^[0-9A-Z]{26,26}$/, `A valid ULID is required for userId`);
//   // .required(`A valid ULID is required for userId`);

// export const itemTypeShape = yup.string()
//   .required(`A valid 'itemType' is required.`);

// export const dateShape = yup.date()
//   .transform(function (castValue, originalValue) {
//     // return this.isType(castValue) ? castValue : new Date(originalValue);
//     return Number.isNaN(originalValue) ? castValue : new Date(originalValue);
//   });

// export const emailShape = yup.string()
//   .email("Please check the email address, it doesn't appear to be a valid format")
//   .required("A valid email is required");

// export const nameShape = yup.string()
//   .trim('No leading or trailing spaces allowed')
//   .min(1)
//   .max(24);
//   // .matches(/^[a-z '-]+$/i, `A valid first name is required`)
//   // .required("First name is required");

// export const firstNameShape = nameShape
//   .matches(/^[a-z '-]+$/i, `A valid first name is required`)
//   .required("First name is required");

// export const lastNameShape = nameShape
//   .matches(/^[a-z '-]+$/i, `A valid last name is required`)
//   .required("Last name is required");

const authShape = {
  firstName: firstNameShape,
  // lastName: yup
  //   .string().trim('No leading or trailing spaces allowed')
  //   .max(24)
  //   .required("Last name is required"),
  lastName: lastNameShape,
  email: yup
    .string()
    .email("Please check the email address, it doesn't appear to be a valid format")
    .required("A valid email is required"),
  confirmCode: yup
    .string()
    .matches(/^[0-9]{6,6}$/, "A 6 digit numeric code was expected")
    .required("A 6 digit numeric code is required"),
  password: yup
    .string()
    // .notOneOf( [ yup.ref('newPassword') ], "You cannot use the same password")
    .min(8, "Passwords must be at least 8 characters")
    .max(16, "Passwords must be no more than 16 characters")
    // .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&_=+-])/, "Passwords must contain at least: 1 capital, 1 number, and 1 of these symbols: !?@#$%^&_=+-")
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&_=+-])/, "Passwords must contain at least: 1 capital, 1 number, and 1 of these symbols: !?@#$%^&_=+-")
    .required("A valid password is required"),
  newPassword: yup
    .string()
    // TODO Test that passwords don't match on the client side...
    // some of all of these might work but I am validating values one by one so I don't have access to the other values
    // .notOneOf( [ yup.ref('password') ], "You cannot use the same password")
    // .doNotMatch('password', "You cannot use the same password")
    // .test('password-match', 'You cannot use the same password', function (value) {
    //   let { password } = this.parent;
    //   console.log('yup', 'value', value, 'password', password, this.parent);
    //   return value === password;
    // })
    .min(8, "Passwords must be at least 8 characters")
    .max(16, "Passwords must be no more than 16 characters")
    // .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&_=+-])/, "Passwords must contain at least: 1 capital, 1 number, and 1 of these symbols: !?@#$%^&_=+-")
    .matches(/(?=.*[A-Z])(?=.*[0-9])(?=.*[!?@#$%^&_=+-])/, "Passwords must contain at least: 1 capital, 1 number, and 1 of these symbols: !?@#$%^&_=+-")
    .required("A valid password is required"),
  agree: yup.boolean().oneOf([true], "You must check that you agree to all"),
    // .required("You must check that you agree to the Terms of Use, Privacy Policy, and Customer Agreement."),
  message: yup.string().required('Please enter a personal message for the invitation'),
  investorStatus: yup.string().oneOf(['ACCREDITED'], "You must be an accredited"),
  isAccredited:  yup.boolean().oneOf([true], "You must verify that you are an accredited investor"),
  doNotContact: yup.boolean(),
};

export const authSchema = yup.object().shape(authShape);

const securityShape = {
  // itemId: yup.string().length(22).required('Item ID is required'),
  // itemType: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Type is required'),
  // itemName: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Name is required'),
  // amount: yup.number().required('Amount is required'),
  email: yup.string().email("Please check the email address, it doesn't appear to be a valid format"),
  backupEmail: yup.string().email("Please check the email address, it doesn't appear to be a valid format"),
  createdAt: yup.string().length(24),
  ipAddress: yup.string().max(15),
  viewerOs: yup.string().max(16),
  viewerTypeProps: yup.string().max(16),
  viewerBrowserProps: yup.string().max(16),
};

export const securitySchema = yup.object().shape(securityShape);

// const realEstateLoanShape = {
//   ...loanShape,
// };

// export const realEstateLoanSchema = yup.object().shape(realEstateLoanShape);

// https://stackoverflow.com/questions/15491894/regex-to-validate-date-format-dd-mm-yyyy
// const transactionShape = {
//   itemId: yup.string().length(22).required('Item ID is required'),
//   itemType: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Type is required'),
//   // itemName: yup.string().trim('No leading or trailing spaces allowed').max(24).required('Item Name is required'),
//   tradeType: yup.string(),
//   eventDate: yup.string(),
//   price: yup.number(),
//   amount: yup.number().positive().moreThan(1).required('Amount is required'),
// };

// export const transactionSchema = yup.object().shape(transactionShape);
