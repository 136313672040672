import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1),
    height: '100%',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    width: '100%',
  },
  body: {
    height: '100%',
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1, 1, 1),
    width: '100%',
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 2, 1, 2),
  },
  underline: {
    width: '100%',
    borderBottom: 'solid',
    borderBottomWidth: theme.spacing(0.5),
    color: theme.palette.gray,
  },
  titleText: {
    padding: theme.spacing(0, 1, 1, 1),
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    color: theme.palette.primary.main,
  },
  list: {
    padding: theme.spacing(0),
  },
  bulletPoint: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 2, 1, 2),
  },
  bullet: {
    color: theme.palette.secondary.main,
  },
  point: {
    display: 'flex',
    margin: '0',
    color: theme.palette.primary.main,
    // lineHeight: 'dense',
    lineHeight: 1.5,
  },
  style: {margin: '0'}
}));

export function Slide (props) {
  let classes = useStyles();
  return(
    <div className={classes.root} >
      {props.children}
    </div>
  );
}

Slide.propTypes = {
  children: PropTypes.any,
};

export function SlideHeader(props) {
  let classes = useStyles();
  return(
    <div className={classes.header} >
      <Typography component='h6' variant='subtitle2' >
        {props.header}
      </Typography>
    </div>
  );
}

SlideHeader.propTypes = {
  header: PropTypes.string,
};

export function SlideBody(props) {
  let classes = useStyles();
  return(
    <div className={classes.body} >
      {props.children}
    </div>
  );
}

SlideBody.propTypes = {
  children: PropTypes.any,
};

export function SlideFooter(props) {
  let classes = useStyles();
  return(
    <div className={classes.footer} >
      <Typography component='h6' variant='subtitle2' >
        {props.pg && `Page ${props.pg.toString()}`}
      </Typography>
    </div>
  );
}

SlideFooter.propTypes = {
  pg: PropTypes.number,
};

export function SlideTitle(props) {
  let classes = useStyles();
  return(
    <div className={classes.title} >
      <Typography component='h1' variant='h4' className={classes.titleText} >
        {props.text}
      </Typography>
      <div className={classes.underline} />
    </div>
  );
}

SlideTitle.propTypes = {
  text: PropTypes.string,
};

export function SlideBullets(props) {
  let classes = useStyles();
  return(
    <List aria-label="presentation bullet points" className={classes.list} >
      {props.children}
    </List>
  );
}

SlideBullets.propTypes = {
  children: PropTypes.any,
};

export function Point(props) {
  let classes = useStyles();
  let primary = <Typography variant='h6' className={classes.point} >{props.children}</Typography>;
  return(
    <ListItem className={classes.bulletPoint} >
      <ListItemIcon>
        <ChevronRightIcon fontSize='large' className={classes.bullet} />
      </ListItemIcon>
      <ListItemText disableTypography={true} primary={primary} className={classes.point} />
    </ListItem>
  );
}

Point.propTypes = {
  children: PropTypes.any,
};

export function BulletPointSlide(props) {
  return(
    <>
      <Slide>
        <SlideHeader />
        <SlideBody>
          <SlideTitle text={props.title} />
          <SlideBullets>
            {props.bullets.slice(0, props.num).map((item, key) => {
              return (<Point key={key} >{item.text}</Point>);
            })}
          </SlideBullets>
        </SlideBody>
        <SlideFooter {...props} />
      </Slide>
    </>
  );
}

BulletPointSlide.propTypes = {
  num: PropTypes.any,
  title: PropTypes.string,
  bullets: PropTypes.array,
};
