import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { default as MuiButton } from '@material-ui/core/Button';
// import Breadcrumbs from '@material-ui/core/Breadcrumbs';
// import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
// import { default as MuiSvgIcon } from '@material-ui/core/SvgIcon';
// import CloseIcon from '@material-ui/icons/Close';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import { ClippedDrawer } from '@org/client-pages-dev';
import {
  Breadcrumbs,
  ButtonBar, Button, PrimaryButton, SecondaryButton, SuccessButton,
  OutlinedButton, TextButton, LinkButton, SmallLinkButton,
  SvgIcon,
  InLink, ExLink, ButtonLink,
  TextField, NumberField, AmountField, PercentField,
} from '@org/client-components-core';
import { // Loader,
  Page, Heading, // PageHeadingWrapper, PageHeadingLeft, PageHeadingCenter, PageHeadingRight,
  Section, SectionHeading, Docs,
  HomeIcon, NextPageIcon, LoaderIcon,
  IconButton,
  FlexRow, FlexColumn,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
// import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';
// import { Chart } from './Chart';
import { ComponentList } from './components/ComponentList';
import { Progress } from './components/Progress';

const useStyles = makeStyles((theme) => ({
  ...styles,
  // section: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-start',
  //   alignItems: 'center',
  //   width: '100%',
  //   minWidth: 'fit-content',
  //   marginBottom: theme.spacing(2),
  // },
  // docs: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'flex-start',
  //   alignItems: 'center',
  //   width: '100%',
  //   minWidth: 'fit-content',
  //   marginTop: theme.spacing(2),
  // },
  // subHeading: {
  //   marginBottom: theme.spacing(2),
  // },
  greyIconButton: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.grey.main,
    color: theme.palette.primary.contrastText,
  },
  link: {
    width: 'auto',
    minWidth: 'fit-content',
  },
  typographyButton: {
    margin: theme.spacing(0, 0, 1, 0),
    textTransform: 'none'
  }
}));

// function Section(props) {
//   let classes = useStyles();

//   return(
//     <section className={classes.section} >
//       {props.children}
//     </section>
//   );
// }

// Section.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// function Heading(props) {
//   let classes = useStyles();

//   return(
//     <Typography variant="h4" component="h2" className={classes.subHeading} >{props.title}</Typography>
//   );
// }

// Heading.propTypes = {
//   title: PropTypes.string.isRequired,
// };

// function Docs(props) {
//   let classes = useStyles();

//   return(
//     <section className={classes.docs} >
//       {props.children}
//     </section>
//   );
// }

// Docs.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export function Components(props) {
  const [ open, setOpen ] = useState(true);
  const [ state, setState ] = useState({ values: { selectField: "selectField2" } });
  let classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState('progress');
  const handleListItemClick = (event, index) => {
    console.log(index);
    setSelectedIndex(index);
  };

  function handleEvent(event) {
    event.persist();
    alert(event);
  }

  function handleChange(event) {
    console.log('handleChange', event);
    setState({ ...state, [event.target.name]: event.target.value});
  }

  let isEditing = true;
  let fieldProps = {
    validated: {
      textField: true,
      numberField: true,
      amountField: true,
      percentField: true,
    },
    values: {
      textField: "Text Field",
      numberField: 0,
      amountField: 0,
      percentField: 0,
    },
    handleBlur: (event) => {
      console.log(event);
    },
    // 
    handleChange: handleChange,
  };

  function toggleDrawer() {
    setOpen(!open);
  }
  console.log(selectedIndex);

  if (selectedIndex === 'progress')
  return(
    <FlexRow>
      <ComponentList selectedIndex={selectedIndex} handleListItemClick={handleListItemClick} />
      {/* <Page>
        <Heading>{'Components'}</Heading>
      </Page> */}
      {selectedIndex === 'progress' &&
      <Progress />}
    </FlexRow>
  );

  // switch (selectedIndex) {
  //   case 'progress':
  //     return(<Progress />);
  //     break;
  //   case 'Mangoes':
  //   case 'Papayas':
  //     console.log('Mangoes and papayas are $2.79 a pound.');
  //     // expected output: "Mangoes and papayas are $2.79 a pound."
  //     break;
  //   default:
  //     console.log(`Sorry, we are out of ${expr}.`);
  // }

  return(
    <>
    <FlexRow>
    {/* <Page id='dev-home'> */}
    {/* <FlexColumn> */}
    {/* <ClippedDrawer open={open} /> */}
    <ComponentList selectedIndex={selectedIndex} handleListItemClick={handleListItemClick} />
    {/* </FlexColumn> */}
    <FlexColumn style={{ flexGrow: 1, width: 'auto' }} >

      {/* <MuiButton onClick={toggleDrawer} >{'anchor'}</MuiButton> */}

      <Breadcrumbs>
        <InLink color='inherit' title='Home' to='/' />
        <InLink color='inherit' title='Dev' to='/dev' />
        {/* <Typography color='textPrimary' >Components</Typography> */}
      </Breadcrumbs>

      {/* <PageHeadingWrapper>
        <PageHeadingLeft />
        <PageHeadingCenter>{'Dev Components'}</PageHeadingCenter>
        <PageHeadingRight />
      </PageHeadingWrapper> */}
        <Heading>{'Dev Components'}</Heading>

      <InLink className={classes.link} title='InLink' to='/internal' />

      <Section>
        <SectionHeading title='Styles' />
        <Docs>
          <ExLink title="Advanced" rel='tab' to='https://material-ui.com/styles/advanced/' />
          <ExLink title="API" rel='tab' to='https://material-ui.com/styles/api/' />
        </Docs>
      </Section>

      <Section>
        <SectionHeading title='Theme' />
        <Docs>
          <ExLink title="Default Theme" rel='tab' to='https://material-ui.com/customization/default-theme/' />
          <ExLink title="Color" rel='tab' to='https://material-ui.com/customization/color/' />
          <ExLink title="Breakpoints" rel='tab' to='https://material-ui.com/customization/breakpoints/#breakpoints' />
          <ExLink title="Theme (CSS) Media Queries" rel='tab' to='https://material-ui.com/customization/breakpoints/#css-media-queries' />
          <ExLink title="JavaScript useMediaQuery Hook" rel='tab' to='https://material-ui.com/customization/breakpoints/#javascript-media-queries' />
        </Docs>
      </Section>

      <Section>
        {/* <Typography variant="h4" component="h2" className={classes.subHeading} >Buttons</Typography> */}
        <SectionHeading title='Buttons' />

        <ButtonBar>
          <PrimaryButton title='Primary' onClick={() => alert('click')} disabled={false} />
          <SecondaryButton title='Secondary' onClick={() => alert('click')} disabled={false} />
          {/* <WarningButton title='Warning' onClick={() => alert('click')} disabled={false} /> */}
          <SuccessButton title='Success' onClick={() => alert('click')} disabled={false} />
          <Button title='Default' /* color='default' */ onClick={() => alert('click')} disabled={false} />
          <Button title='Inherit' color='inherit' onClick={() => alert('click')} disabled={false} />
          <Button title='Disabled' onClick={() => alert('click')} disabled={true} />
        </ButtonBar>

        <ButtonBar>
          <Button title='Small' size='small' onClick={() => alert('click')} disabled={false} />
          <Button title='Medium' /* size='medium' */ onClick={() => alert('click')} disabled={false} />
          <Button title='Large' size='large' onClick={() => alert('click')} disabled={false} />
          <Button title='Disable Ripple' disableRipple={true} onClick={() => alert('click')} disabled={false} />
        </ButtonBar>

        <ButtonBar>
          <OutlinedButton title='Outline' disabled={false} />
          <TextButton title='Text' disabled={false} />
          <Button title='Typography' className={styles.typographyButton} style={{textTransform: 'none'}} disabled={false} />
          <Button title='Typography' style={{textTransform: 'none'}} disabled={false} />
        </ButtonBar>

        <ButtonBar>
          <LinkButton title='LinkButton' to='/internal' validated={true} loading={false} style={{textTransform: 'none'}} />
          <SmallLinkButton title='SmallLinkButton' to='/internal' validated={true} loading={false} />
        </ButtonBar>

        <ButtonBar>
          {`'small' IconButtons: `}
          <IconButton size='small' >
            <CodeRoundedIcon fontSize='inherit' />
          </IconButton>
          <IconButton onClick={() => alert('click')} color='primary' size='small' icon={<CodeRoundedIcon fontSize='small' />} />
          <IconButton onClick={() => alert('click')} color='secondary' size='small' icon={<CodeRoundedIcon fontSize='default' />} />
          <IconButton onClick={() => alert('click')} color='action' size='small' icon={<CodeRoundedIcon fontSize='large' />} />
        </ButtonBar>

        <ButtonBar>
          {`'medium' IconButtons: `}
          <IconButton size='medium' >
            <CodeRoundedIcon fontSize='inherit' />
          </IconButton>
          <IconButton onClick={() => alert('click')} className={classes.greyIconButton} size='medium' icon={<CodeRoundedIcon fontSize='small' />} />
          <IconButton onClick={() => alert('click')} className={classes.greyIconButton} size='medium' icon={<CodeRoundedIcon fontSize='default' />} />
          <IconButton onClick={() => alert('click')} className={classes.greyIconButton} size='medium' icon={<CodeRoundedIcon fontSize='large' />} />
        </ButtonBar>

        <Docs>
          <ExLink title="Buttons Demo" rel='tab' to='https://material-ui.com/components/links/' />
          <ExLink title="Buttons API" rel='tab' to='https://material-ui.com/api/link/' />
        </Docs>
      </Section>

      <Section>
        <SectionHeading title='SvgIcon' />
        <ButtonBar style={{lineHeight: 1}} >
          {/* <MuiSvgIcon fontSize='small' style={{lineHeight: 1}} >
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </MuiSvgIcon> */}
          <SvgIcon fontSize='small' style={{verticalAlign: 'middle'}} >
            <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
          </SvgIcon>
          <HomeIcon style={{verticalAlign: 'middle'}} />
          <HomeIcon fontSize='large' style={{verticalAlign: 'middle'}} />
          <HomeIcon style={{fontSize: 40, verticalAlign: 'middle'}} />
          <NextPageIcon style={{verticalAlign: 'middle'}} />
          <LoaderIcon style={{verticalAlign: 'middle'}} />
        </ButtonBar>
        <Docs>
          <ExLink title="SvgIcon Demo" rel='tab' to='https://material-ui.com/components/icons/#svgicon' />
          <ExLink title="SvgIcon API" rel='tab' to='https://material-ui.com/api/svg-icon/' />
        </Docs>
      </Section>

      <Section>
        <SectionHeading title='Links' />

        <InLink className={classes.link} title='InLink' to='/internal' />
        <ExLink className={classes.link} title="ExLink rel='tab'" rel='tab' to='https://material-ui.com' />
        <ExLink className={classes.link} title="ExLink rel='window'" rel='window' to='https://material-ui.com' />
        <ButtonLink className={classes.link} title='ButtonLink' onClick={(event) => handleEvent(event, props)} disabled={false} />
        <ButtonLink className={classes.link} title='ButtonLink' variant={'body2'} onClick={(event) => handleEvent(event, props)} disabled={false} />

        <Docs>
          <ExLink title="Links Demo" rel='tab' to='https://material-ui.com/components/links/' />
          <ExLink title="Links API" rel='tab' to='https://material-ui.com/api/link/' />
        </Docs>
      </Section>

      <Section>
        <SectionHeading title='Inputs' />

        <TextField
          {...fieldProps} // For demonstration only
          name='textField'
          type='string'
          label='Text Field'
          required={true}
          disabled={!isEditing}
          style={{maxWidth: '38rem'}}
        />
        <NumberField
          {...fieldProps} // For demonstration only
          name='numberField'
          label='Number Field'
          required={true}
          disabled={!isEditing}
          style={{maxWidth: '38rem'}}
        />
        <PercentField
          {...fieldProps} // For demonstration only
          name='percentField'
          label='Percent Field'
          required={true}
          disabled={!isEditing}
          style={{maxWidth: '38rem'}}
        />
        <AmountField
          {...fieldProps} // For demonstration only
          name='amountField'
          label='Amount Field'
          required={true}
          disabled={!isEditing}
          style={{maxWidth: '38rem'}}
        />
        <TextField
          {...fieldProps} // For demonstration only
          select
          id='selectField'
          name='selectField'
          label="Select Field"
          // value={state.values['selectField']}
          // helperText="Please select your currency"
          style={{maxWidth: '38rem'}}
        >
          {[
            { value: 'selectField1', label: 'SelectField1' },
            { value: 'selectField2', label: 'SelectField2' },
            { value: 'selectField3', label: 'SelectField3' },
          ].map((option) => (
            <MenuItem key={option.value} /* value={option.value} */ value={option.value} >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <Docs>
          <ExLink title="TextField Demo" rel='tab' to='https://material-ui.com/components/text-fields/' />
          <ExLink title="TextField as Select Demo" rel='tab' to='https://material-ui.com/components/text-fields/#select' />
          <ExLink title="TextField API" rel='tab' to='https://material-ui.com/api/text-field/' />
          <ExLink title="Pickers Demo" rel='tab' to='https://material-ui.com/components/pickers/' />
          <ExLink title="FormControl API" rel='tab' to='https://material-ui.com/api/form-control/' />
          <ExLink title="InputLabel API" rel='tab' to='https://material-ui.com/api/input-label/' />
          <ExLink title="OutlinedInput API" rel='tab' to='https://material-ui.com/api/outlined-input/' />
          <ExLink title="Select Demo" rel='tab' to='https://material-ui.com/components/selects/' />
          <ExLink title="Select API" rel='tab' to='https://material-ui.com/api/select/' />
        </Docs>
      </Section>

      <Section>
        <SectionHeading title='Checkbox' />
        <Docs>
          <ExLink title="Checkbox Demo" rel='tab' to='https://material-ui.com/components/checkboxes/' />
          <ExLink title="Transfer List Demo" rel='tab' to='https://material-ui.com/components/transfer-list/' />
          <ExLink title="Checkbox API" rel='tab' to='https://material-ui.com/api/checkbox/' />
        </Docs>
      </Section>

      <Section>
        <SectionHeading title='Grid' />
        <Docs>
          <ExLink title="Grid Demo" rel='tab' to='https://material-ui.com/components/grid/' />
          <ExLink title="Grid API" rel='tab' to='https://material-ui.com/api/grid/' />
        </Docs>
      </Section>

      {/* <Section>
        <SectionHeading title='Recharts' />

        <Chart />

      </Section> */}

      <Section>
        <SectionHeading title='Tables' />
      </Section>

      <Section>
        <SectionHeading title='Custom' />
        {/* <Loader /> */}
        {/* <Spinner /> */}
      </Section>

      <DisplayState title='state' state={{ state }} open={true} />
    </FlexColumn>
    </FlexRow>
    {/* </Page> */}
    </>
  );
}

export default Components;
