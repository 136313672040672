import React from 'react';
import PropTypes from 'prop-types';
import { PasswordField, PasswordTooltip, PasswordTooltipTitle } from '@org/client-components-core';
// import { makeStyles } from '@material-ui/core/styles';
// import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles(styles);

// https://material-ui.com/components/click-away-listener/

export function PasswordFieldTooltip(props) {
  let { children } = props;

  // const classes = useStyles();

  return(
    <>
    <PasswordTooltip
      placement="top"
      disableHoverListener //arrow
      title={<PasswordTooltipTitle />}
    >
      <div>
        <PasswordField {...props} />
        {children}
      </div>
    </PasswordTooltip>
    </>
  );
}

export default PasswordFieldTooltip;

PasswordFieldTooltip.propTypes = {
  children: PropTypes.any,
};
