import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  SvgIconsSize, HomeIcon,
  // CubitLoader, FlexRow,
} from '@org/client-components-custom';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';

const { PUBLIC_URL } = process.env;

const localStyles = {
  ...styles,
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'flex-start',
    alignItems: 'center',
  },
  spinnerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // size: '2.4rem',
    //color: '#007bff',
    color: 'blue',
    margin: '0',
    animation: 'spin 2s infinite linear',
    // position: 'relative',
    // -webkitAnimation: 'movingImage 2s linear infinite',
    // animation: 'movingImage 2s linear 0s infinite normal none running',
  },
};

const useStyles = makeStyles(localStyles);

export function SVG() {
  let classes = useStyles();

  return(
    <div className={classes.pageRoot} >
      <Typography variant={localStyles.pageTitleVariant} component='h1' className={classes.pageTitle} >
        {'Dev'}
      </Typography>

      {/* <Spinner /> */}
      {/* <Loader style={{border: '1px solid'}}/> */}
      {/* <SvgIconsSize /> */}
      {/* <HomeIcon /> */}
      {/* <LoaderIcon /> */}

      <div style={{display: 'flex', flexDirection: 'row'}} >
        <img className={classes.spinnerStyle} src={PUBLIC_URL + '/svg/logo.svg'} alt='cubit.svg' height={50} />
        {/* <img src={PUBLIC_URL + '/svg/react-logo.svg'} alt='cubit.svg' height={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/loader.svg'} alt='cubit.svg' height={50} /> */}
        <img src={PUBLIC_URL + '/svg/logo.svg'} alt='logo.svg' height={50} width={50} />
        {/* <img src={PUBLIC_URL + '/svg/cubit.svg'} alt='cubit.svg' height={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/cubit2.svg'} alt='cubit2.svg' height={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/cubit3.svg'} alt='cubit3.svg' height={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/cubit4.svg'} alt='cubit4.svg' height={50} width={50} /> */}
        {/* <img className={classes.spinnerStyle} src={PUBLIC_URL + '/svg/cubit4.svg'} alt='cubit4.svg' height={50} width={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/cubit5.svg'} alt='cubit5.svg' height={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/layer1.svg'} alt='layer1.svg' height={50} /> */}
        {/* <img src={PUBLIC_URL + '/svg/layer1.svg'} alt='layer1.svg' height={50} /> */}
      </div>

      {/* <FlexRow>
        <CubitLoader />
      </FlexRow> */}
    </div>
  );
}

export default SVG;
