import React from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import PlusIcon from '@material-ui/icons/PlusIcon';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { styles } from '@org/client-libs-styles';

const localStyles = {
  ...styles,
};

const useStyles = makeStyles(localStyles);

// Icon fontSize = inherit | small | default | large
// let fontSize = 'default'; // default: 'inherit'
// IconButton size = small | medium
// let size = 'medium'; // default: 'medium'

function Icon(props) {
  let { icon: Icon, fontSize } = props;

  fontSize = fontSize ? fontSize : 'default';

  return (
    <Icon fontSize={fontSize} />
  );
}

Icon.propTypes = {
  icon: PropTypes.object,
  fontSize: PropTypes.string,
};

export function IconButton(props) {
  // console.log('IconButton', props);
  let { icon, handleClick } = props;
  let classes = useStyles();

  // if (!props.handleClick || props.onClick)
  //   console.error('Pass handleClick NOT onClick to IconButtons');

  let size = props.size ? props.size : 'medium';

  if (icon)
    return (
      <MuiIconButton className={classes.raisedIconButton} onClick={handleClick} size={size} >
        {icon}
      </MuiIconButton>
    );

  return(
    <MuiIconButton className={classes.raisedIconButton} onClick={handleClick} size={size} >
      {props.children}
    </MuiIconButton>
  );
}

IconButton.propTypes = {
  icon: PropTypes.object,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export function AddIconButton(props) {
  let { size, fontSize, ...rest } = props;
  size = size ? size : 'small';
  fontSize = fontSize ? fontSize : 'default';
  let icon = <Icon {...rest} icon={AddIcon} fontSize={fontSize} />;
  return(
    <IconButton {...rest} size={size} icon={icon} />
  );
}

AddIconButton.propTypes = {
  size: PropTypes.string,
  fontSize: PropTypes.string,
  // handleClick: PropTypes.func,
  // onClick: PropTypes.func,
};

// URGENT What is staticContext and why is it being sent to BackIconButton???
// export function BackIconButton(props) {
//   let { size, fontSize, ...rest } = props;
//   let classes = useStyles();
//   if (props.handleClick || props.onClick)
//     console.error('BackIconButton does not take an onClick or handleClick prop');
//   if (!props.history)
//     console.error('BackIconButton needs history prop');
//   // let { staticContext, ...rest } = props;
//   // if (!staticContext)
//   //   console.log(`What is going on here with staticContext: ${staticContext}`);
//   let icon = <ChevronLeftIcon fontSize={fontSize} />;
//   return(
//     <MuiIconButton className={classes.raisedIconButton} onClick={() => props.history.goBack()} icon={icon} {...rest} size={size} />
//   );
// }
export function BackIconButton(props) {
  let { history, ...rest } = props;
  let size = 'small';
  let fontSize = 'default';
  return (
    <IconButton size={size} {...rest} handleClick={() => history.goBack()} >
      <ChevronLeftIcon fontSize={fontSize} />
    </IconButton>
  );
}

BackIconButton.propTypes = {
  size: PropTypes.string,
  fontSize: PropTypes.string,
  history: PropTypes.shape(),
  // staticContext: PropTypes.any,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
};

export function CloseIconButton(props) {
  let size = 'small';
  let fontSize = 'default';
  return (
    <IconButton size={size} {...props} >
      <CloseOutlinedIcon fontSize={fontSize} />
    </IconButton>
  );
}

CloseIconButton.propTypes = {
  size: PropTypes.string,
  fontSize: PropTypes.string,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
};

// export function EditIconButton(props) {
//   let { size, fontSize, handleClick, ...rest } = props;
//   if (!handleClick || props.onClick)
//     console.error('Pass handleClick NOT onClick to IconButtons');
//   let classes = useStyles();
//   let icon = <EditOutlinedIcon {...rest} fontSize={fontSize} />;
//   return(
//     <MuiIconButton className={classes.raisedIconButton} onClick={handleClick} icon={icon} {...rest} size={size} />
//   );
// }

export function EditIconButton(props) {
  // let { size, fontSize, onClick } = props;
  // size = size ? size : 'medium';
  // fontSize = fontSize ? fontSize : 'inherit';
  let size = 'small';
  let fontSize = 'default';
  return (
    <IconButton size={size} {...props} >
      <EditOutlinedIcon fontSize={fontSize} />
    </IconButton>
  );
}

EditIconButton.propTypes = {
  size: PropTypes.string,
  fontSize: PropTypes.string,
  handleClick: PropTypes.func,
  onClick: PropTypes.func,
};
