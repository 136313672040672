import React from 'react';

// https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser

export function MethodOne() {
  let userAgent = "";

  // Opera 8.0+
  if ((!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0)
    userAgent = 'Opera';
  // Firefox 1.0+
  else if (typeof InstallTrigger !== 'undefined')
    userAgent = 'Firefox';
  // Safari 3.0+ "[object HTMLElementConstructor]" 
  else if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)))
    userAgent = 'Safari';
  // Internet Explorer 6-11
  // var isIE = /*@cc_on!@*/false || !!document.documentMode;
  else if (/*@cc_on!@*/false || !!document.documentMode)
    userAgent = 'IE';
  // Edge 20+
  else if(userAgent != 'IE' && !!window.StyleMedia)
    userAgent = 'Edge';
  // Chrome 1 - 79
  else if(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime))
    userAgent = 'Chrome';
  // Edge (based on chromium) detection
  else if (userAgent != 'Chrome' && (navigator.userAgent.indexOf("Edg") != -1))
    userAgent = 'Edge';
  // Blink engine detection
  else if ((userAgent != 'Chrome' || userAgent != 'Opera') && !!window.CSS)
    userAgent = 'Blink';

  return <div>{`Method 1: ${userAgent}`}</div>;
}

export function MethodTwo() {
  let userAgent = "";
  if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 )
    userAgent = 'Opera';
  else if(navigator.userAgent.indexOf("Chrome") != -1 )
    userAgent = 'Chrome';
  else if(navigator.userAgent.indexOf("Safari") != -1)
    userAgent = 'Safari';
  else if(navigator.userAgent.indexOf("Firefox") != -1 )
    userAgent = 'Firefox';
  else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    userAgent = 'IE';
  else
    userAgent = 'unknown';

  return <div>{`Method 2: ${userAgent}`}</div>;
}

// https://developers.whatismybrowser.com/useragents/explore/operating_system_name/windows-phone/
// userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 11_0_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36"
// userAgent: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.16; rv:83.0) Gecko/20100101 Firefox/83.0"
// userAgent: "Mozilla/5.0 (Linux; Android 11; Pixel 3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.101 Mobile Safari/537.36"
// User Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36
// User Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:82.0) Gecko/20100101 Firefox/82.0
// User Agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36 Edg/87.0.664.66 // MS Edge
// Mozilla/5.0 (Mobile; Windows Phone 8.1; Android 4.0; ARM; Trident/7.0; Touch; rv:11.0; IEMobile/11.0; NOKIA; Lumia 635) like iPhone OS 7_0_3 Mac OS X AppleWebKit/537 (KHTML, like Gecko) Mobile Safari/537

export function getDeviceType() {
  const check = function(regexp) { return regexp.test(navigator.userAgent); };
  switch (true) {
    case check(/Macintosh; Intel Mac OS/i): return 'Desktop';
    case check(/Windows NT/i): return 'Desktop';
    case check(/Windows Phone/i): return 'Mobile';
    case check(/Windows Mobile/i): return 'Mobile';
    default: return 'Unknown';
  }
}

export function getDeviceOs() {
  const check = function(regexp) { return regexp.test(navigator.userAgent); };
  switch (true) {
    case check(/Macintosh; Intel Mac OS/i): return 'macOS';
    case check(/Windows NT/i): return 'Windows';
    case check(/Windows Phone/i): return 'Windows Phone';
    case check(/Windows Mobile/i): return 'Windows Mobile';
    case check(/Android/i): return 'Android';
    case check(/Linux/i): return 'Linux';
    case check(/iOS/i): return 'iOS';
    default: return 'Unknown';
  }
}

export function getBrowserType() {
  const check = function(regexp) { return regexp.test(navigator.userAgent); };
  switch (true) {
    case check(/Edg/i): return 'MS Edge';
    case check(/Trident/i): return 'MS IE';
    case check(/IEMobile/i): return 'MS IE Mobile';
    case check(/Firefox|fxios/i): return 'Firefox';
    case check(/opr/i): return 'Opera';
    case check(/Chrome|chromium|crios/i): return 'Chrome';
    case check(/Mobile Safari/i): return 'Mobile Safari';
    case check(/Safari/i): return 'Safari';
    default: return 'Unknown';
  }
}

export function getBrowserVersion(browserType) {
  const userAgent = navigator.userAgent;
  let browserVersion = "";
  switch (browserType) {
    case 'Chrome':
      browserVersion = userAgent.split('Chrome/')[1].split(' ')[0];
      break;
    case 'Safari':
      browserVersion = userAgent.split('Safari/')[1];
      break;
    case 'Firefox':
      browserVersion = userAgent.split('Firefox/')[1];
      break;
    default: return 'Unknown';
  }
  let regex = /^[0-9.]/g; // Only allow numbers and '.'
  if (regex.test(browserVersion))
    return browserVersion;
  else
    return 'Unknown';
}

export function getBrowser() {
  let type = getBrowserType();
  let version = getBrowserVersion(type);
  return { type, version};
}

export function ViewerAgent() {
  let browser = getBrowser();
  return (
    // <div style={{border: '1px solid'}} >
    <div>
      {/* <div>{`User Agent`}</div> */}
      {/* <MethodOne /> */}
      {/* <MethodTwo /> */}
      {/* <div>User Agent: {navigator.userAgent}</div> */}
      <div>Device Type: {getDeviceType()}</div>
      <div>Device OS: {getDeviceOs()}</div>
      <div>Browser Type: {browser.type}</div>
      <div>Browser Version: {browser.version}</div>
    </div>
  );
}

export default ViewerAgent;
