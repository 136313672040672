import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
// import { styles } from '@org/client-libs-styles';
import theme from '@org/client-libs-themes';

// https://material-ui.com/components/pickers/#material-ui-pickers
// https://material-ui-pickers.dev/demo/datepicker
// https://material-ui-pickers.dev/api/KeyboardDatePicker

let styles = {
  datePicker: {
    margin: theme.spacing(0, 0, 2, 0),
  }
};

const useStyles = makeStyles(styles);

export function DatePicker(props) {
  // console.log('DatePicker', props);
  // console.log('DatePicker', props.name, props.values[props.name]);
  // let { name, type, label, values, errors, validated, handleChange, handleBlur, required, ...rest } = props;
  let { name, label, values, errors, handleChange, required } = props;

  let value = (values && name && values[name]) != null ? values[name] : "";
  value = props.value ? props.value : value;
  let error = (errors && name && errors[name]) ? true : false;

  const classes = useStyles();

  return(
    <MuiPickersUtilsProvider utils={DayjsUtils} >
      <div className={classes.datePicker} >
        <MuiKeyboardDatePicker
          // {...rest}
          id={name}
          name={name}
          label={label}
          fullWidth
          disableToolbar
          variant='inline'
          inputVariant='outlined'
          format='MM/DD/YYYY'
          // margin='normal'
          // value={selectedDate}
          value={value}
          error={error} // necessary?
          helperText={error && errors[name]}
          // onChange={handleDateChange}
          // onChange={handleChange ? handleChange : null}
          // onChange={(event, ) => handleChange(event, name)}
          onChange={(date) => handleChange(date, name)}
          required={required ? required : false}
          disabled={props.disabled}
          autoOk={true} // close picker on date select
          minDate={props.minDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  validated: PropTypes.shape(),
  handleChange: PropTypes.func,
  handleChangeEvent: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  endAdornment: PropTypes.node,
  autoFocus: PropTypes.bool,
};
