import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Form, ButtonBar, PrimaryButton, TextField, EmailField, PasswordFieldTooltip } from '@org/client-components-core';
import { FormLayout, Heading, FormMessage, FormError, AgreeCheckbox } from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { authSchema } from '@org/common-yup';
import { handleError } from '@org/common-errors';
import { styles } from '@org/client-libs-styles';
import { PublicHandler } from '@org/client-graphql';

const useStyles = makeStyles(styles);

let defaultValues = {
  email: "",
  confirmCode: "",
  password: "",
  agree: false,
};

let stateValues = {};

let isMounted = false;
let publicHandler;

let resend = 0;

export function ConfirmSignUp(props) {
  const [ state, setState ] = useState({ isLoading: true, isSubmitting: false, formMessage: 'Please check your email and enter the code here', formError: null });
  let classes = useStyles();

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (props.location.state && props.location.state.data)
      stateValues = props.location.state.data;

    if (isMounted)
      setState({ ...state, isLoading: false });

    return () => { isMounted = false; };
  }, []);

  async function handleResendCode(event, form) {
    event.preventDefault();
    let { email } = form.values;

    try {
      if (!form.validated['email'])
        throw new Error('Please enter a valid email address');

      let input = { email };
      await publicHandler.resendSignUp(input);

      let formMessage;
      if (resend === 0)
        formMessage = `New code sent. Don't forget to check your spam folder.`;
      else if (resend === 1)
        formMessage = `Another new code sent. Are you sure you entered the correct email address?`;

      setState({ ...state, formMessage, formError: null });
    } catch (error) {
      setState({ ...state, formError: error });
    }
  }

  async function handleSubmit(event, form) {
    event.preventDefault();
    setState({ ...state, isSubmitting: true, formMessage: null, formError: null });
    try {
      let { email, confirmCode, password } = form.values;
      let input = { email, confirmCode, password };

      let response = await publicHandler.confirmSignUp(input);

      await publicHandler.handleAuthentication(response);
      props.history.push('/user');

    } catch (error) {
      if (isMounted)
        setState({ ...state, isSubmitting: false, formError: handleError({ error }) });
    }
  }

  return (
    <>
    <Form id='confirm-sign-up' defaultValues={defaultValues} stateValues={stateValues} validationSchema={authSchema} onSubmit={handleSubmit} {...props} >
      {props => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && props.isValidated;
      return (
        <>
        <FormLayout id='confirm-email' >

            <Heading>{'Confirm Sign Up'}</Heading>

            <Grid id='confirm-email' style={{width: '100%'}} >

              <Grid item >
                <FormMessage message={state.formMessage} />
              </Grid>

              {state.formError &&
              <Grid item >
                <FormError message={state.formError.message} />
              </Grid>}

              {!stateValues.email && // show email it was not passed in from props.location.state.data
              <Grid item >
                <EmailField {...props.fieldProps} name='email' required={true} disabled={!fieldEnabled} />
              </Grid>}

              <Grid item >
                <TextField {...props.fieldProps}
                  name='confirmCode'
                  label='Confirm Code'
                  disabled={!fieldEnabled}
                  required={true}
                />
              </Grid>

              <Grid item >
                <PasswordFieldTooltip name='password' {...props.passwordProps} required={true} disabled={!fieldEnabled} />
              </Grid>

              <Grid>
                <AgreeCheckbox {...props.checkboxProps} disabled={!fieldEnabled} />
              </Grid>

              <Grid>
                <ButtonBar>
                  <PrimaryButton
                    {...props.submitProps}
                    name='confirm-sign-Up'
                    title='Submit'
                    disabled={!submitEnabled}
                    onClick={(event) => handleSubmit(event, props)}
                  />
                </ButtonBar>
              </Grid>

              <Grid item className={classes.bottomLink} >
                <Link variant='body2' component="button" onClick={(event) => handleResendCode(event, props)} >
                  {'Resend confirmation code'}
                </Link>
              </Grid>

            </Grid>

          </FormLayout>

          <DisplayState title='state' state={{ local: state, defaultValues, stateValues, form: props.stateProps }} open={true} />
          </>
        );
        }}
      </Form>
    </>
  );
}

ConfirmSignUp.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  stateProps: PropTypes.shape(),
  fieldProps: PropTypes.shape(),
  passwordProps: PropTypes.shape(),
  checkboxProps: PropTypes.shape(),
  submitProps: PropTypes.shape(),
};
