import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from '@material-ui/core/styles';
import { Page, Heading } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';

// const localStyles = {
//   ...styles,
// };

// const useStyles = makeStyles(localStyles);

let isMounted = false;

export function News() {
  const [ state, setState ] = useState({ isLoading: true });
  // let classes = useStyles();

  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) { // only do this on the initial page load
        window.scrollTo(0, 0);
        if (isMounted)
            setState({ ...state, isLoading: false });
      }
    })();
    
    return () => { isMounted = false; };
  }, []);

  return (
    <Page id='news' >
      <Heading>{'News'}</Heading>
      Coming soon...
    </Page>
  );
}

// Blog.propTypes = {
//   system: PropTypes.shape(),
// };
