import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import * as yup from 'yup';
import * as yup from 'yup/es';
import Grid from '@material-ui/core/Grid';
import {
  FormUp2, ButtonBar, PrimaryButton, PasswordFieldTooltip,
} from '@org/client-components-core';
import {
  Heading, FormLayout, FormMessage, FormError, AgreeCheckbox,
} from '@org/client-components-custom';
import { InvitationStepper } from '@org/client-pages-auth-invitation';
import { DisplayState } from '@org/client-components-utils';
import { agreeShape, confirmCodeShape, passwordShape, ulidShape } from '@org/common-yup';
import { PublicHandler } from '@org/client-graphql';

let defaultValues = {
  confirmCode: "",
  messageId: "",
  password: "",
  agree: false,
};

let initialValues;

let requiredValues = {
  password: true,
  agree: true,
};

let validationShape = {
  confirmCode: confirmCodeShape,
  messageId: ulidShape,
  password: passwordShape,
  agree: agreeShape,
};
const validationSchema = yup.object().shape(validationShape);

let publicHandler;

export function ChangePassword(props) {
  console.info('ChangePassword', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false,
    formMessage: 'Please enter a new password', formError: null,
  });
  let { activeStep } = props.state;

  if (!publicHandler)
    publicHandler = new PublicHandler({ location: props.location, history: props.history });

  if (!initialValues)
    initialValues = { ...defaultValues, ...props.location?.state?.data };

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});
    
    return () => { isMounted = false; };
  }, []);

  async function handleSubmit(event, form) {
    // console.log('handleSubmit', 'event', event, 'form', form);
    let { values } = form;
    setState({ ...state, isSubmitting: true });

    try {
      let input = {
        email: values.email, // manual
        messageId: values.messageId, // link
        confirmCode: values.confirmCode, // props.state.data.c,
        password: values.password,
      };

      let response = await publicHandler.confirmInvitation(input);
  
      await publicHandler.handleAuthentication(response);

      props.setState({ ...props.state, activeStep: 'investorStatus' });
    } catch (error) {
      setState({ ...state, isSubmitting: false, formError: error });
    }
  }

  return (
    <>
    <FormUp2
      id='invitation-change-password-form'
      {...props}
      initialValues={initialValues}
      requiredValues={requiredValues}
      validationSchema={validationSchema}
    >
      {formProps => {
      let fieldEnabled = !state.isLoading && !state.isSubmitting;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <InvitationStepper activeStep={activeStep} />

          <Heading>{'Change Password'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <PasswordFieldTooltip name='password' {...formProps.passwordProps} required={true} />
            </Grid>

            <Grid>
              <AgreeCheckbox {...formProps.checkboxProps} />
            </Grid>

            <Grid item >
              <ButtonBar>
                <PrimaryButton title='Submit' disabled={!submitEnabled}
                  onClick={(event) => handleSubmit(event, formProps)} />
              </ButtonBar>
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: formProps }} open={true} />
        </>
      );}}

    </FormUp2>
    </>
  );
}

ChangePassword.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  isValidated: PropTypes.bool,
  // fieldProps: PropTypes.shape(),
  // buttonProps: PropTypes.shape(),
  state: PropTypes.shape(),
  setState: PropTypes.func,
};
