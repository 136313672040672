import dayjs from 'dayjs';
import { RecordClass } from './Record';

export class CashflowClass extends RecordClass {
  constructor(input) {
    super(input);

    // this.table = UserTable;

    // this.schema = loanRecordSchema;

    this.keys.itemType = 'CASHFLOW';
    // this.keys.itemType = `CASHFLOW-${input.referenceId}-${input.referenceId}`;

    this.defaultValues = {
      referenceType: "", // 'NOTE' | 'LOAN'
      referenceId: "",
      eventDate: dayjs().format('YYYY-MM-DD'),
      interestAmount: 0.0,
      principalAmount: 0.0,
      paymentAmount: 0.0,
      balance: 0.0,
    };

    this.requiredValues = {};
    // this.requiredValues = {
    //   referenceType: true,
    //   referenceId: true,
    // };

    this.attributes = {
      ...this.defaultValues,
    };

    this.calculateValues(input);

    if (input)
      this.update(input);
  }

  calculateValues(input) {
    console.info(`${this.constructor.name} calculateValues input: ${JSON.stringify(input, null, ' ')}`);

    // We need the to make a copy so we can compare the results with input in FormUp2
    let values = {
      ...this.attributes, // allow not all values to be passed in during construction
      ...input
    };

  //   if (!values.balance !== this.attributes.balance)
  //     values.balance -= this.attributes.principalAmount;

  //   this.attributes = { ...this.attributes, ...values };

    // for FormUp2
    return values;
  }

}
