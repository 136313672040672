import React, { Component } from 'react';
// import { useQuery } from '@apollo/react-hooks';
// import { useQuery } from '@apollo/client';
// import gql from 'graphql-tag';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '@org/client-libs-styles';
import { publicClient, publicSchema } from '@org/client-graphql';

// export async function isOnline() {
//   // let response = await publicResolvers.isOnline();
//   let response = await publicHandler.isOnline();
//   return response;
// }

// const IS_ONLINE = gql`{ isOnline @client }`;

class OnlineComponent extends Component {
  constructor(props) {
    super(props);
    this.setOnline = this.setOnline.bind(this);
    this.state = {
      isOnline: false,
    };
  }

  componentDidMount() {
    this.setOnline();
    window.addEventListener('online', this.setOnline);
    window.addEventListener('offline', this.setOnline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.setOnline);
    window.removeEventListener('offline', this.setOnline);
  }

  async setOnline() {
    let isOnline = navigator.onLine;
    this.setState({ isOnline });
    // publicClient.writeData({ data: { isOnline } });
    publicClient.writeQuery({
      query: publicSchema.IS_ONLINE,
      data: { isOnline },
    });
  }

  render() {
    if (this.state.isOnline) return null;

    return(
      <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center' }} >
        {'OFFLINE'}
      </Typography>
    );
  }
}

export const Online = withStyles(styles)(OnlineComponent);
