import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloProvider } from '@apollo/client';
// import { authorizedClient } from '@org/client-graphql';
import { InLink } from '@org/client-components-core';
// import { Page, Heading, FlexRow, GetUser } from '@org/client-components-custom';
import { Page, Heading, FlexRow } from '@org/client-components-custom';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(1),
    // padding: theme.spacing(0, 0, 0, 1),
    whiteSpace: 'nowrap',
    border: '1px solid',
    borderRadius: '4px',
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexStart',
    padding: theme.spacing(0, 0, 0, 1),
    width: '100%',
    // borderBottom: '1px solid',
  },
  sectionBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 1, 1),
  },
}));

let isMounted = false;

function Section(props) {
  const classes = useStyles();
  return (
    <>
    <section className={classes.section} >
      {props.children}
    </section>
    </>
  );
}

Section.propTypes = {
  children: PropTypes.node,
};

function SectionTitle(props) {
  const classes = useStyles();
  return (
    <span className={classes.sectionTitle} >
      {props.children}
    </span>
  );
}

SectionTitle.propTypes = {
  children: PropTypes.string,
};

function SectionBody(props) {
  const classes = useStyles();
  return (
    <span className={classes.sectionBody} >
      {props.children}
    </span>
  );
}

SectionBody.propTypes = {
  // children: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
};

export function Admin() {
  const [ state, setState ] = useState({ isLoading: true });
  useEffect(() => {
    isMounted = true;

    (async () => {
      if (state.isLoading) {
        window.scrollTo(0, 0);

        if (isMounted)
          setState({ ...state, isLoading: false});
      }
    })();
    
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Page>
      <Heading>{'Admin Home'}</Heading>

      {/* <GetUser id={'ZbOraWCaRKO3yzfb8mUUfA'} /> */}

      <FlexRow>

        <Section name='users' >
          <SectionTitle>{'Users'}</SectionTitle>
          <SectionBody>
            {/* <InLink title='Manage Users' to='/admin/manage-users' /> */}
            {/* <InLink title='Manage Sessions' to='/admin/manage-sessions' /> */}
            <InLink title='Manage' to='/admin/manage' />
            <InLink title='Create Invitation' to='/admin/create-invitation' />
          </SectionBody>
        </Section>

        <Section name='notes' >
          <SectionTitle>{'Notes'}</SectionTitle>
          <SectionBody>
            {/* <InLink title='Manage Notes' to='/admin/manage-notes' /> */}
            <InLink title='Create Note' to='/admin/create-note' />
          </SectionBody>
        </Section>

        <Section name='loans' >
          <SectionTitle>{'Loans'}</SectionTitle>
          <SectionBody>
            {/* <InLink title='Manage Loans' to='/admin/manage-loans' /> */}
            <InLink title='Create Loan' to='/admin/create-loan' />
          </SectionBody>
        </Section>

        <Section name='cashflows' >
          <SectionTitle>{'Cashflows'}</SectionTitle>
          <SectionBody>
            <InLink title='Create Cashflow' to='/admin/create-cashflow' />
          </SectionBody>
        </Section>

      </FlexRow>

    </Page>
  );
}

export default Admin;

Admin.propTypes = {
  history: PropTypes.shape(),
};
