import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { default as MuiLink } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
// import {useHistory} from "react-router-dom";

const localStyles = {
  link: {
    display: 'inline',
    whiteSpace: 'nowrap',
  },
};

const useStyles = makeStyles(localStyles);

/**
 * A React-Router Link to an internal address.
 */
export function InLink(props) {
  let classes = useStyles();
  let { to, title } = props;
  return(<MuiLink className={classes.link} component={RouterLink} to={to} {...props} >{title}</MuiLink>);
}

InLink.propTypes = {
  /** The link address */
  to: PropTypes.string,
  /** The link title */
  title: PropTypes.string,
};

/**
 * A React-Router Link wrapper to an internal address.
 * Use this to wrap around another component(s).
 */
export function InLinkWrapper(props) {
  let classes = useStyles();
  let { to, children } = props;
  return(<MuiLink className={classes.link} component={RouterLink} to={to} {...props} >{children}</MuiLink>);
}

InLinkWrapper.propTypes = {
  /** The link address */
  to: PropTypes.string,
  /** The link title */
  children: PropTypes.any,
};

/**
 * A Material-UI Link to an external address.
 */
export function ExLink(props) {
  // console.log('ExLink', 'props', props);
  let { to, title, rel } = props;
  let classes = useStyles();

  // console.log(to, title, rel);

  if (rel && rel === 'same')
    return(<MuiLink className={classes.link} href={to} {...props} >{title}</MuiLink>);

  if (rel && rel === 'window')
    return (<MuiLink className={classes.link} href={to} target='popup' onClick={() => { window.open(to, 'popup','width=400', 'height=600'); return false; }} {...props} >{title}</MuiLink>);

  return(<MuiLink className={classes.link} href={to} target='_blank' rel='noopener noreferrer' {...props} >{title}</MuiLink>);
}

ExLink.propTypes = {
  /** The link address */
  to: PropTypes.string,
  /** The link title */
  title: PropTypes.string,
  /** Where to open the link. Default: `tab` */
  rel: PropTypes.oneOf(['same', 'tab', 'window']),
};

/**
 * A Material-UI Link to download a file.
 */
/* <Link className={classes.download} variant='h6' underline="always" href={url} download={file} >download the spreadsheet model</Link> */
export function DownloadLink(props) {
  let classes = useStyles();
  let underline = props.underline ? props.underline : 'always';
  return (<MuiLink className={classes.link} underline={underline} href={props.to} download={props.download} {...props} >{props.title}</MuiLink>);
}

DownloadLink.propTypes = {
  /** The link address */
  to: PropTypes.string,
  /** The link title */
  title: PropTypes.string,
  /** Path to file to download. Either /static/media if file is import or a blob url. */
  download: PropTypes.string,
  /** Override. Default: 'always' */
  underline: PropTypes.oneOf(['always', 'hover', 'none']),
};

export function ButtonLink(props) {
  // console.log('ButtonLink', props);

  let classes = useStyles();

  if (!props.onClick)
    console.warn('ButtonLink must receive onClick prop');

  let onClick;
  if (props.onClick)
    onClick = props.onClick;
  else if (props.handleClick)
    onClick = props.handleClick;

  let variant = props.variant ? props.variant : 'body1';

  function handleClick(event) {
    event.preventDefault();
    // console.log('ButtonLink', 'handleClick', event);
    onClick(event);
  }

  return (<MuiLink component='button' className={classes.link} variant={variant} onClick={handleClick} >{props.title}</MuiLink>);
}

ButtonLink.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.string,
  handleClick: PropTypes.func,
  onClick: PropTypes.func, // URGENT Make a decision between onClick vs handleClick in props...
};

// https://stackoverflow.com/questions/46681387/react-router-v4-how-to-go-back
export function GoBackButtonLink(props) {
  // console.log('GoBackButtonLink', props);

  let title = props.title ? props.title : 'Back';

  return (<ButtonLink title={title} handleClick={props.history.goBack} />);
}

GoBackButtonLink.propTypes = {
  history: PropTypes.shape(),
};
