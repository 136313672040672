import dayjs from 'dayjs';
import { AuthorizedHandler } from '@org/client-graphql';

export const columns = [
  { field: 'id', headerName: 'Email', width: 250 },
  // { field: 'firstName', headerName: 'First Name', width: 130 },
  // { field: 'lastName', headerName: 'Last Name', width: 130 },
  // { field: 'fullName', headerName: 'Full Name', width: 160,
  //   valueGetter: (params) => `${params.getValue(params.id, 'firstName')} ${params.getValue(params.id, 'lastName')}` },
  { field: 'confirmationDate', headerName: 'Confirmation Date', width: 170, type: 'date' },
  { field: 'userId', headerName: 'User ID', width: 260 },
];

export async function getRows(props) {
  let authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  let emails = await authorizedHandler.listEmails();

  emails.forEach(email => {
    email.confirmationDate = dayjs(email.confirmationDate).format('YY-MM-DD hh:mm a');
  });

  return emails;
}

export async function handleRowClick(event) {
  alert(JSON.stringify(event.row, null, ' '));
}
