import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import theme from '@org/client-libs-themes';
// import { InLink } from '@org/client-components-core';
import { styles } from '@org/client-libs-styles';

let localStyles = {
  ...styles,
  fadeOutSection: {
    opacity: '1',
    // transform: 'translateY(1vh)',
    visibility: 'visible',
    transition: 'opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out',
    willChange: 'opacity, transform, visibility',
  },
  hidden: {
    opacity: '0',
    transform: 'none',
    visibility: 'hidden',
  }
};

const useStyles = makeStyles(localStyles);

export function FadeOutSection(props) {
  let classes = useStyles();
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <div
      // className={`${isVisible ? 'fadeOutSectionVisible' : 'fadeOutSectionHidden'}`}
      className={!isVisible ? clsx(classes.fadeOutSection, classes.hidden) : clsx(classes.fadeOutSection)}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

FadeOutSection.propTypes = {
  children: PropTypes.any,
};
