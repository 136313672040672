import React from 'react';
// import PropTypes from 'prop-types';
// import { useQuery } from '@apollo/react-hooks';
import { useQuery } from '@apollo/client';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import { useStyles } from './appBarStyles';
// import CodeRoundedIcon from '@material-ui/icons/Code';
// import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
// import Fade from '@material-ui/core/Fade';
import SupervisorAccountSharpIcon from '@material-ui/icons/SupervisorAccountSharp';
import { IconButton } from '@org/client-components-core';
import { publicSchema } from '@org/client-graphql';

export function AdminIconButton(props) {
  // const { loading, data, error } = useQuery(publicSchema.IS_ADMINISTRATOR);
  const { data } = useQuery(publicSchema.IS_ADMINISTRATOR);
  const theme = useTheme();
  // const isSm = useMediaQuery(theme.breakpoints.down("sm")); // not only!!!
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  // console.log('AdminIconButton', loading, data, error);
  let isAdmin = true;
  if (data)
    isAdmin = data.isAdministrator;
  // const classes = useStyles();

  let size = 'medium';
  let fontSize = 'large';
  if (isXs)
    size = 'small';

  if (isAdmin)
    // return <IconButton onClick={() => alert('click')} icon={<SupervisorAccountSharpIcon fontSize='large' />} />;
    // <IconButton onClick={() => alert('click')} className={ classes.greyIconButton } icon={<CodeRoundedIcon fontSize='large' />} />
    return (
      // <Fade in={isAdmin} style={{ transitionDelay: isAdmin ? '3000ms' : '0ms' }} >
      // <Fade in={isAdmin} style={{ transitionDelay: isAdmin ? '3000ms' : '0ms' }} >
      <IconButton
        // ref={props.forwardRef}
        name='admin-icon-button'
        aria-label="admin button"
        size={size}
        onClick={() => props.history.push('/admin')}
        icon={<SupervisorAccountSharpIcon fontSize={fontSize} />}
      />
      // </Fade>
    );
  else
    return null;
}

// export default AdminIconButton;
// export default React.forwardRef((props, ref) => <AdminIconButton {...props} forwardedRef={ref} />);

// DevIconButton.propTypes = {
//   open: PropTypes.bool,
//   handleOpenDrawer: PropTypes.func,
// };
