import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    width: theme.spacing(180/8),
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ComponentListItem(props) {
  let { handleListItemClick, selectedIndex, index, primary } = props;
  return (
    <ListItem
    button
    selected={selectedIndex === index}
    onClick={(event) => handleListItemClick(event, index)}
  >
    <ListItemText primary={primary} />

  </ListItem>
  );
}

export function ComponentList(props) {
  let { selectedIndex, handleListItemClick } = props;
  const classes = useStyles();
  // const [selectedIndex, setSelectedIndex] = React.useState(1);

  // const handleListItemClick = (event, index) => {
  //   setSelectedIndex(index);
  // };

  let listItemProps = { selectedIndex, handleListItemClick };

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders" dense={true} >
        <ComponentListItem {...listItemProps} primary='Progress' index={'progress'} />
        <ComponentListItem {...listItemProps} primary='Buttons' index={'buttons'} />
        <ComponentListItem {...listItemProps} primary='Links' index={'links'} />
        <ComponentListItem {...listItemProps} primary='Progress' index={3} />

        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          {/* <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon> */}
          <ListItemText primary="Drafts" />
        </ListItem>
      {/* </List>
      <Divider />
      <List component="nav" aria-label="secondary mailbox folder"> */}
        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemText primary="Trash" />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemText primary="Spam" />
        </ListItem>
      </List>
    </div>
  );
}
