import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
// import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { LineChart } from 'recharts/es6/chart/LineChart';
import { Line } from 'recharts/es6/cartesian/Line';
import { XAxis } from 'recharts/es6/cartesian/XAxis';
import { YAxis } from 'recharts/es6/cartesian/YAxis';
import { Label } from 'recharts/es6/component/Label';
import { ResponsiveContainer } from 'recharts/es6/component/ResponsiveContainer';
import theme from '@org/client-libs-themes';

// https://github.com/mui-org/material-ui/tree/master/docs/src/pages/getting-started/templates/dashboard

const localStyles = {
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  fixedHeight: {
    height: 400,
  },
  yAxisLabel: { whiteSpace: 'normal', textAnchor: 'middle', fill: theme.palette.text.primary },
};

const useStyles = makeStyles(localStyles);

// // Generate Sales Data
// function createData(date, amount) {
//   return { date, amount };
// }

// const data = [
//   createData('Dec \'19', 100),
//   createData('Jan \'20', 101),
//   createData('Feb \'20', 101.75),
//   createData('Mar \'20', 103),
//   createData('Apr \'20', 103.5),
//   createData('May \'20', 104.5),
//   createData('Jun \'20', 105.25),
//   createData('Jul \'20', 106),
//   createData('Aug \'20', 107.5),
//   createData('Sep \'20', 108.5),
//   createData('Oct \'20', 109.75),
//   createData('Nov \'20', 110.5),
//   // createData('Dec \'20', null),
//   // createData('15:00', 2000),
//   // createData('18:00', 2400),
//   // createData('21:00', 2400),
//   // createData('24:00', undefined),
// ];

function Title(props) {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom style={{ textAlign: 'center' }} >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};

export function Chart(props) {
  let classes = useStyles();
  // const theme = useTheme();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={fixedHeightPaper} >
      <Title>{'Year-to-Date Performance'}</Title>
      <ResponsiveContainer>
        <LineChart
          // height='100%' width='100%'
          margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
        >
          <YAxis stroke={theme.palette.text.secondary} type="number" domain={[100, 110]} >
            <Label position="left"  offset={0} angle={270} className={classes.yAxisLabel} >
              {'Portfolio Value ($1,000s)'}
            </Label>
          </YAxis>
          <XAxis dataKey="date" stroke={theme.palette.text.secondary} >
            <Label position='bottom' offset={0} >{'Coming soon...'}</Label>
          </XAxis>
          <Line type="linear" dataKey="amount" dot={{stroke: theme.palette.primary.main, r: 2}} data={props.data} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
