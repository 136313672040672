import { Handler } from '@org/client-libs-classes';
import { authorizedClient } from './client';
import { authorizedSchema } from './schema';

export class AuthorizedHandler extends Handler {
  constructor(props) {
    super({ ...props, client: authorizedClient });
    this.schema = authorizedSchema;
  }

  async pingAuthorized() {
    let params = {
      query: this.schema.PING_AUTHORIZED,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async downloadFile(input) {
    let params = {
      query: this.schema.DOWNLOAD_USER_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async batchDeleteTransactions(input) {
    let params = {
      mutation: this.schema.BATCH_DELETE_TRANSACTIONS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createCashflow(input) {
    let params = {
      mutation: this.schema.CREATE_CASHFLOW,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createDeposit(input) {
    let params = {
      mutation: this.schema.CREATE_DEPOSIT,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createLoan(input) {
    let params = {
      mutation: this.schema.CREATE_LOAN,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createNote(input) {
    let params = {
      mutation: this.schema.CREATE_NOTE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createPayments(input) { // rename batchCreatePayments???
    let params = {
      mutation: this.schema.CREATE_PAYMENTS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createTrade(input) {
    let params = {
      mutation: this.schema.CREATE_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async createWithdrawal(input) {
    let params = {
      mutation: this.schema.CREATE_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteCashflow(input) {
    let params = {
      mutation: this.schema.DELETE_CASHFLOW,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteDeposit(input) {
    let params = {
      mutation: this.schema.DELETE_DEPOSIT,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteEmail(input) {
    let params = {
      mutation: this.schema.DELETE_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteFile(input) {
    let params = {
      mutation: this.schema.DELETE_USER_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteLoan(input) {
    let params = {
      mutation: this.schema.DELETE_LOAN,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteNote(input) {
    let params = {
      mutation: this.schema.DELETE_NOTE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deletePayment(input) {
    let params = {
      mutation: this.schema.DELETE_PAYMENT,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteTrade(input) {
    let params = {
      mutation: this.schema.DELETE_TRADE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteTransaction(input) {
    let params = {
      mutation: this.schema.DELETE_TRANSACTION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async deleteUser(input) {
    let params = {
      mutation: this.schema.DELETE_USER,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }
  
  async getEmail(input) {
    let params = {
      query: this.schema.GET_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getInvitation(input) {
    let params = {
      query: this.schema.GET_INVITATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getLoan(input) {
    let params = {
      query: this.schema.GET_LOAN,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getNote(input) {
    let params = {
      query: this.schema.GET_NOTE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async getSession(input) {
    let params = {
      query: this.schema.GET_SESSION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }
  
  async getTransaction(input) {
    let params = {
      query: this.schema.GET_TRANSACTION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async initiateInvitation(input) {
    let params = {
      mutation: this.schema.INITIATE_INVITATION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async listCashflows(input) {
    let params = {
      query: this.schema.LIST_CASHFLOWS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listDeposits(input) {
    let params = {
      query: this.schema.LIST_DEPOSITS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listLoans(input) {
    let params = {
      query: this.schema.LIST_LOANS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listInvitations(input) {
    let params = {
      query: this.schema.LIST_INVITATIONS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listNotes(input) {
    let params = {
      query: this.schema.LIST_NOTES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listPayments(input) {
    let params = {
      query: this.schema.LIST_PAYMENTS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listSessions(input) {
    let params = {
      query: this.schema.LIST_SESSIONS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listTables() { // Do not copy, data response is not typical
    let params = {
      query: this.schema.LIST_RDS_TABLES,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listTrades(input) {
    let params = {
      query: this.schema.LIST_TRADES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listTransactions(input) {
    let params = {
      query: this.schema.LIST_TRANSACTIONS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listUsers(input) {
    let params = {
      query: this.schema.LIST_USERS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listUserEmails(input) {
    let params = {
      query: this.schema.LIST_USER_EMAILS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async listUserFiles(input) {
    let params = {
      query: this.schema.LIST_USER_FILES,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async migrateInvitations() {
    let params = {
      mutation: this.schema.MIGRATE_INVITATIONS,
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async migrateSessions() {
    let params = {
      mutation: this.schema.MIGRATE_SESSIONS,
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async migrateUsers() {
    let params = {
      mutation: this.schema.MIGRATE_USERS,
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async migrateRecords(input) {
    let params = {
      mutation: this.schema.MIGRATE_RECORDS,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async testEmail(input) {
    let params = {
      query: this.schema.TEST_EMAIL,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async testError(input) {
    let params = {
      query: this.schema.TEST_ERROR,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async updateLoan(input) {
    let params = {
      mutation: this.schema.UPDATE_LOAN,
      variables: { input: input },
    };
    return this.mutate(params);
  }

  async updateNote(input) {
    let params = {
      mutation: this.schema.UPDATE_NOTE,
      variables: { input: input },
    };
    return this.mutate(params);
  }

  async createExtension(input) {
    let params = {
      mutation: this.schema.PG_CREATE_EXTENSION,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async closeConnections() {
    let params = {
      mutation: this.schema.CLOSE_CONNECTIONS,
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

  async listActivity(input) {
    let params = {
      query: this.schema.LIST_ACTIVITY,
      variables: { input: input },
    };
    return this.query(params);
  }

  async listConnections() { // Do not copy, data response is not typical
    let params = {
      query: this.schema.LIST_RDS_CONNECTIONS,
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async describeTable(input) { // Do not copy, data response is not typical
    let params = {
      query: this.schema.DESCRIBE_RDS_TABLE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.query(params);
  }

  async migrateTransactionsTable() {
    let params = {
      mutation: this.schema.MIGRATE_TRANSACTIONS_TABLE,
    };
    return this.mutate(params);
  }

  async migrateActivityTable() {
    let params = {
      mutation: this.schema.MIGRATE_ACTIVITY_TABLE,
    };
    return this.mutate(params);
  }
  
  async migrateCashflowsTable() {
    let params = {
      mutation: this.schema.MIGRATE_CASHFLOWS_TABLE,
    };
    return this.mutate(params);
  }

  async uploadFile(input) { // Do not copy, data response is not typical
    let params = {
      mutation: this.schema.UPLOAD_FILE,
      variables: { input: input },
      fetchPolicy: "no-cache",
    };
    return this.mutate(params);
  }

}

// Needed for offline tests
export const authorizedHandler = new AuthorizedHandler({ location: {}, history: {} });
