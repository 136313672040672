import React from 'react';
import PropTypes from 'prop-types';
// import { ApolloProvider } from '@apollo/react-hooks';
// import { ApolloProvider } from '@apollo/client';
// import { useTheme, makeStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import { default as MuiAppBar } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { publicResolvers } from '@org/client-graphql';
// import { InLinkWrapper } from '@org/client-components-core';
// import { AppBarBrand } from '@org/client-components-custom';
import { AppBarBrand } from './AppBarBrand';
import { MainDrawer } from './MainDrawer';
import { UserDrawer } from './UserDrawer';
// import MainIconButton from './MainIconButton';
import { UserIconButton } from './UserIconButton';
import { DevIconButton } from './DevIconButton';
import { AdminIconButton } from './AdminIconButton';
import { MainAppBarLinkList } from './MainAppBarLinkList';
import { appBarStyles } from './appBarStyles';

const useStyles = makeStyles(appBarStyles);

export async function isAuthenticated() {
  return await publicResolvers.isAuthenticated();
}

export function AppBar(props) {
  const classes = useStyles();
  // const theme = useTheme();
  // const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  // // const isAuthenticated = publicResolvers.isAuthenticated();
  // // const isDeveloper = publicResolvers.isDeveloper();
  // // const isAdministrator = publicResolvers.isAdministrator();
  // const isAuthenticated = publicHandler.isAuthenticated();
  // const isDeveloper = publicHandler.isDeveloper();
  // const isAdministrator = publicHandler.isAdministrator();
  let { mainDrawerProps, userDrawerProps } = props;
  let { mainDrawerOpen, handleOpenMainDrawer } = props;
  let { userDrawerOpen, handleOpenUserDrawer } = props;

  let mainIconButtonProps = { open: mainDrawerOpen, handleOpenDrawer: handleOpenMainDrawer };
  let userIconButtonProps = { open: userDrawerOpen, handleOpenDrawer: handleOpenUserDrawer };
  let appBarBrandProps = { mainIconButtonProps };

  return (
    <>
      <MuiAppBar id='app-bar' position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: mainDrawerOpen, }, { [classes.appBarShiftRight]: userDrawerOpen }, { [classes.appBarShiftBoth]: mainDrawerOpen && userDrawerOpen, }, )} >
        <Toolbar className={classes.toolbar} >
          {/* <MainIconButton {...mainIconButtonProps} /> */}

          <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', grow: '1', width: '100%' }} >
            {/* <span className={classes.appBarTitleWrapper} >
              <img src={'/cubit4.svg'} alt='cubit4.s' height={30} color={'white'} className={classes.appBarLogo} />
              <InLink to='/' title={process.env.REACT_APP_SHORT_NAME} variant="h3" color="inherit" noWrap className={classes.appBarBrand} underline='none' />
            </span> */}

            {/* <span className={classes.appBarTitleWrapper} >
              <MainIconButton {...mainIconButtonProps} />
              <InLinkWrapper to='/' underline='none' style={{ display: 'flex', alignItems: 'center', color: 'white'}} >
                {!isSmall &&
                <img src={'/cubit4.svg'} alt='cubit4.s' height={30} color={'white'} className={classes.appBarLogo} />}
                <Typography variant="h3" color="inherit" className={classes.appBarBrand} >
                  {process.env.REACT_APP_SHORT_NAME}
                </Typography>
              </InLinkWrapper>
            </span> */}
            <AppBarBrand {...appBarBrandProps} />

            {/* <ApolloProvider client={publicClient} > */}
              <MainAppBarLinkList pathname={props.pathname} />
            {/* </ApolloProvider> */}

            <span id='app-bar-icons' className={classes.userIconButtonWrapper} >
              {/* <ApolloProvider client={publicClient} > */}
                <DevIconButton {...props} />
                <AdminIconButton {...props} />
                <UserIconButton {...userIconButtonProps} {...props} />
              {/* </ApolloProvider> */}
            </span>
          </span>
        </Toolbar>
      </MuiAppBar>
      <div className={classes.offset} />
      <MainDrawer {...mainDrawerProps} />
      <UserDrawer {...userDrawerProps} />
    </>
  );
}

export default AppBar;

AppBar.propTypes = {
  mainDrawerProps: PropTypes.shape(),
  userDrawerProps: PropTypes.shape(),
  mainDrawerOpen: PropTypes.bool,
  handleOpenMainDrawer: PropTypes.func,
  userDrawerOpen: PropTypes.bool,
  handleOpenUserDrawer: PropTypes.func,
  pathname: PropTypes.string,
};
