import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup/es';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  FormUp2, PrimaryButton, TextField, EmailField, TextArea, SmallLinkButton, AddFab,
} from '@org/client-components-core';
import { 
  FlexRow, Heading, FormLayout, FormMessage, FormError,
} from '@org/client-components-custom';
import { DisplayState } from '@org/client-components-utils';
import { AuthorizedHandler } from '@org/client-graphql';
import { InvitationClass } from '@org/common-classes/Invitation';
import { firstNameShape, lastNameShape, emailShape } from '@org/common-yup';
import theme from '@org/client-libs-themes';
import { styles } from '@org/client-libs-styles';

let invitationClass;

let initialValues;

let requiredValues;

let validationShape = {
  firstName: firstNameShape,
  lastName: lastNameShape,
  email: emailShape,
    // .required("Email is required"),
  message: yup.string().trim(),
};
const validationSchema = yup.object().shape(validationShape);

let authorizedHandler;

const useStyles = makeStyles(styles);

let isMounted = false;

export function CreateInvitation(props) {
  // console.log('CreateInvitation', 'props', props);
  const [ state, setState ] = useState({
    isLoading: true, isSubmitting: false, isCompleted: false, formMessage: null, formError: null,
  });

  let classes = useStyles();

  if (!authorizedHandler)
    authorizedHandler = new AuthorizedHandler({ location: props.location, history: props.history });

  if (state.isLoading) {
    let input = {
      // firstName: "z",
      message: "Name,\n\nI hope this note finds you well. I wanted to show you the project I’ve been working on for some time, and invite you to be a demo user. I still have some work to do but I'm finally getting close to being ready to launch. And hopefully you will be interested in using the site when we goes live!\n\nThanks,\n\nZach",
    };
    invitationClass = new InvitationClass(input);

    initialValues = invitationClass.values();
    // console.log('CreateInvitation', 'initialValues', initialValues);
    requiredValues = invitationClass.requiredValues;
  }

  useEffect(() => {
    isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false});

    return () => { isMounted = false; };
  }, []);

  function handleAddClick(props) {
    // console.log('handleAddClick', props);

    props.resetForm();
    setState({ ...state, isCompleted: false, formMessage: null, formError: null });
  }

  async function handleSubmit(event, form) {
    // console.log('CreateInvitation', 'handleSubmit', event, form);
    setState({ ...state, isSubmitting: true, formError: null });

    try {
      invitationClass.update(form.values);
      let input = invitationClass.getInput();
      console.log('CreateInvitation', 'handleSubmit', 'input', input);

      let response = await authorizedHandler.initiateInvitation(input);
      console.log('CreateInvitation', 'handleSubmit', 'response', response);
      // await authorizedHandler.initiateInvitation(input);

      setState({ ...state, isSubmitting: false, isCompleted: true, formMessage: 'Invitation successfully created', formError: null });
    } catch (error) {
      // console.error('CreateInvitation', error);
      setState({ ...state, isSubmitting: false, formMessage: null, formError: error });
    }
  }

  return (
    <>
    <FormUp2
      id='create-invitation-form'
      {...props}
      initialValues={initialValues}
      requiredValues={requiredValues}
      validationSchema={validationSchema}
      // calculateValues={calculateValues}
    >
      {formProps => {
      {/* let enabled = (props.isValidated && !state.isSubmitting && !state.isCompleted); */}
      let { fieldProps } = formProps;
      {/* console.log('CreateInvitation', 'fieldProps', fieldProps); */}
      let fieldEnabled = !state.isLoading && !state.isSubmitting && !state.isCompleted;
      {/* let addEnabled = state.isCompleted; */}
      let addEnabled = true;
      let submitEnabled = fieldEnabled && formProps.isValidated;
      return (
        <>
        <FormLayout>

          <FlexRow style={{ justifyContent: 'space-between', margin: theme.spacing(0, 0, 1, 0) }} >
            {/* <InLink to='/invest' title='Back' /> */}
            <SmallLinkButton {...props} title='Back' to='/admin' />
            {/* <AddFab onClick={handleAddClick} disabled={!addEnabled} /> */}
            <AddFab onClick={() => handleAddClick(formProps)} disabled={!addEnabled} />
          </FlexRow>

          {/* <HeadingWrapper>
            <HeadingLeft>
              <BackIconButton {...props.submitProps} />
            </HeadingLeft>
            <HeadingCenter>
              <HeadingTitle style={{whiteSpace: 'normal', textAlign: 'center'}} >{'Create Invitation'}</HeadingTitle>
            </HeadingCenter>
            <HeadingRight>
              <AddIconButton {...props.submitProps} handleClick={handleAddClick} />
            </HeadingRight>
          </HeadingWrapper> */}
          <Heading>{'Create Invitation'}</Heading>

          <Grid style={{width: '100%'}} >

            {state.formMessage &&
            <Grid item >
              <FormMessage message={state.formMessage} />
            </Grid>}

            {state.formError &&
            <Grid item >
              <FormError message={state.formError.message} />
            </Grid>}

            <Grid item >
              <TextField
                { ...fieldProps }
                name='firstName'
                type='string'
                label='First Name'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <TextField
                { ...fieldProps }
                name='lastName'
                type='string'
                label='Last Name'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <EmailField
                { ...fieldProps }
                name='email'
                // type='email'
                // label='Email'
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item >
              <TextArea
                { ...fieldProps }
                name='message'
                // type='string'
                label='Message'
                multiline={true}
                // rows={3}
                required={true}
                disabled={!fieldEnabled}
              />
            </Grid>

            <Grid item className={clsx(classes.centered, classes.formButtonSpan)} >
              <PrimaryButton {...props.submitProps} title='Submit' disabled={!submitEnabled}
                onClick={(event) => handleSubmit(event, formProps)} />
            </Grid>

          </Grid>

        </FormLayout>

        <DisplayState title='state' state={{ state, form: formProps }} open={true} />
        </>
      );
      }}
    </FormUp2>
    </>
  );
}

export default CreateInvitation;

CreateInvitation.propTypes = {
  location: PropTypes.shape(),
  history: PropTypes.shape(),
  submitProps: PropTypes.shape(),
  resetForm: PropTypes.func,
};
