import { ApolloLink } from '@apollo/client';
// import { ApolloLink } from 'apollo-link';

const { REACT_APP_IS_OFFLINE } = process.env;

// sessionCookie and the getter and setter are for offline end-to-end testing only
// let cookies = [];
let sessionCookie = "";

export const getSessionCookie = () => {
  return sessionCookie;
};

// setSessionCookie("") will delete any existing session cookie
export const setSessionCookie = (value) => {
  sessionCookie = value;
};

// `offlineLink` is used to handle cookies when running offline end-to-end tests.
// Because we're using Apollo, we aren't calling fetch directly, so we don't
// have access to the response headers when fetch is called.
// Cookies in response headers are set on: headers.set-cookie = [ cookie1, ... ]
export const offlineLink = new ApolloLink((operation, forward) => {
  // console.log('offlineLink', 'operation', operation);
  // console.log('offlineLink', 'forward', forward);

  return forward(operation).map(response => {
    // console.log('offlineLink', 'response', response);
    // console.log('testLink', REACT_APP_IS_OFFLINE, REACT_APP_HTTP_URI);
    if (REACT_APP_IS_OFFLINE) {
      let context = operation.getContext();
      // console.log('offlineLink', 'context.response.headers', context.response.headers);
      // console.log('offlineLink', 'context.response.headers[\'set-cookie\']', context.response.headers['set-cookie']);
      // if (context.response.headers['set-cookie'])
      //   sessionCookie = context.response.headers.get('set-cookie');
      try {
        let cookies = context.response.headers.get('set-cookie');
        console.warn('offlineLink', 'cookies', cookies);
        if (typeof cookies == 'string')
          sessionCookie = cookies;
      } catch (error) {
        // console.log(`[ offlineLink Error ]: ${error}`);
        console.error('offlineLink', error);
      }
      // URGENT Does more than one cookie come in an array?
      // console.log('testLink', 'sessionCookie', sessionCookie, typeof sessionCookie);
      // let resolver = Object.keys(response.data)[0];
      // response.data[resolver].cookie = cookie;
      // console.log('offlineLink', 'sessionCookie', sessionCookie);
    }
    return response;
  }); 

});