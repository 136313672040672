import { RetryLink } from "@apollo/client/link/retry";
// import { RetryLink } from 'apollo-link-retry';

export const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    // console.log('retryLink', count, operation, error);
    // max: 0,
    // retryIf: (error, _operation) => !!error,
    return !!error && operation.operationName != 'specialCase';
  },
  delay: (count, operation, error) => { // eslint-disable-line
    // console.log('retryLink', count, operation, error);
    return count * 1000 * Math.random();
  },
});