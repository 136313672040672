import { ulid } from "ulid";

/**
 * RecordClass
 * 
 * This is the basis for all the classes which are used to create DynamoDB
 * query inputs.
 */

export class RecordClass {
  constructor(input) {
    console.info(this.constructor.name, 'Class constructor', input);

    this.table = null;

    this.isValidated = false;

    // this.keys = {};
    this.keys = {
      id: input?.id ? input?.id : ulid(),
    };

    this.attributes = {};

    this.requiredValues = {};
  }

  values() {
    // URGENT validate RecordClass as soon as yup in working in node
    // if (!this.isValidated)
    //   // throw new Error(`${this.constructor.name} has not been validated!`);
    //   console.warn(`${this.constructor.name} has not been validated!`);

    return { ...this.attributes, ...this.keys };
  }

  async validate() {
    // let values = { ...this.attributes, ...this.keys };
    // console.log('${this.constructor.name}', 'validate', values);

    // // URGENT Fix yup import in Node. Why did this suddenly stop working???
    // console.warn(`${this.constructor.name}: validate not working due to issues loading yup...`);
    // if (this?.schema) {
    //   try {
    //     await this?.schema?.validate(values);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
    // else
    //   console.warn(`Cannot validate '${this.constructor.name}', no schema is set!`);

    this.isValidated = true;
  }

  // allow any values to be passed into class construction and
  // just ignore them if they are in values
  initialize(input) {
    let { itemType, id } = input; // eslint-disable-line
    Object.keys(input).forEach(name => {
      if (this.attributes[name] !== undefined)
        this.attributes[name] = input[name];
      else
        console.warn(`${this.constructor.name} initialize: ${name} found in input but not in attributes. Ignoring...`)
    });
  }

  update(input) {
    if (input) {
      // console.log(`${this.constructor.name} update attributes: '${JSON.stringify(this.attributes)}'`);
      // console.log(`${this.constructor.name} update input: '${JSON.stringify(input)}'`);
      Object.keys(input).forEach(name => {
        // console.log(`${this.constructor.name} update name: '${name}' value: '${input[name]}'`);
        if (name !== 'id' && name !== 'itemType') {
          if (this.attributes[name] != null)
            this.attributes[name] = input[name];
          else
            throw new Error(`${this.constructor.name} '${name}' not found in attributes`);
        }
      });

      if (this.calculateValues)
        return this.calculateValues(this.attributes);
      else
        return this.attributes;
    }
  }

  // async create() {
  //   if (!this.table)
  //     throw new Error(`${this.constructor.name} does not have a value for 'table'!`);

  //   await this.validate();
  //   let values = this.values();
  //   this.table.put(values);
  // }
}
