import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Page, Heading, Section, SectionBody, Bullet } from '@org/client-components-custom';

export function About () {
  const [ state, setState ] = useState({ isLoading: true });

  useEffect(() => {
    let isMounted = true;
    window.scrollTo(0, 0);

    if (isMounted)
      setState({ ...state, isLoading: false });
    
    return () => { isMounted = false; };
  }, []);

  return (
    <Page id='company-about' >

      <Heading>{'About Us'}</Heading>

      {/* <Section>
        <SectionHeading>{'The Right Team at The Right Time'}</SectionHeading>
      </Section> */}

      <Section>
        <SectionBody>
          {`We’re life-long credit investors, passionate about technology, and determined to democratize credit investing.`}
        </SectionBody>
      </Section>

      <Section>
        <SectionBody>
          {'More than 25 years of the right experience:'}<br/>
          <Bullet>{'Managing multi-billion dollar funds, levered funds, seperately managed accounts, and a private REIT'}</Bullet>
          <Bullet>{'Investing in all types of credit investment structures including MBS, CDOs, CLOs, and whole loans'}</Bullet>
          <Bullet>{'Investing in numerous asset classes including residential and commercial real estate, equipment loans, merchant cash advances, franchise loans, cell towers, auto loans, student loans, containers, airplanes, and many others'}</Bullet>
          <Bullet>{'Structuring a variety of securitizations including agency and private label MBS, across several asset classes including residential real estate, commercial real estate, and franchise loans'}</Bullet>
          <Bullet>{'Lending and origination via direct loan origination programs, forward flow loan purchase programs, and lending partnerships'}</Bullet>
          <Bullet>{'Developing large scale applications, software development project management, and investment company infrastructure management'}</Bullet>
          <Bullet>{'Legal and regulatory experience through several fund launches and originations of other structures and investment vehicles'}</Bullet>
        </SectionBody>
      </Section>

    </Page>
  );
}

About.propTypes = {
  system: PropTypes.shape(),
};
