import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
// import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@org/client-components-core';
// import { default as MuiTextField } from '@material-ui/core/TextField';
// import { CheckAdornment } from '@org/client-components-custom';
// import { styles } from '@org/client-libs-styles';

// const useStyles = makeStyles((theme) => ({
//   select: {
//     width: theme.spacing(40),
//     maxWidth: theme.spacing(40),
//   },
// }));

export function SelectTextField(props) {
  // console.log('SelectTextField', props);
  let { items } = props;
  // const classes = useStyles();
  // let value = values[name];

  return(
    // <TextField select endAdornment={<></>} {...rest} handleChange={(event) => props.handleChange(event, 'select')} >
    <TextField select endAdornment={<></>} {...props} >
      {/* {props.stateProps.values.emails.map((option, index) => ( */}
      {items.map((elem, index) => (
        /* <MenuItem key={index} value={elem.value} > */
        <MenuItem key={elem.value} value={elem.value} >
          {elem.label}
        </MenuItem>
      ))}
    </TextField>
  );

  // return(
  //   <MuiTextField select endAdornment={<></>} onChange={handleChange} name={name} value={value} >
  //     {items.map((elem, index) => (
  //       <MenuItem key={elem.value} value={elem.value} >
  //         {elem.label}
  //       </MenuItem>
  //     ))}
  //   </MuiTextField>
  // );
}

SelectTextField.propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.array,
  handleChange: PropTypes.func,
};
